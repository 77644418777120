import { Chip, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { has } from "ramda";
import { styled } from "@mui/material/styles";

const StyledChip = styled(Chip)(() => ({
  backgroundColor: "#EF4068",
  margin: "0 8px",
  color: "white",
  letterSpacing: "0.16px",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "18px",
  minWidth: "45px",
  height: "20px",
  ".MuiChip-label": {
    padding: "1px 2px",
  },
}));

export default ({ navigationItem }) => {
  const ItemText = (
    <ListItemText>
      {navigationItem.text}
      {has("chip")(navigationItem) && (
        <StyledChip
          label={navigationItem.chip.label}
          sx={navigationItem.chip.style}
        />
      )}
    </ListItemText>
  );

  return has("icon")(navigationItem) ? (
    <>
      <ListItemIcon
        sx={has("placeholder")(navigationItem) ? { color: "#00000038" } : {}}
      >
        <navigationItem.icon />
      </ListItemIcon>
      {ItemText}
    </>
  ) : (
    ItemText
  );
};
