import React, { useState } from "react";
import { juxt, path, pipe } from "ramda";

import Checkbox from "@mui/material/Checkbox";
import DebounceInput from "react-debounce-input";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export default ({
  doSearch,
  onlyChanges,
  setOnlyChanges,
  onlyChangesEnabled,
}) => {
  const [searchValue, setSearchValue] = useState("");
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={4}>
        <DebounceInput
          element={TextField}
          minLength={2}
          debounceTimeout={1000}
          margin="normal"
          label="Search"
          variant="outlined"
          fullWidth
          value={searchValue}
          onChange={pipe(
            path(["target", "value"]),
            juxt([setSearchValue, doSearch])
          )}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyChanges}
              onChange={({ target: { checked } }) => setOnlyChanges(checked)}
              name="onlyChanges"
              color="primary"
              disabled={!onlyChangesEnabled}
            />
          }
          label="Show only changes"
        />
      </Grid>
    </Grid>
  );
};
