import { Box, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import DateIcon from "@mui/icons-material/DateRangeOutlined";
import DateSelector from "./DateSelector";
import DateTimeContext from "../../contexts/DateTimeContext";
import { not } from "ramda";

export default ({ isWithLimitByTime }) => {
  const { fromDate, timeZoneMoment, toDate } = useContext(DateTimeContext);

  const [dateSelectionOpen, setDateSelectionOpen] = useState(false);
  const [dateSelectionAnchorEl, setDateSelectionAnchorEl] = useState(null);

  const dateFormat = "MMM D z";
  const selectedDateRange = `${timeZoneMoment(fromDate).format(
    dateFormat
  )} - ${timeZoneMoment(toDate).format(dateFormat)}`;

  const handleDatePickerOpen = ({ currentTarget }) => {
    setDateSelectionAnchorEl(currentTarget);
    setDateSelectionOpen(not);
  };

  return (
    <>
      <Box
        sx={{
          fontSize: "14px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          display: "inline-flex",
        }}
        data-testid="selected-date-range"
      >
        {selectedDateRange}
      </Box>
      <IconButton color="inherit" onClick={handleDatePickerOpen} size="large">
        <DateIcon />
      </IconButton>
      <DateSelector
        limitByTime={isWithLimitByTime}
        isOpen={dateSelectionOpen}
        onClose={() => setDateSelectionOpen(false)}
        anchorElement={dateSelectionAnchorEl}
      />
    </>
  );
};
