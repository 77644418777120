import {
  ACCEPTING_NEW_PATIENTS,
  EXPERTISE,
  ONLINE_SCHEDULING,
  PHONE,
  PROFESSIONAL_SUFFIX,
  PROFILE_LINK,
  Relations,
  SPECIALTY,
  SUBSPECIALTY,
} from "../../../../triplets";
import { Avatar, Box, Button, Divider, Tooltip } from "@mui/material";
import {
  CTA_ELEMENT,
  DESCRIPTION_ELEMENT,
  SUBTITLE_ELEMENT,
  TAG_ELEMENT,
} from "./Constants";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import React from "react";
import sampleAvatar from "../../../../static/images/sample_avatar.png";
import { styled } from "@mui/material/styles";

const PREFIX = "ResultCardPreview";

const classes = {
  resultCardPreview: `${PREFIX}-resultCardPreview`,
  tagsContainer: `${PREFIX}-tagsContainer`,
  tag: `${PREFIX}-tag`,
  ctaButton: `${PREFIX}-ctaButton`,
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
  contactText: `${PREFIX}-contactText`,
  contactRow: `${PREFIX}-contactRow`,
  contactIcon: `${PREFIX}-contactIcon`,
  tooltip: `${PREFIX}-tooltip`,
  avatar: `${PREFIX}-avatar`,
};

const Root = styled("div")(() => ({
  [`& .${classes.resultCardPreview}`]: {
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
    paddingBottom: 12,
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
    minHeight: 340,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.tagsContainer}`]: {
    marginTop: 20,
  },

  [`& .${classes.tag}`]: {
    backgroundColor: "rgba(81, 45, 109, 0.1)",
    borderRadius: "2px",
    fontFamily: "roboto",
    fontSize: 13,
    color: "#512D6D",
    fontWeight: 400,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 4,
    paddingRight: 4,
  },

  [`& .${classes.ctaButton}`]: {
    backgroundColor: "#512D6D",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#512D6D",
      color: "#FFFFFF",
    },
    marginTop: 14,
    width: "100%",
  },

  [`& .${classes.title}`]: {
    marginTop: 13,
    fontSize: 17,
    fontWeight: 500,
    fontFamily: "roboto",
    color: "#353535",
  },

  [`& .${classes.text}`]: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "roboto",
    color: "#353535",
  },

  [`& .${classes.contactText}`]: {
    color: "#6E6E6E",
    fontSize: 13,
    fontWeight: 400,
  },

  [`& .${classes.contactRow}`]: {
    marginTop: 13,
    marginBottom: 13,
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
  },

  [`& .${classes.contactIcon}`]: {
    paddingLeft: 0,
    paddingRight: 8,
    fontSize: 24,
  },

  [`& .${classes.tooltip}`]: {
    fontSize: 14,
    fontWeight: "normal",
  },

  [`& .${classes.avatar}`]: {
    marginBottom: 2,
  },
}));

export default ({ renderConfig, focusedElement }) => {
  const sampleContent = {
    [SUBTITLE_ELEMENT]: {
      [PROFESSIONAL_SUFFIX]: "M.D.",
    },
    [DESCRIPTION_ELEMENT]: {
      [EXPERTISE]: "Internal Medicine",
      [SUBSPECIALTY]: "Cardiology",
      [SPECIALTY]: "General Practitioner",
    },
    [TAG_ELEMENT]: {
      [ACCEPTING_NEW_PATIENTS]: "Accepting New Patients",
      [ONLINE_SCHEDULING]: "Online Scheduling",
    },
    [CTA_ELEMENT]: {
      [PROFILE_LINK]: "Visit Page",
      [PHONE]: "Call Provider",
    },
  };

  return (
    <Root>
      <Box className={classes.resultCardPreview}>
        <Avatar
          alt="Sample Avatar"
          src={sampleAvatar}
          className={classes.avatar}
        />
        <Box display="flex" flexDirection="row">
          <span className={classes.title}>Jane Smith</span>
          {renderConfig[Relations.RENDER_TITLE] && (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              arrow
              title={SUBTITLE_ELEMENT}
              open={focusedElement === SUBTITLE_ELEMENT}
              placement="right-start"
            >
              <span className={classes.title}>{`, ${
                sampleContent[SUBTITLE_ELEMENT][
                  renderConfig[Relations.RENDER_TITLE]
                ]
              }`}</span>
            </Tooltip>
          )}
        </Box>

        {renderConfig[Relations.RENDER_TEXT] && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            arrow
            title={DESCRIPTION_ELEMENT}
            open={focusedElement === DESCRIPTION_ELEMENT}
            placement="left-start"
          >
            <div className={classes.text}>
              {
                sampleContent[DESCRIPTION_ELEMENT][
                  renderConfig[Relations.RENDER_TEXT]
                ]
              }
            </div>
          </Tooltip>
        )}

        <div>
          <div className={classes.contactRow}>
            <PhoneIcon className={classes.contactIcon} htmlColor="#512D6D" />
            <div className={classes.contactText}>(646) 962-3404</div>
          </div>
          <Divider />

          <div className={classes.contactRow}>
            <LocationOnIcon
              className={classes.contactIcon}
              htmlColor="#512D6D"
            />
            <div className={classes.contactText}>
              Convert Health UN Health Scheme...
              <br />
              3333 Ilias Creek Road, Wonstin...
            </div>
          </div>
          <Divider />
        </div>

        {renderConfig[Relations.RENDER_TAGS] && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            arrow
            title={TAG_ELEMENT}
            open={focusedElement === TAG_ELEMENT}
            placement="left-start"
          >
            <div className={classes.tagsContainer}>
              <span className={classes.tag}>
                {
                  sampleContent[TAG_ELEMENT][
                    renderConfig[Relations.RENDER_TAGS]
                  ]
                }
              </span>
            </div>
          </Tooltip>
        )}

        {renderConfig[Relations.RENDER_SUGGESTIONS] && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            arrow
            title={CTA_ELEMENT}
            open={focusedElement === CTA_ELEMENT}
            placement="left-start"
          >
            <Button className={classes.ctaButton} variant="contained">
              {renderConfig[Relations.RENDER_CTA_TEXT] ||
                sampleContent[CTA_ELEMENT][
                  renderConfig[Relations.RENDER_SUGGESTIONS]
                ]}
            </Button>
          </Tooltip>
        )}
      </Box>
    </Root>
  );
};
