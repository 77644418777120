import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  CUSTOMIZED_CODE_KEY,
  CUSTOMIZED_CODE_LABEL,
  MAPPING_METHOD_KEY,
  NEW_NAME_KEY,
  STATUSES,
  STATUS_KEY,
  STATUS_LABEL,
  TEMPLATE_KEY,
  TEMPLATE_LABEL,
} from "./Constants";
import { assoc, equals, isEmpty, not } from "ramda";
import { Alert } from "@mui/material";
import { HyroDialog } from "../../hyro-components";
import React from "react";

// Todo(Tom): Once we agree on the UI commonalities, generalize Dialog and TextField components and remove the new_name and the name condition.
export default ({ editedRow, setEditedRow, handleAddRow }) => {
  const setEditedRowField =
    (field) =>
    ({ target: { value } }) =>
      setEditedRow(assoc(field, value));
  return (
    <HyroDialog
      saveDisabledOverride={false}
      fieldSpec={[
        {
          component: (
            <>
              {not(Boolean(editedRow.name)) && (
                <Box mt={1} mb={1}>
                  <TextField
                    fullWidth
                    placeholder="My attribute name"
                    variant="outlined"
                    label="Attribute name"
                    value={editedRow.new_name}
                    onChange={setEditedRowField(NEW_NAME_KEY)}
                  />
                </Box>
              )}
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  value={
                    editedRow.mapping_method
                      ? editedRow.mapping_method
                      : TEMPLATE_LABEL
                  }
                  onChange={setEditedRowField(MAPPING_METHOD_KEY)}
                >
                  <FormControlLabel
                    value={TEMPLATE_LABEL}
                    control={<Radio color="primary" />}
                    label={TEMPLATE_LABEL}
                  />
                  {equals(editedRow.mapping_method, TEMPLATE_LABEL) ||
                  !editedRow.mapping_method ? (
                    <Box ml={4} mb={1}>
                      <TextField
                        fullWidth
                        placeholder={TEMPLATE_KEY}
                        variant="outlined"
                        label={TEMPLATE_KEY}
                        value={editedRow.template}
                        onChange={setEditedRowField(TEMPLATE_KEY)}
                      />
                    </Box>
                  ) : null}
                  <FormControlLabel
                    value={CUSTOMIZED_CODE_LABEL}
                    control={<Radio color="primary" />}
                    label={CUSTOMIZED_CODE_LABEL}
                  />
                  {equals(editedRow.mapping_method, CUSTOMIZED_CODE_LABEL) && (
                    <Box ml={4} mb={1}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Alert severity="info">
                            This requires further manual set up. Please make
                            sure to open a Jira ticket with the requirements.
                          </Alert>
                        </Grid>
                        <Grid item>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label={STATUS_LABEL}
                            value={editedRow.status}
                            onChange={setEditedRowField(STATUS_KEY)}
                          >
                            {STATUSES.map((status) => (
                              <MenuItem key={status} value={status}>
                                {status}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label={CUSTOMIZED_CODE_LABEL}
                            multiline
                            rows={8}
                            value={editedRow.customized_code}
                            onChange={setEditedRowField(CUSTOMIZED_CODE_KEY)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </RadioGroup>
              </FormControl>
            </>
          ),
        },
      ]}
      handleClose={() => setEditedRow({})}
      handleSubmit={handleAddRow}
      open={not(isEmpty(editedRow))}
      submitButtonText="Save"
      title={editedRow.name || "Add custom attribute"}
    />
  );
};
