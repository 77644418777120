import { Box } from "@mui/material";
import React from "react";
import emptyBoxIcon from "../static/images/empty_box.svg";

export default () => (
  <Box
    mt={4}
    mx={4}
    display="flex"
    flexDirection="column"
    height="calc(100vh - 64px)"
  >
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      alignItems="center"
      justifyContent="center"
    >
      <img width="100px" height="55px" src={emptyBoxIcon} />
      <Box
        fontStyle="normal"
        fontWeight={400}
        fontSize="16px"
        color="rgba(45, 44, 68, 0.7)"
        lineHeight="150%"
        m="16px"
      >
        Nothing here yet
      </Box>
    </Box>
  </Box>
);
