import React, { useState } from "react";
import {
  hasPermissionSelector,
  roomIdSelector,
  userPermissionsSelector,
} from "../../stores/selectors/appSelectors";
import { isEmpty, not } from "ramda";
import DashboardHyroLogo from "../Logos/DashboardHyroLogo";
import Drawer from "./Drawer";
import NavigationMenuButton from "./NavigationMenuButton";
import { filterItemByPermission } from "../../permissionsHandler";
import navigationItems from "./navigationItems";
import useAppStore from "../../stores/appStore";

export default () => {
  const hasPermission = useAppStore(hasPermissionSelector);
  const userPermissions = useAppStore(userPermissionsSelector);
  const selectedRoomId = useAppStore(roomIdSelector);
  const showNavigationMenuButton =
    not(isEmpty(userPermissions)) &&
    filterItemByPermission(
      userPermissions,
      hasPermission
    )(navigationItems(selectedRoomId)).length > 1;
  const [navigationDrawerOpen, setNavigationDrawerOpen] = useState(false);
  const handleNavigationDrawerClick = () => setNavigationDrawerOpen(not);
  return (
    <>
      {showNavigationMenuButton && (
        <NavigationMenuButton handleClick={handleNavigationDrawerClick} />
      )}
      <Drawer
        open={navigationDrawerOpen}
        setOpen={setNavigationDrawerOpen}
        handleClick={handleNavigationDrawerClick}
        navigationItems={navigationItems(selectedRoomId)}
        Logo={DashboardHyroLogo}
      />
    </>
  );
};
