import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  adaptV4Theme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import {
  T,
  always,
  any,
  anyPass,
  both,
  complement,
  cond,
  equals,
  evolve,
  filter,
  fromPairs,
  has,
  ifElse,
  isEmpty,
  isNil,
  juxt,
  map,
  not,
  pick,
  pipe,
  prop,
  propEq,
  props,
  values,
} from "ramda";
import { DebounceInput } from "react-debounce-input";
import { INPUT_TYPES } from "../../utils";

const renderErrors = (errors) =>
  errors ? (
    <>
      {map((err) => (
        <React.Fragment key={err}>
          {err}
          <br />
        </React.Fragment>
      ))(errors)}
    </>
  ) : (
    ""
  );

const dialogTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#3f51b5",
      },
      secondary: {
        main: "#F44336",
      },
    },
  })
);

export default ({
  saveDisabledOverride,
  fieldSpec,
  handleClose,
  handleSubmit,
  open,
  submitButtonText,
  submitButtonColor,
  title,
}) => {
  const initialData = pipe(
    filter(has("key")),
    map(props(["key", "defaultValue"])),
    fromPairs
  )(fieldSpec);
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [saveDisabled, setSaveDisabled] = useState(true);

  const onChange =
    (fieldName) =>
    ({ target: { value } }) => {
      setData({ ...data, [fieldName]: value });
    };

  useEffect(() => {
    setErrors(
      pipe(
        map(
          juxt([
            prop("key"),
            ifElse(
              has("validationFunction"),
              prop("validationFunction"),
              always(always([]))
            ),
          ])
        ),
        fromPairs,
        evolve
      )(fieldSpec)(data)
    );
  }, [data, fieldSpec, setErrors]);

  useEffect(() => {
    setSaveDisabled(
      anyPass([
        both(equals(initialData), complement(isEmpty)),
        pipe(
          pick(pipe(filter(prop("required")), map(prop("key")))(fieldSpec)),
          values,
          any(isEmpty)
        ),
      ])(data) || pipe(values, any(pipe(isEmpty, not)))(errors)
    );
  }, [errors, initialData, data, fieldSpec]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={isNil(open) ? true : open} maxWidth="sm" fullWidth>
          <DialogTitle sx={{ paddingX: "24px", paddingY: "24px" }}>
            {title}
          </DialogTitle>
          <DialogContent sx={{ paddingX: "24px" }}>
            {map(
              cond([
                [
                  propEq("inputType", INPUT_TYPES.TEXT),
                  (fieldSpec) => (
                    <Box mt="6px" mb="8px" key={fieldSpec.key}>
                      <DebounceInput
                        {...fieldSpec.props}
                        key={fieldSpec.key}
                        element={TextField}
                        error={pipe(isEmpty, not)(errors[fieldSpec.key])}
                        helperText={renderErrors(errors[fieldSpec.key])}
                        label={fieldSpec.label}
                        variant="outlined"
                        value={data[fieldSpec.key]}
                        placeholder={fieldSpec.label}
                        onChange={onChange(fieldSpec.key)}
                        fullWidth
                        debounceTimeout={700}
                      />
                    </Box>
                  ),
                ],
                [
                  propEq("inputType", INPUT_TYPES.DESCRIPTION),
                  (fieldSpec) => (
                    <Box key={fieldSpec.label} sx={{ color: "black" }}>
                      {fieldSpec.label}
                    </Box>
                  ),
                ],
                [T, prop("component")],
              ])
            )(fieldSpec)}
          </DialogContent>
          <DialogActions sx={{ paddingX: "24px", paddingY: "24px" }}>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={saveDisabled || saveDisabledOverride}
              color={submitButtonColor || "primary"}
              variant="contained"
              onClick={() => {
                handleSubmit(data, setData);
                handleClose();
              }}
            >
              {submitButtonText || "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
