export const accessTokenSelector = (state) => state.accessToken;
export const roomIdSelector = (state) => state.selectedRoomId;
export const roomsSelector = (state) => state.rooms;
export const userPermissionsSelector = (state) => state.userPermissions;
export const clientNameSelector = (state) => state.clientName;
export const changeRequestsSelector = (state) => state.changeRequests;
export const hasPermissionSelector = (state) => state.hasPermission;
export const userDataSelector = (state) => state.userData;
export const setRoomIdSelector = (state) => state.setSelectedRoomId;
export const setupAppSelector = (state) => state.setupApp;
export const getSetRoomIdSelector = (state) => [
  state.selectedRoomId,
  state.setSelectedRoomId,
];
export const isBotPreviewableSelector = (state) => state.isBotPreviewable;
