import create from "zustand";
import createAppSlice from "./slices/createAppSlice";
import createPreviewSlice from "./slices/createPreviewSlice";
import createTripletsSlice from "./slices/createTripletsSlice";
import { subscribeWithSelector } from "zustand/middleware";

const useAppStore = create(
  subscribeWithSelector((...args) => ({
    ...createAppSlice(...args),
    ...createPreviewSlice(...args),
    ...createTripletsSlice(...args),
  }))
);
export default useAppStore;
