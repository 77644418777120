import React, { useEffect, useState } from "react";
import { isEmpty, map, not, pipe, prop, propEq } from "ramda";

import ConversationModal from "./ConversationModal";
import moment from "moment";
import { replayService } from "../../../services";

const handleConversationError = (historical) => (replayed) =>
  replayed.error
    ? {
        ...replayed,
        actions: historical
          .filter(propEq("eventType", "BOT_UTTERANCE"))
          .map(() => ({
            eventType: "BOT_UTTERANCE",
            text: `Error while running conversation: ${replayed.error}`,
          })),
      }
    : replayed;

export default ({ isOpen, dismiss, id, images, cycleConversations }) => {
  const [historical, setHistorical] = useState();
  const [replayed, setReplayed] = useState();

  useEffect(() => {
    (async () => {
      if (!isOpen) {
        return;
      }
      const [historical, replayed] = await Promise.all([
        replayService.getHistoricalConversation({
          id,
        }),
        Promise.all(
          images.map((image) =>
            replayService.getReplayedConversation({
              id,
              image,
            })
          )
        ),
      ]);
      setHistorical(historical);
      setReplayed(replayed);
    })();
  }, [isOpen, id, images]);

  return historical &&
    replayed &&
    not(isEmpty(historical)) &&
    not(isEmpty(replayed)) ? (
    <ConversationModal
      isOpen={isOpen}
      dismiss={dismiss}
      cycleConversations={cycleConversations}
      conversation={{
        label: "Historical",
        detail: historical,
        conversationTime: moment(historical[0].createdAt),
        isLoading: false,
        isLoaded: true,
      }}
      updatedResponses={{
        responses: map(
          pipe(
            prop("conversationResponse"),
            handleConversationError(historical),
            prop("actions")
          ),
          replayed
        ),
        labels: images,
        compare: [1, 2],
      }}
      conversationId={id}
    />
  ) : null;
};
