import React from "react";

export default ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 13C10 11.9 10.9 11 12 11C13.1 11 14 11.9 14 13C14 14.1 13.1 15 12 15C10.9 15 10 14.1 10 13ZM14.9526 7.77729C16.7712 8.80888 18 10.7628 18 13C18 15.22 16.79 17.15 15 18.19L14 16.45C15.19 15.75 16 14.48 16 13C16 11.585 15.2662 10.3421 14.1581 9.63109L14.9526 7.77729ZM8.25494 5.92819C5.72336 7.271 4 9.93332 4 13C4 15.96 5.61 18.53 7.99 19.92L6.99 21.65C4.01 19.92 2 16.7 2 13C2 9.11208 4.22247 5.74009 7.46543 4.086L8.25494 5.92819ZM9.04741 7.77729C7.22884 8.80888 6 10.7628 6 13C6 15.22 7.21 17.15 9 18.19L10 16.45C8.81 15.75 8 14.48 8 13C8 11.585 8.73384 10.3421 9.84189 9.63109L9.04741 7.77729ZM15.7451 5.92819C18.2766 7.271 20 9.93332 20 13C20 15.96 18.39 18.53 16 19.92L17 21.65C19.99 19.92 22 16.7 22 13C22 9.11208 19.7775 5.74009 16.5346 4.086L15.7451 5.92819Z"
      fill={fill}
      fillOpacity="1"
    />
  </svg>
);
