import React, { useEffect, useState } from "react";
import BaseSettings from "../components/widgets/bot-settings/BaseSettings";
import { CONNECTOR_TYPES } from "../utils";
import HyroLoader from "../components/hyro-components/HyroLoader";
import { deploymentService } from "../services";
import { getGeneralSettings } from "./SettingsConfigs";
import { isEmpty } from "ramda";
import { roomIdSelector } from "../stores/selectors/appSelectors";
import useAppStore from "../stores/appStore";

export default () => {
  const [clientName, setClientName] = useState("");
  const [settingsConfig, setSettingsConfig] = useState({});

  const selectedRoomId = useAppStore(roomIdSelector);

  useEffect(() => {
    if (selectedRoomId) {
      (async () => {
        const connector = await deploymentService.findConnector({
          connectorType: CONNECTOR_TYPES.BOT_SOCKET_CONNECTOR,
        });
        setClientName(connector[0].config.clientName);
        const roomInfo = await deploymentService.findRoom({});
        setSettingsConfig(roomInfo);
      })();
    }
  }, [selectedRoomId]);

  return isEmpty(settingsConfig) ? (
    <HyroLoader />
  ) : (
    <BaseSettings
      title="General"
      tabsConfig={getGeneralSettings(clientName)}
      settings={settingsConfig}
      publishSettings={async (roomConfig) => {
        await deploymentService.updateRoom({
          room: roomConfig,
        });
      }}
    />
  );
};
