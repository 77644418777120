import { Box } from "@mui/material";
import React from "react";
import starsLoader from "../../static/lottie/stars_loader.json";
import { useLottie } from "lottie-react";

const StarsLottie = () => {
  const { View } = useLottie(
    { animationData: starsLoader, loop: true, autoplay: true },
    { height: 80 }
  );
  return View;
};

export default () => (
  <Box
    display="flex"
    flexDirection="column"
    height="100vh"
    alignItems="center"
    justifyContent="center"
    bgcolor="white"
  >
    <Box mb="18px">
      <StarsLottie animationData={starsLoader} />
    </Box>
    <Box fontWeight={400} fontSize="14px" color={"#00000099"}>
      Just a moment...
    </Box>
  </Box>
);
