import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import {
  always,
  any,
  concat,
  head,
  identity,
  ifElse,
  isEmpty,
  map,
  not,
  pipe,
  prop,
} from "ramda";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FileUploadCta from "./FileUploadCta";
import { styled } from "@mui/material/styles";
import { useDropzone } from "react-dropzone";

const InvalidFileSpan = styled("span")(() => ({
  color: "#F44336",
  fontSize: "14px",
  verticalAlign: "middle",
  display: "inline-flex",
}));

const ValidFileSpan = styled("span")(() => ({
  color: "#4CAF50",
  fontSize: "14px",
  verticalAlign: "middle",
  display: "inline-flex",
}));

const NoFileSpan = styled("span")(() => ({
  color: "rgb(0, 0, 0, 0.6)",
  fontSize: "14px",
}));

const renderErrors = (errors) =>
  errors ? (
    <>
      {map((err) => (
        <React.Fragment key={err}>
          {err}
          <br />
        </React.Fragment>
      ))(errors)}
    </>
  ) : (
    ""
  );

const onDrop =
  (validate, setErrors, onValid, setFileInFocus) => async (acceptedFiles) => {
    setFileInFocus(true);
    if (acceptedFiles.length > 0) {
      const errors = validate ? await validate(acceptedFiles[0]) : [];
      if (isEmpty(errors)) onValid(acceptedFiles[0]);
      else setErrors(errors);
    }
  };

export default ({ onValid, onClear, acceptedTypes, validate }) => {
  const [fileInFocus, setFileInFocus] = useState(false);
  const [errors, setErrors] = useState([]);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    open,
    draggedFiles,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop(validate, setErrors, onValid, setFileInFocus),
    accept: acceptedTypes, // ".csv,.xlsx" etc.
    maxFiles: 1,
  });

  return (
    <Box flex={1}>
      <div
        {...getRootProps({
          style: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "#eeeeee",
            borderStyle: "dashed",
            outline: "none",
            transition: "border .24s ease-in-out",
            minHeight: "154px",
            "&:hover": {
              backgroundColor: "rgb(63, 81, 181, 0.08)",
            },
            ...(not(isEmpty(draggedFiles)) && {
              backgroundColor: "rgb(63, 81, 181, 0.08)",
              opacity: 0.75,
              border: "1px solid rgba(69, 72, 196, 0.5)",
            }),
            ...(fileInFocus && {
              border: "1px solid rgba(69, 72, 196, 0.5)",
            }),
          },
        })}
      >
        <input {...getInputProps()} />
        <Grid item>
          {not(fileInFocus) ? (
            <FileUploadCta
              ctaContent="Drop your file here or"
              buttonContent="upload from your drive"
              buttonAction={open}
            />
          ) : (
            <FileUploadCta
              ctaContent={
                acceptedFiles.length > 0
                  ? acceptedFiles[0].name
                  : fileRejections[0].file.name
              }
              buttonContent="Clear"
              buttonAction={() => {
                setFileInFocus(false);
                setErrors([]);
                onClear();
              }}
            />
          )}
        </Grid>
        <Grid item>
          {any(pipe(isEmpty, not))([fileRejections, errors]) && fileInFocus ? (
            <InvalidFileSpan>
              <ErrorOutlineIcon
                style={{ marginRight: "5px" }}
                fontSize="small"
                color="error"
              />
              {pipe(
                head,
                ifElse(
                  identity,
                  pipe(prop("errors"), map(prop("message"))),
                  always([])
                ),
                concat(errors),
                renderErrors
              )(fileRejections)}
            </InvalidFileSpan>
          ) : fileInFocus ? (
            <ValidFileSpan>
              <CheckIcon
                style={{ marginRight: "5px" }}
                fontSize="small"
                color="success"
              />
              Uploaded successfully
            </ValidFileSpan>
          ) : (
            <NoFileSpan>Supported {acceptedTypes}</NoFileSpan>
          )}
        </Grid>
      </div>
    </Box>
  );
};
