import {
  DEVELOPMENT_ENV,
  PRODUCTION_ENV,
  makeSnippet,
} from "../../../snippetGenerator";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Grid from "@mui/material/Grid";
import SyntaxHighlighter from "react-syntax-highlighter";
import { roomIdSelector } from "../../../stores/selectors/appSelectors";
import { styled } from "@mui/material/styles";
import useAppStore from "../../../stores/appStore";

const StyledButton = styled(Button)`
  font-family: "roboto";
  font-weight: 500;
  font-size: 14px;
`;

export default () => {
  const roomId = useAppStore(roomIdSelector);
  const [environment, setEnvironment] = useState(PRODUCTION_ENV);

  return (
    <Grid container alignItems="stretch" spacing={1}>
      <Grid item xs={12}>
        <RadioGroup
          aria-label="position"
          name="position"
          value={environment}
          onChange={(e, val) => setEnvironment(val)}
          row
        >
          <FormControlLabel
            value={DEVELOPMENT_ENV}
            control={<Radio color="primary" />}
            label="Development"
            labelPlacement="end"
          />
          <FormControlLabel
            value={PRODUCTION_ENV}
            control={<Radio color="primary" />}
            label="Production"
            labelPlacement="end"
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <SyntaxHighlighter
          language="javascript"
          wrapLongLines
          customStyle={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "8px",
            backgroundColor: "rgb(250, 250, 250)",
            padding: "16px",
          }}
        >
          {makeSnippet({ roomId, environment })}
        </SyntaxHighlighter>
      </Grid>
      <Grid container xs={12} justifyContent="flex-end">
        <CopyToClipboard text={makeSnippet({ roomId, environment })}>
          <StyledButton variant="outlined" color="primary">
            Copy to clipboard
          </StyledButton>
        </CopyToClipboard>
      </Grid>
    </Grid>
  );
};
