import PropTypes from "prop-types";
import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/material/styles";

const PREFIX = "Header";

const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
};

const StyledTableHead = styled(TableHead)(() => ({
  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const HEADERS_COLOR = "#3B44BD";

const StyledTableSortLabel = styled(TableSortLabel)(() => ({
  color: `${HEADERS_COLOR} !important`,
  ".MuiTableSortLabel-icon": {
    color: "#F34068 !important",
  },
}));

const Header = ({ order, orderBy, onRequestSort, headCells }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id && order}
          >
            <StyledTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              classes={{
                root: classes.root,
                active: classes.active,
                icon: classes.icon,
              }}
            >
              {headCell.label}
              {orderBy === headCell.id && (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              )}
            </StyledTableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </StyledTableHead>
  );
};

Header.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default Header;
