import JSONView from "react-json-view";
import React from "react";

const handleCopy = (copy) =>
  navigator.clipboard.writeText(JSON.stringify(copy.src, null, "\t"));

const CustomJSONView = ({ src }) => (
  <JSONView
    src={src}
    iconStyle="square"
    collapsed={false}
    collapseStringsAfterLength={10}
    displayObjectSize={false}
    displayDataTypes={false}
    enableClipboard={handleCopy}
  />
);

export default CustomJSONView;
