import React, { useMemo, useState } from "react";
import {
  always,
  apply,
  applySpec,
  concat,
  equals,
  find,
  head,
  ifElse,
  isNil,
  juxt,
  last,
  length,
  mergeAll,
  not,
  pipe,
  prop,
  reject,
  unless,
} from "ramda";
import {
  elementHash,
  getAllElements,
  isString,
  makeEdgeIndex,
  makeTripletIndex,
} from "./tripletsIndex";
import { object, relation, subject } from "../../../triplets";

import Box from "@mui/material/Box";
import { Relations } from "../../../triplets";
import TripletTreeViewControls from "./TripletTreeViewControls";
import { default as gamla } from "gamlajs";

const findFirstDisplayText = pipe(
  find(pipe(relation, equals(Relations.CONCEPT_DISPLAY))),
  unless(isNil, pipe(object, prop("text")))
);

const display = (element, edges) =>
  findFirstDisplayText(edges) || element.text || "<None>";

const isSubject = (context, edge) => context === subject(edge);

const nodeId = (element, index) => `${JSON.stringify(element)}${index}`;

const edgeToElement = (context, edge) =>
  isSubject(context, edge) ? object(edge) : subject(edge);

const ADD = "add";
const DELETE = "delete";

const resolveTripletOp = (baseGraphContains, diffGraphContains, edge) => {
  if (!edge) {
    return;
  }

  if (baseGraphContains(edge) && !diffGraphContains(edge)) {
    return DELETE;
  }

  if (!baseGraphContains(edge) && diffGraphContains(edge)) {
    return ADD;
  }
};

const TripletDisplay = ({
  element,
  edge,
  index: { nodeToEdges, baseGraphContains, diffGraphContains },
  onlyChanges,
}) => {
  const op = resolveTripletOp(baseGraphContains, diffGraphContains, edge);
  if (onlyChanges && edge && !op) {
    return null;
  }

  return (
    <div
      style={{
        color: op === ADD ? "green" : op === DELETE ? "red" : "",
      }}
    >
      {isSubject(element, edge) ? (
        <>
          {display(element, nodeToEdges(element)).slice(0, 40)}&nbsp;
          <sub style={{ color: "#70757a" }}>{relation(edge)}</sub>
        </>
      ) : (
        <>
          <sub style={{ color: "#70757a" }}>{relation(edge)}</sub>&nbsp;
          {display(element, nodeToEdges(element)).slice(0, 40)}
        </>
      )}
    </div>
  );
};

const Triplet = ({ element, index, edge, onlyChanges }) => {
  const [expanded, setExpanded] = useState(false);
  const { nodeToEdges } = index;

  return (
    <Box
      title={JSON.stringify(element)}
      onClick={(e) => {
        e.stopPropagation();
        setExpanded(not);
      }}
      mt={1}
      mb={1}
    >
      <TripletDisplay
        edge={edge}
        element={element}
        index={index}
        onlyChanges={onlyChanges}
      />
      {expanded && (
        <Box ml={5}>
          {nodeToEdges(element).map((edge, i) => (
            <Triplet
              key={nodeId(edgeToElement(element, edge), i)}
              element={edgeToElement(element, edge)}
              index={index}
              edge={edge}
              onlyChanges={onlyChanges}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

const makeGraphIndex = gamla.timeit(
  (time) => console.log(`makeGraphIndex took ${time}ms`),
  pipe(
    juxt([prop("baseGraph"), prop("diffGraph")]),
    juxt([
      pipe(
        apply(concat),
        applySpec({
          allElements: gamla.timeit(
            (time) => console.log(`getAllElements took ${time}ms`),
            getAllElements
          ),
          nodeToEdges: gamla.timeit(
            (time) => console.log(`makeEdgeIndex took ${time}ms`),
            makeEdgeIndex
          ),
        })
      ),
      ifElse(
        pipe(last, length, equals(0)),
        always({
          baseGraphContains: always(true),
          diffGraphContains: always(true),
        }),
        applySpec({
          baseGraphContains: gamla.timeit(
            (time) => console.log(`building baseGraph index took ${time}ms`),
            pipe(head, makeTripletIndex)
          ),
          diffGraphContains: gamla.timeit(
            (time) => console.log(`building diffGraph index took ${time}ms`),
            pipe(last, makeTripletIndex)
          ),
        })
      ),
    ]),
    mergeAll
  )
);

const doSearch = (allElements, setRoots) => (value) => {
  if (value.length < 3 || !gamla.isValidRegExp(value)) {
    setRoots([]);
    return;
  }

  // Compile a regex before the reject as it is costly.
  const regex = new RegExp(value);
  setRoots(
    reject(
      pipe(unless(isString, JSON.stringify), (str) => str.match(regex), isNil),
      allElements
    )
  );
};

export default ({ baseGraph, diffGraph }) => {
  const [roots, setRoots] = useState([]);
  const [onlyChanges, setOnlyChanges] = useState(false);

  const graphIndex = useMemo(
    () => makeGraphIndex({ baseGraph, diffGraph }),
    [baseGraph, diffGraph]
  );

  if (!baseGraph.length) {
    return null;
  }

  return (
    <Box width="100%">
      <TripletTreeViewControls
        doSearch={doSearch(graphIndex.allElements, setRoots)}
        onlyChanges={onlyChanges}
        setOnlyChanges={setOnlyChanges}
        onlyChangesEnabled={diffGraph.length}
      />

      {roots.length
        ? roots.map((root) => (
            <Triplet
              key={elementHash(root)}
              element={root}
              index={graphIndex}
              onlyChanges={onlyChanges}
            />
          ))
        : "No results"}
    </Box>
  );
};
