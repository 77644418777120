import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  hasPermissionSelector,
  roomIdSelector,
} from "../../../../stores/selectors/appSelectors";
import ReactAudioPlayer from "react-audio-player";
import USER_PERMISSIONS from "../../../../userPermissions";
import { recordingService } from "../../../../services";
import useAppStore from "../../../../stores/appStore";

export default ({ cloudRecordingId, speechDuration, autoPlay }) => {
  const [recordingUrl, setRecordingUrl] = useState(null);
  const audioEl = useRef(null);
  const hasPermission = useAppStore(hasPermissionSelector);
  const selectedRoomId = useAppStore(roomIdSelector);

  useEffect(() => {
    if (!recordingUrl && hasPermission(USER_PERMISSIONS.RECORDING)) {
      recordingService
        .get({
          id: cloudRecordingId,
        })
        .then(setRecordingUrl);
    }
  }, [
    hasPermission,
    recordingUrl,
    setRecordingUrl,
    cloudRecordingId,
    selectedRoomId,
  ]);

  if (!hasPermission(USER_PERMISSIONS.RECORDING)) {
    return null;
  }

  return (
    <>
      {recordingUrl && (
        <Grid item>
          <Box mt="5px">
            <ReactAudioPlayer
              ref={audioEl}
              src={recordingUrl}
              autoPlay={autoPlay}
              controls
              onLoadedMetadata={() =>
                (audioEl.current.audioEl.current.currentTime = speechDuration
                  ? audioEl.current.audioEl.current.duration -
                    speechDuration -
                    2
                  : 0)
              }
            />
          </Box>
        </Grid>
      )}
    </>
  );
};
