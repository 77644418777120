import { INPUT_TYPES, checkValidCssValueOrBlank } from "../utils";
import DeactivateBot from "../components/widgets/bot-settings/DeactivateBot";
import React from "react";
import SnippetGenerator from "../components/widgets/bot-settings/SnippetGenerator";

const getGeneralSettings = (clientName) => [
  {
    title: "Bot Settings",
    sections: [
      {
        title: "Client Name",
        fields: [
          {
            name: "Client Name",
            element: (
              <h1
                style={{
                  fontWeight: 400,
                  fontSize: 16,
                  fontFamily: "roboto",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                {clientName}
              </h1>
            ),
          },
        ],
      },
      {
        title: "Bot Name",
        fields: [
          {
            name: "Bot Name",
            type: INPUT_TYPES.TEXT,
            path: ["friendlyName"],
          },
        ],
      },
      {
        title: "Error Response Text",
        fields: [
          {
            name: "Error Response Text",
            type: INPUT_TYPES.TEXT,
            path: ["errorResponseText"],
          },
        ],
      },
      {
        title: "Looker",
        fields: [
          {
            name: "Dashboard ID",
            type: INPUT_TYPES.TEXT,
            path: ["config", "analysis", "looker", "id"],
          },
          {
            name: "Group IDs",
            type: INPUT_TYPES.COMMA_SEPARATED_ARRAY,
            path: ["config", "analysis", "looker", "groupIds"],
          },
        ],
      },
      {
        title: "Danger Zone",
        fields: [
          {
            name: "Deactivate bot",
            element: <DeactivateBot />,
          },
        ],
      },
    ],
  },
  {
    title: "Snippet",
    sections: [
      {
        title: "Environment",
        fields: [
          {
            name: "Snippet",
            element: <SnippetGenerator />,
          },
        ],
      },
    ],
  },
];

const WIDGET_CONFIG = [
  {
    title: "General",
    sections: [
      {
        title: "Basic Information",
        fields: [
          {
            name: "Prompt",
            type: INPUT_TYPES.TEXT,
            path: ["config", "defaultPathConfig", "promptMessage"],
          },
          {
            name: "Top bar title",
            type: INPUT_TYPES.TEXT,
            path: ["config", "defaultPathConfig", "UIFeatures", "title"],
          },
          {
            name: "Placeholder text",
            type: INPUT_TYPES.TEXT,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "textAreaPlaceholder",
            ],
          },
          {
            name: "Disclaimer",
            type: INPUT_TYPES.DESCRIPTION,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "topRibbonText",
            ],
          },
        ],
      },
      {
        title: "Bot Website",
        fields: [
          {
            name: "URL",
            type: INPUT_TYPES.TEXT,
            path: ["config", "metadata", "websiteUrl"],
          },
        ],
      },
    ],
  },
  {
    title: "Appearance",
    sections: [
      {
        title: "Colors",
        fields: [
          {
            name: "Primary",
            type: INPUT_TYPES.COLOR,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "theme",
              "--main-color-palette",
            ],
            validate: checkValidCssValueOrBlank("background"),
          },
          {
            name: "Secondary",
            type: INPUT_TYPES.COLOR,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "theme",
              "--secondary-color-palette",
            ],
            validate: checkValidCssValueOrBlank("color"),
          },
        ],
        hiddenFields: [
          {
            name: "Chat widget color",
            type: INPUT_TYPES.COLOR,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "theme",
              "--chat-widget",
            ],
            validate: checkValidCssValueOrBlank("background"),
          },
          {
            name: "Avatar color",
            type: INPUT_TYPES.COLOR,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "theme",
              "--avatar-color",
            ],
            validate: checkValidCssValueOrBlank("background"),
          },
          {
            name: "Chat widget background color",
            type: INPUT_TYPES.COLOR,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "theme",
              "--chat-widget-light-white",
            ],
            validate: checkValidCssValueOrBlank("background-color"),
          },
          {
            name: "Chat suggestions color",
            type: INPUT_TYPES.COLOR,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "theme",
              "--chat-suggestion",
            ],
            validate: checkValidCssValueOrBlank("background-color"),
          },
          {
            name: "Chat header color",
            type: INPUT_TYPES.COLOR,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "theme",
              "--chat-header",
            ],
            validate: checkValidCssValueOrBlank("background"),
          },
          {
            name: "Text color",
            type: INPUT_TYPES.COLOR,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "theme",
              "--text-color",
            ],
            validate: checkValidCssValueOrBlank("color"),
          },
        ],
      },
      {
        title: "Icons",
        fields: [
          {
            name: "Widget Icon",
            type: INPUT_TYPES.DROPZONE,
            text: "Recommended: 128x128px PNG with transparent background",
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "customWidgetImage",
            ],
            width: "128px",
            height: "128px",
          },
          {
            name: "Top Bar Logo",
            type: INPUT_TYPES.DROPZONE,
            text: "Recommended: 38px height",
            path: ["config", "defaultPathConfig", "UIFeatures", "headerLogo"],
            height: "38px",
          },
          {
            name: "Avatar Icon",
            type: INPUT_TYPES.DROPZONE,
            text: "Recommended: 60x60px PNG with transparent background",
            path: ["config", "defaultPathConfig", "UIFeatures", "customAvatar"],
            width: "60px",
            height: "60px",
          },
        ],
      },
    ],
  },
  {
    title: "Advanced",
    sections: [
      {
        title: "User Utterance Recording",
        fields: [
          {
            name: "User Utterance Recording",
            type: INPUT_TYPES.BOOLEAN,
            path: ["config", "features", "userUtteranceRecording"],
          },
        ],
      },
      {
        title: "Use Widget Open State",
        fields: [
          {
            name: "Use Widget Open State",
            type: INPUT_TYPES.BOOLEAN,
            path: ["config", "features", "useWidgetOpenState"],
          },
        ],
      },
      {
        title: "Allow Traffic Mobile",
        fields: [
          {
            name: "Allow Traffic Mobile",
            type: INPUT_TYPES.BOOLEAN,
            path: [
              "config",
              "defaultPathConfig",
              "allowTraffic",
              "platforms",
              "mobile",
            ],
          },
        ],
      },
      {
        title: "Allow Traffic Desktop",
        fields: [
          {
            name: "Allow Traffic Desktop",
            type: INPUT_TYPES.BOOLEAN,
            path: [
              "config",
              "defaultPathConfig",
              "allowTraffic",
              "platforms",
              "desktop",
            ],
          },
        ],
      },
      {
        title: "Traffic",
        fields: [
          {
            name: "Traffic Percentage (in %)",
            type: INPUT_TYPES.TEXT,
            path: ["config", "features", "limitPercentage"],
          },
        ],
      },
      {
        title: "Forbidden Urls",
        fields: [
          {
            name: "Forbidden URLs Mobile",
            type: INPUT_TYPES.COMMA_SEPARATED_ARRAY,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "forbiddenUrls",
              "mobile",
            ],
          },
          {
            name: "Forbidden URLs Desktop",
            type: INPUT_TYPES.COMMA_SEPARATED_ARRAY,
            path: [
              "config",
              "defaultPathConfig",
              "UIFeatures",
              "forbiddenUrls",
              "desktop",
            ],
          },
        ],
      },
    ],
  },
];

const VOICE_TYPES = [
  "en-US-Wavenet-A",
  "en-US-Wavenet-B",
  "en-US-Wavenet-D",
  "en-US-Wavenet-I",
  "en-US-Wavenet-C",
  "en-US-Wavenet-E",
  "en-US-Wavenet-F",
];

const PHONE_CONFIG = [
  {
    title: "Telephony",
    sections: [
      {
        title: "Phone Number",
        fields: [
          {
            name: "Phone Number",
            type: INPUT_TYPES.ADD_ONE,
            text: "Connect a phone number",
            path: ["phoneNumbers"],
          },
        ],
      },
      {
        title: "Assistant's Voice",
        fields: [
          {
            name: "Assistant's Voice",
            type: INPUT_TYPES.DROPDOWN,
            options: VOICE_TYPES.map((voice) => ({
              display: voice,
              value: voice,
            })),
            path: ["config", "features", "voiceName"],
          },
        ],
      },
    ],
  },
  {
    title: "Advanced",
    sections: [
      {
        title: "User Utterance Recording",
        fields: [
          {
            name: "User Utterance Recording",
            type: INPUT_TYPES.BOOLEAN,
            path: ["config", "features", "userUtteranceRecording"],
          },
        ],
      },
    ],
  },
];

export { getGeneralSettings, WIDGET_CONFIG, PHONE_CONFIG };
