export default {
  AJAX_ACTION: "AJAX_ACTION",
  BOT_UTTERANCE: "BOT_UTTERANCE",
  BOT_UTTERANCE_DIFF: "BOT_UTTERANCE_DIFF",
  BOT_UTTERANCE_FEEDBACK: "BOT_UTTERANCE_FEEDBACK",
  CONVERSATION_START: "CONVERSATION_START",
  CONVERSATION_RECORDING: "CONVERSATION_RECORDING",
  CONVERSION: "CONVERSION",
  DISAMBIGUATION: "DISAMBIGUATION",
  ERROR: "ERROR",
  EXTERNAL: "EXTERNAL",
  EXTERNAL_SEARCH: "EXTERNAL_SEARCH",
  FAQ_RESPONSE: "FAQ_RESPONSE",
  INFEASIBLE: "INFEASIBLE",
  INTENT: "INTENT",
  KNOWLEDGE: "KNOWLEDGE",
  MATCHED_WORD_REVIEW_CLICK: "MATCHED_WORD_REVIEW_CLICK",
  MATCHED_WORD_REVIEW: "MATCHED_WORD_REVIEW",
  MISUNDERSTANDING: "MISUNDERSTANDING",
  MOUSE_ENTER: "MOUSE_ENTER",
  MOUSE_LEAVE: "MOUSE_LEAVE",
  PHONE_TAG_CLICK: "PHONE_TAG_CLICK",
  RESULT_CLICK: "RESULT_CLICK",
  SUGGESTION_CLICK: "SUGGESTION_CLICK",
  SWITCH_COMMAND: "SWITCH_COMMAND",
  THINKING: "THINKING",
  URL_TAG_CLICK: "URL_TAG_CLICK",
  USER_UTTERANCE: "USER_UTTERANCE",
  WIDGET_CLICK: "WIDGET_CLICK",
  WIDGET_LOAD: "WIDGET_LOAD",
};
