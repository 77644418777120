import {
  DASHBOARD,
  DATA_SOURCES,
  FIND_A_PROVIDER,
  GENERAL_SETTINGS,
  INSIGHTS,
  KNOWLEDGE_EDITOR,
  KNOWLEDGE_EXPLORER,
  RESPONSES,
  SITE_SEARCH,
  TEMPLATE_SETTINGS,
  parseCurrentPage,
  parseRoomId,
} from "../../utils";
import {
  botPortalService,
  deploymentService,
  previewService,
  usersService,
} from "../../services";
import TagManager from "react-gtm-module";
import axios from "axios";
import { history } from "../../layout/browserHistory";
import { join } from "ramda";

const CLIENT_SPECIFIC_PAGES = [
  DASHBOARD,
  DATA_SOURCES,
  FIND_A_PROVIDER,
  GENERAL_SETTINGS,
  INSIGHTS,
  KNOWLEDGE_EDITOR,
  KNOWLEDGE_EXPLORER,
  RESPONSES,
  SITE_SEARCH,
  TEMPLATE_SETTINGS,
];

export default (set, get) => ({
  userData: {},
  userPermissions: {},
  selectedRoomId: null,
  accessToken: null,
  rooms: [],
  clientName: null,
  changeRequests: null,
  isBotPreviewable: true,
  setupApp: async (accessToken, userData) => {
    // TODO(ENG-3478): Error handling
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    set({ accessToken, userData });
    const userPermissions = await usersService.userPermissions();
    set({ userPermissions });
    TagManager.dataLayer({
      dataLayer: {
        event: "userData",
        userId: userPermissions.userId,
        userType: userPermissions.isAdmin ? "isAdmin" : "nonAdmin",
        project: userPermissions.isAdmin
          ? "hyroAdmin"
          : join("-", userPermissions.allowedRooms),
      },
    });
    const rooms = await deploymentService.findActiveRooms();
    set({ rooms });
    const roomId = parseRoomId(window.location.pathname);
    const selectedRoomId = roomId || rooms[0].roomId;
    const { setSelectedRoomId } = get();
    await setSelectedRoomId(selectedRoomId);
  },
  setSelectedRoomId: async (selectedRoomId) => {
    // TODO(ENG-3478): Error handling
    const { initTriplets, clearTriplets } = get();
    clearTriplets();
    set({ changeRequests: null });
    const { pathname } = window.location;
    const currentPage = parseCurrentPage(pathname) || DASHBOARD;
    const urlRoomId = parseRoomId(pathname);
    if (
      urlRoomId !== selectedRoomId &&
      CLIENT_SPECIFIC_PAGES.includes(currentPage)
    )
      history.replace(`/${currentPage}/${selectedRoomId}`);
    set({ selectedRoomId });
    const clientName = await botPortalService.getClientName();
    set({ clientName });
    const changeRequests = await botPortalService.getChangeRequests();
    set({ changeRequests });
    const isBotPreviewable = await previewService.isBotPreviewable();
    set({ isBotPreviewable });
    initTriplets();
  },
  hasPermission: (permission) => {
    const { userPermissions, selectedRoomId } = get();
    return (
      userPermissions &&
      (userPermissions.isAdmin ||
        (selectedRoomId &&
          userPermissions.permissions &&
          userPermissions.permissions[selectedRoomId] &&
          userPermissions.permissions[selectedRoomId].includes(permission)))
    );
  },
});
