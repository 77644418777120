import React from "react";

export default ({ fill }) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6665 0H2.6665C1.5665 0 0.676504 0.9 0.676504 2L0.666504 16C0.666504 17.1 1.5665 18 2.6665 18H16.6665C17.7665 18 18.6665 17.1 18.6665 16V2C18.6665 0.9 17.7665 0 16.6665 0ZM16.6665 16H2.6665V2H16.6665V16ZM8.1665 14H11.1665V10.5H14.6665V7.5H11.1665V4H8.1665V7.5H4.6665V10.5H8.1665V14Z"
      fill={fill}
    />
  </svg>
);
