import {
  ButtonBase,
  Chip,
  CircularProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { DASHBOARD, MOBILE_BROWSER_INFO } from "../../../utils";
import { Link, useLocation, withRouter } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import {
  T,
  always,
  apply,
  applySpec,
  cond,
  isEmpty,
  juxt,
  map,
  pipe,
  prop,
  propEq,
} from "ramda";
import {
  roomIdSelector,
  userPermissionsSelector,
} from "../../../stores/selectors/appSelectors";
import Box from "@mui/material/Box";
import { CSVLink } from "react-csv";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import DateInfo from "../../../layout/Header/DateInfo";
import DateTimeContext from "../../../contexts/DateTimeContext";
import DeviceIcon from "./DeviceIcon";
import Grid from "@mui/material/Grid";

import HistoricalConversationModal from "./HistoricalConversationModal";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import Mousetrap from "mousetrap";
import NoConversationsIcon from "./NoConversationsIcon";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { default as gamla } from "gamlajs";
import { styled } from "@mui/material/styles";
import useAppStore from "../../../stores/appStore";

const StyledFilterSelect = styled(Select)(() => ({
  paddingLeft: 10,
  marginLeft: 10,
  fontSize: 14,
  fontWeight: 500,
}));

const StyledTitle = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-start",
  fontSize: "1.2rem",
  paddingTop: 10,
  paddingLeft: 20,
}));

const StyledFilterContainer = styled("div")(() => ({
  paddingTop: 10,
  paddingLeft: 20,
  gap: 8,
  display: "flex",
  alignItems: "center",
}));

const NoConversationsContainer = styled("div")(() => ({
  textAlign: "center",
  margin: "200px 0",
}));

const PAGE_SIZE = 50;

const Filters = {
  NumOfMessages: "#OfMessages",
  Medium: "medium",
  Device: "device",
  Origin: "origin",
};

const TableHeader = ({ handleFilterChange, filterParameters, useFilters }) => (
  <TableHead>
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell sx={{ textAlign: "center" }}>Time</TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        # of messages
        {useFilters && (
          <StyledFilterSelect
            disableUnderline
            variant="standard"
            value={filterParameters[Filters.NumOfMessages]}
            onChange={({ target: { value } }) =>
              handleFilterChange(Filters.NumOfMessages, value)
            }
          >
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2-3">2 - 3</MenuItem>
            <MenuItem value="4-5">4 - 5</MenuItem>
            <MenuItem value="5+">5+</MenuItem>
            <MenuItem value="all">All</MenuItem>
          </StyledFilterSelect>
        )}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        Medium
        {useFilters && (
          <StyledFilterSelect
            disableUnderline
            variant="standard"
            value={filterParameters[Filters.Medium]}
            onChange={({ target: { value } }) =>
              handleFilterChange(Filters.Medium, value)
            }
          >
            <MenuItem value="voice">Voice</MenuItem>
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="suggestion click">Suggestion click</MenuItem>
            <MenuItem value="all">All</MenuItem>
          </StyledFilterSelect>
        )}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        Device
        {useFilters && (
          <StyledFilterSelect
            disableUnderline
            variant="standard"
            value={filterParameters[Filters.Device]}
            onChange={({ target: { value } }) =>
              handleFilterChange(Filters.Device, value)
            }
          >
            <MenuItem value="desktop">Desktop</MenuItem>
            <MenuItem value="mobile">Mobile</MenuItem>
            <MenuItem value="phone">Phone</MenuItem>
            <MenuItem value="all">All</MenuItem>
          </StyledFilterSelect>
        )}
      </TableCell>
    </TableRow>
  </TableHead>
);

const ConversationRow = ({ conversation, selectConversationBound, roomId }) => {
  const { timeZoneMoment } = useContext(DateTimeContext);
  const location = useLocation();
  return (
    <TableRow
      hover
      onClick={() => selectConversationBound(conversation.conversationId)}
    >
      <TableCell>
        <Link
          to={{
            pathname: `/${location.pathname.split("/")[1]}/${roomId}`,
            search: `?conversationId=${conversation.conversationId}`,
          }}
          onClick={(e) => e.preventDefault()}
          style={{ color: "#20a8d8", textDecoration: "none" }}
        >
          {conversation.conversationId}
        </Link>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <Typography sx={{ color: "#73818f", fontSize: "14px" }}>
          {timeZoneMoment(conversation.date).format("L LT")}
        </Typography>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <ChatBubbleOutlineIcon size="small" sx={{ opacity: "60%" }} />
          </Grid>
          <Grid item>
            <Box ml={2}>
              <span>{conversation.messageCount}</span>
            </Box>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {conversation.isVoice && (
          <VolumeUpIcon
            title="Voice"
            style={{ fontSize: 24 + "px", marginRight: "10px", opacity: "60%" }}
          />
        )}
        {conversation.isText && (
          <KeyboardIcon
            title="Text"
            style={{ fontSize: 24 + "px", margin: "auto", opacity: "60%" }}
          />
        )}
        {!conversation.isVoice && !conversation.isText && (
          <TouchAppIcon
            title="Suggestion click"
            style={{ fontSize: 20 + "px", margin: "auto", opacity: "60%" }}
          />
        )}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <DeviceIcon browserInfo={conversation.browserInfo} />
      </TableCell>
    </TableRow>
  );
};

const selectConversation =
  ({ history, pathname, setSelectedConversationId }) =>
  (conversationId) => {
    history.push({
      pathname,
      search: "?conversationId=" + conversationId,
    });
    setSelectedConversationId(conversationId);
  };

const selectNextConversation =
  ({
    currentIndex,
    selectConversationBound,
    dismissModalBound,
    conversations,
  }) =>
  (n) => {
    const nextIndex = currentIndex === -1 ? 0 : currentIndex + n;
    if (nextIndex > -1 && nextIndex < conversations.length) {
      selectConversationBound(conversations[nextIndex].conversationId);
    } else {
      dismissModalBound();
    }
  };

const dismissModal =
  ({ setSelectedConversationId, pathname, history }) =>
  () => {
    setSelectedConversationId(null);
    history.push({
      pathname,
      search: "",
    });
  };

const DOWNLOAD_BUTTON_COLOR = "#4153AF";
const DOWNLOAD_BUTTON_SELECTED_COLOR = "#314299";

const LatestConversations = ({
  history,
  isDataLoading,
  page,
  setPage,
  count,
  filterParameters,
  conversations,
  handleFilterChange,
  useFilters,
  usePagers,
  location: { pathname },
}) => {
  const { isAdmin } = useAppStore(userPermissionsSelector);
  const selectedRoomId = useAppStore(roomIdSelector);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [downloadButtonColor, setDownloadButtonColor] = useState(
    DOWNLOAD_BUTTON_COLOR
  );

  const showOriginFilters =
    isAdmin && filterParameters && filterParameters[Filters.Origin];

  const filterOriginProps = (filter) => ({
    clickable: true,
    size: "small",
    label: filter === "live" ? "Live" : "Test",
    color: "primary",
    onClick: () => handleFilterChange(Filters.Origin, filter),
    variant:
      filterParameters[Filters.Origin] &&
      filterParameters[Filters.Origin] === filter
        ? ""
        : "outlined",
  });
  const currentIndex = conversations.findIndex(
    propEq("conversationId", selectedConversationId)
  );

  useEffect(() => {
    const selectNextConversationBound = selectNextConversation({
      currentIndex,
      selectConversationBound: selectConversation({
        pathname,
        history,
        setSelectedConversationId,
      }),
      conversations,
      dismissModalBound: dismissModal({
        setSelectedConversationId,
        history,
        pathname,
      }),
    });
    const hotkeys = {
      n: () => selectNextConversationBound(1),
      p: () => selectNextConversationBound(-1),
    };
    Object.keys(hotkeys).forEach((key) => Mousetrap.bind(key, hotkeys[key]));
    return () => Object.keys(hotkeys).forEach(Mousetrap.unbind);
  }, [history, conversations, selectedConversationId, pathname, currentIndex]);

  const selectConversationBound = selectConversation({
    pathname,
    history,
    setSelectedConversationId,
  });

  const filterColumns = (data) => {
    if (data && data.length > 0) {
      return map(
        applySpec({
          conversationLink: pipe(
            juxt([prop("botId"), prop("conversationId")]),
            apply(
              (botId, conversationId) =>
                `https://dashboard.hyro.ai/${DASHBOARD}/${botId}?conversationId=${conversationId}`
            )
          ),
          date: prop("date"),
          numberOfMessages: prop("messageCount"),
          medium: cond([
            [prop("isVoice"), always("voice")],
            [prop("isText"), always("text")],
            [T, always("suggestion click")],
          ]),
          device: pipe(
            prop("browserInfo"),
            prop("os"),
            cond([
              [gamla.contains(MOBILE_BROWSER_INFO), always("mobile")],
              [gamla.contains(["nexmo", "phone", "twilio"]), always("phone")],
              [T, always("desktop")],
            ])
          ),
          conversationTranscript: prop("conversationTranscript"),
        })
      )(data);
    }
    return [];
  };

  const dismissModalBound = dismissModal({
    setSelectedConversationId,
    history,
    pathname,
  });

  return (
    <Paper
      style={{
        padding: "15px",
      }}
    >
      <StyledFilterContainer>
        {showOriginFilters && (
          <>
            Show:
            <Chip {...filterOriginProps("live")} />
            <Chip {...filterOriginProps("test")} />
          </>
        )}
        <div
          style={{
            marginLeft: "auto",
          }}
        >
          <DateInfo />
          {isDataLoading && <CircularProgress size={20} />}
        </div>
      </StyledFilterContainer>

      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="space-between"
      >
        <Grid item>
          <StyledTitle>
            Latest Conversations ({conversations.length} results found{" "}
            {isDataLoading ? "so far..." : "in total"})
          </StyledTitle>
        </Grid>
        <Grid item>
          {conversations && conversations.length > 1 && (
            <CSVLink
              filename="Latest conversations query.csv"
              data={filterColumns(conversations)}
            >
              <Box mt={1}>
                <ButtonBase
                  disabled={isEmpty(conversations)}
                  onMouseEnter={() =>
                    setDownloadButtonColor(DOWNLOAD_BUTTON_SELECTED_COLOR)
                  }
                  onMouseLeave={() =>
                    setDownloadButtonColor(DOWNLOAD_BUTTON_COLOR)
                  }
                >
                  <svg
                    width="27"
                    height="20"
                    viewBox="0 0 27 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.8744 8.80125C22.2845 4.15455 18.3047 0.550049 13.5 0.550049C9.7794 0.550049 6.5475 2.7249 5.07195 6.15255C2.17215 7.01925 0 9.75705 0 12.7C0 16.422 3.02805 19.45 6.75 19.45H8.1V16.75H6.75C4.5171 16.75 2.7 14.9329 2.7 12.7C2.7 10.8046 4.31865 8.9781 6.30855 8.62845L7.0929 8.49075L7.3521 7.73745C8.30115 4.9686 10.6569 3.25005 13.5 3.25005C17.222 3.25005 20.25 6.2781 20.25 10V11.35H21.6C23.0891 11.35 24.3 12.561 24.3 14.05C24.3 15.5391 23.0891 16.75 21.6 16.75H18.9V19.45H21.6C24.5781 19.45 27 17.0281 27 14.05C26.9984 12.8398 26.5911 11.665 25.8432 10.7135C25.0953 9.76201 24.05 9.08871 22.8744 8.80125V8.80125Z"
                      fill={downloadButtonColor}
                    />
                    <path
                      d="M14.8333 13.9667V9.30005H12.5V13.9667H9L13.6667 19.8L18.3333 13.9667H14.8333Z"
                      fill={downloadButtonColor}
                    />
                  </svg>
                </ButtonBase>
              </Box>
            </CSVLink>
          )}
        </Grid>
      </Grid>
      {conversations?.length > 0 ? (
        <>
          <Table>
            <TableHeader
              useFilters={useFilters}
              handleFilterChange={handleFilterChange}
              filterParameters={filterParameters}
            />
            <TableBody>
              {conversations.map((c) => (
                <React.Fragment key={c.conversationId}>
                  <ConversationRow
                    conversation={c}
                    selectConversationBound={selectConversationBound}
                    roomId={selectedRoomId}
                  />
                  <HistoricalConversationModal
                    cycleConversations={selectNextConversation({
                      currentIndex,
                      conversations,
                      selectConversationBound,
                      dismissModalBound,
                    })}
                    isOpen={c.conversationId === selectedConversationId}
                    conversationId={c.conversationId}
                    dismiss={dismissModalBound}
                  />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          {usePagers && (
            <TablePagination
              rowsPerPageOptions={[PAGE_SIZE]}
              component="div"
              count={count}
              rowsPerPage={PAGE_SIZE}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onPageChange={(_, newPage) => setPage(newPage)}
              onRowsPerPageChange={() => {}}
            />
          )}
        </>
      ) : (
        <NoConversationsContainer>
          <NoConversationsIcon />
          <div {...{ style: { marginTop: "16px" } }}>
            No testing conversations were made lately, <br />
            try changing the date range.
          </div>
        </NoConversationsContainer>
      )}
    </Paper>
  );
};

LatestConversations.defaultProps = {
  showRefresh: false,
};

export default withRouter(LatestConversations);
