import {
  always,
  both,
  equals,
  head,
  ifElse,
  isEmpty,
  keys,
  map,
  not,
  of,
  pipe,
  prop,
  tap,
  uniq,
} from "ramda";
import { Box } from "@mui/material";
import { CSVLink } from "react-csv";
import { HyroFileUploader } from "../../hyro-components";
import React from "react";
import { Relations } from "../../../triplets";
import downloadSample from "../../../static/images/download_sample.svg";
import { default as gamla } from "gamlajs";
import { parse } from "csv-parse/browser/esm/sync";
import { readTextFileAsync } from "../../../utils";

const getHeaders = pipe(map(prop(Relations.FIELD_LABEL)));

const parseCsv = (fileContents) =>
  parse(fileContents, {
    columns: true,
    skip_empty_lines: true,
  });

const validateCsv = (fieldSpec) =>
  pipe(
    map(keys),
    uniq,
    ifElse(
      both(pipe(isEmpty, not), pipe(head, equals(getHeaders(fieldSpec)))),
      always([]),
      always(["Invalid CSV"])
    )
  );

export default ({ setParsedCSV, fieldSpec }) => (
  <Box display="flex" flexDirection="column" gridRowGap="26px">
    <CSVLink
      filename="sample file"
      data={pipe(getHeaders, of)(fieldSpec)}
      style={{
        color: "rgb(0, 0, 0, 0.54)",
        fontSize: "15px",
        fontWeight: 500,
        "&:hover": {
          color: "rgb(0, 0, 0, 0.54)",
        },
        textDecoration: "none",
      }}
    >
      <img src={downloadSample} alt="download sample" /> Download a sample file
    </CSVLink>
    <HyroFileUploader
      onValid={gamla.asyncPipe(readTextFileAsync, parseCsv, tap(setParsedCSV))}
      onClear={() => setParsedCSV([])}
      acceptedTypes=".csv"
      validate={gamla.asyncPipe(
        readTextFileAsync,
        parseCsv,
        validateCsv(fieldSpec)
      )}
    />
  </Box>
);
