import {
  Button,
  Chip,
  Grid,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { cond, equals, pipe } from "ramda";
import Box from "@mui/material/Box";
import { CHANGE_REQUEST_STATES } from "../../../utils";
import React from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";

const StyledButton = styled(Button)(() => ({
  "&:focus": {
    outline: 0,
  },
  color: "rgba(0, 0, 0, 0.87)",
  textTransform: "none",
  fontWeight: 500,
  opacity: 0.6,
  fontSize: "12px",
}));

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})(({ backgroundColor }) => ({
  margin: "8px",
  flexGrow: 1,
  overflow: "hidden",
  maxWidth: "290px",
  padding: "8px",
  backgroundColor,
  borderRadius: "4px",
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#323232",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#323232",
    maxWidth: 160,
  },
}));

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})(({ backgroundColor }) => ({
  backgroundColor,
  border: "none",
  color: "white",
  fontSize: "12px",
  fontWeight: 500,
  padding: "4px",
}));

export default ({
  changeRequest: { description, updated_at, created_by, change_status, draft },
  setDraftToDelete,
}) => {
  const {
    palette: { primary, info, success },
  } = useTheme();
  return (
    <StyledBox
      backgroundColor={
        equals(CHANGE_REQUEST_STATES.LIVE)(change_status) ? "#E8FAF4" : ""
      }
    >
      <Grid container wrap="nowrap" direction="column" mb="7px">
        <Grid item xs>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "14px",
            }}
            noWrap
          >
            {description}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              opacity: 0.6,
              fontSize: "12px",
            }}
          >
            {moment(updated_at || "").fromNow()} by {created_by}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifycontent="space-between" alignItems="center">
        <Grid item xs>
          {pipe(
            cond([
              [
                equals(CHANGE_REQUEST_STATES.SUBMITTED),
                () => ({
                  chipColor: primary.main,
                  tooltipTitle:
                    "Your changes have been submitted and are now going through the testing phase",
                }),
              ],
              [
                equals(CHANGE_REQUEST_STATES.IN_REVIEW),
                () => ({
                  chipColor: info.main,
                  tooltipTitle:
                    "Yay! Your changes were submitted successfully and are now being processed for going live",
                }),
              ],
              [
                equals(CHANGE_REQUEST_STATES.LIVE),
                () => ({
                  chipColor: success.main,
                  tooltipTitle: "Your changes are in production!",
                }),
              ],
            ]),
            ({ chipColor, tooltipTitle }) => (
              <StyledTooltip title={tooltipTitle} placement="right-end" arrow>
                <StyledChip
                  label={change_status}
                  backgroundColor={chipColor}
                  variant="outlined"
                  size="small"
                />
              </StyledTooltip>
            )
          )(change_status)}
        </Grid>
        {equals(CHANGE_REQUEST_STATES.SUBMITTED)(change_status) && (
          <Grid item>
            <StyledButton onClick={() => setDraftToDelete(draft)}>
              Discard
            </StyledButton>
          </Grid>
        )}
      </Grid>
    </StyledBox>
  );
};
