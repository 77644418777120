import { ALL_ASSISTANTS, getClientDisplayName } from "../../../utils";
import { Autocomplete, Paper } from "@mui/material";
import {
  StyledDropdownLastItem,
  StyledDropdownLastItemText,
} from "../../../styles/Dropdown";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";
import { TextField } from "@mui/material";
import { propEq } from "ramda";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";

const StyledOpenInNewIcon = styled(OpenInNewIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export default ({ createBot, rooms, selectRoomId, selectedRoomId }) => {
  const history = useHistory();

  return (
    <Autocomplete
      disableClearable
      loading={!rooms.length}
      onChange={(_, { roomId }) => {
        selectRoomId(roomId);
      }}
      options={rooms.sort((c1, c2) =>
        getClientDisplayName(c1).localeCompare(getClientDisplayName(c2))
      )}
      getOptionLabel={getClientDisplayName}
      renderInput={(params) => (
        <TextField {...params} fullWidth variant="standard" />
      )}
      value={rooms.find(propEq("roomId", selectedRoomId)) || null}
      sx={{
        "& .MuiAutocomplete-inputRoot": {
          color: "inherit",
        },
        width: "300px",
      }}
      PaperComponent={({ children }) => (
        <Paper>
          {children}
          {createBot && (
            <StyledDropdownLastItem
              onMouseDown={() => history.push(`/admin/${ALL_ASSISTANTS}`)}
            >
              <StyledOpenInNewIcon fontSize="small" />
              <StyledDropdownLastItemText>
                Assistants Manager
              </StyledDropdownLastItemText>
            </StyledDropdownLastItem>
          )}
        </Paper>
      )}
    />
  );
};
