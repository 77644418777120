import {
  HyroFeedbackMessage,
  HyroLoader,
  HyroStepperDialog,
  HyroTitleWithButton,
} from "../components/hyro-components";
import React, { useState } from "react";
import { Relations, relationIn, tripletsToObjects } from "../triplets";
import {
  any,
  assoc,
  filter,
  flip,
  identity,
  isEmpty,
  isNil,
  juxt,
  map,
  mergeAll,
  not,
  pipe,
  prop,
} from "ramda";
import {
  currentTripletsSelector,
  isRefreshingTripletsSelector,
} from "../stores/selectors/tripletsSelectors";
import { Box } from "@mui/material";
import DataSourcesEmpty from "../components/widgets/data-sources/DataSourcesEmpty";
import DataSourcesList from "../components/widgets/data-sources/DataSourcesList";
import DataTypeChoice from "../components/widgets/data-sources/DataSourceChoice";
import ExternalFileUpload from "../components/widgets/data-sources/ExternalFileUpload";
import { replaceSpecialChars } from "../utils";
import { storageService } from "../services";
import useAppStore from "../stores/appStore";
import { useEffectAsync } from "../hooks";

const getDataSourceKey = replaceSpecialChars("_");
const getDataSources = pipe(
  filter(
    relationIn([
      Relations.DATA_SOURCE_FILE_HASH,
      Relations.DATA_SOURCE_FORMAT,
      Relations.DATA_SOURCE_NAME,
    ])
  ),
  tripletsToObjects
);

const EXTERNAL_FILE_SPEC = {
  type: "",
  name: "",
  file: null,
};

export default () => {
  const currentTriplets = useAppStore(currentTripletsSelector);
  const isRefreshingTriplets = useAppStore(isRefreshingTripletsSelector);
  const [dataSources, setDataSources] = useState([]);

  useEffectAsync(async () => {
    if (not(isEmpty(currentTriplets))) {
      const dataSources = getDataSources(currentTriplets);
      const filesMetadata = await storageService.getFilesMetaData({
        fileHashes: map(prop(Relations.DATA_SOURCE_FILE_HASH))(dataSources),
      });
      setDataSources(
        map(
          pipe(
            juxt([
              identity,
              pipe(
                prop(Relations.DATA_SOURCE_FILE_HASH),
                flip(prop)(filesMetadata)
              ),
            ]),
            mergeAll
          )
        )(dataSources)
      );
    }
  }, [currentTriplets]);
  const [feedbackConfig, setFeedbackConfig] = useState({
    show: false,
    message: "",
    severity: "success",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dataSource, setDataSource] = useState(EXTERNAL_FILE_SPEC);
  const steps = [
    {
      heading: "Add data source",
      component: (
        <DataTypeChoice
          setDataType={(dataType) => setDataSource(assoc("type", dataType))}
        />
      ),
      nextDisabled: isEmpty(dataSource.type),
      autoNext: true,
      dialogDims: {
        minWidth: "816px",
        minHeight: "480px",
      },
    },
    {
      heading: "Add External File As Data Source",
      component: (
        <ExternalFileUpload
          name={dataSource.name}
          setName={(name) => setDataSource(assoc("name", name))}
          file={dataSource.file}
          setFile={(file) => setDataSource(assoc("file", file))}
        />
      ),
      nextDisabled:
        any(isEmpty)([dataSource.name, dataSource.key]) ||
        isNil(dataSource.file),
      dialogDims: {
        minWidth: "600px",
        minHeight: "460px",
      },
    },
  ];
  const externalFileUpload = async () => {
    try {
      const fileForm = new FormData();
      fileForm.append("file", dataSource.file, dataSource.file.name);
      fileForm.append("dataSourceName", dataSource.name);
      fileForm.append("key", getDataSourceKey(dataSource.name));
      await storageService.createDataSource(fileForm);
      setFeedbackConfig({
        show: true,
        message: "Data source successfully added",
        severity: "success",
      });
      setDataSource(EXTERNAL_FILE_SPEC);
      setDialogOpen(false);
    } catch (e) {
      setFeedbackConfig({
        show: true,
        message: "Failed to add data source",
        severity: "error",
      });
    }
  };

  if (isRefreshingTriplets) return <HyroLoader />;

  return (
    <Box
      mt={4}
      mx={4}
      display="flex"
      flexDirection="column"
      height="calc(100vh - 64px)"
    >
      <HyroTitleWithButton
        // Adding a data source is disabled until ENG-4099 is done.
        buttonDisabled
        title="Data Sources"
        buttonText="Add data source"
        handleClick={() => setDialogOpen(true)}
      />
      {not(isEmpty(dataSources)) ? (
        <DataSourcesList dataSources={dataSources} />
      ) : (
        <DataSourcesEmpty />
      )}
      {dialogOpen && (
        <HyroStepperDialog
          open={dialogOpen}
          steps={steps}
          handleDone={externalFileUpload}
          handleCancel={() => {
            setDataSource(EXTERNAL_FILE_SPEC);
            setDialogOpen(false);
          }}
          hideProgress
        />
      )}
      <HyroFeedbackMessage
        message={feedbackConfig.message}
        severity={feedbackConfig.severity}
        showFeedback={feedbackConfig.show}
        closeFeedback={() => setFeedbackConfig(assoc("show", false))}
      />
    </Box>
  );
};
