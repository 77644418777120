import { isEmpty, not } from "ramda";
import {
  roomIdSelector,
  setupAppSelector,
  userPermissionsSelector,
} from "../stores/selectors/appSelectors";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { AUTH0_DASHBOARD_AUDIENCE } from "../utils";
import { Box } from "@mui/material";
import Content from "./Content";
import Header from "./Header";
import React from "react";
import { datadogRum } from "@datadog/browser-rum";
import useAppStore from "../stores/appStore";
import { useEffectAsync } from "../hooks";

export default withAuthenticationRequired(
  () => {
    const setupApp = useAppStore(setupAppSelector);
    const userPermissions = useAppStore(userPermissionsSelector);
    const selectedRoomId = useAppStore(roomIdSelector);

    const { getAccessTokenSilently, user: userData } = useAuth0();

    useEffectAsync(async () => {
      const token = await getAccessTokenSilently({
        audience: AUTH0_DASHBOARD_AUDIENCE,
      });
      setupApp(token, userData);
      datadogRum.setUser({
        id: userData.sub,
        name: userData.name,
        email: userData.email,
      });
    }, [getAccessTokenSilently, setupApp, userData]);

    return (
      not(isEmpty(userPermissions)) &&
      selectedRoomId && (
        <Box height="100vh" display="flex" flexDirection="column">
          <Header />
          <Content />
        </Box>
      )
    );
  },
  () => {}
);
