import { Badge, Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import {
  changeRequestsSelector,
  roomIdSelector,
} from "../../../stores/selectors/appSelectors";
import { isEmpty, isNil, not } from "ramda";
import Box from "@mui/material/Box";
import ChangeRequestMenuItem from "./ChangeRequestMenuItem";
import HyroDialog from "../../hyro-components/HyroDialog";
import { INPUT_TYPES } from "../../../utils";
import LastUpdateMenuItem from "./LastUpdateMenuItem";
import NotFoundMenuItem from "./NotFoundMenuItem";
import { botPortalService } from "../../../services";
import { deletePublishedDraftSelector } from "../../../stores/selectors/tripletsSelectors";
import mergeIcon from "../../../static/images/merge_icon.svg";
import { styled } from "@mui/material/styles";
import useAppStore from "../../../stores/appStore";
import { useEffectAsync } from "../../../hooks";

const StyledButton = styled(Button)(() => ({
  "&:focus": {
    outline: 0,
  },
  width: "36px",
}));

const StyledMenu = styled(Menu)(() => ({
  ".MuiMenu-list": {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  ".MuiMenu-paper": {
    marginTop: "45px",
  },
}));

export default () => {
  const deletePublishedDraft = useAppStore(deletePublishedDraftSelector);
  const selectedRoomId = useAppStore(roomIdSelector);
  const changeRequests = useAppStore(changeRequestsSelector);
  const [lastLiveChangeRequest, setLastLiveChangeRequest] = useState({});
  const [changeRequestsMenuAnchorEl, setChangeRequestsMenuAnchorEl] =
    useState(null);
  const [draftToDelete, setDraftToDelete] = useState({});

  useEffectAsync(async () => {
    setLastLiveChangeRequest(await botPortalService.getLastLiveChangeRequest());
  }, [selectedRoomId]);

  const discardChange = async () => {
    await deletePublishedDraft(draftToDelete);
    setDraftToDelete({});
  };

  return (
    <>
      <HyroDialog
        fieldSpec={[
          {
            inputType: INPUT_TYPES.DESCRIPTION,
            label:
              "Once you discard this change, this cannot be undone and will delete all the changes you made.",
          },
        ]}
        handleClose={() => setDraftToDelete({})}
        handleSubmit={discardChange}
        open={not(isEmpty(draftToDelete))}
        submitButtonText="Discard"
        title="Discard change?"
      />
      <StyledButton
        onClick={({ currentTarget }) =>
          setChangeRequestsMenuAnchorEl(currentTarget)
        }
        disabled={isNil(changeRequests)}
      >
        <Badge
          color="secondary"
          badgeContent={changeRequests?.length}
          invisible={!changeRequests?.length}
        >
          <img src={mergeIcon} alt="updates" />
        </Badge>
      </StyledButton>
      <StyledMenu
        anchorEl={changeRequestsMenuAnchorEl}
        open={Boolean(changeRequestsMenuAnchorEl)}
        onClose={() => setChangeRequestsMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        // Moving the menu slightly to the left
        PaperProps={{
          style: {
            transform: "translateX(-14px)",
          },
        }}
      >
        <Box width="300px">
          <MenuItem disabled style={{ opacity: 1, minHeight: "48px" }} divider>
            <Box sx={{ fontWeight: 500 }}>Change requests</Box>
          </MenuItem>
          {changeRequests && not(isEmpty(changeRequests)) ? (
            <>
              {changeRequests.map((changeRequest) => (
                <ChangeRequestMenuItem
                  key={changeRequest.id}
                  changeRequest={changeRequest}
                  setDraftToDelete={setDraftToDelete}
                />
              ))}
            </>
          ) : (
            <NotFoundMenuItem />
          )}
          <LastUpdateMenuItem lastLiveChangeRequest={lastLiveChangeRequest} />
        </Box>
      </StyledMenu>
    </>
  );
};
