import React from "react";

export default ({ fill }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 9.3V4H16.5V6.6L12.5 3L2.5 12H5.5V20H11.5V14H13.5V20H19.5V12H22.5L19.5 9.3ZM17.5 18H15.5V12H9.5V18H7.5V10.19L12.5 5.69L17.5 10.19V18Z"
      fill={fill}
    />
    <path
      d="M10.5 10H14.5C14.5 8.9 13.6 8 12.5 8C11.4 8 10.5 8.9 10.5 10Z"
      fill={fill}
    />
  </svg>
);
