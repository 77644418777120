import { Chip, Paper } from "@mui/material";
import React, { useState } from "react";

import JSONView from "./JSONView";
import ReactMarkdown from "react-markdown";
import { getHyroColorRGB } from "../../../../utils";
import { not } from "ramda";
import { styled } from "@mui/material/styles";

const PREFIX = "BotUtterance";

const classes = {
  utterance: `${PREFIX}-utterance`,
  cardLoaded: `${PREFIX}-cardLoaded`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(() => ({
  [`& .${classes.utterance}`]: {
    "& a": { color: "#fff", lineHeight: 1.3, textDecoration: "underline" },
    // Undo ReactMarkdown's exaggerated margin.
    "& p": { margin: 0, lineHeight: 1.3 },
  },

  [`& .${classes.cardLoaded}`]: {
    color: "#fff",
    padding: "16px",
    backgroundColor: getHyroColorRGB(0.95),
  },
}));

export default ({ isDetailedMode, ...props }) => {
  const [showJSON, setShowJSON] = useState(false);

  return (
    <Root>
      <Paper
        onClick={() => setShowJSON(not)}
        className={classes.cardLoaded}
        style={{
          maxWidth: "100%",
          marginBottom: 1,
          cursor: "pointer",
          fontSize: "14px",
        }}
      >
        <ReactMarkdown className={classes.utterance}>
          {isDetailedMode
            ? props.text
            : props.text.replace(/(<([^>]+)>)/gi, "")}
        </ReactMarkdown>

        {props.requestLatency ? (
          <small style={{ fontSize: "10px" }}>
            {Math.round(props.requestLatency / 1000)} seconds
          </small>
        ) : null}
      </Paper>
      {showJSON
        ? props.suggestions.map(({ displayText }, index) => (
            <Chip
              key={index}
              style={{ margin: "5px" }}
              size="small"
              label={displayText}
            />
          ))
        : null}
      {showJSON && <JSONView src={props} />}
    </Root>
  );
};
