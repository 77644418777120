import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "ColorPicker";

const classes = {
  colorInput: `${PREFIX}-colorInput`,
  colorBox: `${PREFIX}-colorBox`,
  colorTextField: `${PREFIX}-colorTextField`,
};

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "background",
})(({ background }) => ({
  [`&.${classes.colorInput}`]: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },

  [`& .${classes.colorBox}`]: {
    background: background || "#FAFBFB",
    height: "20px",
    width: "20px",
    border: "1px solid #E0E0E0",
    clear: "both",
    marginRight: "15px",
  },

  [`& .${classes.colorTextField}`]: {
    flex: 1,
  },
}));

export default ({ value, onChange, label, validate }) => {
  const errorMessage = validate(value);
  return (
    <Root background={value} className={classes.colorInput}>
      <div className={classes.colorBox} />
      <TextField
        className={classes.colorTextField}
        helperText={errorMessage}
        error={Boolean(errorMessage)}
        value={value || ""}
        onChange={onChange}
        label={label}
        variant="outlined"
      />
    </Root>
  );
};
