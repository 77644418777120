import React from "react";

export default ({ fill }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4902 0C4.97023 0 0.490234 4.48 0.490234 10C0.490234 15.52 4.97023 20 10.4902 20C16.0102 20 20.4902 15.52 20.4902 10C20.4902 4.48 16.0102 0 10.4902 0ZM14.1002 6.34C15.1702 6.34 16.0302 7.2 16.0302 8.27C16.0302 9.34 15.1702 10.2 14.1002 10.2C13.0302 10.2 12.1702 9.34 12.1702 8.27C12.1602 7.2 13.0302 6.34 14.1002 6.34ZM8.10023 4.76C9.40024 4.76 10.4602 5.82 10.4602 7.12C10.4602 8.42 9.40024 9.48 8.10023 9.48C6.80023 9.48 5.74023 8.42 5.74023 7.12C5.74023 5.81 6.79024 4.76 8.10023 4.76ZM8.10023 13.89V17.64C5.70023 16.89 3.80023 15.04 2.96023 12.68C4.01023 11.56 6.63023 10.99 8.10023 10.99C8.63023 10.99 9.30023 11.07 10.0002 11.21C8.36023 12.08 8.10023 13.23 8.10023 13.89ZM10.4902 18C10.2202 18 9.96023 17.99 9.70023 17.96V13.89C9.70023 12.47 12.6402 11.76 14.1002 11.76C15.1702 11.76 17.0202 12.15 17.9402 12.91C16.7702 15.88 13.8802 18 10.4902 18Z"
      fill={fill}
    />
  </svg>
);
