import { Grid, Paper, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import {
  currentTripletsSelector,
  isRefreshingTripletsSelector,
  updateCurrentTripletsSelector,
} from "../stores/selectors/tripletsSelectors";
import Box from "@mui/material/Box";
import ConfigureResultCard from "../components/widgets/provider-config/result-cards/ConfigureResultCard";
import DataSource from "../components/widgets/find-a-provider/DataSource";
import { HyroLoader } from "../components/hyro-components";
import LocationSettings from "../components/widgets/provider-config/LocationSettings";
import MapData from "../components/widgets/find-a-provider/MapData";
import SortingLogic from "../components/widgets/find-a-provider/SortingLogic";
import { styled } from "@mui/material/styles";
import useAppStore from "../stores/appStore";

const PREFIX = "FindAProvider";

const classes = {
  content: `${PREFIX}-content`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.content}`]: {
    height: 500,
    padding: "1%",
  },
  tab: {
    padding: "0px",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  pageHeading: {
    paddingTop: "0px !important",
  },
}));

export default () => {
  const currentTriplets = useAppStore(currentTripletsSelector);
  const isRefreshingTriplets = useAppStore(isRefreshingTripletsSelector);
  const updateCurrentTriplets = useAppStore(updateCurrentTripletsSelector);
  const [activeTab, setActiveTab] = useState("data_source");

  const handleChange = (event, newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  const TAB_HEADERS = [
    {
      label: "Data Source",
      value: "data_source",
      content: (
        <DataSource
          pendingTriplets={currentTriplets}
          setPendingTriplets={updateCurrentTriplets}
        />
      ),
    },
    {
      label: "Map Data",
      value: "map_data",
      content: (
        <MapData
          pendingTriplets={currentTriplets}
          setPendingTriplets={updateCurrentTriplets}
        />
      ),
    },
    {
      label: "Result Cards",
      value: "result_cards",
      content: <ConfigureResultCard />,
    },
    {
      label: "Sorting Logic",
      value: "sorting_logic",
      content: (
        <SortingLogic
          pendingTriplets={currentTriplets}
          setPendingTriplets={updateCurrentTriplets}
        />
      ),
    },
    {
      label: "Location Settings",
      value: "location_settings",
      content: (
        <LocationSettings
          pendingTriplets={currentTriplets}
          setPendingTriplets={updateCurrentTriplets}
        />
      ),
    },
  ];

  if (isRefreshingTriplets) return <HyroLoader />;

  return (
    <StyledBox mx={4} mt={4}>
      <Grid
        container
        spacing={10}
        direction="row"
        justifyContent="space-between"
      >
        <Grid item xs={10} className={classes.pageHeading}>
          <h1>Find a Provider</h1>
        </Grid>
      </Grid>
      <TabContext value={activeTab}>
        <Box borderBottom="1px solid #b5b5b5" mt={3}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            {TAB_HEADERS.map(({ label, value }) => (
              <Tab label={label} value={value} key={value} />
            ))}
          </Tabs>
        </Box>
        {TAB_HEADERS.map(({ label, content, value }) => (
          <TabPanel value={value} key={value} className={classes.tab}>
            {content ?? (
              <Paper className={classes.content}>{label} content</Paper>
            )}
          </TabPanel>
        ))}
      </TabContext>
    </StyledBox>
  );
};
