import { BOT_TYPES, DASHBOARD, WORKSPACE_TYPES } from "../../../utils";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Paper,
  TablePagination,
} from "@mui/material";
import { HyroHeader, HyroLoader, HyroTable } from "../../hyro-components";
import React, { useEffect, useState } from "react";
import {
  T,
  always,
  any,
  cond,
  equals,
  filter,
  includes,
  is,
  isEmpty,
  isNil,
  length,
  lt,
  map,
  path,
  pipe,
  prop,
  reject,
  slice,
  toLower,
  values,
  when,
} from "ramda";
import AddIcon from "@mui/icons-material/Add";
import CreateBotWizard from "../bot-creation/CreateBotWizard";
import botTypeDemoIcon from "../../../static/images/demo_icon.svg";
import botTypeProductionIcon from "../../../static/images/production_icon.svg";
import botTypeTestIcon from "../../../static/images/test_icon.svg";
import noMatchesFoundIcon from "../../../static/images/no_matches_found.svg";
import { setRoomIdSelector } from "../../../stores/selectors/appSelectors";
import { styled } from "@mui/material/styles";
import useAppStore from "../../../stores/appStore";
import { useEffectAsync } from "../../../hooks";
import { useHistory } from "react-router-dom";
import workspaceTypeClientIcon from "../../../static/images/type_client_icon.svg";
import workspaceTypeInternalHyroIcon from "../../../static/images/type_internal_hyro_icon.svg";
import workspaceTypeProspectIcon from "../../../static/images/type_prospect_icon.svg";
import { workspacesService } from "../../../services";

const StyledTitle = styled("h1")(({ theme }) => ({
  color: path(["palette", "text", "primary"])(theme),
}));

const NoMatchesFoundSpan = styled("span")(() => ({
  color: "#2D2C44",
  fontSize: "16px",
  opacity: "70%",
}));

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})(({ theme, backgroundColorPath }) => ({
  backgroundColor: backgroundColorPath
    ? path(["palette", ...backgroundColorPath])(theme)
    : "transparent",
}));

const WORKSPACE_NAME_AND_TYPE = "workspaceNameAndType";
const BOT_NAME = "botName";
const BOT_TYPE = "botType";
const CHANNEL = "channel";
const VERTICAL = "vertical";
const HYRO_OWNER = "hyroOwner";

const WORKSPACE_TYPE_TO_ICON = {
  [WORKSPACE_TYPES.CLIENT]: workspaceTypeClientIcon,
  [WORKSPACE_TYPES.INTERNAL]: workspaceTypeInternalHyroIcon,
  [WORKSPACE_TYPES.PROSPECT]: workspaceTypeProspectIcon,
};

const BOT_TYPE_TO_ICON = {
  [BOT_TYPES.DEMO]: botTypeDemoIcon,
  [BOT_TYPES.LIVE]: botTypeProductionIcon,
  [BOT_TYPES.TEST]: botTypeTestIcon,
};

const COLUMNS = [
  {
    id: BOT_NAME,
    label: "Name",
    width: "16%",
  },
  {
    id: CHANNEL,
    label: "Channel",
    width: "16%",
  },
  {
    id: BOT_TYPE,
    label: "Type",
    width: "16%",
    renderCell: (value) => (
      <StyledChip
        size="small"
        label={value}
        icon={value && <img src={BOT_TYPE_TO_ICON[value]} alt="bot_type" />}
        backgroundColorPath={cond([
          [equals(BOT_TYPES.LIVE), always(["success", "alert", "background"])],
          [equals(BOT_TYPES.DEMO), always(["secondary", "main"])],
          [equals(BOT_TYPES.TEST), always(["grey", "200"])],
          [T, always(null)],
        ])(value)}
      />
    ),
  },
  {
    id: WORKSPACE_NAME_AND_TYPE,
    label: "Workspace",
    width: "16%",
    renderCell: ({ name, type }) => (
      <Box display="flex" alignItems="center">
        {name}
        <Box pl={1}>
          <img src={WORKSPACE_TYPE_TO_ICON[type]} alt="workspace_type" />
        </Box>
      </Box>
    ),
  },
  {
    id: VERTICAL,
    label: "Vertical",
    width: "16%",
  },
  {
    id: HYRO_OWNER,
    label: "Hyro owner",
    width: "16%",
  },
];

export default () => {
  const selectRoomId = useAppStore(setRoomIdSelector);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [createBotOpen, setCreateBotOpen] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const history = useHistory();

  useEffectAsync(async () => {
    setData(await workspacesService.getAssistantsData());
  }, []);

  useEffect(() => {
    setFilteredRows(
      filter(
        pipe(
          values,
          reject(isNil),
          map(pipe(when(is(Object), prop("name")), toLower)),
          any(includes(searchText))
        )
      )(data)
    );
    setPageNum(0);
  }, [searchText, data]);

  return isEmpty(data) ? (
    <HyroLoader />
  ) : (
    <Box mx={4} mt={20}>
      <Grid
        container
        spacing={10}
        direction="row"
        justifyContent="space-between"
      >
        <Grid item xs={10} sx={{ paddingTop: "0px !important" }}>
          <StyledTitle>All Assistants</StyledTitle>
        </Grid>
      </Grid>
      <Divider />
      <Paper>
        <Box p={3} mt={1}>
          <HyroHeader
            title={`${filteredRows.length} Assistants`}
            handleSearch={setSearchText}
            buttonSpec={{
              icon: <AddIcon />,
              text: "Create New Assistant",
              run: () => setCreateBotOpen(true),
            }}
          />
          {isEmpty(filteredRows) && searchText ? (
            <Grid
              container
              spacing={1}
              direction="column"
              display="flex"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <img src={noMatchesFoundIcon} alt="bot_type" />
              </Grid>
              <Grid item>
                <NoMatchesFoundSpan>
                  No assistants were found that match your search
                </NoMatchesFoundSpan>
              </Grid>
            </Grid>
          ) : (
            <HyroTable
              onRowClick={({ roomId }) => {
                history.push(`/${DASHBOARD}`);
                selectRoomId(roomId);
              }}
              columns={COLUMNS}
              rows={when(
                pipe(length, lt(pageSize)),
                slice(pageNum * pageSize, pageNum * pageSize + pageSize)
              )(filteredRows)}
            />
          )}
        </Box>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={length(filteredRows)}
          rowsPerPage={pageSize}
          page={pageNum}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          onPageChange={(_, newPage) => setPageNum(newPage)}
          onRowsPerPageChange={({ target: { value } }) => setPageSize(value)}
        />
      </Paper>
      {createBotOpen && (
        <CreateBotWizard
          open={createBotOpen}
          handleClose={() => setCreateBotOpen(false)}
        />
      )}
    </Box>
  );
};
