import {
  getSetRoomIdSelector,
  hasPermissionSelector,
  roomsSelector,
} from "../../../stores/selectors/appSelectors";
import BotSelector from "./BotSelector";
import React from "react";
import USER_PERMISSIONS from "../../../userPermissions";
import useAppStore from "../../../stores/appStore";

export default () => {
  const hasPermission = useAppStore(hasPermissionSelector);
  const [selectedRoomId, selectRoomId] = useAppStore(getSetRoomIdSelector);
  const rooms = useAppStore(roomsSelector);
  return (
    <BotSelector
      createBot={hasPermission(USER_PERMISSIONS.CREATE_BOT)}
      rooms={rooms}
      selectRoomId={selectRoomId}
      selectedRoomId={selectedRoomId}
    />
  );
};
