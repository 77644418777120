import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { __, add, subtract, times } from "ramda";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== "dialogDims",
})(({ dialogDims }) => ({
  "& .MuiDialog-paper": dialogDims || {},
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  display: "flex",
  paddingRight: "48px",
  paddingLeft: "48px",
  paddingBottom: "48px",
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "48px",
  paddingRight: "48px",
}));

const StyledButton = styled(Button)(() => ({
  color: "#00000099",
}));

const StepIndicator = ({ isActive }) => (
  <Box
    mx="4px"
    width="24px"
    height="4px"
    borderRadius="2px"
    bgcolor={isActive ? "#3F51B5" : "#E0E0E0"}
  />
);

const StepProgress = ({ numSteps, activeStep }) => (
  <Box display="flex" flexDirection="row" justifyContent="center" mt="24px">
    {times(
      (stepNum) => (
        <StepIndicator key={stepNum} isActive={activeStep >= stepNum} />
      ),
      numSteps
    )}
  </Box>
);

export default ({ open, steps, handleDone, handleCancel, hideProgress }) => {
  const [activeStep, setActiveStep] = useState(0);

  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === steps.length - 1;

  const { autoNext, component, dialogDims, heading, nextDisabled } =
    steps[activeStep];

  useEffect(() => {
    if (
      steps[activeStep].autoNext &&
      !steps[activeStep].nextDisabled &&
      !isLastStep
    ) {
      setActiveStep(add(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  return (
    <StyledDialog dialogDims={dialogDims} open={open} maxWidth="sm" fullWidth>
      {!hideProgress && (
        <StepProgress numSteps={steps.length} activeStep={activeStep} />
      )}
      <Box
        fontSize="24px"
        fontWeight={500}
        textAlign="center"
        mt="24px"
        mx="15px"
        fontFamily="roboto"
      >
        {heading}
      </Box>
      <StyledDialogContent>
        <Box
          height="240px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flex={1}
        >
          {component}
        </Box>
      </StyledDialogContent>
      <StyledDialogActions>
        {!isFirstStep && (
          <Box flex={1}>
            <StyledButton
              disabled={isFirstStep}
              onClick={() => setActiveStep(subtract(__, 1))}
              startIcon={
                <ArrowBackIcon
                  sx={{
                    color: "#00000099",
                  }}
                />
              }
            >
              Back
            </StyledButton>
          </Box>
        )}
        <Box
          flex={1}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          gridColumnGap="8px"
        >
          <Button color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          {!autoNext && (
            <Button
              variant="contained"
              color="primary"
              onClick={isLastStep ? handleDone : () => setActiveStep(add(1))}
              disabled={nextDisabled}
            >
              {isLastStep ? "Done" : "Next"}
            </Button>
          )}
        </Box>
      </StyledDialogActions>
    </StyledDialog>
  );
};
