import { equals, when } from "ramda";

import { SEVERITY_LEVELS } from "../../utils";
const SUCCESS_ALERT = {
  message: "Call has been triggered successfully",
  severity: SEVERITY_LEVELS.SUCCESS,
};

const ERROR_ALERT = {
  message: "Something went wrong please try again",
  severity: SEVERITY_LEVELS.ERROR,
};
import { default as gamla } from "gamlajs";
import { previewService } from "../../services";

export default (set, get) => ({
  previewAssistantId: null,
  createPreviewAssistantInProgress: false,
  previewAssistantCreationFailed: false,
  createAssistantForPreview: async () => {
    const { currentTriplets, clientName } = get();
    set({
      createPreviewAssistantInProgress: true,
      previewAssistantCreationFailed: false,
    });
    try {
      const previewAssistantId = await previewService.createBotForTesting({
        triplets: currentTriplets,
        friendlyName: clientName,
      });
      set({ previewAssistantId });

      const loop = setInterval(async () => {
        try {
          await gamla.asyncPipe(
            previewService.isBotReadyForTesting,
            when(equals(true), () => {
              clearInterval(loop);
              set({
                createPreviewAssistantInProgress: false,
                previewAssistantCreationFailed: false,
              });
            })
          )({ createdRoomIdForTesting: previewAssistantId });
        } catch (err) {
          set({
            createPreviewAssistantInProgress: false,
            previewAssistantCreationFailed: true,
          });
          clearInterval(loop);
          throw err;
        }
      }, 2000);
    } catch {
      set({
        createPreviewAssistantInProgress: false,
        previewAssistantCreationFailed: true,
      });
    }
  },
  initiateCallToAssistant: async (phoneNumber) => {
    const { previewAssistantId } = get();
    try {
      await previewService.initiateTestCall({
        botId: previewAssistantId,
        phoneNumber,
      });
      return SUCCESS_ALERT;
    } catch (error) {
      return ERROR_ALERT;
    }
  },
});
