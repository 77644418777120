import { Collapse, MenuItem } from "@mui/material";
import { EVENTS, RESPONSE, SUGGESTIONS, TITLE, TRIGGERS } from "../utils";
import React, { useState } from "react";
import {
  addIndex,
  anyPass,
  append,
  apply,
  equals,
  filter,
  flatten,
  head,
  join,
  map,
  not,
  pipe,
  prop,
  propEq,
  reject,
} from "ramda";
import {
  currentTripletsSelector,
  updateCurrentTripletsSelector,
} from "../../../../stores/selectors/tripletsSelectors";
import { sortTriplets, subjectEq, subjectIn } from "../../../../triplets";
import ActionDiff from "../ActionDiff";
import BotEditorEditableTableCellContent from "./EditableTableCellContent";
import Box from "@mui/material/Box";
import ConfirmDialog from "../../clients/ConfirmDialog";
import Event from "../../../Event";
import Highlighter from "react-highlight-words";
import MuiTableCell from "@mui/material/TableCell";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ThreeDotMenu from "../../knowledge-editor/ThreeDotMenu";
import useAppStore from "../../../../stores/appStore";

const TableCellWithoutBorderBottom = MuiTableCell;

export default ({
  originalTriplets,
  row,
  isGeneralResponsesView,
  setEditedRow,
  setDialog,
  setDialogActiveTab,
}) => {
  const currentTriplets = useAppStore(currentTripletsSelector);
  const updateCurrentTriplets = useAppStore(updateCurrentTripletsSelector);
  const actionKey = row.response.key;
  const eventKeys = map(prop("key"))(row.events);
  const conceptKey = pipe(prop(TRIGGERS), head, prop("key"))(row);
  const suggestionKeys = map(prop("key"))(row.suggestions);

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [reviewChangeExpandedPanel, setReviewChangeExpandedPanel] =
    useState(false);

  const [cellHovers, setCellHovers] = useState({});

  const rowTriplets = filter(
    subjectIn(flatten([actionKey, eventKeys, conceptKey, suggestionKeys]))
  );
  const rowChanged =
    currentTriplets &&
    originalTriplets &&
    pipe(
      map(pipe(rowTriplets, sortTriplets)),
      apply(equals),
      not
    )([currentTriplets, originalTriplets]);

  const handleReviewChange = () => {
    toggleReviewChangeExpandedPanel();
  };

  const toggleReviewChangeExpandedPanel = () =>
    setReviewChangeExpandedPanel(not);

  const props = {
    row,
    setDialog,
    setDialogActiveTab,
    setEditedRow,
  };

  const deleteRow = () => {
    updateCurrentTriplets(
      reject(
        anyPass([
          subjectEq(conceptKey),
          subjectEq(actionKey),
          subjectIn(suggestionKeys),
          subjectIn(eventKeys),
        ])
      )
    );
  };

  const setCellHover = (key) => (isHovered) => {
    setCellHovers({ [key]: isHovered });
  };

  const editableTableCellProps = (key) => ({
    sx: { paddingLeft: 0, paddingRight: 0 },
    onMouseEnter: () => setCellHover(key)(true),
    onMouseLeave: () => setCellHover(key)(false),
  });

  const allRowCells = [
    {
      id: EVENTS,
      cellContent: (
        <BotEditorEditableTableCellContent
          {...props}
          editableCellContent={
            row.events.length ? (
              pipe(
                prop(EVENTS),
                map(prop("type")),
                addIndex(map)((event, idx) => (
                  <React.Fragment key={idx}>
                    <Event color="primary" text={event} />
                    <br />
                  </React.Fragment>
                ))
              )(row)
            ) : (
              <Event color="secondary" text="No event" />
            )
          }
          colName={EVENTS}
          hover={prop(EVENTS)(cellHovers)}
        />
      ),
      cellProps: {
        width: "10%",
        ...editableTableCellProps(EVENTS),
      },
    },
    {
      id: TRIGGERS,
      cellContent: (
        <BotEditorEditableTableCellContent
          {...props}
          editableCellContent={pipe(
            prop(TRIGGERS),
            map(prop("value")),
            join(", ")
          )(row)}
          colName={TRIGGERS}
          hover={prop(TRIGGERS)(cellHovers)}
        />
      ),
      cellProps: {
        width: "30%",
        ...editableTableCellProps(TRIGGERS),
      },
    },
    {
      id: TITLE,
      cellContent: prop(TITLE)(row),
      cellProps: {
        width: "30%",
      },
    },
    {
      id: RESPONSE,
      cellContent: (
        <BotEditorEditableTableCellContent
          {...props}
          editableCellContent={
            <Highlighter
              highlightStyle={{
                background: "#f8f8f8",
                border: "1px solid #ddd",
                borderRadius: "0.2rem",
                color: "#e01e5a",
                fontFamily:
                  "Monaco,Menlo,Consolas,'Courier New',monospace!important",
                fontSize: "0.7rem",
                padding: "2px 3px 1px",
                whiteSpace: "normal",
              }}
              searchWords={[/{[^{]*}|<[^<]*>/g]}
              textToHighlight={row.response.value}
            />
          }
          colName={RESPONSE}
          hover={prop(RESPONSE)(cellHovers)}
        />
      ),
      cellProps: {
        width: "40%",
        ...editableTableCellProps(RESPONSE),
      },
    },
    {
      id: SUGGESTIONS,
      cellContent: (
        <BotEditorEditableTableCellContent
          {...props}
          editableCellContent={prop(SUGGESTIONS)(row).map(
            ({ displayText }, idx) => (
              <div key={idx}>
                {idx + 1}. {displayText}
              </div>
            )
          )}
          colName={SUGGESTIONS}
          hover={prop(SUGGESTIONS)(cellHovers)}
        />
      ),
      cellProps: {
        width: "15%",
        ...editableTableCellProps(SUGGESTIONS),
      },
    },
    {
      id: "status",
      cellContent: rowChanged ? (
        <Box
          sx={{
            color: "#5C65E6",
          }}
        >
          Pending Publishing
        </Box>
      ) : (
        <Box
          sx={{
            color: "rgba(21, 22, 71, 0.5)",
          }}
        >
          Live
        </Box>
      ),
      cellProps: {
        width: "5%",
      },
    },
  ];

  const generalResponsesRowCells = reject(propEq("id", TRIGGERS))(allRowCells);

  const cannedResponsesRowCells = pipe(
    reject(propEq("id", TITLE)),
    append({
      id: "icons",
      cellContent: (
        <>
          <ThreeDotMenu
            menuItems={[
              <MenuItem
                key="delete"
                onClick={() => setDeleteConfirmDialogOpen(true)}
              >
                Delete
              </MenuItem>,
              <MenuItem
                key="review"
                disabled={!rowChanged}
                onClick={handleReviewChange}
              >
                Review change
              </MenuItem>,
            ]}
          />
          <ConfirmDialog
            open={deleteConfirmDialogOpen}
            close={() => setDeleteConfirmDialogOpen(false)}
            title="Delete this response?"
            text="You're about to delete this canned response and all its triggers and suggestions, are you sure you want to proceed?"
            confirmFunction={deleteRow}
            disableConfirmButton={false}
          />
        </>
      ),
      cellProps: {
        align: "right",
        width: "2%",
      },
    })
  )(allRowCells);

  const rowCells = isGeneralResponsesView
    ? generalResponsesRowCells
    : cannedResponsesRowCells;

  return (
    <>
      <TableRow hover>
        {rowCells.map(({ cellContent, cellProps, id }) => (
          <TableCellWithoutBorderBottom key={id} {...cellProps}>
            {cellContent}
          </TableCellWithoutBorderBottom>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={rowCells.length}
        >
          <Collapse
            in={reviewChangeExpandedPanel}
            onClick={toggleReviewChangeExpandedPanel}
            timeout="auto"
            unmountOnExit
          >
            <Box mb={1}>
              <ActionDiff
                actionKey={actionKey}
                conceptKey={conceptKey}
                newTriplets={currentTriplets}
                oldTriplets={originalTriplets}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
