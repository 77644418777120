import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";

export default ({ title, body }) => (
  <Box
    sx={{
      padding: "15px",
      backgroundColor: "#EAF4FC",
      borderRadius: "4px",
      marginBottom: "25px",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <InfoOutlinedIcon
        sx={{
          color: "#2196F3",
          width: 18,
          height: 18,
          marginRight: "15px",
        }}
      />
      <h1
        style={{
          fontWeight: 500,
          fontSize: 16,
          fontFamily: "roboto",
        }}
      >
        {title}
      </h1>
    </Box>
    {body}
  </Box>
);
