import { DASHBOARD, QUERIES, parseRoomIdForPath } from "../utils";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { HyroFeedbackMessage, HyroLoader } from "../components/hyro-components";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  accessTokenSelector,
  getSetRoomIdSelector,
} from "../stores/selectors/appSelectors";
import {
  all,
  always,
  any,
  append,
  assoc,
  complement,
  filter,
  findIndex,
  head,
  identity,
  ifElse,
  inc,
  insert,
  isEmpty,
  isNil,
  lensProp,
  map,
  mergeAll,
  pipe,
  prop,
  propEq,
  reject,
  reverse,
  set,
  sortBy,
  sum,
  take,
  values,
} from "ramda";
import BarChart from "../components/widgets/charts/BarChart";
import { CSVLink } from "react-csv";
import ChartWrapper from "../components/widgets/overview/ChartWrapper";
import ClientSocketContext from "../contexts/ClientSocketContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DateTimeContext from "../contexts/DateTimeContext";
import LatestConversations from "../components/widgets/overview/LatestConversations";
import QueryRow from "../components/widgets/queries/QueryRow";
import TableHead from "../components/widgets/queries/TableHead";
import { styled } from "@mui/material/styles";
import useAppStore from "../stores/appStore";
import { useHistory } from "react-router-dom";
import { useQueryParamState } from "../hooks";

const PREFIX = "Queries";
const classes = {
  title: `${PREFIX}-title`,
  table: `${PREFIX}-table`,
};

const Root = styled("div")(() => ({
  [`& .${classes.title}`]: {
    display: "flex",
    fontSize: "1.2rem",
    paddingTop: 10,
    paddingLeft: 20,
    justifyContent: "flex-start",
  },

  [`& .${classes.table}`]: {
    minWidth: 650,
  },
}));

const parseRoomId = parseRoomIdForPath(QUERIES);

const FILTER_BY_EVENTS_AND_DATA_QUERY_NAME = "filter_by_events_and_data";

const WS_QUERY_MESSAGE_TYPES = {
  EXECUTE_QUERY: "executeQuery",
  GET_EVENT_DATA_KEYS: "getEventDataKeys",
  GET_EVENT_TYPES: "getEventTypes",
  GET_QUERIES: "getQueries",
  STOP_ITERATION: "stopIteration",
};

const updateResultInTable = (key) => (tableResults) => {
  const incrementedCount = pipe(
    filter(propEq("key", key)),
    ifElse(isEmpty, always(1), pipe(head, prop("count"), inc))
  )(tableResults);

  return pipe(
    reject(propEq("key", key)),
    append({ key, count: incrementedCount }),
    sortBy(prop("count")),
    reverse
  )(tableResults);
};

const eventHandlers = (
  conversations,
  setConversations,
  setTableResults,
  setInputs,
  setAvailableQueries,
  setIsDataLoading,
  showNoResult,
  setShowNoResult,
  updateInputValues,
  setErrorFeedback
) => ({
  error: (data) => {
    setErrorFeedback(data.message);
  },
  executeQuery: (data) => {
    setConversations((conversations) => [...conversations, data]);
    if (data.keys) {
      map(pipe(updateResultInTable, setTableResults))(data.keys);
    }
  },
  getQueries: (data) => {
    const availableQueries = data;
    setInputs(
      pipe(
        map(({ inputs: queryInputs, name }) => ({
          [name]: pipe(
            map(pipe(prop("key"), (key) => ({ [key]: "" }))),
            mergeAll
          )(queryInputs),
        })),
        mergeAll
      )(availableQueries)
    );
    setAvailableQueries(availableQueries);
    const queryNameToFalseValue = pipe(
      map(({ name }) => ({ [name]: false })),
      mergeAll
    )(availableQueries);
    setIsDataLoading(queryNameToFalseValue);
    setShowNoResult(queryNameToFalseValue);
  },
  stopIteration: (data) => {
    setIsDataLoading(map(always(false)));
    if (conversations && !conversations.length) {
      setShowNoResult({ ...showNoResult, [data]: true });
    }
  },
  getEventTypes: (data) => {
    setAvailableQueries(updateInputValues("event_type", data));
    setIsDataLoading((setIsDataLoading) => ({
      ...setIsDataLoading,
      [FILTER_BY_EVENTS_AND_DATA_QUERY_NAME]: false,
    }));
  },
  getEventDataKeys: (data) => {
    setAvailableQueries(updateInputValues("data_key", data));
    setIsDataLoading((setIsDataLoading) => ({
      ...setIsDataLoading,
      [FILTER_BY_EVENTS_AND_DATA_QUERY_NAME]: false,
    }));
  },
});

const queryInputValues = (inputKey, queryName) =>
  pipe(
    queryInputs(queryName),
    filter(propEq("key", inputKey)),
    head,
    prop("values")
  );

const queryInputs = (queryName) =>
  pipe(filter(propEq("name", queryName)), head, prop("inputs"));

export default () => {
  const [selectedRoomId, selectRoomId] = useAppStore(getSetRoomIdSelector);
  const accessToken = useAppStore(accessTokenSelector);

  const { ws, lastMessage, isAnalysisWsReady } =
    useContext(ClientSocketContext);

  const { fromDate, toDate } = useContext(DateTimeContext);
  const [availableQueries, setAvailableQueries] = useState([]);
  const [analysisWSIsReady, setAnalysisWSIsReady] = useState(false);
  const [showConversations, setShowConversations] = useState(false);
  const [showNoResult, setShowNoResult] = useState({});
  const [inputs, setInputs] = useState({});

  // Fetch previous state of inputs object (https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state).
  const prevInputsRef = useRef();
  useEffect(() => {
    prevInputsRef.current = inputs;
  });
  const prevInputs = prevInputsRef.current;

  const [conversations, setConversations] = useState([]);
  const [tableResults, setTableResults] = useState([]);
  const [outputType, setOutputType] = useState("list");
  const [isDataLoading, setIsDataLoading] = useState({});
  const historyRef = useRef(useHistory());

  const [conversationId] = useQueryParamState("conversationId");
  const conversationsRef = useRef(conversations);
  const showNoResultRef = useRef(showNoResult);

  const [feedbackConfig, setFeedbackConfig] = useState({
    show: false,
    message: "",
    severity: "success",
  });

  const updateInputValues = useCallback(
    (inputKey, values) => (availableQueries) => {
      // Quick-fix for stale lastMessage in clientSocketContext on re-entry to queries page
      if (isEmpty(availableQueries)) return [];

      const inputIndex = pipe(
        queryInputs(FILTER_BY_EVENTS_AND_DATA_QUERY_NAME),
        findIndex(propEq("key", inputKey))
      )(availableQueries);

      const updatedInput = pipe(
        queryInputs(FILTER_BY_EVENTS_AND_DATA_QUERY_NAME),
        filter(propEq("key", inputKey)),
        head,
        set(lensProp("values"), values)
      )(availableQueries);

      const updatedInputs = pipe(
        queryInputs(FILTER_BY_EVENTS_AND_DATA_QUERY_NAME),
        reject(propEq("key", inputKey)),
        insert(inputIndex, updatedInput)
      )(availableQueries);

      const queryIndex = findIndex(
        propEq("name", FILTER_BY_EVENTS_AND_DATA_QUERY_NAME)
      )(availableQueries);

      const updatedQuery = pipe(
        filter(propEq("name", FILTER_BY_EVENTS_AND_DATA_QUERY_NAME)),
        head,
        set(lensProp("inputs"), updatedInputs)
      )(availableQueries);

      return pipe(
        reject(propEq("name", FILTER_BY_EVENTS_AND_DATA_QUERY_NAME)),
        insert(queryIndex, updatedQuery)
      )(availableQueries);
    },
    []
  );

  useEffect(() => {
    if (selectedRoomId && conversationId) {
      historyRef.current.replace(
        `/${DASHBOARD}/${selectedRoomId}?conversationId=${conversationId}`
      );
    }
  }, [conversationId, historyRef, selectedRoomId]);

  useEffect(() => {
    const urlSnippetId = parseRoomId(historyRef.current.location);
    if (urlSnippetId) {
      selectRoomId(urlSnippetId);
    }
  }, [selectRoomId]);

  useEffect(() => {
    if (
      selectedRoomId &&
      parseRoomId(historyRef.current.location) !== selectedRoomId &&
      !historyRef.current.location.search.includes("conversationId")
    ) {
      historyRef.current.replace(`/${QUERIES}/${selectedRoomId}`);
    }
  }, [selectedRoomId]);

  const setErrorFeedback = (message) =>
    setFeedbackConfig({
      show: true,
      message,
      severity: "error",
    });

  useEffect(() => {
    if (lastMessage) {
      const f = eventHandlers(
        conversationsRef.current,
        setConversations,
        setTableResults,
        setInputs,
        setAvailableQueries,
        setIsDataLoading,
        showNoResultRef.current,
        setShowNoResult,
        updateInputValues,
        setErrorFeedback
      )[lastMessage.type];
      if (f) f(lastMessage.data);
    }
  }, [
    lastMessage,
    conversationsRef,
    setConversations,
    setTableResults,
    setInputs,
    setAvailableQueries,
    setIsDataLoading,
    showNoResultRef,
    setShowNoResult,
    updateInputValues,
  ]);

  useEffect(() => {
    if (ws && isAnalysisWsReady) {
      setAnalysisWSIsReady(true);
    }

    if (
      ws?.readyState &&
      analysisWSIsReady &&
      availableQueries &&
      !availableQueries.length
    ) {
      ws.send(JSON.stringify({ type: WS_QUERY_MESSAGE_TYPES.GET_QUERIES }));
    }
  }, [
    ws,
    availableQueries,
    isAnalysisWsReady,
    analysisWSIsReady,
    setAnalysisWSIsReady,
  ]);

  useEffect(() => {
    if (pipe(values, any(identity))(showNoResult) && !conversations.length)
      setShowConversations(false);
  }, [conversations.length, showNoResult]);

  useEffect(() => {
    if (
      ws &&
      ws.readyState &&
      selectedRoomId &&
      availableQueries.length &&
      pipe(
        queryInputValues("event_type", FILTER_BY_EVENTS_AND_DATA_QUERY_NAME),
        isNil
      )(availableQueries) &&
      pipe(values, all(complement(identity)))(isDataLoading)
    ) {
      setIsDataLoading((setIsDataLoading) => ({
        ...setIsDataLoading,
        [FILTER_BY_EVENTS_AND_DATA_QUERY_NAME]: true,
      }));
      ws.send(
        JSON.stringify({
          type: WS_QUERY_MESSAGE_TYPES.GET_EVENT_TYPES,
          roomId: selectedRoomId,
        })
      );
    }
  }, [availableQueries, selectedRoomId, ws, isDataLoading]);

  useEffect(() => {
    if (
      ws &&
      inputs.filter_by_events_and_data?.event_type &&
      inputs.filter_by_events_and_data?.event_type !==
        prevInputs.filter_by_events_and_data?.event_type
    ) {
      setIsDataLoading((setIsDataLoading) => ({
        ...setIsDataLoading,
        [FILTER_BY_EVENTS_AND_DATA_QUERY_NAME]: true,
      }));
      ws.send(
        JSON.stringify({
          type: WS_QUERY_MESSAGE_TYPES.GET_EVENT_DATA_KEYS,
          input: {
            eventType: inputs?.filter_by_events_and_data?.event_type,
          },
        })
      );
    }
  }, [inputs, prevInputs, ws]);

  const buildFilters = () => ({
    bot_id: selectedRoomId,
    from_date: fromDate,
    to_date: toDate,
  });

  const sendQuery = (queryName) => () => {
    setTableResults([]);
    const executeQueryMessage = JSON.stringify({
      token: accessToken,
      type: WS_QUERY_MESSAGE_TYPES.EXECUTE_QUERY,
      filters: buildFilters(),
      input: inputs[queryName],
      queryName,
    });
    ws.send(executeQueryMessage);
    setIsDataLoading((setIsDataLoading) => ({
      ...setIsDataLoading,
      [queryName]: true,
    }));
    setConversations([]);
    setShowConversations(true);
    setShowNoResult(map(always(false)));
  };

  return isEmpty(availableQueries) ? (
    <HyroLoader />
  ) : (
    <Root>
      <Paper>
        <Table>
          <TableHead
            headCells={["Report name", "Description", "Attributes", "Actions"]}
          />
          <TableBody>
            {availableQueries?.map((availableQuery) => (
              <QueryRow
                analysisWSIsReady={analysisWSIsReady}
                availableQuery={availableQuery}
                conversations={conversations}
                key={name}
                inputs={inputs}
                isDataLoading={isDataLoading}
                sendQuery={sendQuery}
                setInputs={setInputs}
                showNoResult={showNoResult}
                ws={ws}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
      {tableResults.length ? (
        <ToggleButtonGroup
          value={outputType}
          exclusive
          onChange={(event, newOutputType) => setOutputType(newOutputType)}
        >
          <ToggleButton value="list">List</ToggleButton>
          <ToggleButton value="table">Table</ToggleButton>
          <ToggleButton value="graph">Graph</ToggleButton>
        </ToggleButtonGroup>
      ) : null}
      {outputType === "list" && showConversations && conversations && (
        <LatestConversations
          isDataLoading={pipe(values, any(identity))(isDataLoading)}
          showRefresh
          useFilters={false}
          usePagers={false}
          conversations={conversations}
        />
      )}
      {outputType === "table" && tableResults.length ? (
        <Paper
          style={{
            padding: "15px",
          }}
        >
          <Grid
            container
            direction="row"
            spacing={3}
            justifyContent="space-between"
          >
            <Grid item>
              <div className={classes.title}>
                Query summary ({sum(map(prop("count"))(tableResults))} results
                found{" "}
                {pipe(values, any(identity))(isDataLoading)
                  ? "so far..."
                  : "in total"}
                )
              </div>
            </Grid>
            <Grid item>
              <CSVLink filename="Query summary" data={tableResults}>
                <IconButton
                  color="inherit"
                  className={classes.button}
                  disabled={isEmpty(tableResults)}
                  size="large"
                >
                  <CloudDownloadIcon />
                </IconButton>
              </CSVLink>
            </Grid>
          </Grid>
          <Table>
            <TableHead headCells={["Data key", "Count"]} />
            <TableBody>
              {tableResults.map((obj) => (
                <TableRow key={obj.key}>
                  <TableCell>{obj.key}</TableCell>
                  <TableCell>{obj.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      ) : null}
      {outputType === "graph" && tableResults.length ? (
        <ChartWrapper
          title="Visualization"
          showNoDataMessage={!tableResults.length}
        >
          <BarChart
            data={pipe(
              map((result, idx) => ({
                label: result.key,
                value: result.count,
                id: idx,
                shortLabel: result.key,
              })),
              take(10)
            )(tableResults)}
            totalSum={pipe(map(prop("count")), sum)(tableResults)}
            layout="vertical"
          />
        </ChartWrapper>
      ) : null}
      <HyroFeedbackMessage
        message={feedbackConfig.message}
        severity={feedbackConfig.severity}
        showFeedback={feedbackConfig.show}
        closeFeedback={() => setFeedbackConfig(assoc("show", false))}
      />
    </Root>
  );
};
