import { Box, Paper } from "@mui/material";
import {
  HyroInfoBox,
  HyroLoader,
  HyroTabsWithTitle,
} from "../components/hyro-components";
import {
  isRefreshingTripletsSelector,
  knowledgeEntityTypesSelector,
} from "../stores/selectors/tripletsSelectors";
import KnowledgeEditorTable from "../components/widgets/knowledge-editor/KnowledgeEditorTable";
import React from "react";
import { default as gamla } from "gamlajs";
import { isEmpty } from "ramda";
import useAppStore from "../stores/appStore";

const getTabsSpec = (types) =>
  types.map((type) => ({
    label: gamla.capitalize(type),
    value: type,
    content: <KnowledgeEditorTable entityType={type} />,
  }));

export default () => {
  const knowledgeEntityTypes = useAppStore(knowledgeEntityTypesSelector);
  const isRefreshingTriplets = useAppStore(isRefreshingTripletsSelector);
  const tabsSpec = getTabsSpec(knowledgeEntityTypes);

  if (isRefreshingTriplets) return <HyroLoader />;

  return isEmpty(knowledgeEntityTypes) ? (
    <Box mx={4}>
      <Paper>
        <HyroInfoBox
          title="Not available"
          body="Please define a knowledge editor table spec for this assistant."
        />
      </Paper>
    </Box>
  ) : (
    <Box mt={4}>
      <HyroTabsWithTitle title="Knowledge Editor" tabsSpec={tabsSpec} />
    </Box>
  );
};
