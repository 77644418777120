import { Box } from "@mui/material";
import DataSourcesIcon from "../../../static/images/data_sources.svg";
import React from "react";

export default () => (
  <Box
    display="flex"
    flexDirection="column"
    flex={1}
    alignItems="center"
    justifyContent="center"
  >
    <img width="40px" height="40px" src={DataSourcesIcon} />
    <Box
      fontFamily="roboto"
      fontWeight={500}
      fontSize="20px"
      color="#00000099"
      pt="30px"
    >
      No data sources were added yet
    </Box>
  </Box>
);
