import React from "react";

export default ({ fill }) => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8322 7.33L15.8322 14H3.83221L8.83221 7.33V2H10.8322V7.33ZM13.7922 0H5.87221C5.45221 0 5.22221 0.48 5.48221 0.81L6.83221 2.5V6.67L1.03221 14.4C0.542207 15.06 1.01221 16 1.83221 16H17.8322C18.6522 16 19.1222 15.06 18.6322 14.4L12.8322 6.67V2.5L14.1822 0.81C14.4422 0.48 14.2122 0 13.7922 0Z"
      fill={fill}
      fillOpacity="1"
    />
  </svg>
);
