import React, { useEffect, useState } from "react";
import { isEmpty, pathOr, pipe } from "ramda";
import {
  roomIdSelector,
  roomsSelector,
} from "../stores/selectors/appSelectors";
import moment from "moment-timezone";
import { objectByRoomId } from "../utils";
import useAppStore from "../stores/appStore";

const DateTimeContext = React.createContext(null);

const DEFAULT_DATE_RANGE = moment.duration(1, "w");
const DEFAULT_ET_TIME_ZONE = "America/New_York";

const DateTimeProvider = ({ children }) => {
  const rooms = useAppStore(roomsSelector);
  const [timeZone, setTimeZone] = useState(DEFAULT_ET_TIME_ZONE);
  const selectedRoomId = useAppStore(roomIdSelector);

  const timeZoneMoment = (timeString) => moment(timeString).tz(timeZone);

  const [fromDate, setFromDate] = useState(
    timeZoneMoment().subtract(DEFAULT_DATE_RANGE).startOf("d").toDate()
  );
  const [toDate, setToDate] = useState(timeZoneMoment().toDate());

  const ctxValue = {
    timeZone,
    setTimeZone,
    timeZoneMoment,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
  };

  useEffect(() => {
    if (selectedRoomId && !isEmpty(rooms)) {
      setTimeZone(
        pipe(
          objectByRoomId,
          pathOr(DEFAULT_ET_TIME_ZONE, ["config", "analysis", "timezone"])
        )(selectedRoomId, rooms)
      );
    }
  }, [rooms, selectedRoomId, setTimeZone]);

  return (
    <DateTimeContext.Provider value={ctxValue}>
      {children}
    </DateTimeContext.Provider>
  );
};

export default DateTimeContext;

export { DateTimeProvider };
