import { CircularProgress, Grid, Typography } from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import React from "react";
import useAppStore from "../../stores/appStore";
import { userDataSelector } from "../../stores/selectors/appSelectors";

export default ({ isSavingTriplets }) => {
  const { given_name } = useAppStore(userDataSelector);
  return (
    <Grid
      item
      container
      spacing={2}
      direction="row"
      justifycontent="flex-start"
      alignItems="center"
      xs={5}
    >
      <Grid item spacing={2}>
        <Typography variant="subtitle2">
          {given_name}, you are in draft mode
        </Typography>
      </Grid>
      <Grid item spacing={1}>
        <Typography sx={{ color: "rgba(255,255,255,0.4)" }}>|</Typography>
      </Grid>

      <Grid display="flex" gap={1} item spacing={1}>
        {isSavingTriplets ? (
          <CircularProgress size="16px" {...{ style: { color: "white" } }} />
        ) : (
          <CheckIcon fontSize="small" {...{ style: { color: "white" } }} />
        )}

        <Typography variant="subtitle2">
          {
            isSavingTriplets ? "Saving..." : `Saved`
            // THIS NEEDS TO BE ADDED AFTER WE KEEP TIMESTAMP ON DRAFT
            // ${
            //     lastUpdateTimestamp
            //       ? parseInt(
            //           moment
            //             .duration(moment().diff(lastUpdateTimestamp))
            //             .asMinutes()
            //         ) + " minutes ago"
            //       : ""
            //   }`
          }
        </Typography>
      </Grid>
    </Grid>
  );
};
