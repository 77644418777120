const CTA_ELEMENT = "CTA";
const DESCRIPTION_ELEMENT = "Description";
const SUBTITLE_ELEMENT = "Subtitle";
const TAG_ELEMENT = "Tag";

const RESULT_CARD_CONFIG_DIALOG_TYPES = {
  OPTIONS: "options",
  OPTIONS_WITH_TEXT: "options with text",
};

export {
  CTA_ELEMENT,
  DESCRIPTION_ELEMENT,
  SUBTITLE_ELEMENT,
  TAG_ELEMENT,
  RESULT_CARD_CONFIG_DIALOG_TYPES,
};
