import { Box, ButtonBase } from "@mui/material";
import React from "react";

export default ({ name, Icon, selected, onClick, disabled }) => (
  <ButtonBase
    sx={{
      border: `1px solid ${selected ? "#3F51B5" : "#00000025"}`,
      borderRadius: "4px",
      flex: 1,
      ...(selected
        ? { backgroundColor: "#3F51B514" }
        : {
            "&:hover": {
              backgroundColor: "#3F51B514",
              border: "1px solid #3F51B514",
            },
          }),
      height: "56px",
    }}
    disabled={disabled}
    onClick={onClick}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "12px",
      }}
    >
      {Icon && (
        <Box pr="10px">
          <Icon fill={selected ? "#3F51B5" : "#00000099"} />
        </Box>
      )}
      <Box
        fontSize="16px"
        fontFamily="roboto"
        color={selected ? "#3F51B5" : "#00000099"}
      >
        {name}
      </Box>
    </Box>
  </ButtonBase>
);
