import { Grid } from "@mui/material";
import HyroTabs from "./HyroTabs";
import React from "react";

export default ({ title, tabsSpec }) => (
  <HyroTabs
    headerComponent={
      <Grid
        container
        spacing={10}
        direction="row"
        justifyContent="space-between"
      >
        <Grid item xs={5}>
          <h1>{title}</h1>
        </Grid>
      </Grid>
    }
    tabsSpec={tabsSpec}
  />
);
