import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";

export default ({ handleClick }) => (
  <IconButton
    color="inherit"
    onClick={handleClick}
    edge="start"
    size="large"
    sx={{
      marginRight: "10px",
      "&:focus": {
        outline: 0,
      },
    }}
  >
    <MenuIcon />
  </IconButton>
);
