import {
  allPass,
  any,
  apply,
  ascend,
  complement,
  concat,
  cond,
  equals,
  find,
  flatten,
  fromPairs,
  groupBy,
  has,
  head,
  ifElse,
  isEmpty,
  isNil,
  juxt,
  length,
  map,
  mergeAll,
  mergeWith,
  nth,
  objOf,
  of,
  omit,
  pipe,
  prop,
  reduce,
  reject,
  sortWith,
  split,
  toPairs,
  unapply,
  uniq,
  values,
  when,
  xprod,
} from "ramda";

import { default as gamla } from "gamlajs";

const subject = prop(0);
const relation = prop(1);
const object = prop(2);

const sortTriplets = sortWith([
  ascend(subject),
  ascend(relation),
  ascend(object),
]);

const RESULT_CARD_CONFIG_RELATIONS = {
  RENDER_TITLE: "render/title",
  RENDER_TEXT: "render/text",
  RENDER_TAGS: "render/tags",
  RENDER_SUGGESTIONS: "render/suggestions",
  RENDER_CTA_TEXT: "render/cta_text",
};

const Relations = {
  ...RESULT_CARD_CONFIG_RELATIONS,
  ACTION_EVENT: "action/event",
  ACTION_SUGGESTIONS: "action/suggestions",
  ACTION_SWITCH_COMMAND: "action/switch_command",
  ACTION_EXPLICIT_CONTEXT_DROP: "action/explicit_context_drop",
  ACTION_TEXT: "action/text",
  ASK_IF_QUESTION: "ask_if/question",
  ATTRIBUTE_TEMPLATE: "attribute/template",
  ATTRIBUTE_STATUS: "attribute/status",
  ATTRIBUTE_NAME: "attribute/name",
  ATTRIBUTE_CUSTOMIZED_CODE: "attribute/customized_code",
  SWITCH_COMMAND_PHONE: "switch_command/phone",
  SWITCH_COMMAND_TYPE: "switch_command/type",
  CONCEPT_DISPLAY: "concept/display",
  CONCEPT_TRIGGER: "concept/trigger",
  CONCEPT_PRIORITY_GROUP: "concept/priority_group",
  CONDITION_ALL: "condition/all",
  CONDITION_ANY: "condition/any",
  CONDITION_COMPLEMENT: "condition/complement",
  CONDITION_GT: "condition/gt",
  CONDITION_LT: "condition/lt",
  CONDITION_QUESTION: "condition/question",
  CONDITION_IS_TRUE: "condition/is_true",
  CONSTANT_KEY: "constant/key",
  CONSTANT_VALUE: "constant/value",
  CONSTANT_DESCRIPTION: "constant/description",
  DATA_SOURCE_FORMAT: "data_source/format",
  DATA_SOURCE_FILE_HASH: "data_source/file_hash",
  DATA_SOURCE_NAME: "data_source/name",
  GOAL_DO: "goal/do",
  GOAL_MISUNDERSTANDING: "goal/misunderstanding",
  GOAL_CONDITION: "goal/condition",
  ENTITY_FIELDS: "entity/fields",
  EVENT_DATA: "event/data",
  EVENT_TYPE: "event/type",
  FIELD_ENTITY: "field/entity",
  FIELD_LABEL: "field/label",
  FIELD_OPTION: "field/option",
  FIELD_RELATION: "field/relation",
  FIELD_REQUIRED: "field/required",
  FIELD_TYPE: "field/type",
  QUESTION_ACTION: "question/action",
  QUESTION_LISTENER: "question/listener",
  LISTENER_TYPE: "listener/type",
  SUGGESTION_DISPLAY_TEXT: "suggestion/display_text",
  SUGGESTION_PHONE: "suggestion/phone",
  SUGGESTION_SAY_TEXT: "suggestion/say_text",
  SUGGESTION_URL: "suggestion/url",
  CONCEPT_ACTION: "concept/action",
  RELATION_TYPE: "relation/type",
  DO_CONDITION: "do/condition",
  DO_REQUEST: "do/request",
  DO_MANDATORY: "do/mandatory",
  DO_IMPLEMENTATION: "do/implementation",
  IMPLEMENTATION_NAME: "implementation/name",
  IMPLEMENTATION_MODULE: "implementation/module",
  REQUEST_KEY_VALUE: "request/key_value",
  REQUEST_METHOD: "request/method",
  REQUEST_CONTENT_TYPE: "request/content_type",
  REQUEST_AUTHORIZATION: "request/authorization_prod",
  REQUEST_URL: "request/url",
  REQUIREMENT_KEY: "requirement/key",
  REQUIREMENT_VALUE: "requirement/value",
  KV_KEY: "key_value/key",
  KV_VALUE: "key_value/value",
  KV_STRING: "key_value/string",
  PHONE_SOURCE: "phone/source",
  MAPPER_ORIGIN: "mapper/origin",
  LOCATION_CONTEXT_SEARCH_RADIUS: "location_context/search_radius",
  LOCATION_CONTEXT_SOUTHWEST_LONGITUDE: "location_context/southwest_longitude",
  LOCATION_CONTEXT_SOUTHWEST_LATITUDE: "location_context/southwest_latitude",
  LOCATION_CONTEXT_NORTHEAST_LONGITUDE: "location_context/northeast_longitude",
  LOCATION_CONTEXT_NORTHEAST_LATITUDE: "location_context/northeast_latitude",
  CONDITION_IS_CONCEPT_TYPE: "condition/is_concept_type",
  CONDITION_IS_EVENT_TYPE: "condition/is_event_type",
  CONDITION_IS_ELEMENT_TYPE: "condition/is_element_type",
  GOAL_COMBINE: "goal/combine",
  AGENT_ORDER: "agent/order",
  SITE_SEARCH_BLOCK_URL: "site_search/block_url",
  SITE_SEARCH_BLOCK_NOTE: "site_search/block_note",
  SITE_SEARCH_URL: "site_search/url",
  SITE_SEARCH_DEPTH: "site_search/depth",
  MODULE_PATH: "module/path",
};

const TRIPLETS_STATUSES = {
  IDLE: "idle",
  LOADING: "loading",
  SAVING: "saving",
  PUBLISHING: "publishing",
};

const objectEq = (obj) => pipe(object, equals(obj));
const objectIn = (objs) => pipe(object, gamla.contains(objs));
const relationEq = (rel) => pipe(relation, equals(rel));
const relationIn = (rels) => pipe(relation, gamla.contains(rels));
const relationPrefixEq = (pref) =>
  pipe(relation, split("/"), nth(0), equals(pref));
const subjectEq = (sub) => pipe(subject, equals(sub));
const subjectIn = (subs) => pipe(subject, gamla.contains(subs));
const getObjectByRelation = (rel) => pipe(find(relationEq(rel)), object);

const groupBySubject = groupBy(subject);
const groupByRelationship = groupBy(relation);

/**
 * Receives object.
 * Morphs an object into [subject,relation,object] triplets with a shared "id"
 * @example
 * objectToTriplets({id: "id_001", key1: "val1", key2: "val2"})
 *
 * Out:
 * [["id_001", "key1", "val1"],
 * ["id_001", "key2", "val2"]]
 */
const objectToTriplets = pipe(
  juxt([pipe(prop("id"), of), pipe(omit(["id"]), reject(isEmpty), toPairs)]),
  apply(xprod),
  map(flatten)
);

// TODO(Marco): Move to Gamlajs
/**
 * checks if the first arg equals the length of the second arg
 */
const lenEq = (num) => pipe(length, equals(num));

/**
 * Combines items uniquely into a flattened list, if a list is necessary
 */
const combineUniq = pipe(unapply(flatten), uniq, when(lenEq(1), head));

// TODO(Marco): Move to Gamlajs
/**
 * Merges an array of objects into a single object,
 * assigning a duplicated key to the output of fn called on its values
 */
const mergeAllWith = (fn) => reduce(mergeWith(fn), {});

/**
 * Receives triplets.
 * Extracts triplets by relation list, groups by id, and creates objects with key:value pairs.
 * Duplicate relations form arrays of values
 * fillRels not found for particular groups are filled with "".
 * @example
 * const triplets =[
 *  ["id_001", "key1", "dog"],
 *  ["id_001", "key2", "cat"],
 *  ["id_002", "key1", "wolf"],
 *  ["id_002", "key1", "husky"],
 * ]
 * tripletsToObjects(triplets)
 *
 * Out:
 * [
 *  { id: "id_001", key1: "dog", key2: "cat" },
 *  { id: "id_002", key1: ["wolf", "husky"], key2: "" },
 * ]
 */
const tripletsToObjects = pipe(
  groupBySubject,
  map(
    pipe(
      map(
        pipe(
          juxt([
            pipe(subject, objOf("id")),
            pipe(juxt([relation, object]), of, fromPairs),
          ]),
          mergeAll
        )
      ),
      mergeAllWith(combineUniq)
    )
  ),
  values
);

/**
 * Receives: triplets setState, followed by object.
 * Runs setState rejecting any current triplets that match by id
 */
const rejectTripletsByObjectId = (setPendingTriplets) => (obj) => {
  setPendingTriplets(reject(subjectEq(prop("id")(obj))));
};

/**
 * Receives: triplets setState, followed by object which contains id key,
 * and rel:obj key-value pairs.
 * Runs setState, rejecting any current triplets that match by id,
 * and concat new triplets based on the object's definition.
 */
const addObjectToTriplets = (setPendingTriplets) => (obj) => {
  const newTriplets = objectToTriplets(obj);
  setPendingTriplets(
    pipe(
      reject(subjectEq(pipe(head, subject)(newTriplets))),
      concat(newTriplets)
    )
  );
};

const possiblyLookupDisplay = (triplets) => {
  const subjectIndex = groupBySubject(triplets);
  return when(
    allPass([
      complement(isNil),
      pipe((subject) => subjectIndex[subject], complement(isNil)),
    ]),
    pipe(
      (subject) => subjectIndex[subject],
      ifElse(
        any(relationEq(Relations.CONCEPT_DISPLAY)),
        pipe(find(relationEq(Relations.CONCEPT_DISPLAY)), object),
        pipe(head, subject)
      )
    )
  );
};

const PHONE = "phone";
const URL = "url";
const SAY_TEXT = "sayText";
const PROVIDER = "provider";
const PROFESSIONAL_SUFFIX = "professional suffix";
const SPECIALTY = "specialty";
const SUBSPECIALTY = "subspecialty";
const EXPERTISE = "expertise";
const ACCEPTING_NEW_PATIENTS = "accepting new patients";
const ONLINE_SCHEDULING = "online scheduling";
const PROFILE_LINK = "profile link";

const getSuggestionContent = cond([
  [has(PHONE), prop(PHONE)],
  [has(URL), prop(URL)],
  [has(SAY_TEXT), prop(SAY_TEXT)],
]);

export {
  PHONE,
  PROVIDER,
  PROFESSIONAL_SUFFIX,
  SPECIALTY,
  TRIPLETS_STATUSES,
  SUBSPECIALTY,
  EXPERTISE,
  ACCEPTING_NEW_PATIENTS,
  ONLINE_SCHEDULING,
  PROFILE_LINK,
  RESULT_CARD_CONFIG_RELATIONS,
  Relations,
  SAY_TEXT,
  URL,
  addObjectToTriplets,
  getObjectByRelation,
  getSuggestionContent,
  groupByRelationship,
  groupBySubject,
  object,
  objectEq,
  objectIn,
  objectToTriplets,
  possiblyLookupDisplay,
  rejectTripletsByObjectId,
  relation,
  relationEq,
  relationIn,
  relationPrefixEq,
  sortTriplets,
  subject,
  subjectEq,
  subjectIn,
  tripletsToObjects,
};
