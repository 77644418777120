import { Grid, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "ChangeRequestsNotFound";

const classes = {
  noMrText: `${PREFIX}-noMrText`,
  noMrBox: `${PREFIX}-noMrBox`,
  checkIcon: `${PREFIX}-checkIcon`,
};

const StyledMenuItem = styled(MenuItem)(() => ({
  [`& .${classes.noMrText}`]: {
    opacity: 0.6,
    fontSize: "14px",
  },

  [`& .${classes.noMrBox}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  [`& .${classes.checkIcon}`]: {
    opacity: 0.4,
    width: "40px",
    height: "40px",
  },
}));

export default () => (
  <StyledMenuItem
    disabled
    style={{
      opacity: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    divider
  >
    <Box height="150px" className={classes.noMrBox}>
      <Grid
        container
        direction="column"
        justifycontent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <CheckCircleIcon className={classes.checkIcon} />
        </Grid>
        <Grid item>
          <span className={classes.noMrText}>There are no change requests</span>
        </Grid>
      </Grid>
    </Box>
  </StyledMenuItem>
);
