import { BotUtterance, BotUtteranceDiff, Event, UserUtterance } from ".";
import { Box, Button, CircularProgress, Grid, Tooltip } from "@mui/material";
import { HYRO_COLOR_HEX, SEVERITY_LEVELS } from "../../../../utils";
import {
  __,
  all,
  always,
  anyPass,
  eqBy,
  filter,
  hasPath,
  includes,
  join,
  length,
  map,
  pipe,
  prop,
  propEq,
  reduce,
  toPairs,
} from "ramda";

import React from "react";
import UtterancePlayer from "./UtterancePlayer";
import eventTypes from "./EventTypes";
import moment from "moment";

const stringifyEventData = (data) =>
  isString(data)
    ? data
    : (data.name ? [data.name] : []) +
      toPairs(data)
        .filter(([key, value]) => key !== "name" && value)
        .map(pipe(map(JSON.stringify), join(": ")))
        .join(", ");

const isString = (value) =>
  typeof value === "string" || value instanceof String;

const getUpdatedResponse = (responses, [left, right]) =>
  responses[left].data.text === responses[right].data.text
    ? responses[0]
    : {
        eventType: eventTypes.BOT_UTTERANCE_DIFF,
        data: [...responses.map(prop("data"))],
        compared: [left, right],
      };
const enrichBotUtterancesReducer =
  (responses, compare, labels) =>
  ({ result, botUtterancesIndex }, event) =>
    event.eventType === eventTypes.BOT_UTTERANCE &&
    !event.data.lowConfidenceSTT &&
    (!event.data.sender || event.data.sender === "bot")
      ? {
          result: [
            ...result,
            {
              ...getUpdatedResponse(
                [event, ...responses.map(prop(botUtterancesIndex))],
                compare
              ),
              labels,
            },
          ],
          botUtterancesIndex: botUtterancesIndex + 1,
        }
      : {
          result: [...result, event],
          botUtterancesIndex,
        };

const enhanceConversationWithUpdatedResponses = (responses, compare, labels) =>
  pipe(
    reduce(enrichBotUtterancesReducer(responses, compare, labels), {
      // Will store the new events.
      result: [],
      // Keeps track of the amount of bot utterances seen.
      botUtterancesIndex: 0,
    }),
    prop("result")
  );

const isSuggestionClickNonTextual = (data) =>
  data &&
  (data.imageUrl ||
    data.phone ||
    data.url ||
    data.fax ||
    data.httpRequest ||
    data.urlToRedirect);

const isConversionEvent = ({ eventType, data }) =>
  [
    eventTypes.RESULT_CLICK,
    eventTypes.PHONE_TAG_CLICK,
    eventTypes.URL_TAG_CLICK,
    eventTypes.AJAX_ACTION,
  ].includes(eventType) ||
  (eventType === eventTypes.SUGGESTION_CLICK &&
    isSuggestionClickNonTextual(data));

const ofType = propEq("eventType");

const renderEvent =
  (isDetailedMode, isLoadingEither, conversationTime) => (event) => {
    const duration = moment.duration(
      moment(event.createdAt).diff(conversationTime)
    );

    return (
      <Tooltip
        key={event._id}
        placement="right"
        target={`tooltip-${event._id}`}
        title={`+${Math.trunc(duration.asMinutes())}m ${duration.seconds()}s`}
        enterDelay={0}
      >
        <Box mb={1} mx={0.5} id={`tooltip-${event._id}`}>
          {(
            perEventRendering(isDetailedMode)[event.eventType] ||
            ((event) => (
              <Event
                color="secondary"
                data={event.data}
                text={event.eventType}
              />
            ))
          )(event)}
        </Box>
      </Tooltip>
    );
  };

const successEvent = ({ eventType, data }) => (
  <Event color={SEVERITY_LEVELS.SUCCESS} text={eventType} data={data} />
);

const mouseEvent = ({ data }) => (
  <Event color="secondary" text={stringifyEventData(data)} />
);

const perEventRendering = (isDetailedMode) => ({
  [eventTypes.WIDGET_LOAD]: ({ data, browserInfo }) => (
    <Event
      color="primary"
      text={`Widget loaded (${
        data && data.location ? data.location.pathname : ""
      })`}
      data={{ data, browserInfo }}
    />
  ),
  [eventTypes.WIDGET_CLICK]: ({ data }) => (
    <Event
      color="primary"
      text={`Widget ${data && data.name === "open" ? "opened" : "closed"}`}
    />
  ),
  [eventTypes.RESULT_CLICK]: successEvent,
  [eventTypes.AJAX_ACTION]: successEvent,
  [eventTypes.URL_TAG_CLICK]: successEvent,
  [eventTypes.PHONE_TAG_CLICK]: successEvent,
  [eventTypes.SUGGESTION_CLICK]: ({ data }) => (
    <Event
      color={
        isSuggestionClickNonTextual(data)
          ? SEVERITY_LEVELS.SUCCESS
          : "secondary"
      }
      text={`Suggestion Click ${data && isString(data) ? data : ""}`}
      data={data && !isString(data) ? data : null}
    />
  ),
  [eventTypes.MATCHED_WORD_REVIEW]: ({ data }) => (
    <Event
      color="warning"
      text={`REVIEW: ${data.originalText} ⟷ ${data.suggestedText}`}
    />
  ),
  [eventTypes.MATCHED_WORD_REVIEW_CLICK]: ({ data }) => (
    <Event
      color={SEVERITY_LEVELS.SUCCESS}
      text={`CLICK: ${data.originalText} ⟷ ${data.suggestedText} ${
        data.approve ? "✅" : "❌"
      }`}
    />
  ),
  [eventTypes.MISUNDERSTANDING]: () => (
    <Event color="danger" text="Misunderstanding" />
  ),
  [eventTypes.INFEASIBLE]: () => <Event color="danger" text="Infeasible" />,
  [eventTypes.INTENT]: ({ data }) => (
    <Event color="warning" text={stringifyEventData(data)} />
  ),
  [eventTypes.DISAMBIGUATION]: () => (
    <Event color="warning" text="Disambiguation" />
  ),
  [eventTypes.CONVERSION]: ({ data }) => (
    <Event
      color={SEVERITY_LEVELS.SUCCESS}
      text={`CONVERSION (${stringifyEventData(data)})`}
    />
  ),
  [eventTypes.BOT_UTTERANCE]: ({ data }) => (
    <BotUtterance {...data} isDetailedMode={isDetailedMode} />
  ),
  [eventTypes.BOT_UTTERANCE_DIFF]: ({ data, _id, labels }) => (
    <BotUtteranceDiff responses={data} labels={labels} id={_id} />
  ),
  [eventTypes.BOT_UTTERANCE_FEEDBACK]: ({ data }) => (
    <Event
      color={data.feedback === "THUMB_UP" ? SEVERITY_LEVELS.SUCCESS : "danger"}
      text={`Feedback ${data.feedback === "THUMB_UP" ? "👍" : "👎"}`}
      data={data}
    />
  ),
  [eventTypes.USER_UTTERANCE]: ({ data }) => <UserUtterance {...data} />,
  [eventTypes.MOUSE_ENTER]: mouseEvent,
  [eventTypes.MOUSE_LEAVE]: mouseEvent,
  [eventTypes.ERROR]: ({ data }) => (
    <Event
      color="danger"
      text={`Error (${data && data.code ? data.code : ""})`}
      data={data}
    />
  ),
});

const ConversationRecording = ({ events }) => {
  const recordingEvent = events.find(
    propEq("eventType", eventTypes.CONVERSATION_RECORDING)
  );
  return (
    <Box mb={1}>
      {recordingEvent && (
        <UtterancePlayer cloudRecordingId={recordingEvent.data.url} />
      )}
    </Box>
  );
};

export default ({
  conversation,
  updatedResponses: { isLoading, responses, labels, hasError, reload, compare },
  isDetailedMode,
  showDiff,
  setAlignableDiff,
}) => {
  const isLoadingEither = conversation.isLoading || isLoading;

  const botUtterances = filter(
    ofType(eventTypes.BOT_UTTERANCE),
    conversation.detail
  );

  responses = map(
    (arr) => map((e) => Object({ data: e, eventType: "BOT_UTTERANCE" }), arr),
    responses
  );

  const alignableDiff = all(eqBy(length, botUtterances), responses);

  if (alignableDiff && responses.length) {
    setAlignableDiff(true);
  }

  const conversationEvents = (
    alignableDiff && responses.length && showDiff
      ? enhanceConversationWithUpdatedResponses(responses, compare, [
          conversation.label,
          ...labels,
        ])(conversation.detail)
      : conversation.detail
  ).filter(
    isDetailedMode
      ? always(true)
      : anyPass([
          pipe(
            prop("eventType"),
            includes(__, [
              eventTypes.BOT_UTTERANCE_DIFF,
              eventTypes.BOT_UTTERANCE_FEEDBACK,
              eventTypes.BOT_UTTERANCE,
              eventTypes.USER_UTTERANCE,
              eventTypes.WIDGET_LOAD,
            ])
          ),
          isConversionEvent,
          hasPath(["data", "cloudRecordingId"]),
        ])
  );

  return (
    <>
      {isLoadingEither && (
        <Grid item mt={1} textAlign="center">
          <CircularProgress
            size={24}
            style={{ color: HYRO_COLOR_HEX, marginBottom: "5px" }}
          />
        </Grid>
      )}

      {!alignableDiff && (
        <Grid item>
          <Event
            color="warning"
            text="Mismatched number of bot responses between runs! not showing diff!"
          />
        </Grid>
      )}
      {
        <Grid item>
          <ConversationRecording events={conversation.detail} />
        </Grid>
      }
      {conversationEvents.map((event) => (
        <Grid item width="100%" key={event}>
          {renderEvent(
            isDetailedMode,
            isLoadingEither,
            conversation.conversationTime
          )(event)}
        </Grid>
      ))}
      {conversation.hasError && (
        <>
          <Grid item>
            <Event color="warning" text="Error while loading conversation." />
          </Grid>
          <Grid item mt={1}>
            <Button
              color="primary"
              variant="contained"
              onClick={conversation.reload}
            >
              Retry
            </Button>
          </Grid>
        </>
      )}
      {hasError && (
        <>
          <Grid item>
            <Event
              color="warning"
              text="Error while loading updated responses."
            />
          </Grid>
          <Grid item mt={1}>
            <Button variant="contained" color="primary" onClick={reload}>
              Retry
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};
