import { Divider, FormControlLabel, RadioGroup, Switch } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { HyroDialog } from "../../../hyro-components";
import RadioWithHelperText from "./RadioWithHelperText";
import { map } from "ramda";

export default ({ name, value, options, nullable, onSave, onClose }) => {
  const [newValue, setNewValue] = useState(value);
  const [showOnCard, setShowOnCard] = useState(nullable ? value !== "" : true);
  const toggleShowOnCard = (checked) => {
    setNewValue(checked ? value : "");
    setShowOnCard(checked);
  };
  return (
    <HyroDialog
      saveDisabledOverride={value === newValue}
      fieldSpec={[
        {
          component: (
            <>
              {nullable && (
                <>
                  <FormControlLabel
                    label="Show on card"
                    control={
                      <Switch
                        checked={showOnCard}
                        onChange={({ target: { checked } }) =>
                          toggleShowOnCard(checked)
                        }
                        color="primary"
                      />
                    }
                  />
                  <Box paddingTop={1} paddingBottom={2}>
                    <Divider />
                  </Box>
                </>
              )}
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label={name}
                  value={newValue}
                  onChange={({ target: { value } }) => setNewValue(value)}
                  name="radio-buttons-group"
                >
                  {map(({ text, helperText }) => (
                    <RadioWithHelperText
                      disabled={!showOnCard}
                      value={text}
                      helperText={helperText}
                    />
                  ))(options)}
                </RadioGroup>
              </FormControl>
            </>
          ),
        },
      ]}
      handleClose={onClose}
      handleSubmit={() => onSave(newValue)}
      submitButtonText="Save"
      title={`Edit ${name}`}
    />
  );
};
