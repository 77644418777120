import { Card, CardContent, Grid } from "@mui/material";
import React, { useState } from "react";

import JSONView from "./JSONView";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import UtterancePlayer from "./UtterancePlayer";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { not } from "ramda";

const utteranceTypeToDisplay = (utteranceType, onPlay) =>
  ({
    voice: (
      <VolumeUpIcon
        size="small"
        onClick={onPlay}
        style={{ cursor: "pointer", margin: 0, opacity: "60%" }}
      />
    ),
    text: <KeyboardIcon size="small" style={{ margin: 0, opacity: "60%" }} />,
    suggestion: (
      <TouchAppIcon size="small" style={{ margin: 0, opacity: "60%" }} />
    ),
  }[utteranceType]);

export default (data) => {
  const [utterancePlayVisible, setUtterancePlayVisible] = useState(false);
  const [showJSON, setShowJSON] = useState(false);

  const { utteranceType, utterance, cloudRecordingId, speechDuration } = data;

  return (
    <Grid container justifyContent="flex-end" mt="5px">
      <Card>
        <CardContent
          style={{
            backgroundColor: "#f4f5f6",
            padding: "16px",
          }}
        >
          <Grid container alignItems="center" direction="row">
            <Grid item mr={1} sx={{ display: "flex", alignItems: "center" }}>
              {utteranceTypeToDisplay(utteranceType, () =>
                setUtterancePlayVisible(true)
              )}
            </Grid>
            <Grid
              item
              onClick={() => setShowJSON(not)}
              style={{ fontSize: "14px", lineHeight: 1.3 }}
            >
              {utterance}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {showJSON && <JSONView src={{ ...data }} />}
      {utterancePlayVisible && (
        <UtterancePlayer
          cloudRecordingId={cloudRecordingId}
          speechDuration={speechDuration}
          autoPlay
        />
      )}
    </Grid>
  );
};
