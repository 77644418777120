import { Box, Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";

export default ({ title, handleClick, buttonDisabled, buttonText }) => (
  <Box borderBottom="1px solid #b5b5b5" pb="25px" mb="25px">
    <Grid container spacing={10} direction="row" justifyContent="space-between">
      <Grid item>
        <h1>{title}</h1>
      </Grid>
      <Grid item>
        <Button
          disabled={buttonDisabled}
          color="primary"
          size="medium"
          variant="contained"
          onClick={handleClick}
          startIcon={<AddIcon />}
        >
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  </Box>
);
