import { isNil, not, path, pipe } from "ramda";
import { objectByRoomId } from "./utils";

const roomHasLookerId = pipe(
  objectByRoomId,
  path(["config", "analysis", "looker", "id"]),
  isNil,
  not
);

export { roomHasLookerId };
