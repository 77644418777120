import {
  always,
  anyPass,
  filter,
  flatten,
  ifElse,
  isEmpty,
  juxt,
  map,
  not,
  pipe,
  reject,
  test,
  trim,
} from "ramda";
import { object, relationEq, subjectEq } from "../../../triplets";
import { default as gamla } from "gamlajs";

const batchValidate = (checks) => pipe(juxt(checks), flatten);

/**
 * Receives: a subject to exclude, and a relation to filter by, followed by triplets.
 * Filters for all triplets by relation, excludes the currently edited subject (currSubj),
 * and returns the objects of remaining triplets.
 */
const getOtherObjectsByRelation = (urlRel, currSubj) =>
  pipe(filter(relationEq(urlRel)), reject(subjectEq(currSubj)), map(object));

const urlCheckPrefix = ifElse(
  pipe(anyPass([isEmpty, test(/^(https:\/\/)/), test(/^(http:\/\/)/)]), not),
  always([`Url must start with "http://" or "https://"`]),
  always([])
);

const urlCheckUnique = (urlRel) => (pendingTriplets, currSubj) =>
  ifElse(
    gamla.contains(
      getOtherObjectsByRelation(urlRel, currSubj)(pendingTriplets)
    ),
    always(["This url is already in use"]),
    always([])
  );

const depthCheckNumber = ifElse(
  pipe(trim, isNaN),
  always(["Depth must be a number"]),
  always([])
);

export { batchValidate, depthCheckNumber, urlCheckPrefix, urlCheckUnique };
