import {
  Relations,
  objectEq,
  relationEq,
  subject,
  subjectIn,
  tripletsToObjects,
} from "../../../triplets";
import { both, filter, flatten, map, of, pipe, reject } from "ramda";

const FIELD_TYPES = {
  TEXT_FIELD: "textfield",
  RADIO: "radio",
  MULTIPLE_VALUES: "multipleValues",
  IMAGE: "image",
  REF: "ref",
};

const tripletsToRows = (entityType) => (triplets) =>
  pipe(
    filter(subjectIn(getAllEntities(entityType)(triplets))),
    reject(relationEq(Relations.RELATION_TYPE)),
    tripletsToObjects,
    // TODO: This map ensures field values are all in arrays,
    // because the table/dialog field rendering requires arrays.
    // This should be changed in future.
    map(map(pipe(of, flatten)))
  )(triplets);

const getAllEntities = (entityType) =>
  pipe(
    filter(both(relationEq(Relations.RELATION_TYPE), objectEq(entityType))),
    map(subject)
  );

export { FIELD_TYPES, tripletsToRows, getAllEntities };
