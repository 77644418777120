import Box from "@mui/material/Box";
import React from "react";

export default ({
  bgColor,
  children,
  handleMouseEnter,
  handleMouseLeave,
  styleProps,
}) => (
  <Box
    bgcolor={bgColor}
    boxShadow={1}
    fontSize={14}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    {...styleProps}
  >
    {React.cloneElement(children)}
  </Box>
);
