import {
  Avatar,
  Box,
  Button,
  Grid,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { FIELD_TYPES, getAllEntities, tripletsToRows } from "./utils";
import React, { useEffect, useState } from "react";
import {
  Relations,
  object,
  objectEq,
  possiblyLookupDisplay,
  relationEq,
  subjectEq,
  subjectIn,
  tripletsToObjects,
} from "../../../triplets";
import {
  T,
  __,
  adjust,
  always,
  apply,
  both,
  clone,
  complement,
  concat,
  cond,
  dec,
  divide,
  equals,
  filter,
  find,
  flatten,
  flip,
  fromPairs,
  gt,
  gte,
  head,
  identity,
  ifElse,
  includes,
  isEmpty,
  isNil,
  juxt,
  keys,
  length,
  lt,
  map,
  max,
  pair,
  pipe,
  prepend,
  prop,
  propEq,
  reduce,
  reject,
  slice,
  split,
  times,
  toLower,
  toPairs,
  uniq,
  uniqBy,
  unless,
  unnest,
  values,
  when,
  zipWith,
} from "ramda";
import {
  currentTripletsSelector,
  updateCurrentTripletsSelector,
} from "../../../stores/selectors/tripletsSelectors";
import AddEntitiesMenuItem from "./AddEntitiesMenuItem";
import BulkUpload from "./BulkUpload";
import ChipContainer from "./ChipContainer";
import ConfirmDialog from "../clients/ConfirmDialog";
import { HyroDialog } from "../../hyro-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RowDialog from "./Dialog";
import SearchIcon from "@mui/icons-material/Search";
import ThreeDotMenu from "./ThreeDotMenu";
import { default as gamla } from "gamlajs";
import { styled } from "@mui/material/styles";
import useAppStore from "../../../stores/appStore";
import { v4 as uuidv4 } from "uuid";

const PAGE_SIZE = 10;

const PREFIX = "KnowledgeEditor";

const classes = {
  button: `${PREFIX}-button`,
  controlsRow: `${PREFIX}-controlsRow`,
  countsText: `${PREFIX}-countsText`,
  titleGrid: `${PREFIX}-titleGrid`,
  title: `${PREFIX}-title`,
  textOverflow: `${PREFIX}-textOverflow`,
  paper: `${PREFIX}-paper`,
  tableOverflow: `${PREFIX}-tableOverflow`,
};

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  [`& .${classes.button}`]: {
    textTransform: "unset !important",
    fontWeight: 500,
    "&:focus": {
      outline: 0,
    },
  },

  [`& .${classes.controlsRow}`]: {
    marginLeft: spacing(3),
    marginRight: spacing(3),
    padding: "5px 15px",
  },

  [`& .${classes.countsText}`]: {
    padding: "5px",
  },

  [`& .${classes.titleGrid}`]: {
    flex: 1,
  },

  [`& .${classes.title}`]: {
    fontWeight: 500,
    fontSize: "20px",
  },

  [`& .${classes.textOverflow}`]: {
    display: "block",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  [`& .${classes.paper}`]: {
    padding: "24px",
  },

  [`& .${classes.tableOverflow}`]: {
    overflowX: "auto",
  },
}));

const getFieldIdsByEntity = (entityType) =>
  pipe(
    find(both(subjectEq(entityType), relationEq(Relations.ENTITY_FIELDS))),
    object,
    ifElse(isNil, always([]), split(","))
  );

const getFieldSpec = (entityType) => (triplets) =>
  pipe(
    filter(subjectIn(getFieldIdsByEntity(entityType)(triplets))),
    tripletsToObjects
  )(triplets);

const StyledMenu = styled(Menu)(() => ({
  paper: {
    marginTop: "90px",
  },
}));

const paginate = (pageNum, pageSize) =>
  when(
    pipe(length, lt(pageSize)),
    slice(pageNum * pageSize, pageNum * pageSize + pageSize)
  );

const filterEntities = (text) =>
  filter(
    pipe(
      values,
      flatten,
      map(toLower),
      filter(pipe(toLower, includes)(text)),
      complement(isEmpty)
    )
  );

const tableHead = (fieldSpec) => (
  <TableHead>
    <TableRow>
      {map((field) => (
        <TableCell align="left" style={{ color: "#000000DE" }} key={field.id}>
          {field[Relations.FIELD_LABEL]}
        </TableCell>
      ))(fieldSpec)}
      <TableCell key="three-dots" />
    </TableRow>
  </TableHead>
);

const entityDictToTriplets = (entityType) => (entityDict) =>
  pipe(
    toPairs,
    map(([prop, value]) =>
      map((element) => [head(entityDict.id), prop, element])(value)
    ),
    unnest,
    reject(objectEq(head(entityDict.id))),
    prepend([head(entityDict.id), Relations.RELATION_TYPE, entityType])
  )(entityDict);

const rowsToTriplets = (entityType) =>
  pipe(map(entityDictToTriplets(entityType)), unnest);

export default ({ entityType }) => {
  const [pageNum, setPageNum] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [parsedCSV, setParsedCSV] = useState([]);
  const [bulkPropertiesDialogOpen, setBulkPropertiesDialogOpen] =
    useState(false);
  const [addEntityMenuAnchorEl, setAddEntityMenuAnchorEl] = useState(null);
  const [fieldSpec, setFieldSpec] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const currentTriplets = useAppStore(currentTripletsSelector);
  const updateCurrentTriplets = useAppStore(updateCurrentTripletsSelector);

  useEffect(() => {
    setRows(tripletsToRows(entityType)(currentTriplets));
    setFieldSpec(getFieldSpec(entityType)(currentTriplets));
  }, [currentTriplets, entityType, setRows]);

  useEffect(() => {
    setFilteredRows(filterEntities(searchText)(rows));
  }, [rows, searchText]);

  useEffect(() => {
    if (searchText) setPageNum(0);
  }, [filteredRows, searchText]);

  const getNewTriplets = (getUpdatedRows) =>
    pipe(concat, uniq)(
      pipe(
        juxt([pipe(getAllEntities(entityType), subjectIn), identity]),
        apply(reject)
      )(currentTriplets),
      pipe(getUpdatedRows, rowsToTriplets(entityType))(rows)
    );

  const handleNewRowClick = () => {
    setRowToEdit({ id: [`${uuidv4()}_${entityType}`] });
    setAddEntityMenuAnchorEl(null);
  };
  const addRow = (row) => {
    const getUpdatedRows = pipe(prepend(row), uniqBy(pipe(prop("id"), head)));
    updateCurrentTriplets(always(getNewTriplets(getUpdatedRows)));
    setRows(pipe(getUpdatedRows, filter(pipe(keys, length, lt(1)))));
    setRowToEdit(null);
    setPageNum(0);
  };
  const handleClose = () => {
    setRowToEdit(null);
    setRows(filter(pipe(keys, length, lt(1)), rows));
  };
  const setPageNumAfterDeletion = pipe(
    pipe(length, dec),
    cond([
      [gte(PAGE_SIZE), () => setPageNum(0)],
      [
        pipe(divide(__, PAGE_SIZE), Math.ceil, gt(pageNum + 1)),
        () => setPageNum(pipe(dec, max(0))),
      ],
    ])
  );
  const deleteRow = (row) => () => {
    const getUpdatedRows = reject(propEq("id", row.id));
    updateCurrentTriplets(always(getNewTriplets(getUpdatedRows)));
    setRows(getUpdatedRows);
    setRowToEdit(null);
    setPageNumAfterDeletion(filteredRows);
  };

  const LABEL_TO_RELATION = pipe(
    map(juxt([prop(Relations.FIELD_LABEL), prop(Relations.FIELD_RELATION)])),
    fromPairs
  )(fieldSpec);

  const addParsedCsvToTriplets = concat(
    pipe(
      juxt([identity, pipe(length, times(uuidv4))]),
      apply(zipWith(pair)),
      map(([row, id]) =>
        pipe(
          toPairs,
          map(
            pipe(
              adjust(0, flip(prop)(LABEL_TO_RELATION)),
              prepend(`${id}_${entityType}`)
            )
          ),
          prepend([`${id}_${entityType}`, Relations.RELATION_TYPE, entityType])
        )(row)
      ),
      reduce(concat, [])
    )(parsedCSV)
  );
  const display = unless(isNil, map(possiblyLookupDisplay(currentTriplets)));
  return (
    <StyledBox>
      <Paper sx={{ padding: "24px" }}>
        <HyroDialog
          saveDisabledOverride={isEmpty(parsedCSV)}
          fieldSpec={[
            {
              component: (
                <BulkUpload
                  setValidationErrors={setValidationErrors}
                  setParsedCSV={setParsedCSV}
                  validationErrors={validationErrors}
                  parsedCSV={parsedCSV}
                  fieldSpec={fieldSpec}
                />
              ),
            },
          ]}
          handleClose={() => {
            setBulkPropertiesDialogOpen(false);
            setValidationErrors([]);
            setParsedCSV([]);
          }}
          handleSubmit={() => updateCurrentTriplets(addParsedCsvToTriplets)}
          open={bulkPropertiesDialogOpen}
          submitButtonText="Add"
          title="Add Properties"
        />
        <Box mr={1} mb="26px">
          <Grid container spacing={3} justify="flex-end" alignItems="center">
            <Grid item className={classes.titleGrid} alignItems="center">
              <Box className={classes.title}>
                {gamla.capitalize(entityType)} Management
              </Box>
            </Grid>
            <Grid item>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={`Search ${entityType}`}
                onChange={({ target: { value } }) => setSearchText(value)}
                size="small"
                type="text"
                value={searchText}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                variant="contained"
                onClick={({ currentTarget }) =>
                  setAddEntityMenuAnchorEl(currentTarget)
                }
                endIcon={<KeyboardArrowDownIcon />}
              >
                Add {gamla.capitalize(entityType)}
              </Button>
              <StyledMenu
                anchorEl={addEntityMenuAnchorEl}
                open={Boolean(addEntityMenuAnchorEl)}
                onClose={() => setAddEntityMenuAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box width="300px">
                  <MenuItem onClick={handleNewRowClick}>
                    <AddEntitiesMenuItem
                      title="Manually"
                      description={`Add a single ${entityType}`}
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setBulkPropertiesDialogOpen(true);
                      setAddEntityMenuAnchorEl(null);
                    }}
                  >
                    <AddEntitiesMenuItem
                      title="Bulk"
                      description="Add multiple entities from CSV file"
                    />
                  </MenuItem>
                </Box>
              </StyledMenu>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.tableOverflow}>
          <Table size="medium">
            {tableHead(fieldSpec)}
            <TableBody>
              {paginate(
                pageNum,
                PAGE_SIZE
              )(filteredRows).map((row) => (
                <TableRow key={row.id[0]} hover>
                  {map((field) => (
                    <TableCell
                      align="left"
                      key={field.id}
                      onClick={() => setRowToEdit(clone(row))}
                      style={{ cursor: "pointer" }}
                    >
                      {pipe(
                        prop(Relations.FIELD_TYPE),
                        cond([
                          [
                            equals(FIELD_TYPES.MULTIPLE_VALUES),
                            always(
                              <>
                                {prop(field[Relations.FIELD_RELATION], row) && (
                                  <ChipContainer
                                    values={
                                      row[field[Relations.FIELD_RELATION]]
                                    }
                                  />
                                )}
                              </>
                            ),
                          ],
                          [
                            equals(FIELD_TYPES.IMAGE),
                            always(
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Avatar
                                  src={row[field[Relations.FIELD_RELATION]]}
                                />
                              </Box>
                            ),
                          ],
                          [
                            equals(FIELD_TYPES.REF),
                            always(
                              <Box className={classes.textOverflow}>
                                {display(row[field[Relations.FIELD_RELATION]])}
                              </Box>
                            ),
                          ],
                          [
                            T,
                            always(
                              <Box className={classes.textOverflow}>
                                {row[field[Relations.FIELD_RELATION]]}
                              </Box>
                            ),
                          ],
                        ])
                      )(field)}
                    </TableCell>
                  ))(fieldSpec)}
                  <TableCell size="small" align="right">
                    <ThreeDotMenu
                      menuItems={[
                        <MenuItem
                          key="delete"
                          onClick={() => {
                            setRowToEdit(clone(row));
                            setDeleteConfirmDialogOpen(true);
                          }}
                          dense
                        >
                          <ListItemIcon>
                            <DeleteOutlined />
                          </ListItemIcon>
                          <ListItemText>Delete</ListItemText>
                        </MenuItem>,
                        <MenuItem
                          key="edit"
                          onClick={() => setRowToEdit(clone(row))}
                          dense
                        >
                          <ListItemIcon>
                            <EditOutlined />
                          </ListItemIcon>
                          <ListItemText>Edit</ListItemText>
                        </MenuItem>,
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          rowsPerPageOptions={[PAGE_SIZE]}
          component="div"
          count={length(filteredRows)}
          rowsPerPage={PAGE_SIZE}
          page={pageNum}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          onPageChange={(_, newPage) => setPageNum(newPage)}
          onChangeRowsPerPage={() => {}}
        />
        {!deleteConfirmDialogOpen && (
          <RowDialog
            entityType={entityType}
            spec={fieldSpec}
            row={rowToEdit}
            addRow={addRow}
            handleClose={handleClose}
          />
        )}
        <ConfirmDialog
          open={deleteConfirmDialogOpen && Boolean(rowToEdit)}
          close={() => {
            setDeleteConfirmDialogOpen(false);
            setRowToEdit(null);
          }}
          title={rowToEdit && `Delete ${rowToEdit.name}?`}
          text="This action can't be undone"
          confirmFunction={deleteRow(rowToEdit)}
          disableConfirmButton={false}
        />
      </Paper>
    </StyledBox>
  );
};
