import {
  Button,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Relations,
  object,
  relation,
  relationEq,
  subject,
} from "../../../triplets";
import {
  applySpec,
  ascend,
  complement,
  concat,
  descend,
  dissoc,
  equals,
  filter,
  flatten,
  groupBy,
  includes,
  isEmpty,
  map,
  nth,
  pipe,
  prop,
  reject,
  sort,
  split,
  toLower,
  values,
} from "ramda";
import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Header from "../responses/table/Header";
import { HyroDialog } from "../../hyro-components";
import SearchBox from "./SearchBox";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";

const PREFIX = "DataSource";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledPaper = styled(Paper)(({ theme: { spacing } }) => ({
  [`&.${classes.root}`]: {
    minHeight: 500,
    padding: "1%",
    marginTop: spacing(1),
    width: "100%",
  },

  [`& .${classes.button}`]: {
    textTransform: "unset !important",
    fontWeight: 500,
  },

  [`& .${classes.icon}`]: {
    cursor: "pointer",
  },
}));

const KEY = "key";
const VALUE = "value";
const DESCRIPTION = "description";
const CONSTANT = "constant";

const getObjectByRelation = (rel) =>
  pipe(filter(relationEq(rel)), nth(0), object);

const filterEntities = (text) =>
  filter(
    pipe(
      dissoc("id"),
      values,
      flatten,
      map(toLower),
      filter(includes(text)),
      complement(isEmpty)
    )
  );

export default ({ pendingTriplets, setPendingTriplets }) => {
  const [rows, setRows] = useState(null);
  const [editedRow, setEditedRow] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(KEY);
  const [rowHover, setRowHover] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (pendingTriplets) {
      const rows = pipe(
        filter(pipe(relation, split("/"), nth(0), equals(CONSTANT))),
        groupBy(subject),
        map(
          applySpec({
            id: pipe(nth(0), subject),
            key: getObjectByRelation(Relations.CONSTANT_KEY),
            value: getObjectByRelation(Relations.CONSTANT_VALUE),
            description: getObjectByRelation(Relations.CONSTANT_DESCRIPTION),
          })
        ),
        values
      )(pendingTriplets);

      setRows(rows);
    }
  }, [setRows, pendingTriplets]);

  const headCells = [
    { id: KEY, label: KEY },
    { id: VALUE, label: VALUE },
    { id: DESCRIPTION, label: DESCRIPTION },
  ];

  const handleRequestSort = (_, property) => {
    setOrder(orderBy === property && order === "asc" ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortByAttribute = (order, orderBy) =>
    sort((order === "desc" ? descend : ascend)(prop(orderBy)));

  const handleAddRow = () => {
    setPendingTriplets(
      pipe(
        reject(pipe(subject, equals(editedRow.id))),
        concat([
          [editedRow.id, Relations.CONSTANT_KEY, editedRow.key],
          [editedRow.id, Relations.CONSTANT_VALUE, editedRow.value],
          [editedRow.id, Relations.CONSTANT_DESCRIPTION, editedRow.description],
        ])
      )
    );
    setEditedRow(null);
  };

  return (
    <StyledPaper className={classes.root}>
      <Grid container spacing={3} justifyContent="flex-end" alignItems="center">
        <Grid item>
          <SearchBox searchText={searchText} setSearchText={setSearchText} />
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            startIcon={<AddIcon />}
            color="primary"
            size="medium"
            variant="contained"
            onClick={() =>
              setEditedRow({
                id: `${uuidv4()}_${CONSTANT}`,
                key: "",
                value: "",
                description: "",
              })
            }
          >
            Add constant
          </Button>
        </Grid>
      </Grid>
      {editedRow && (
        <HyroDialog
          saveDisabledOverride={false}
          fieldSpec={[
            {
              component: (
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  alignItems="stretch"
                >
                  {headCells.map(({ id }) => (
                    <Grid item key={id}>
                      <TextField
                        fullWidth
                        label={id}
                        variant="outlined"
                        value={editedRow[id]}
                        onChange={({ target: { value } }) =>
                          setEditedRow({ ...editedRow, [id]: value })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              ),
            },
          ]}
          handleClose={() => setEditedRow(null)}
          handleSubmit={handleAddRow}
          open={Boolean(editedRow)}
          submitButtonText="Submit"
          title="Add constant"
        />
      )}
      <TableContainer>
        <Table size="medium">
          <Header
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          {rows && (
            <TableBody>
              {pipe(
                filterEntities(searchText),
                sortByAttribute(order, orderBy),
                map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={() => setEditedRow(row)}
                    onMouseEnter={() => setRowHover(row.id)}
                    onMouseLeave={() => setRowHover(null)}
                  >
                    {headCells.map(({ id }) => (
                      <TableCell key={id} width="30%">
                        {row[id]}
                      </TableCell>
                    ))}
                    <TableCell>
                      {rowHover === row.id && (
                        <BorderColorIcon
                          className={classes.icon}
                          fontSize="small"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )(rows)}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </StyledPaper>
  );
};
