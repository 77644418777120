import makeService from "./makeService";

const botPortalService = makeService({
  service: "botPortal",
  subservices: [
    "draftExists",
    "createDraft",
    "createDraftAndUpdateTriplets",
    "deleteDraft",
    "editTriplets",
    "getLastDraftByUserId",
    "getChangeRequests",
    "getClientName",
    "getLastLiveChangeRequest",
    "getDraftRequest",
    "mergePendingBranch",
    "originalTriplets",
    "tripletsFromDraft",
  ],
});

const conversationService = makeService({
  service: "conversation",
  subservices: ["find", "get", "create"],
});

const deploymentService = makeService({
  service: "switch",
  subservices: [
    "addNewNumber",
    "clientNames",
    "createAssistant",
    "deactivateRoom",
    "findAllRooms",
    "findActiveRooms",
    "findConnector",
    "findRoom",
    "findRoomFriendlyNames",
    "updateClient",
    "updateConnector",
    "updateRoom",
  ],
});

const insightsService = makeService({
  service: "insights",
  subservices: [
    "getLookerEmbedDashboardObject",
    "getLookerEmbedDashboardObjectByName",
  ],
});

const kgviewerService = makeService({
  service: "kgviewer",
  subservices: ["getGraph", "getVersionsFile"],
});

const previewService = makeService({
  service: "preview",
  subservices: [
    "createBotForTesting",
    "isBotReadyForTesting",
    "initiateTestCall",
    "isBotPreviewable",
  ],
});

const recordingService = makeService({
  service: "recording",
  subservices: ["get"],
});

const replayService = makeService({
  service: "replay",
  subservices: [
    "getReplay",
    "getReplayIds",
    "getReplayedConversation",
    "getHistoricalConversation",
  ],
});

const storageService = makeService({
  service: "storage",
  subservices: [
    "createDataSource",
    "downloadFile",
    "getFilesMetaData",
    "uploadImageFile",
  ],
});

const usersService = makeService({
  service: "users",
  subservices: ["userPermissions", "findHyroUserEmails"],
});

const workspacesService = makeService({
  service: "workspaces",
  subservices: ["createWorkspace", "getMyWorkspaceNames", "getAssistantsData"],
});

export {
  botPortalService,
  conversationService,
  deploymentService,
  insightsService,
  kgviewerService,
  previewService,
  recordingService,
  replayService,
  storageService,
  usersService,
  workspacesService,
};
