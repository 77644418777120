import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  PHONE,
  Relations,
  SAY_TEXT,
  URL,
  getSuggestionContent,
  object,
  relationEq,
  subjectEq,
  subjectIn,
} from "../../../../triplets";
import React, { useState } from "react";
import {
  always,
  append,
  apply,
  both,
  complement,
  concat,
  cond,
  either,
  equals,
  flip,
  has,
  indexOf,
  insert,
  isEmpty,
  isNil,
  join,
  juxt,
  last,
  map,
  of,
  pipe,
  prop,
  propEq,
  reduce,
  reject,
  remove,
  unless,
  update,
} from "ramda";
import BotEditorAddButton from "../AddButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SUGGESTIONS } from "../utils";
import SuggestionRow from "./SuggestionRow";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";

const PREFIX = "Suggestions";

const classes = {
  subtitle: `${PREFIX}-subtitle`,
  columnHeader: `${PREFIX}-columnHeader`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.subtitle}`]: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "24px",
    color: "#3B44BD",
  },

  [`& .${classes.columnHeader}`]: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "24px",
    color: "#595A7C",
  },
}));

export default ({
  actionKey,
  rowContent,
  setInvalidFormat,
  setPendingUpdatedTriplets,
  setRowContent,
  triplets,
}) => {
  const [suggestions, setSuggestions] = useState(prop(SUGGESTIONS)(rowContent));

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const newSuggestions = (suggestions) =>
      pipe(
        juxt([prop(result.source.index), remove(result.source.index, 1)]),
        apply(insert(result.destination.index))
      )(suggestions);
    setSuggestions(newSuggestions);

    const updatedRowContent = {
      ...rowContent,
      suggestions: newSuggestions(suggestions),
    };
    setRowContent(updatedRowContent);
    updateSuggestionsInTriplets(updatedRowContent);
  };

  const handleNewSuggestion = () => {
    if (
      !rowContent.suggestions.length ||
      pipe(last, prop("displayText"))(rowContent.suggestions)
    ) {
      const newSuggestions = (suggestions) =>
        append(
          {
            key: `${uuidv4()}_suggestion`,
            displayText: "",
            new: true,
          },
          suggestions
        );
      setSuggestions(newSuggestions);
      setRowContent((rowContent) => ({
        ...rowContent,
        suggestions: newSuggestions(suggestions),
      }));
    }
  };

  const handleEditSuggestions = ({ value: suggestionObj }) => {
    const newSuggestions = (suggestions) =>
      update(
        indexOf(suggestionObj.key, map(prop("key"), suggestions)),
        suggestionObj
      )(suggestions);
    setSuggestions(newSuggestions);

    const updatedRowContent = {
      ...rowContent,
      suggestions: newSuggestions(suggestions),
    };
    setRowContent(updatedRowContent);
    updateSuggestionsInTriplets(updatedRowContent);
  };

  const handleDeleteSuggestions = (suggestionObj) => () => {
    const newSuggestions = reject(propEq("key", suggestionObj.key));
    setSuggestions(newSuggestions);

    const updatedRowContent = {
      ...rowContent,
      suggestions: newSuggestions(suggestions),
    };
    setRowContent(updatedRowContent);
    setInvalidFormat(`${suggestionObj.key}.content`)(false);
    setInvalidFormat(`${suggestionObj.key}.displayText`)(false);
    updateSuggestionsInTriplets(updatedRowContent);
  };

  const updateSuggestionsInTriplets = (updatedRowContent) => {
    if (
      pipe(
        map(prop(SUGGESTIONS)),
        apply(complement(equals))
      )([updatedRowContent, rowContent])
    ) {
      const suggestionToDisplayTextTriplet = juxt([
        prop("key"),
        always(Relations.SUGGESTION_DISPLAY_TEXT),
        prop("displayText"),
      ]);

      const suggestionToAttributeTriplet = juxt([
        prop("key"),
        cond([
          [has(PHONE), always(Relations.SUGGESTION_PHONE)],
          [has(URL), always(Relations.SUGGESTION_URL)],
          [has(SAY_TEXT), always(Relations.SUGGESTION_SAY_TEXT)],
        ]),
        getSuggestionContent,
      ]);

      const newTriplets = pipe(
        juxt([
          unless(
            isEmpty,
            pipe(
              map(prop("key")),
              join(","),
              flip(append)([
                updatedRowContent.response.key,
                Relations.ACTION_SUGGESTIONS,
              ]),
              of
            )
          ),
          pipe(
            map(
              pipe(
                juxt([
                  suggestionToDisplayTextTriplet,
                  suggestionToAttributeTriplet,
                ]),
                reject(pipe(object, either(isNil, isEmpty)))
              )
            ),
            reduce(concat, [])
          ),
        ]),
        apply(concat)
      )(updatedRowContent.suggestions);

      const suggestionKeys = map(prop("key"))(rowContent.suggestions);
      setPendingUpdatedTriplets(
        pipe(
          reject(
            either(
              both(
                relationEq(Relations.ACTION_SUGGESTIONS),
                subjectEq(actionKey)
              ),
              subjectIn(suggestionKeys)
            )
          ),
          concat(newTriplets)
        )
      );
    }
  };

  return (
    <StyledBox maxHeight="330px">
      <Grid container direction="column" justifyContent="flex-end">
        <Grid container direction="column-reverse" spacing={1}>
          <Box display="flex" pl={2}>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              className={classes.columnHeader}
            >
              <Grid item xs={1}>
                Type
              </Grid>
              <Grid item xs={3}>
                Text on button
              </Grid>
              <Grid item xs={3}>
                Linked information / text
              </Grid>
              <Grid item xs={3}>
                Expected Bot outcome
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="suggestions">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {suggestions.map((suggestion, index) => (
                  <Draggable
                    key={suggestion.key}
                    draggableId={suggestion.key}
                    index={index}
                  >
                    {(provided) => (
                      <Grid
                        item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <SuggestionRow
                          handleEdit={handleEditSuggestions}
                          handleDelete={handleDeleteSuggestions(suggestion)}
                          initialSuggestionObj={suggestion}
                          setInvalidFormat={setInvalidFormat}
                          triplets={triplets}
                        />
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Grid item>
          <Box pb={4}>
            <BotEditorAddButton
              handleCreate={handleNewSuggestion}
              label="Add a new suggestion"
            />
          </Box>
        </Grid>
      </Grid>
    </StyledBox>
  );
};
