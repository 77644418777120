import BoxWithInvalidFormatMessages from "./BoxWithInvalidFormatMessages";
import { DebounceInput } from "react-debounce-input";
import { InputBase } from "@mui/material";
import React from "react";

export default ({
  computeInvalidFormatMessages,
  currentValue,
  handleBlur,
  handleInputRef,
  handleFocus,
  handleChange,
  setInvalidFormat,
  value,
}) => (
  <BoxWithInvalidFormatMessages
    boxProps={{ p: 2 }}
    computeInvalidFormatMessages={computeInvalidFormatMessages}
    currentValue={currentValue}
    setInvalidFormat={setInvalidFormat}
  >
    <DebounceInput
      element={InputBase}
      color="primary"
      debounceTimeout={500}
      fullWidth
      inputRef={handleInputRef}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      placeholder="Type here"
      value={value}
    />
  </BoxWithInvalidFormatMessages>
);
