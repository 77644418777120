import React, { useRef } from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";

export default ({ redirectTo, src }) => {
  const history = useHistory();
  const historyRef = useRef(history);

  const handleClick = () => historyRef.current.replace(redirectTo);

  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      }}
      onClick={handleClick}
    >
      <img
        style={{
          height: "28px",
          width: "auto",
        }}
        src={src}
        alt="logo"
      />
    </Box>
  );
};
