import {
  Relations,
  groupBySubject,
  object,
  relationEq,
  relationIn,
  subjectEq,
  subjectIn,
} from "../../../triplets";
import {
  always,
  apply,
  assoc,
  both,
  complement,
  cond,
  difference,
  eqProps,
  filter,
  flip,
  head,
  identity,
  ifElse,
  inc,
  includes,
  indexOf,
  insert,
  intersection,
  isEmpty,
  join,
  juxt,
  map,
  mergeAll,
  not,
  objOf,
  path,
  pipe,
  prop,
  reduce,
  split,
  unless,
  when,
} from "ramda";
import Grid from "@mui/material/Grid";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableCell } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

const PREFIX = "ActionDiff";

const classes = {
  added: `${PREFIX}-added`,
  color: `${PREFIX}-color`,
  deleted: `${PREFIX}-deleted`,
  header: `${PREFIX}-header`,
};

const StyledTableContainer = styled(TableContainer)(() => ({
  [`& .${classes.added}`]: {
    backgroundColor: "rgba(184, 255, 212, 0.61)",
  },

  [`& .${classes.color}`]: {
    color: "#151647",
  },

  [`& .${classes.deleted}`]: {
    backgroundColor: "rgba(255, 232, 232, 0.81)",
    color: "#847878",
    textDecoration: "line-through",
  },

  [`& .${classes.header}`]: {
    color: "rgba(21, 22, 71, 0.7)",
    width: "120px",
  },
}));

export default ({ actionKey, conceptKey, newTriplets, oldTriplets }) => {
  const eventKeys = pipe(
    filter(both(relationEq(Relations.ACTION_EVENT), subjectEq(actionKey))),
    map(object)
  )(newTriplets);

  const events = pipe(
    filter(both(relationEq(Relations.EVENT_TYPE), subjectIn(eventKeys))),
    map(object),
    join(", ")
  )(newTriplets);

  const getTriggers = pipe(
    filter(both(relationEq(Relations.CONCEPT_TRIGGER), subjectEq(conceptKey))),
    map(object)
  );
  const oldTriggers = getTriggers(oldTriplets);
  const newTriggers = getTriggers(newTriplets);

  const sameTriggers = pipe(intersection(oldTriggers), join(", "))(newTriggers);
  const addedTriggers = pipe(difference(newTriggers), join(", "))(oldTriggers);
  const deletedTriggers = pipe(
    difference(oldTriggers),
    join(", ")
  )(newTriggers);

  const getResponse = pipe(
    filter(both(relationEq(Relations.ACTION_TEXT), subjectEq(actionKey))),
    head,
    object
  );
  const oldResponse = getResponse(oldTriplets);
  const newResponse = getResponse(newTriplets);

  const getDisplay = pipe(
    filter(both(relationEq(Relations.CONCEPT_DISPLAY), subjectEq(conceptKey))),
    ifElse(isEmpty, always(""), pipe(head, object))
  );
  const oldDisplay = getDisplay(oldTriplets);
  const newDisplay = getDisplay(newTriplets);

  const getSuggestionKeys = pipe(
    filter(
      both(relationEq(Relations.ACTION_SUGGESTIONS), subjectEq(actionKey))
    ),
    when(complement(isEmpty), pipe(head, object, split(",")))
  );

  // TODO(David): Generalize this function and move to `triplets.js`.
  const getSuggestions = (oldTriplets) => {
    const suggestionKeys = getSuggestionKeys(oldTriplets);
    return pipe(
      filter(
        both(
          relationIn([
            Relations.SUGGESTION_DISPLAY_TEXT,
            Relations.SUGGESTION_PHONE,
            Relations.SUGGESTION_SAY_TEXT,
            Relations.SUGGESTION_URL,
          ]),
          subjectIn(suggestionKeys)
        )
      ),
      groupBySubject,
      map(
        pipe(
          juxt([
            pipe(
              filter(relationEq(Relations.SUGGESTION_DISPLAY_TEXT)),
              head,
              object,
              objOf("displayText")
            ),
            pipe(
              filter(
                relationIn([
                  Relations.SUGGESTION_PHONE,
                  Relations.SUGGESTION_SAY_TEXT,
                  Relations.SUGGESTION_URL,
                ])
              ),
              unless(isEmpty, pipe(head, object)),
              objOf("attribute")
            ),
          ]),
          mergeAll,
          when(
            pipe(prop("attribute"), isEmpty),
            pipe(
              juxt([prop("displayText"), identity]),
              apply(assoc("attribute"))
            )
          )
        )
      )
    )(oldTriplets);
  };

  const oldSuggestions = getSuggestions(oldTriplets);
  const newSuggestions = getSuggestions(newTriplets);
  const oldOrderedSuggestionKeys = getSuggestionKeys(oldTriplets);
  const newOrderedSuggestionKeys = getSuggestionKeys(newTriplets);
  const addedSuggestionKeys = difference(
    newOrderedSuggestionKeys,
    oldOrderedSuggestionKeys
  );
  const deletedSuggestionKeys = difference(
    oldOrderedSuggestionKeys,
    newOrderedSuggestionKeys
  );
  const sameSuggestionKeys = intersection(
    oldOrderedSuggestionKeys,
    newOrderedSuggestionKeys
  );
  const editedSuggestionKeys = filter((key) =>
    not(eqProps(key, newSuggestions, oldSuggestions))
  )(sameSuggestionKeys);
  const untouchedSuggestionKeys = difference(
    sameSuggestionKeys,
    editedSuggestionKeys
  );

  const displaySuggestions = (
    newOrderedSuggestionKeys,
    deletedSuggestionKeys
  ) => {
    const orderedSuggestionKeys = pipe(
      map(juxt([flip(indexOf)(oldOrderedSuggestionKeys), identity])),
      reduce(
        (acc, [index, suggestion]) => insert(index, suggestion, acc),
        newOrderedSuggestionKeys
      )
    )(deletedSuggestionKeys);

    const suggestionDisplay = (suggestionKey, suggestions, style) => (
      <span className={style}>
        {path([suggestionKey, "displayText"], suggestions)}
        (Action:
        {path([suggestionKey, "attribute"], suggestions)})
      </span>
    );

    return map((suggestionKey) => (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={0} style={{ minWidth: "3%" }}>
          {not(includes(suggestionKey, deletedSuggestionKeys)) &&
            `(${inc(indexOf(suggestionKey, newOrderedSuggestionKeys))})`}
        </Grid>
        <Grid item>
          {cond([
            [
              flip(includes)(deletedSuggestionKeys),
              always(
                suggestionDisplay(
                  suggestionKey,
                  oldSuggestions,
                  classes.deleted
                )
              ),
            ],
            [
              flip(includes)(addedSuggestionKeys),
              always(
                suggestionDisplay(suggestionKey, newSuggestions, classes.added)
              ),
            ],
            [
              flip(includes)(editedSuggestionKeys),
              always(
                <>
                  {suggestionDisplay(
                    suggestionKey,
                    newSuggestions,
                    classes.added
                  )}
                  {suggestionDisplay(
                    suggestionKey,
                    oldSuggestions,
                    classes.deleted
                  )}
                </>
              ),
            ],
            [
              flip(includes)(untouchedSuggestionKeys),
              always(suggestionDisplay(suggestionKey, newSuggestions)),
            ],
          ])(suggestionKey)}
        </Grid>
      </Grid>
    ))(orderedSuggestionKeys);
  };

  return (
    <StyledTableContainer>
      <Table size="medium">
        <TableBody>
          {oldDisplay || newDisplay ? (
            <TableRow>
              <TableCell className={classes.header}>Name:</TableCell>
              <TableCell className={classes.color}>
                {oldDisplay === newDisplay ? (
                  oldDisplay
                ) : (
                  <>
                    <span className={classes.added}>{newDisplay}</span>
                    {oldDisplay ? (
                      <>
                        <br />
                        <span className={classes.deleted}>{oldDisplay}</span>
                      </>
                    ) : null}
                  </>
                )}
              </TableCell>
            </TableRow>
          ) : null}
          {events ? (
            <TableRow>
              <TableCell className={classes.header}>Events:</TableCell>
              <TableCell className={classes.color}>{events}</TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell className={classes.header}>Triggers:</TableCell>
            <TableCell className={classes.color}>
              <span className={classes.added}>
                {addedTriggers}
                {addedTriggers &&
                (deletedTriggers || (!deletedTriggers && sameTriggers))
                  ? ","
                  : ""}
              </span>{" "}
              <span className={classes.deleted}>
                {deletedTriggers}
                {deletedTriggers && sameTriggers ? "," : ""}
              </span>{" "}
              <span>{sameTriggers}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.header}>Bot response:</TableCell>
            <TableCell className={classes.color}>
              {oldResponse === newResponse ? (
                oldResponse
              ) : (
                <>
                  <span className={classes.added}>{newResponse}</span>
                  <br />
                  <span className={classes.deleted}>{oldResponse}</span>
                </>
              )}
            </TableCell>
          </TableRow>
          {oldOrderedSuggestionKeys.length ||
          newOrderedSuggestionKeys.length ? (
            <TableRow>
              <TableCell className={classes.header}>Suggestions:</TableCell>
              <TableCell className={classes.color}>
                {displaySuggestions(
                  newOrderedSuggestionKeys,
                  deletedSuggestionKeys
                )}
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
