import { Box, Paper } from "@mui/material";
import { HyroDialog, HyroHeader, HyroTable } from "../../hyro-components";
import React, { useState } from "react";
import {
  addObjectToTriplets,
  rejectTripletsByObjectId,
  relationIn,
  tripletsToObjects,
} from "../../../triplets";
import {
  filter,
  fromPairs,
  head,
  includes,
  map,
  mergeRight,
  pipe,
  prop,
  sortBy,
  toLower,
  xprod,
} from "ramda";
import AddIcon from "@mui/icons-material/Add";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { INPUT_TYPES } from "../../../utils";
import { v4 as uuidv4 } from "uuid";

const newSiteSearchId = () => `${uuidv4()}_site_search`;

const filterObjectsByText = (key) => (text) =>
  filter(pipe(prop(key), toLower, includes(text)));

const addMissingKeys = (keys) => mergeRight(pipe(xprod(keys), fromPairs)([""]));

const rowToDialogSpec = (dialogFields, pendingTriplets) => (row) => ({
  id: prop("id")(row),
  fieldSpec: map((field) => ({
    ...field,
    defaultValue: row[field.key],
    inputType: INPUT_TYPES.TEXT,
    validationFunction: field.setValidationFn(pendingTriplets, row),
  }))(dialogFields),
});

export default ({
  pendingTriplets,
  setPendingTriplets,
  dialogFields,
  dialogTitle,
  headerTitle,
  firstRelation,
  firstAlias,
  secondRelation,
  secondAlias,
  statusIcon,
}) => {
  const [searchText, setSearchText] = useState("");
  const [dialogSpec, setDialogSpec] = useState(null);
  return (
    <Paper>
      <Box p={3} mt={1}>
        {dialogSpec && (
          <HyroDialog
            fieldSpec={dialogSpec.fieldSpec}
            handleClose={() => setDialogSpec(null)}
            handleSubmit={(newDialogDataSource) => {
              addObjectToTriplets(setPendingTriplets)({
                ...newDialogDataSource,
                id: dialogSpec.id,
              });
            }}
            title={dialogTitle}
          />
        )}

        <HyroHeader
          title={headerTitle}
          handleSearch={setSearchText}
          buttonSpec={{
            icon: <AddIcon />,
            text: "ADD URL",
            run: () =>
              pipe(
                rowToDialogSpec(dialogFields, pendingTriplets),
                setDialogSpec
              )({
                id: newSiteSearchId(),
                [firstRelation]: "",
                [secondRelation]: "",
              }),
          }}
        />

        <HyroTable
          columns={[
            {
              id: firstRelation,
              label: firstAlias,
              width: "96%",
              renderCell: (value) => (
                <Box display="flex" alignItems="center">
                  {statusIcon}
                  <Box pl={1}>{value}</Box>
                </Box>
              ),
            },
            {
              id: secondRelation,
              label: secondAlias,
              width: "4%",
            },
          ]}
          rows={pipe(
            filter(relationIn([firstRelation, secondRelation])),
            tripletsToObjects,
            map(addMissingKeys([firstRelation, secondRelation])),
            sortBy(prop(head([firstRelation, secondRelation]))),
            filterObjectsByText(firstRelation)(searchText)
          )(pendingTriplets)}
          rowActions={[
            {
              id: "edit",
              display: (
                <>
                  <Box pr={1}>
                    <CreateOutlinedIcon color="action" />
                  </Box>
                  Edit
                </>
              ),
              run: pipe(
                rowToDialogSpec(dialogFields, pendingTriplets),
                setDialogSpec
              ),
            },
            {
              id: "delete",
              display: (
                <>
                  <Box pr={1}>
                    <DeleteOutlinedIcon color="action" />
                  </Box>
                  Delete
                </>
              ),
              run: rejectTripletsByObjectId(setPendingTriplets),
            },
          ]}
        />
      </Box>
    </Paper>
  );
};
