import React, { useState } from "react";
import { useEffectAsync, useWindowDimensions } from "../../../hooks";

export default ({ lookerEmbedDashboardObjectGetter }) => {
  const [embedUrl, setEmbedUrl] = useState("");
  const { height, width } = useWindowDimensions();

  useEffectAsync(async () => {
    const { embedUrl: embedDashboardUrl, sessionLength } =
      await lookerEmbedDashboardObjectGetter();
    setEmbedUrl(embedDashboardUrl);

    const interval = setInterval(async () => {
      const { embedUrl: embedDashboardUrl } =
        await lookerEmbedDashboardObjectGetter();
      setEmbedUrl(embedDashboardUrl);
    }, sessionLength * 1000);

    return () => clearInterval(interval);
  }, [lookerEmbedDashboardObjectGetter, setEmbedUrl]);

  return (
    <iframe src={embedUrl} width={width} height={height} frameBorder="0" />
  );
};
