import { DISPLAY, RESPONSE, SUGGESTIONS, TEXT_COLOR, TRIGGERS } from "../utils";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  adaptV4Theme,
} from "@mui/material";
import React, { useState } from "react";
import {
  Relations,
  relationEq,
  sortTriplets,
  subjectEq,
} from "../../../../triplets";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { TabContext, TabPanel } from "@mui/lab";
import {
  addIndex,
  always,
  any,
  append,
  apply,
  assoc,
  both,
  curry,
  equals,
  flatten,
  fromPairs,
  head,
  identity,
  juxt,
  map,
  not,
  pair,
  pipe,
  prop,
  reject,
  values,
} from "ramda";

import AppBar from "@mui/material/AppBar";
import BotEditorDialogConfirmation from "./Confirmation";
import BotEditorDialogEvents from "./Events";
import BotEditorDialogResponse from "./Response";
import BotEditorDialogSuggestions from "./Suggestions";
import BotEditorDialogTriggers from "./Triggers";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { styled } from "@mui/material/styles";

const BACKGROUND_COLOR = "#3B44BD";

const StyledAppBar = styled(AppBar)(({ theme: { spacing } }) => ({
  backgroundColor: "#5C65E6",
  paddingLeft: spacing(3),
  paddingTop: spacing(4),
  minHeight: 500,
}));

const StyledButton = styled(Button)(({ theme: { spacing }, focus }) => ({
  backgroundColor: "#e0e0e0",
  filter: "brightness(100%)",
  transition: "0.2s",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  color: "rgba(0, 0, 0, 0.87)",
  marginRight: spacing(1),
  ...(focus && { filter: "brightness(90%)" }),
}));

const StyledDialogActions = styled(DialogActions)(({ theme: { spacing } }) => ({
  marginBottom: spacing(2),
  marginRight: spacing(2),
}));

const StyledDialogButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: BACKGROUND_COLOR,
  "&:hover": {
    backgroundColor: BACKGROUND_COLOR,
  },
  "&$.Mui-active": {
    backgroundColor: BACKGROUND_COLOR,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(() => ({
  color: "#151647",
}));

const StyledTab = styled(Tab)(() => ({
  backgroundColor: "#5C65E6",
  minWidth: 100,
  textTransform: "unset !important",
  alignItems: "normal",
  textAlign: "left",
}));

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: TEXT_COLOR,
      },
      secondary: {
        main: BACKGROUND_COLOR,
        light: "#f2f2f2",
      },
      text: {
        primary: TEXT_COLOR,
        secondary: BACKGROUND_COLOR,
      },
    },
  })
);

export default ({
  activeTab,
  deploymentDisplayName,
  dialogTabs,
  handleClose,
  isNewRow,
  open,
  row,
  setActiveTab,
  setTriplets,
  triplets,
}) => {
  const conceptKey = pipe(prop(TRIGGERS), head, prop("key"))(row);
  const actionKey = row.response.key;

  const [cancelButtonNotice, setCancelButtonNotice] = useState(false);

  const [invalidFormats, setInvalidFormats] = useState(
    pipe(
      juxt([
        always(DISPLAY),
        always(RESPONSE),
        pipe(
          prop(TRIGGERS),
          addIndex(map)((val, idx) => `trigger${idx}`)
        ),
      ]),
      flatten,
      map((x) => pair(x, isNewRow)),
      fromPairs
    )(row)
  );
  const [isReview, setIsReview] = useState(false);
  const [pendingUpdatedTriplets, setPendingUpdatedTriplets] =
    useState(triplets);
  const [rowContent, setRowContent] = useState(row);

  const setInvalidFormat = (key) => (isInvalidFormat) => {
    if (invalidFormats[key] !== isInvalidFormat) {
      setInvalidFormats(curry(assoc)(key, isInvalidFormat));
    }
  };

  const dialogProps = {
    pendingUpdatedTriplets,
    row,
    rowContent,
    setInvalidFormat,
    setPendingUpdatedTriplets,
    setRowContent,
  };

  const tripletsAreDifferent = pipe(
    map(sortTriplets),
    apply(equals),
    not
  )([pendingUpdatedTriplets, triplets]);

  const saveDisabled =
    pipe(values, any(identity))(invalidFormats) || !tripletsAreDifferent;

  const handleChange = (event, newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  const handleSave = () => {
    if (tripletsAreDifferent) {
      setTriplets(() => [
        ...pendingUpdatedTriplets,
        ...(isNewRow
          ? [[conceptKey, Relations.CONCEPT_ACTION, actionKey]]
          : []),
      ]);
    }
    handleClose();
  };

  const noticeCancelButton = () => {
    setCancelButtonNotice(true);
    setTimeout(() => {
      setCancelButtonNotice(false);
    }, 400);
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Dialog
            sx={{ margin: 0 }}
            fullWidth
            maxWidth="lg"
            onClose={() => {
              tripletsAreDifferent ? noticeCancelButton() : handleClose();
            }}
            open={open}
          >
            <TabContext value={activeTab}>
              <Grid container>
                <Grid item xs={2}>
                  <StyledAppBar position="static">
                    {isReview ? (
                      <Box sx={{ color: "white" }}>
                        <VisibilityOutlinedIcon
                          sx={{ verticalAlign: "middle" }}
                          fontSize="small"
                        />
                        <b> Review</b>
                      </Box>
                    ) : (
                      <Tabs
                        TabIndicatorProps={{
                          style: {
                            opacity: 0,
                          },
                        }}
                        orientation="vertical"
                        onChange={handleChange}
                        textColor="inherit"
                        value={activeTab}
                      >
                        {dialogTabs.map(({ label, value }) => (
                          <StyledTab key={value} label={label} value={value} />
                        ))}
                      </Tabs>
                    )}
                  </StyledAppBar>
                </Grid>
                <Grid item xs={10}>
                  <Grid
                    alignItems="stretch"
                    sx={{ height: "100%" }}
                    container
                    direction="column"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    {isReview ? (
                      <>
                        <Grid item>
                          <Box mx={4} mt={4}>
                            <BotEditorDialogConfirmation
                              actionKey={actionKey}
                              conceptKey={conceptKey}
                              deploymentDisplayName={deploymentDisplayName}
                              pendingUpdatedTriplets={pendingUpdatedTriplets}
                              triplets={triplets}
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row-reverse">
                            <Grid item xs={5}>
                              <StyledDialogActions>
                                <StyledButton
                                  fullWidth
                                  onClick={() => setIsReview(false)}
                                  variant="contained"
                                >
                                  Back to editing
                                </StyledButton>
                                <StyledDialogButton
                                  disabled={saveDisabled}
                                  fullWidth
                                  onClick={handleSave}
                                  variant="contained"
                                >
                                  Add to publish list
                                </StyledDialogButton>
                              </StyledDialogActions>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item>
                          <TabPanel value="events">
                            <StyledDialogTitle>Events</StyledDialogTitle>
                            <DialogContent>
                              <BotEditorDialogEvents
                                actionKey={actionKey}
                                {...dialogProps}
                              />
                            </DialogContent>
                          </TabPanel>
                          <TabPanel value={TRIGGERS}>
                            <StyledDialogTitle>Triggers</StyledDialogTitle>
                            <DialogContent>
                              <BotEditorDialogTriggers
                                conceptKey={conceptKey}
                                {...dialogProps}
                              />
                            </DialogContent>
                          </TabPanel>
                          <TabPanel value={RESPONSE}>
                            <StyledDialogTitle>Bot response</StyledDialogTitle>
                            <DialogContent>
                              <BotEditorDialogResponse
                                handleInvalidFormat={setInvalidFormat(RESPONSE)}
                                response={rowContent.response.value}
                                setResponse={(response) => {
                                  setRowContent({
                                    ...rowContent,
                                    response: {
                                      ...rowContent.response,
                                      value: response,
                                    },
                                  });
                                  if (response !== row.response.value) {
                                    setPendingUpdatedTriplets(
                                      pipe(
                                        reject(
                                          both(
                                            relationEq(Relations.ACTION_TEXT),
                                            subjectEq(actionKey)
                                          )
                                        ),
                                        values,
                                        append([
                                          actionKey,
                                          Relations.ACTION_TEXT,
                                          response,
                                        ])
                                      )
                                    );
                                  }
                                }}
                              />
                            </DialogContent>
                          </TabPanel>
                          <TabPanel value={SUGGESTIONS}>
                            <StyledDialogTitle>Suggestions</StyledDialogTitle>
                            <DialogContent>
                              <BotEditorDialogSuggestions
                                actionKey={actionKey}
                                triplets={triplets}
                                {...dialogProps}
                              />
                            </DialogContent>
                          </TabPanel>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row-reverse">
                            <Grid item xs={5}>
                              <StyledDialogActions>
                                <StyledButton
                                  fullWidth
                                  onClick={() => {
                                    setRowContent(row);
                                    handleClose();
                                  }}
                                  variant="contained"
                                  focus={cancelButtonNotice}
                                >
                                  Cancel
                                </StyledButton>
                                <StyledDialogButton
                                  disabled={saveDisabled}
                                  fullWidth
                                  onClick={() =>
                                    tripletsAreDifferent && setIsReview(true)
                                  }
                                  variant="contained"
                                >
                                  Save
                                </StyledDialogButton>
                              </StyledDialogActions>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </TabContext>
          </Dialog>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};
