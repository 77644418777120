import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DebounceInput from "react-debounce-input";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import TripletTreeView from "../components/widgets/knowledge-debugger/TripletTreeView";
import { default as gamla } from "gamlajs";
import { kgviewerService } from "../services";
import { mergeAll } from "ramda";
import { roomIdSelector } from "../stores/selectors/appSelectors";
import useAppStore from "../stores/appStore";

const loadGraphs = (setLoading, graphsToLoad, setGraphData, loadGraph) => {
  (async () => {
    setLoading(true);

    await gamla.asyncPipe(
      gamla.asyncMap(([hash, setter]) =>
        gamla.asyncPipe(() => loadGraph({ graphHash: hash }), setter)()
      ),
      mergeAll,
      (graphData) =>
        setGraphData((prevState) => ({ ...prevState, ...graphData }))
    )(graphsToLoad);

    setLoading(false);
  })();
};

const Loading = () => (
  <Grid
    container
    alignContent="center"
    justifyContent="center"
    direction="column"
  >
    <Grid item>
      <CircularProgress size={24} color="secondary" />
    </Grid>
  </Grid>
);

export default () => {
  const selectedRoomId = useAppStore(roomIdSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [baseGraphHash, setBaseGraphHash] = useState(null);

  const [diffGraphHash, setDiffGraphHash] = useState(null);

  const [graphData, setGraphData] = useState({
    baseGraph: { data: [] },
    diffGraph: { data: [] },
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const graphsToLoad = [];
    const pushIfDifferentHash = (graphHash, graphName) => {
      if (graphHash && graphHash !== graphData[graphName].hash) {
        graphsToLoad.push([
          graphHash,
          (data) => ({ [graphName]: { data, hash: graphHash } }),
        ]);
      }
    };
    pushIfDifferentHash(baseGraphHash, "baseGraph");
    pushIfDifferentHash(diffGraphHash, "diffGraph");

    if (graphsToLoad.length) {
      loadGraphs(
        setIsLoading,
        graphsToLoad,
        setGraphData,
        gamla.asyncTimeit(
          (time, args) =>
            console.log(`took ${time}ms to load hash ${args[0].graphHash}`),
          kgviewerService.getGraph
        ),
        selectedRoomId
      );
    }
  }, [
    isLoading,
    graphData,
    setGraphData,
    baseGraphHash,
    diffGraphHash,
    selectedRoomId,
  ]);

  const { baseGraph, diffGraph } = graphData;

  return (
    <Box p={1} mt={2} height="100%">
      <Paper>
        <Box p={1}>
          <Grid container direction="column">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <DebounceInput
                  element={TextField}
                  minLength={40}
                  debounceTimeout={1000}
                  margin="normal"
                  label="Base Graph Hash"
                  variant="outlined"
                  value={baseGraphHash}
                  fullWidth
                  onChange={({ target: { value } }) => setBaseGraphHash(value)}
                />
              </Grid>
              <Grid item xs={4}>
                <DebounceInput
                  element={TextField}
                  minLength={40}
                  debounceTimeout={1000}
                  margin="normal"
                  label="Diff Graph Hash"
                  variant="outlined"
                  value={diffGraphHash}
                  fullWidth
                  disabled={!baseGraphHash || baseGraphHash.length < 40}
                  onChange={({ target: { value } }) => setDiffGraphHash(value)}
                />
              </Grid>
            </Grid>
            <Grid item>
              {isLoading ? (
                <Loading />
              ) : (
                <TripletTreeView
                  baseGraph={baseGraph.data.triplets || []}
                  diffGraph={diffGraph.data.triplets || []}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};
