import DataSourceCard from "./DataSourceCard";
import { Grid } from "@mui/material";
import React from "react";
import { Relations } from "../../../triplets";
import { map } from "ramda";

export default ({ dataSources }) => (
  <Grid container direction="row" spacing={2}>
    {map((dataSource) => (
      <Grid item>
        <DataSourceCard
          key={dataSource["id"]}
          name={dataSource[Relations.DATA_SOURCE_NAME]}
          fileHash={dataSource[Relations.DATA_SOURCE_FILE_HASH]}
          updateDate={dataSource["lastModified"]}
        />
      </Grid>
    ))(dataSources)}
  </Grid>
);
