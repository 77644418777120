import { pipe, prop } from "ramda";
import { datadogRum } from "@datadog/browser-rum";
import { objectByRoomId } from "../utils";
import useAppStore from "../stores/appStore";

const CUSTOM_ACTIONS = {
  CREATE_ASSISTANT: "createAssistant",
  PREVIEW_USAGE: "previewUsage",
  PUBLISH_CHANGES: "publishChanges",
};

const sendNotification = (action, context) =>
  datadogRum.addAction(action, context);

const getSelectedAssistantType = pipe(objectByRoomId, prop("type"));

const notifyCreateAssistant = (newRoomId, assistantType) => {
  const {
    userPermissions: { userId },
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.CREATE_ASSISTANT, {
    assistantType,
    isNewAssistant: true,
    assistantId: newRoomId,
    userId,
  });
};

const notifyPreviewUsage = (previewUsageType) => {
  const {
    selectedRoomId,
    userPermissions: { userId },
    originalTriplets,
    rooms,
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.PREVIEW_USAGE, {
    previewUsageType,
    assistantType: getSelectedAssistantType(rooms, selectedRoomId),
    isNewAssistant: originalTriplets.length === 0,
    assistantId: selectedRoomId,
    userId,
  });
};

const notifyPublishChanges = () => {
  const {
    selectedRoomId,
    userPermissions: { userId },
    originalTriplets,
    rooms,
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.PUBLISH_CHANGES, {
    assistantType: getSelectedAssistantType(rooms, selectedRoomId),
    isNewAssistant: originalTriplets.length === 0,
    assistantId: selectedRoomId,
    userId,
  });
};

export { notifyCreateAssistant, notifyPreviewUsage, notifyPublishChanges };
