import { compressSync, strToU8 } from "fflate";
import { fromPairs, ifElse, is, map, mergeLeft, pipe } from "ramda";
import axios from "axios";
import useAppStore from "../stores/appStore";

const gzipJSON = pipe(JSON.stringify, strToU8, compressSync);

const getFormDataRequestParams = (body) => {
  const { selectedRoomId } = useAppStore.getState();
  body.append("roomId", selectedRoomId);
  return {
    payload: body,
    contentType: "multipart/form-data",
  };
};

const getJSONRequestParams = (body) => {
  const { selectedRoomId } = useAppStore.getState();
  return {
    payload: pipe(mergeLeft({ roomId: selectedRoomId }), gzipJSON)(body),
    contentType: "application/json",
  };
};

const serviceCall =
  (service, subservice) =>
  async ({ payload, contentType }) => {
    const baseUrl = process.env.REACT_APP_DASHBOARD_API_URL;
    const { data } = await axios.post(
      `${baseUrl}/${service}/${subservice}`,
      payload,
      {
        headers: {
          "Content-Encoding": "gzip",
          "Content-Type": contentType,
        },
      }
    );
    return data;
  };

// We pass the body explicitly to the pipe, as services are often called with undefined arguments
const makeSubservice = (service) => (subservice) => (body) =>
  pipe(
    ifElse(is(FormData), getFormDataRequestParams, getJSONRequestParams),
    serviceCall(service, subservice)
  )(body);

export default ({ service, subservices }) =>
  pipe(
    map((subservice) => [subservice, makeSubservice(service)(subservice)]),
    fromPairs
  )(subservices);
