import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HyroFeedbackMessage } from "../../hyro-components";
import { assoc } from "ramda";
import { deploymentService } from "../../../services";
import { styled } from "@mui/material/styles";

const PREFIX = "AddPhoneNumber";

const classes = {
  button: `${PREFIX}-button`,
  progress: `${PREFIX}-progress`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(() => ({
  [`& .${classes.button}`]: {
    width: "228px",
  },

  [`& .${classes.progress}`]: {
    color: "#FFF",
  },
}));

export default ({ value, onChange, text }) => {
  const [loading, setLoading] = React.useState(false);
  const [originalValues, setOriginalValues] = useState(null);

  const [feedbackConfig, setFeedbackConfig] = useState({
    show: false,
    message: "",
    severity: "success",
  });
  const handleAddNumber = async () => {
    setLoading(true);
    try {
      const newPhoneNumber = await deploymentService.addNewNumber({});
      onChange({
        target: { value: value.concat([newPhoneNumber]) },
      });
      setFeedbackConfig({
        show: true,
        message: "Successfully added a new number",
        severity: "success",
      });
    } catch (e) {
      setFeedbackConfig({
        show: true,
        message: "An error occurred while adding a new number",
        severity: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!originalValues) {
      setOriginalValues(value);
    }
  }, [originalValues, setOriginalValues, value]);
  return (
    <Root>
      <Button
        className={classes.button}
        color="primary"
        size="medium"
        variant="contained"
        onClick={handleAddNumber}
      >
        {loading ? (
          <CircularProgress size={25} className={classes.progress} />
        ) : (
          text
        )}
      </Button>
      {value ? (
        <List>
          {" "}
          {value.map((val) => (
            <ListItem divider key={val}>
              <ListItemText
                style={{
                  color:
                    originalValues && originalValues.includes(val)
                      ? "black"
                      : "green",
                }}
                primary={val}
              />
            </ListItem>
          ))}
        </List>
      ) : null}
      <HyroFeedbackMessage
        message={feedbackConfig.message}
        severity={feedbackConfig.severity}
        showFeedback={feedbackConfig.show}
        closeFeedback={() => setFeedbackConfig(assoc("show", false))}
      />
    </Root>
  );
};
