import {
  filter,
  head,
  join,
  pathOr,
  pipe,
  propEq,
  replace,
  toLower,
} from "ramda";
import { matchPath } from "react-router-dom";

export const AUTH0_DASHBOARD_AUDIENCE =
  process.env.REACT_APP_AUTH0_DASHBOARD_AUDIENCE;
export const AUTH0_DASHBOARD_CLIENT_ID =
  process.env.REACT_APP_AUTH0_DASHBOARD_CLIENT_ID;
export const AUTH0_DASHBOARD_DOMAIN =
  process.env.REACT_APP_AUTH0_DASHBOARD_DOMAIN;
export const INJECT_GTM = process.env.REACT_APP_INJECT_GTM;

const SERVICE = "bot-portal";
const BOTS_DIRECTORY_NAME = "data";
const ADMIN = "admin";
const DASHBOARD = "dashboard";
const DATA_SOURCES = "data-sources";
const FIND_A_PROVIDER = "find-a-provider";
const GENERAL_SETTINGS = "general-settings";
const HEALTHCARE_INSIGHTS = "healthcare-insights";
const INSIGHTS = "insights";
const KNOWLEDGE_DEBUGGER = "knowledge-debugger";
const KNOWLEDGE_EDITOR = "knowledge-editor";
const KNOWLEDGE_EXPLORER = "knowledge-explorer";
const QUERIES = "queries";
const REAL_ESTATE_INSIGHTS = "real-estate-insights";
const RESPONSES = "responses";
const SITE_SEARCH = "site-search";
const BORDER_COLOR = "#EF4068";
const CHANNEL_SETTINGS = "channel-settings";
const RND_INSIGHTS = "rnd-insights";
const TEMPLATE_SETTINGS = "template-settings";
const ALL_ASSISTANTS = "all-assistants";

const SEVERITY_LEVELS = {
  INFO: "info",
  ERROR: "error",
  SUCCESS: "success",
};

const WITH_DATE_TIME_PAGES = [QUERIES];

const PORTAL_PAGES = [
  DATA_SOURCES,
  FIND_A_PROVIDER,
  TEMPLATE_SETTINGS,
  KNOWLEDGE_EDITOR,
  RESPONSES,
  SITE_SEARCH,
];

const CHANGE_REQUEST_STATES = {
  SUBMITTED: "Submitted",
  IN_REVIEW: "In review",
  LIVE: "Live",
};

const CONNECTOR_TYPES = {
  BOT_SOCKET_CONNECTOR: "botSocketConnector",
  WEB_CONNECTOR: "webConnector",
  PHONE_CONNECTOR: "phoneConnector",
};

const COLLECTION_NAMES = {
  CONNECTORS: "connectors",
  ROOMS: "rooms",
};

const INSIGHTS_DASHBOARD_NAMES = {
  healthcare: "healthcare",
  overview: "overview",
  rAndD: "r&d",
  realEstate: "realEstate",
};

const INPUT_TYPES = {
  COLOR: "color",
  ADD_ONE: "add_one",
  DESCRIPTION: "description",
  DROPZONE: "dropzone",
  DROPDOWN: "dropdown",
  BOOLEAN: "boolean",
  COMMA_SEPARATED_ARRAY: "comma_separated_array",
  MULTIPLE_CHOICE: "multiple_choice",
  TEXT: "text",
  TEXT_VERIFY: "text_verify",
};

const DATA_SOURCE_TYPES = {
  EXTERNAL_FILE: "External File",
  API: "API",
  MANUAL: "Manual",
};

const MOBILE_BROWSER_INFO = [
  "iOS",
  "Windows Mobile",
  "Android OS",
  "BlackBerry OS",
  "ios",
];

const BOT_TYPES = {
  LIVE: "Live",
  DEMO: "Demo",
  TEST: "Test",
};

const WORKSPACE_TYPES = {
  CLIENT: "Client",
  INTERNAL: "Internal",
  PROSPECT: "Prospect",
};

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const HYRO_COLOR_HEX = "#3f51b5";
const getHyroColorRGB = (alpha) => `rgb(63,81,181, ${alpha})`;

const sortAlphabetically = (array) =>
  array.sort((str1, str2) => str1.localeCompare(str2));

const objectByRoomId = (roomId, rooms) =>
  pipe(filter(propEq("roomId", roomId)), head)(rooms);

const wentLiveDate = (roomId, rooms) =>
  pathOr(
    "2018-01-01 00:00",
    ["config", "analysis", "wentLiveDate"],
    objectByRoomId(roomId, rooms),
    "config.analysis.wentLiveDate"
  );
// TODO (ENG-2230): Generalize the parseSomething functions. They do similar work.
const parseRoomIdForPath = (path) => (location) => {
  const match = matchPath(location.pathname, {
    path: "/" + path + "/:roomId",
    exact: true,
    strict: true,
  });
  return match ? match.params.roomId : null;
};

const parseRoomId = (pathname) => {
  // TODO (ENG-2400): Avoid type checking to parse the room id
  // path: /^(?:\/(?<currentPage>(?:[^/]+?)))?(?:\/(?<roomId>(?:[^/]+?)))?(?:\/(?=$))?(?=\/|$)/i,
  const match = matchPath(pathname, {
    path: "/:currentPage/:roomId",
    strict: true,
  });
  // Added the if statement below to ensure we don't try to take a room id from an admin path
  return match && match.params.currentPage != ADMIN
    ? match.params.roomId
    : null;
};

const parseCurrentPage = (pathname) => {
  const match = matchPath(pathname, {
    path: "/:currentPage",
    strict: true,
  });
  return match ? match.params.currentPage : null;
};

const getClientDisplayName = ({ friendlyName, clientName, roomId }) =>
  friendlyName || clientName || roomId;

const isNumber = (s) => !isNaN(s) && s !== "";

const generateDraft = (userEmail) => ({
  userEmail,
  timestamp: new Date(Date.now()).toISOString().replace(/:/g, ""),
});

const pathMatches = (validPaths) => (pathname) =>
  pathname.match(`/${join("|", validPaths)}`);

const readTextFileAsync = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });

const replaceSpecialChars = (c) => pipe(toLower, replace(/[^A-Za-z0-9]/g, c));

const startsOrEndsWithWhitespace = (str) => /^\s|\s$/.test(str);

const isValidCssValue = (property, value) =>
  CSS.supports(property, value) && !startsOrEndsWithWhitespace(value);

const checkValidCssValueOrBlank = (property) => (value) =>
  isValidCssValue(property, value) || value == ""
    ? ""
    : `Invalid css ${property}`;

export {
  ADMIN,
  ALL_ASSISTANTS,
  BORDER_COLOR,
  BOT_TYPES,
  BOTS_DIRECTORY_NAME,
  RESPONSES,
  CHANNEL_SETTINGS,
  CHANGE_REQUEST_STATES,
  COLLECTION_NAMES,
  CONNECTOR_TYPES,
  DASHBOARD,
  INSIGHTS_DASHBOARD_NAMES,
  DATA_SOURCES,
  DATA_SOURCE_TYPES,
  EMAIL_REGEX,
  FIND_A_PROVIDER,
  GENERAL_SETTINGS,
  HEALTHCARE_INSIGHTS,
  HYRO_COLOR_HEX,
  INPUT_TYPES,
  INSIGHTS,
  KNOWLEDGE_DEBUGGER,
  KNOWLEDGE_EDITOR,
  KNOWLEDGE_EXPLORER,
  MOBILE_BROWSER_INFO,
  PORTAL_PAGES,
  QUERIES,
  REAL_ESTATE_INSIGHTS,
  RND_INSIGHTS,
  SERVICE,
  SEVERITY_LEVELS,
  SITE_SEARCH,
  TEMPLATE_SETTINGS,
  WITH_DATE_TIME_PAGES,
  WORKSPACE_TYPES,
  checkValidCssValueOrBlank,
  objectByRoomId,
  getHyroColorRGB,
  isNumber,
  wentLiveDate,
  parseRoomIdForPath,
  parseRoomId,
  parseCurrentPage,
  pathMatches,
  sortAlphabetically,
  getClientDisplayName,
  generateDraft,
  readTextFileAsync,
  replaceSpecialChars,
};
