import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  adaptV4Theme,
} from "@mui/material";
import {
  DISPLAY,
  EVENTS,
  EVENTS_LABEL,
  RESPONSE,
  RESPONSE_LABEL,
  SUGGESTIONS,
  SUGGESTIONS_LABEL,
  TITLE,
  TITLE_LABEL,
  TRIGGERS,
  TRIGGERS_LABEL,
} from "../utils";
import {
  PHONE,
  Relations,
  SAY_TEXT,
  URL,
  groupBySubject,
  object,
  relationEq,
  subject,
} from "../../../../triplets";
import React, { useState } from "react";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import {
  T,
  always,
  any,
  anyPass,
  apply,
  ascend,
  assoc,
  both,
  complement,
  concat,
  cond,
  descend,
  filter,
  flatten,
  flip,
  fromPairs,
  head,
  identity,
  ifElse,
  invertObj,
  is,
  isEmpty,
  isNil,
  juxt,
  length,
  lens,
  map,
  mergeAll,
  mergeRight,
  mergeWith,
  objOf,
  over,
  path,
  pipe,
  prop,
  propEq,
  reduce,
  reject,
  replace,
  sort,
  split,
  toLower,
  uniq,
  unless,
  values,
} from "ramda";

import {
  currentTripletsSelector,
  originalTripletsSelector,
  updateCurrentTripletsSelector,
} from "../../../../stores/selectors/tripletsSelectors";
import BotEditorDialog from "../dialog/Dialog";
import BotEditorRow from "./Row";
import Grid from "@mui/material/Grid";
import Header from "./Header";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { default as gamla } from "gamlajs";
import { styled } from "@mui/material/styles";
import useAppStore from "../../../../stores/appStore";
import { v4 as uuidv4 } from "uuid";

const PREFIX = "Table";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
};

const StyledPaper = styled(Paper)(({ theme: { spacing } }) => ({
  [`&.${classes.root}`]: {
    marginTop: spacing(1),
    marginLeft: spacing(3),
    marginRight: spacing(3),
    padding: "5px 15px",
  },

  [`& .${classes.button}`]: {
    backgroundColor: "#3f51b5",
    textTransform: "unset !important",
    fontWeight: 500,
  },
}));

const theme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiTableCell: {
        body: {
          color: "#151647",
        },
      },
    },
  })
);

const subjectToObjectByRelation = (relation) =>
  pipe(filter(relationEq(relation)), groupBySubject, map(pipe(head, object)));

const tripletToItem = pipe(
  juxt([pipe(subject, objOf("key")), pipe(object, objOf("value"))]),
  mergeAll
);

const subjectToItemByRelation = (relation) =>
  pipe(
    filter(relationEq(relation)),
    groupBySubject,
    map(pipe(head, tripletToItem))
  );

const subjectToItemsListByRelation = (relation) =>
  pipe(filter(relationEq(relation)), groupBySubject, map(map(tripletToItem)));

const subjectToObjectsListByRelation = (relation) =>
  pipe(filter(relationEq(relation)), groupBySubject, map(map(object)));

const actionToActionName = pipe(
  filter(relationEq(Relations.ACTION_TEXT)),
  map(subject),
  uniq,
  map(juxt([identity, replace(/_/g, " ")])),
  fromPairs
);

const actionToEvents = subjectToObjectsListByRelation(Relations.ACTION_EVENT);
const actionToText = subjectToItemByRelation(Relations.ACTION_TEXT);
const actionToSuggestions =
  subjectToObjectsListByRelation("action/suggestions");
const eventToType = subjectToObjectByRelation("event/type");
const eventToData = subjectToObjectByRelation("event/data");
const suggestionToDisplayText = subjectToItemByRelation(
  Relations.SUGGESTION_DISPLAY_TEXT
);
const suggestionToPhone = subjectToItemByRelation(Relations.SUGGESTION_PHONE);
const suggestionToSayText = subjectToItemByRelation(
  Relations.SUGGESTION_SAY_TEXT
);
const suggestionToUrl = subjectToItemByRelation(Relations.SUGGESTION_URL);
const conceptToAction = subjectToObjectByRelation(Relations.CONCEPT_ACTION);
const conceptToDisplay = subjectToItemByRelation(Relations.CONCEPT_DISPLAY);
const conceptToTriggers = subjectToItemsListByRelation(
  Relations.CONCEPT_TRIGGER
);

const cannedResponsesDialogTabs = [
  { label: EVENTS_LABEL, value: EVENTS },
  { label: TRIGGERS_LABEL, value: TRIGGERS },
  { label: RESPONSE_LABEL, value: RESPONSE },
  { label: SUGGESTIONS_LABEL, value: SUGGESTIONS },
];

const generalResponsesDialogTabs = reject(propEq("label", TRIGGERS_LABEL))(
  cannedResponsesDialogTabs
);

const getBotConfig = (triplets) => {
  // TODO(David): Generalize this function and move to `triplets.js`.
  const actionToSuggestionObjs = pipe(
    actionToSuggestions,
    map(pipe(head, split(","))),
    map(
      map(
        pipe(
          juxt([
            objOf("key"),
            pipe(
              flip(prop)(suggestionToDisplayText(triplets)),
              prop("value"),
              objOf("displayText")
            ),
            pipe(
              flip(prop)(suggestionToPhone(triplets)),
              prop("value"),
              objOf(PHONE)
            ),
            pipe(
              flip(prop)(suggestionToSayText(triplets)),
              prop("value"),
              objOf(SAY_TEXT)
            ),
            pipe(
              flip(prop)(suggestionToUrl(triplets)),
              prop("value"),
              objOf(URL)
            ),
          ]),
          mergeAll,
          filter(identity),
          unless(anyPass([prop(PHONE), prop(SAY_TEXT), prop(URL)]), (obj) =>
            pipe(assoc(SAY_TEXT, prop("displayText")(obj)))(obj)
          )
        )
      )
    )
  );

  const actionToEventTypesAndNames = pipe(
    actionToEvents,
    map(
      map(
        pipe(
          juxt([
            objOf("key"),
            juxt([
              pipe(flip(prop)(eventToType(triplets)), objOf("type")),
              pipe(
                flip(prop)(eventToData(triplets)),
                ifElse(isNil, always(""), identity),
                objOf("data")
              ),
            ]),
          ]),
          flatten,
          mergeAll
        )
      )
    )
  );

  const actionToConceptTriggers = pipe(
    conceptToAction,
    invertObj,
    map(flip(prop)(conceptToTriggers(triplets))),
    map(map(over(lens(prop("id"), assoc("id")), uuidv4)))
  );

  const actionToConceptDisplay = pipe(
    conceptToAction,
    invertObj,
    map(flip(prop)(conceptToDisplay(triplets))),
    reject(isNil)
  );

  return pipe(
    juxt([
      pipe(actionToEventTypesAndNames, map(objOf(EVENTS))),
      pipe(actionToText, map(objOf(RESPONSE))),
      pipe(actionToActionName, map(objOf(TITLE))),
      pipe(actionToConceptDisplay, map(objOf(DISPLAY))),
      pipe(actionToSuggestionObjs, map(objOf(SUGGESTIONS))),
      pipe(actionToConceptTriggers, map(objOf(TRIGGERS))),
    ]),
    reduce(mergeWith(mergeRight), {}),
    map(
      mergeRight({
        [EVENTS]: [],
        [RESPONSE]: {},
        [TITLE]: "",
        [DISPLAY]: { value: "" },
        [SUGGESTIONS]: [],
        [TRIGGERS]: [],
      })
    ),
    values
  )(triplets);
};

const sortByAttribute = (order, orderBy) =>
  sort(
    (order === "desc" ? descend : ascend)(
      pipe(
        prop(orderBy),
        cond([
          [is(String), identity],
          [both(is(Object), complement(is(Array))), prop("value")],
          [
            is(Array),
            cond([
              [isEmpty, always("")],
              [
                pipe(head, prop("displayText")),
                pipe(head, prop("displayText"), toLower),
              ],
              [
                pipe(head, prop("type")),
                pipe(map(pipe(prop("type"), toLower)), apply(concat)),
              ],
              [
                T,
                pipe(head, prop("value"), ifElse(isNil, always(""), toLower)),
              ],
            ]),
          ],
        ])
      )
    )
  );

const anyTerminal = (predicate) =>
  ifElse(
    is(Object),
    pipe(
      values,
      // Make lazy to avoid infinite recursion.
      any((x) => anyTerminal(predicate)(x))
    ),
    predicate
  );

const anyString = (predicate) => anyTerminal(both(is(String), predicate));

const headCells = [
  { id: EVENTS, numeric: false, disablePadding: false, label: EVENTS_LABEL },
  { id: TRIGGERS, numeric: false, disablePadding: true, label: TRIGGERS_LABEL },
  { id: TITLE, numeric: false, disablePadding: true, label: TITLE_LABEL },
  {
    id: RESPONSE,
    numeric: false,
    disablePadding: true,
    label: RESPONSE_LABEL,
  },
  {
    id: SUGGESTIONS,
    numeric: false,
    disablePadding: true,
    label: SUGGESTIONS_LABEL,
  },
  {
    id: "status",
    numeric: false,
    label: "Status",
  },
];

const generalResponsesHeadCells = reject(propEq("id", TRIGGERS))(headCells);

const cannedResponsesHeadCells = reject(propEq("id", TITLE))(headCells);

export default ({ deploymentDisplayName, isGeneralResponsesView }) => {
  const originalTriplets = useAppStore(originalTripletsSelector);
  const currentTriplets = useAppStore(currentTripletsSelector);
  const updateCurrentTriplets = useAppStore(updateCurrentTripletsSelector);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(TRIGGERS);
  const [editedRow, setEditedRow] = useState(null);
  const [dialogActiveTab, setDialogActiveTab] = useState(TRIGGERS);
  const [dialog, setDialog] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isNewRow, setIsNewRow] = useState(false);

  const rows = pipe(
    unless(
      isEmpty,
      pipe(
        getBotConfig,
        gamla.isValidRegExp(searchText)
          ? filter(anyString(gamla.testRegExp(new RegExp(searchText, "gi"))))
          : identity
      )
    )
  )(currentTriplets);

  const handleRequestSort = (_, property) => {
    setOrder(orderBy === property && order === "asc" ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleDialogClose = () => {
    setDialog(false);
    setEditedRow(null);
    setIsNewRow(false);
  };

  const handleNewRow = () => {
    const actionKeyId = uuidv4();
    setEditedRow({
      [EVENTS]: [],
      [SUGGESTIONS]: [],
      [TRIGGERS]: [
        {
          key: `${uuidv4()}_concept`,
          value: "",
          id: uuidv4(),
        },
      ],
      [DISPLAY]: {
        value: "",
      },
      [RESPONSE]: {
        key: `${actionKeyId}_action`,
        value: "",
      },
      [TITLE]: `${actionKeyId} action`,
    });
    setDialog(true);
    setIsNewRow(true);
  };

  return (
    <StyledPaper className={classes.root}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Grid
            container
            spacing={3}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              {!isGeneralResponsesView && (
                <Button
                  className={classes.button}
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={handleNewRow}
                >
                  + Add response
                </Button>
              )}
            </Grid>
            <Grid item>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={({ target: { value } }) => setSearchText(value)}
                size="small"
                value={searchText}
                variant="outlined"
              />
            </Grid>
          </Grid>
          {editedRow && (
            <BotEditorDialog
              activeTab={dialogActiveTab}
              deploymentDisplayName={deploymentDisplayName}
              dialogTabs={
                isGeneralResponsesView
                  ? generalResponsesDialogTabs
                  : cannedResponsesDialogTabs
              }
              handleClose={handleDialogClose}
              isNewRow={isNewRow}
              open={dialog}
              row={editedRow}
              setActiveTab={setDialogActiveTab}
              setTriplets={updateCurrentTriplets}
              triplets={currentTriplets}
            />
          )}
          <TableContainer>
            <Table size="medium">
              <Header
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={
                  isGeneralResponsesView
                    ? generalResponsesHeadCells
                    : cannedResponsesHeadCells
                }
              />
              <TableBody>
                {/* TODO(Tom): Pass rows as prop depending on the condition*/}
                {pipe(
                  sortByAttribute(order, orderBy),
                  map(
                    ifElse(
                      ifElse(
                        always(isGeneralResponsesView),
                        complement(pipe(prop(TRIGGERS), length)),
                        pipe(prop(TRIGGERS), length)
                      ),
                      (row) => (
                        <BotEditorRow
                          deploymentDisplayName={deploymentDisplayName}
                          isGeneralResponsesView={isGeneralResponsesView}
                          key={path(["response", "key"])(row)}
                          originalTriplets={originalTriplets}
                          row={row}
                          setEditedRow={setEditedRow}
                          setDialog={setDialog}
                          setDialogActiveTab={setDialogActiveTab}
                        />
                      ),
                      always(null)
                    )
                  )
                )(rows)}
              </TableBody>
            </Table>
          </TableContainer>
        </ThemeProvider>
      </StyledEngineProvider>
    </StyledPaper>
  );
};
