import React, { useCallback, useEffect, useState } from "react";
import { chain, groupBy, map, mergeAll, pipe, toPairs, zip } from "ramda";

import ChartWrapper from "../components/widgets/overview/ChartWrapper";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Mousetrap from "mousetrap";
import Paper from "@mui/material/Paper";
import ReplayDiffModal from "../components/widgets/overview/ReplayDiffModal";
import { ResponsiveBar } from "@nivo/bar";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { replayService } from "../services";
import { styled } from "@mui/material/styles";
import { useQueryParamState } from "../hooks";

const StyledFormControl = styled(FormControl)(({ theme: { spacing } }) => ({
  margin: spacing(1),
  minWidth: 120,
}));

const StyledPaper = styled(Paper)(({ theme: { spacing } }) => ({
  width: "100%",
  marginTop: spacing(3),
  overflowX: "auto",
}));

const StyledSelectorPaper = styled(Paper)(() => ({
  display: "flex",
  flexWrap: "wrap",
}));

const ROWS_PER_PAGE = 25;

const convertDataToNivoFormat = pipe(
  ({ images, stats }) => zip(images, stats),
  // Flatten everything to triplets of stat name, value and image.
  chain(([image, stats]) =>
    map(([statName, value]) => ({ statName, value, image }), toPairs(stats))
  ),
  groupBy(({ statName }) => statName),
  toPairs,
  map(([statName, objects]) =>
    mergeAll([
      ...map(
        ({ image, value }) => ({
          [image]: value,
        }),
        objects
      ),
      {
        id: statName,
      },
    ])
  )
);

export default () => {
  const [diffs, setDiffs] = useState([]);
  const [selectReplayDiffId, setSelectReplayDiffId] =
    useQueryParamState("diffId");
  const [conversationId, setConversationId] =
    useQueryParamState("conversationId");
  const [replayDiffInfo, setReplayDiffInfo] = useState(null);
  const [page, setPage] = useState(0);

  useEffect(() => {
    (async () => {
      setDiffs(await replayService.getReplayIds({}));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setReplayDiffInfo(
        selectReplayDiffId
          ? await replayService.getReplay({
              id: selectReplayDiffId,
            })
          : null
      );
    })();
  }, [selectReplayDiffId]);

  const getRowsForCurrentPage = useCallback(
    () =>
      replayDiffInfo.diffs.slice(
        page * ROWS_PER_PAGE,
        (page + 1) * ROWS_PER_PAGE
      ),
    [replayDiffInfo, page]
  );

  const selectNextConversation = useCallback(
    (n) => {
      const conversationsInPage = getRowsForCurrentPage();
      const currentIndex = conversationsInPage.findIndex(
        (id) => id === conversationId
      );
      let nextIndex = 0;
      if (currentIndex !== -1) {
        nextIndex = currentIndex + n;
      }
      if (nextIndex >= 0 && nextIndex < conversationsInPage.length) {
        setConversationId(conversationsInPage[nextIndex]);
      } else {
        setConversationId(null);
      }
    },
    [getRowsForCurrentPage, setConversationId, conversationId]
  );

  useEffect(() => {
    const hotkeys = {
      n: () => selectNextConversation(1),
      p: () => selectNextConversation(-1),
    };

    Object.keys(hotkeys).forEach((key) => Mousetrap.bind(key, hotkeys[key]));

    return () => {
      Object.keys(hotkeys).forEach(Mousetrap.unbind);
    };
  }, [selectNextConversation]);

  return (
    <>
      <StyledSelectorPaper>
        <StyledFormControl>
          <InputLabel htmlFor="age-simple">Select Replay Diff</InputLabel>
          <Select
            value={selectReplayDiffId || ""}
            onChange={({ target: { value } }) => {
              setSelectReplayDiffId(value);
              setConversationId(null);
            }}
          >
            <MenuItem value="">None</MenuItem>
            {diffs.map(({ _id, images, createdAt }) => (
              <MenuItem key={_id} value={_id}>
                {images.join(" vs ")} ({createdAt})
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </StyledSelectorPaper>
      {replayDiffInfo && (
        <StyledPaper>
          <ChartWrapper title="Stats">
            <ResponsiveBar
              label={({ id }) => id}
              legend="id"
              keys={replayDiffInfo.images}
              data={convertDataToNivoFormat({
                stats: replayDiffInfo.stats,
                images: replayDiffInfo.images,
              })}
              groupMode="grouped"
              margin={{ top: 50, right: 60, bottom: 80, left: 60 }}
            />
          </ChartWrapper>
          <Table sx={{ minWidth: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getRowsForCurrentPage().map((row) => (
                <TableRow
                  hover
                  key={row}
                  onClick={() => setConversationId(row)}
                >
                  <TableCell component="th" scope="row">
                    {row}
                  </TableCell>
                  {conversationId === row && (
                    <ReplayDiffModal
                      isOpen={conversationId === row}
                      images={replayDiffInfo.images}
                      id={conversationId}
                      cycleConversations={selectNextConversation}
                      dismiss={() => setConversationId(null)}
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={replayDiffInfo.diffs.length}
            rowsPerPage={ROWS_PER_PAGE}
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
          />
        </StyledPaper>
      )}
    </>
  );
};
