import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { colors } from "./ChartColors";

export default ({ data, layout, margin, totalSum }) => (
  <ResponsiveBar
    animate
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    colors={colors}
    data={data}
    indexBy="shortLabel"
    label={({ value }) => value.toLocaleString()}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor="white"
    layout={layout || "horizontal"}
    margin={margin || { top: 15, right: 60, bottom: 80, left: 120 }}
    motionDamping={15}
    motionStiffness={90}
    padding={0.3}
    tooltip={({ data: { label, value } }) => (
      <div>{`${label}: ${value.toLocaleString()}${
        totalSum
          ? `/${totalSum.toLocaleString()} (${parseFloat(
              ((value / totalSum) * 100).toFixed(2)
            )}%)`
          : ""
      }`}</div>
    )}
  />
);
