import React from "react";

export default ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7405 2L19.5651 4.44355L22 5.25809V5.94501L19.5651 6.76933L18.7405 9.20307H18.0533L17.2383 6.76933L14.7934 5.94501V5.25809L17.2383 4.44355L18.0533 2H18.7405ZM13.1243 14.0176V10.946H18.063V14.0372C18.0533 18.4631 14.4595 22.0548 10.0317 22.0548C5.59342 22.0548 2 18.4631 2 14.0274C2 9.59173 5.59342 6 10.0317 6H13.1243V10.9263H10.0317C8.32309 10.9263 6.9387 12.31 6.9387 14.0176C6.9387 15.7251 8.32309 17.1088 10.0317 17.1088C11.7399 17.1088 13.1243 15.7251 13.1243 14.0176Z"
      fill={fill}
    />
  </svg>
);
