import "./styles/App.css";
import {
  AUTH0_DASHBOARD_AUDIENCE,
  AUTH0_DASHBOARD_CLIENT_ID,
  AUTH0_DASHBOARD_DOMAIN,
  INJECT_GTM,
} from "./utils";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { Auth0Provider } from "@auth0/auth0-react";
import { ClientSocketProvider } from "./contexts/ClientSocketContext";
import { DateTimeProvider } from "./contexts/DateTimeContext";
import Layout from "./layout/Layout";
import React from "react";
import { Router } from "react-router-dom";
import TagManager from "react-gtm-module";
import { history } from "./layout/browserHistory";
import initDatadog from "./datadog";

const theme = createTheme({
  palette: {
    action: {
      active: "rgba(45, 44, 68, 0.7)",
      hover: "rgba(45, 44, 68, 0.04)",
      selected: "rgba(45, 44, 68, 0.08)",
      disabled: "rgba(45, 44, 68, 0.26)",
      disabledBackground: "rgba(45, 44, 68, 0.12)",
      focus: "rgba(45, 44, 68, 0.12)",
    },
    error: {
      contrastText: "#FFFFFF",
      dark: "#902931",
      light: "#EF9AA1",
      main: "#EF404B",
    },
    info: {
      contrastText: "#FFFFFF",
      dark: "#41689E",
      light: "#8DBBF2",
      main: "#5f98e8",
    },
    primary: {
      contrastText: "#FFFFFF",
      dark: "#161453",
      light: "#A7AAF8",
      main: "#4548c4",
    },
    secondary: {
      contrastText: "#4548C4",
      dark: "#CDCEEF",
      light: "#F8F8FF",
      main: "#DEDFFF",
    },
    success: {
      contrastText: "#FFFFFF",
      dark: "#428E6E",
      light: "#A8D8C4",
      main: "#4caf85",
      alert: {
        background: "#DDF8EF",
      },
    },
    text: {
      primary: "#363554",
      secondary: "rgba(45, 44, 68, 0.7)",
      disabled: "rgba(45, 44, 68, 0.4)",
    },
    warning: {
      contrastText: "#FFFFFF",
      dark: "#C39214",
      light: "#FDDD8B",
      main: "#f1b00a",
    },
    grey: {
      200: "#EEEEEE",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 8,
  },
});

if (INJECT_GTM) {
  TagManager.initialize({
    gtmId: "GTM-MN294G5",
    dataLayer: {
      project: "DashboardTesting",
    },
  });
}

if (process.env.REACT_APP_ENV === "production") {
  initDatadog();
}

export default () => (
  <Auth0Provider
    domain={AUTH0_DASHBOARD_DOMAIN}
    clientId={AUTH0_DASHBOARD_CLIENT_ID}
    onRedirectCallback={({ returnTo }) =>
      history.replace(returnTo || window.location.pathname)
    }
    redirectUri={window.location.origin}
    audience={AUTH0_DASHBOARD_AUDIENCE}
  >
    <Router history={history}>
      <ClientSocketProvider>
        <DateTimeProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Layout />
            </ThemeProvider>
          </StyledEngineProvider>
        </DateTimeProvider>
      </ClientSocketProvider>
    </Router>
  </Auth0Provider>
);
