import BorderColorIcon from "@mui/icons-material/BorderColor";
import Grid from "@mui/material/Grid";
import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "EditableTableCellContent";

const classes = {
  icon: `${PREFIX}-icon`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.icon}`]: {
    cursor: "pointer",
  },
}));

export default ({
  editableCellContent,
  colName,
  setDialog,
  setDialogActiveTab,
  setEditedRow,
  row,
  hover,
}) => {
  const handleClick = () => {
    setDialog(true);
    setDialogActiveTab(colName);
    setEditedRow(row);
  };

  return (
    <StyledGrid alignItems="center" container justifyContent="center">
      <Grid item xs={11}>
        {editableCellContent}
      </Grid>
      <Grid item xs={1}>
        {hover && (
          <BorderColorIcon
            className={classes.icon}
            fontSize="small"
            onClick={handleClick}
          />
        )}
      </Grid>
    </StyledGrid>
  );
};
