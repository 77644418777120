import { Box, Divider, Drawer, List, ListItemButton } from "@mui/material";
import React, { useState } from "react";
import {
  always,
  any,
  aperture,
  complement,
  equals,
  filter,
  find,
  fromPairs,
  has,
  head,
  ifElse,
  juxt,
  lensIndex,
  lensProp,
  map,
  mergeAll,
  not,
  over,
  pipe,
  prop,
  propEq,
  set,
  toPairs,
  when,
} from "ramda";
import {
  hasPermissionSelector,
  roomIdSelector,
  roomsSelector,
  userPermissionsSelector,
} from "../../stores/selectors/appSelectors";
import { useHistory, useLocation } from "react-router-dom";
import AdminHyroLogo from "../Logos/AdminHyroLogo";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemIconAndText from "./ListItemIconAndText";
import NavigationMenuButton from "./NavigationMenuButton";
import { filterItemByPermission } from "../../permissionsHandler";
import { parseCurrentPage } from "../../utils";
import { styled } from "@mui/material/styles";
import useAppStore from "../../stores/appStore";

const StyledDrawer = styled(Drawer)(() => ({
  [`& .MuiDrawer-paper`]: {
    width: "270px",
  },
}));

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "isNested",
})(({ isNested }) => ({
  ...(isNested && {
    paddingLeft: 88,
    backgroundColor: "#F5F5F5",
    "&:hover": {
      backgroundColor: "#0000001A",
    },
  }),

  "&.Mui-selected": {
    color: "#3F51B5",
    // "&.Mui-focusVisible" is required: https://github.com/mui/material-ui/issues/21318.
    "&.Mui-focusVisible": { color: "#3F51B5" },
  },
}));

export default ({
  className,
  handleClick,
  Logo,
  navigationItems,
  open,
  setOpen,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const hasPermission = useAppStore(hasPermissionSelector);
  const userPermissions = useAppStore(userPermissionsSelector);
  const rooms = useAppStore(roomsSelector);
  const selectedRoomId = useAppStore(roomIdSelector);
  const itemByPermission = filterItemByPermission(
    userPermissions,
    hasPermission
  );

  const isAdminPanel = pathname.includes("/admin");

  const showNavigationByAdmin = (navItem) =>
    isAdminPanel ? navItem?.isAdminPanelItem : !navItem.isAdminPanelItem;

  const filteredNavigationItems = navigationItems.filter(showNavigationByAdmin);
  const hasSelectedByDefaultProp = has("selectedByDefault");

  const [selectedKey, setSelectedKey] = useState(
    pipe(
      ifElse(
        any(hasSelectedByDefaultProp),
        find(hasSelectedByDefaultProp),
        find(propEq("url", `/${parseCurrentPage(pathname)}`))
      ),
      prop("text")
    )(filteredNavigationItems)
  );

  const [sectionIdToIsOpen, setSectionIdToIsOpen] = useState(
    pipe(
      itemByPermission,
      filter(has("children")),
      map(
        pipe(
          juxt([prop("text"), prop("selectedByDefault")]),
          aperture(2),
          fromPairs
        )
      ),
      mergeAll
    )(filteredNavigationItems)
  );

  const handleClickOnParentItem = (sectionId) => () => {
    setSectionIdToIsOpen(
      pipe(
        when(
          propEq(sectionId, false),
          pipe(
            toPairs,
            map(
              when(
                pipe(head, complement(equals(sectionId))),
                set(lensIndex(1), false)
              )
            ),
            fromPairs
          )
        ),
        over(lensProp(sectionId), not)
      )
    );
  };

  const handleListItemClick = (event, itemKey) => {
    setSelectedKey(itemKey);
  };

  const onDrawerUrlClick = (navigationItem, event, isChild) => {
    if (has("url")(navigationItem)) {
      history.push(navigationItem.url);
    }
    setOpen(false);
    handleListItemClick(event, navigationItem.text);
    if (!isChild) {
      setSectionIdToIsOpen(map(always(false)));
    }
  };

  const NavigationListItem = (isNested) => (navigationItem) =>
    (navigationItem?.isHidden &&
      navigationItem.isHidden(selectedRoomId, rooms)) || (
      <StyledListItemButton
        key={navigationItem.key}
        isNested={isNested}
        selected={selectedKey === navigationItem.text}
        onClick={(event) => onDrawerUrlClick(navigationItem, event, isNested)}
        sx={has("placeholder")(navigationItem) ? { color: "#00000038" } : {}}
      >
        <ListItemIconAndText navigationItem={navigationItem} />
      </StyledListItemButton>
    );

  const CollapseChildrenOfParent = (navigationItem) => (
    <Collapse
      in={sectionIdToIsOpen[navigationItem.text]}
      timeout="auto"
      unmountOnExit
    >
      <List component="div">
        {pipe(
          prop("children"),
          itemByPermission,
          map(NavigationListItem(true))
        )(navigationItem)}
      </List>
    </Collapse>
  );

  return (
    <StyledDrawer open={open} onClose={() => setOpen(false)}>
      <Box
        className={className}
        sx={{
          backgroundColor: isAdminPanel ? "#EF4269" : "#3B44BD",
          color: "white",
          display: "flex",
          alignItems: "center",
          paddingLeft: "25px",
        }}
      >
        <NavigationMenuButton handleClick={handleClick} />
        {isAdminPanel ? <AdminHyroLogo /> : <Logo />}
      </Box>
      <Divider />
      <List>
        {pipe(
          itemByPermission,
          map(
            ifElse(
              has("children"),
              (navigationItem) => (
                <React.Fragment key={navigationItem.key}>
                  <StyledListItemButton
                    onClick={handleClickOnParentItem(navigationItem.text)}
                  >
                    <ListItemIconAndText navigationItem={navigationItem} />
                    {sectionIdToIsOpen[navigationItem.text] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </StyledListItemButton>
                  {CollapseChildrenOfParent(navigationItem)}
                </React.Fragment>
              ),
              NavigationListItem(false)
            )
          )
        )(filteredNavigationItems)}
      </List>
    </StyledDrawer>
  );
};
