import { Box, Button } from "@mui/material";
import React, { useRef } from "react";
import { MD5 } from "object-hash";
import { extname } from "path-browserify";
import { storageService } from "../../../services";
import { styled } from "@mui/material/styles";

const PREFIX = "ImageUploader";

const classes = {
  uploadButton: `${PREFIX}-uploadButton`,
  uploadTitle: `${PREFIX}-uploadTitle`,
  uploadNote: `${PREFIX}-uploadNote`,
  emptyImage: `${PREFIX}-emptyImage`,
  image: `${PREFIX}-image`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.uploadButton}`]: {
    width: "140px",
    fontFamily: "roboto",
    fontWeight: 500,
    fontSize: "14px",
    textTransform: "none",
  },

  [`& .${classes.uploadTitle}`]: {
    fontFamily: "roboto",
    fontWeight: 500,
    fontSize: "14px",
  },

  [`& .${classes.uploadNote}`]: {
    fontFamily: "roboto",
    fontWeight: 400,
    fontSize: "12px",
    marginBottom: "20px",
  },

  [`& .${classes.emptyImage}`]: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "5px",
    paddingLeft: "5px",
    fontFamily: "roboto",
    fontWeight: 400,
    fontSize: "12px",
    border: "solid 1px #00000061",
    width: "140px",
    borderRadius: "5px",
    color: "#00000061",
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.image}`]: {
    backgroundColor: "#E0E0E0",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "12px",
    width: "fit-content",
    height: "fit-content",
  },
}));

export default ({ value, onChange, label, uploadNote, width, height }) => {
  const onFileUpload = async (selectedFile) => {
    const formData = new FormData();
    formData.append(
      "file",
      selectedFile,
      MD5(selectedFile) + extname(selectedFile.name)
    );
    const response = await storageService.uploadImageFile(formData);
    return response.url;
  };

  const hiddenFileInput = useRef(null);
  const handleChange = async ({
    target: {
      files: { 0: fileUploaded },
    },
  }) => {
    const uploadId = await onFileUpload(fileUploaded);
    onChange({
      target: {
        value: `https://hyropublic.blob.core.windows.net/${uploadId}`,
      },
    });
  };

  return (
    <StyledBox
      marginTop="20px"
      marginBottom="20px"
      display="flex"
      flexDirection="column"
    >
      <Box className={classes.uploadTitle}>{label}</Box>
      <Box className={classes.uploadNote}>{uploadNote}</Box>
      {value ? (
        <Box className={classes.image}>
          <img
            src={value || ""}
            width={width || "auto"}
            height={height || "auto"}
          />
        </Box>
      ) : (
        <Box className={classes.emptyImage}>No image defined</Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        marginTop="20px"
      >
        <Button
          onClick={() => hiddenFileInput.current.click()}
          className={classes.uploadButton}
          variant="outlined"
          color="primary"
        >
          Upload Image
        </Button>
        <Button
          color="primary"
          className={classes.uploadButton}
          onClick={() => onChange({ target: { value: "" } })}
        >
          Clear
        </Button>
      </Box>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </StyledBox>
  );
};
