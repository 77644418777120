import {
  CANNED_RESPONSES,
  GENERAL_RESPONSES,
} from "../components/widgets/responses/utils";
import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import BotEditorTable from "../components/widgets/responses/table/Table";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HyroLoader } from "../components/hyro-components";
import Typography from "@mui/material/Typography";
import { isRefreshingTripletsSelector } from "../stores/selectors/tripletsSelectors";
import { styled } from "@mui/material/styles";
import useAppStore from "../stores/appStore";

const PREFIX = "Responses";

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.heading}`]: {
    color: "#161453",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "80px",
    paddingLeft: "10px",
  },
}));

export default ({ deploymentDisplayName }) => {
  const isRefreshingTriplets = useAppStore(isRefreshingTripletsSelector);

  const [expanded, setExpanded] = useState(CANNED_RESPONSES);

  const handleChange = (panel) => (event, isExpanded) =>
    setExpanded(isExpanded && panel);

  const accordions = [
    { id: CANNED_RESPONSES, title: "Canned Responses" },
    { id: GENERAL_RESPONSES, title: "General Responses" },
  ];

  if (isRefreshingTriplets) return <HyroLoader />;

  return (
    <StyledBox mx={3} my={4}>
      {accordions.map(({ id, title }) => (
        <Accordion
          key={id}
          expanded={expanded === id}
          onChange={handleChange(id)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BotEditorTable
              key={id}
              deploymentDisplayName={deploymentDisplayName}
              // TODO(Tom): remove this prop once we have two different BotEditorTab components.
              isGeneralResponsesView={id === GENERAL_RESPONSES}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </StyledBox>
  );
};
