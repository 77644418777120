import React, { cloneElement } from "react";
import { map, path, pipe } from "ramda";
import { Box } from "@mui/material";

export default ({ children, value, handleChange }) => (
  <Box display="flex" columnGap="14px" justifyContent="center">
    {map((child) =>
      cloneElement(child, {
        key: child.props.name,
        selected: child.props.name === value,
        onClick: () => pipe(path(["props", "name"]), handleChange)(child),
        ...child.props,
      })
    )(children)}
  </Box>
);
