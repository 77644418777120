export const DEVELOPMENT_ENV = "development";
export const PRODUCTION_ENV = "production";
export const LOCAL_ENV = "local";

const DEV_SNIPPET_LOCATION = "https://demo.airbud.io/widgets/dev/widget.js";

export const resolveEnvironmentSnippetLocation = (environment) =>
  ({
    [DEVELOPMENT_ENV]: DEV_SNIPPET_LOCATION,
    [PRODUCTION_ENV]: "https://api.airbud.io/widgets/widget.js",
    [LOCAL_ENV]: "https://demo.airbud.io/widgets/dev/widget.js",
  }[environment]);

export const makeSnippet = ({ roomId, environment, isBotPortal }) => `<script>${
  isBotPortal
    ? `\n    window.HYRO_WIDGET_SERVER_URL = "${process.env.REACT_APP_PREVIEW_WIDGET_SERVER_URL}";`
    : ""
}
    window.HYRO_WIDGET_ID = "${roomId}";
    (function() {
      const f = function() {
        const d = document;
        const l = d.createElement('script');
        l.type = 'text/javascript';
        l.async = true;
        l.src = '${resolveEnvironmentSnippetLocation(
          environment
        )}?id=' + window.HYRO_WIDGET_ID;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(l, x);
      };
      window.addEventListener('load', f, false);
    })();
  </script>`;
