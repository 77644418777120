import {
  ACCEPTING_NEW_PATIENTS,
  EXPERTISE,
  ONLINE_SCHEDULING,
  PHONE,
  PROFESSIONAL_SUFFIX,
  PROFILE_LINK,
  Relations,
  SPECIALTY,
  SUBSPECIALTY,
} from "../../../../triplets";
import { Box, Divider, List } from "@mui/material";
import {
  CTA_ELEMENT,
  DESCRIPTION_ELEMENT,
  RESULT_CARD_CONFIG_DIALOG_TYPES,
  SUBTITLE_ELEMENT,
  TAG_ELEMENT,
} from "./Constants";
import React, { useState } from "react";
import {
  resultCardConfigSelector,
  updateResultCardConfigSelector,
} from "../../../../stores/selectors/tripletsSelectors";
import ConfigRowWithDialog from "./ConfigRowWithDialog";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import ResultCardPreview from "./ResultCardPreview";
import { map } from "ramda";
import { styled } from "@mui/material/styles";
import useAppStore from "../../../../stores/appStore";

const StyledPaper = styled(Paper)(({ theme: { spacing } }) => ({
  padding: "1%",
  marginTop: spacing(1),
  width: "100%",
  paddingBottom: 24,
  paddingTop: 24,
  paddingLeft: 24,
  paddingRight: 24,
}));

const getResultCardConfigRows = (
  resultCardConfig,
  updateResultCardConfig,
  setFocusedElement
) => [
  {
    name: SUBTITLE_ELEMENT,
    value: resultCardConfig[Relations.RENDER_TITLE],
    options: [
      {
        text: PROFESSIONAL_SUFFIX,
        helperText: "The provider's professional suffix e.g. MD",
      },
    ],
    nullable: true,
    onSave: async (newValue) => {
      await updateResultCardConfig({
        [Relations.RENDER_TITLE]: newValue,
      });
    },
    setInFocus: setFocusedElement,
    dialogType: RESULT_CARD_CONFIG_DIALOG_TYPES.OPTIONS,
  },
  {
    name: DESCRIPTION_ELEMENT,
    value: resultCardConfig[Relations.RENDER_TEXT],
    options: [
      {
        text: SPECIALTY,
        helperText: "The provider's specialty e.g. General Practitioner",
      },
      {
        text: SUBSPECIALTY,
        helperText: "The provider's sub specialty e.g. Cardiology",
      },
      {
        text: EXPERTISE,
        helperText: "The provider's expertise e.g. Internal Medicine",
      },
    ],
    nullable: true,
    onSave: async (newValue) => {
      await updateResultCardConfig({
        [Relations.RENDER_TEXT]: newValue,
      });
    },
    setInFocus: setFocusedElement,
    dialogType: RESULT_CARD_CONFIG_DIALOG_TYPES.OPTIONS,
  },
  {
    name: TAG_ELEMENT,
    value: resultCardConfig[Relations.RENDER_TAGS],
    options: [
      {
        text: ACCEPTING_NEW_PATIENTS,
        helperText: "The provider is accepting new patients",
      },
      {
        text: ONLINE_SCHEDULING,
        helperText: "The provider has online scheduling",
      },
    ],
    nullable: true,
    onSave: async (newValue) => {
      await updateResultCardConfig({
        [Relations.RENDER_TAGS]: newValue,
      });
    },
    setInFocus: setFocusedElement,
    dialogType: RESULT_CARD_CONFIG_DIALOG_TYPES.OPTIONS,
  },
  {
    name: CTA_ELEMENT,
    value: resultCardConfig[Relations.RENDER_SUGGESTIONS],
    text: resultCardConfig[Relations.RENDER_CTA_TEXT],
    options: [
      {
        text: PROFILE_LINK,
        helperText:
          "Redirect to the provider's profile page. Default CTA text: 'Visit Page'",
      },
      {
        text: PHONE,
        helperText: "Open the phone dialer. Default CTA text: 'Call'",
      },
    ],
    nullable: false,
    onSave: async (newValue, newText) => {
      await updateResultCardConfig({
        [Relations.RENDER_SUGGESTIONS]: newValue,
        [Relations.RENDER_CTA_TEXT]: newText,
      });
    },
    setInFocus: setFocusedElement,
    dialogType: RESULT_CARD_CONFIG_DIALOG_TYPES.OPTIONS_WITH_TEXT,
  },
];

export default () => {
  const [focusedElement, setFocusedElement] = useState("");

  const resultCardConfig = useAppStore(resultCardConfigSelector);
  const updateResultCardConfig = useAppStore(updateResultCardConfigSelector);

  const resultCardConfigRows = getResultCardConfigRows(
    resultCardConfig,
    updateResultCardConfig,
    setFocusedElement
  );

  return (
    <StyledPaper>
      <h1
        style={{
          fontWeight: 500,
          fontSize: 20,
          fontFamily: "roboto",
          paddingBottom: 24,
        }}
      >
        Result cards
      </h1>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={7}>
          <Box
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderRadius: "4px",
            }}
          >
            <List disablePadding>
              {map((props) => (
                <React.Fragment key={props.name}>
                  <ConfigRowWithDialog {...props} />
                  <Divider />
                </React.Fragment>
              ))(resultCardConfigRows)}
            </List>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderRadius: "4px",
              backgroundColor: "#FAFAFA",
              paddingBottom: "56px",
              paddingTop: "56px",
              paddingLeft: "124px",
              paddingRight: "124px",
            }}
          >
            <ResultCardPreview
              renderConfig={resultCardConfig}
              focusedElement={focusedElement}
            />
          </Box>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};
