import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import ThreeDotMenu from "../widgets/knowledge-editor/ThreeDotMenu";
import { map } from "ramda";

export default ({ columns, rows, rowActions, onRowClick }) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          {map(({ id, label, width }) => (
            <TableCell key={id} align="left" width={width}>
              {label}
            </TableCell>
          ))(columns)}
          {rowActions && <TableCell key="actions" align="left" width="4%" />}
        </TableRow>
      </TableHead>
      <TableBody>
        {map((row) => (
          <TableRow
            key={row.id}
            hover
            {...(onRowClick && { onClick: () => onRowClick(row) })}
          >
            {map(({ id, renderCell }) => (
              <TableCell key={id} align="left">
                {renderCell ? renderCell(row[id]) : row[id]}
              </TableCell>
            ))(columns)}
            {rowActions && (
              <TableCell align="left">
                <ThreeDotMenu
                  menuItems={map(({ display, id, run }) => (
                    <MenuItem key={id} onClick={() => run(row)}>
                      {display}
                    </MenuItem>
                  ))(rowActions)}
                />
              </TableCell>
            )}
          </TableRow>
        ))(rows)}
      </TableBody>
    </Table>
  </TableContainer>
);
