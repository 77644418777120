const NEW_NAME_KEY = "new_name";
const SEARCHABLE_KEY = "searchable";
const SEARCHABLE_LABEL = "Searchable";
const STATUS_KEY = "status";
const STATUS_LABEL = "Status";
const NAME_KEY = "name";
const ATTRIBUTE_KEY = "attribute";
const ATTRIBUTE_LABEL = "Attribute";
const MAPPING_METHOD_KEY = "mapping_method";
const MAPPING_METHOD_LABEL = "Mapping method";
const CUSTOMIZED_CODE_KEY = "customized_code";
const CUSTOMIZED_CODE_LABEL = "Customized code";
const TEMPLATE_KEY = "template";
const TEMPLATE_LABEL = "Template";
const UNMAPPED_LABEL = "Unmapped";
const DONE_LABEL = "Done";
const PENDING_LABEL = "Pending";
const STATUSES = [PENDING_LABEL, UNMAPPED_LABEL, DONE_LABEL];
const STATUS_TO_COLOR = {
  [PENDING_LABEL]: "#EFA300",
  [DONE_LABEL]: "#38AF46",
  [UNMAPPED_LABEL]: "#A9A9A9",
};

export {
  NEW_NAME_KEY,
  SEARCHABLE_KEY,
  SEARCHABLE_LABEL,
  STATUS_KEY,
  STATUS_LABEL,
  NAME_KEY,
  ATTRIBUTE_KEY,
  ATTRIBUTE_LABEL,
  MAPPING_METHOD_KEY,
  MAPPING_METHOD_LABEL,
  CUSTOMIZED_CODE_KEY,
  CUSTOMIZED_CODE_LABEL,
  TEMPLATE_KEY,
  TEMPLATE_LABEL,
  UNMAPPED_LABEL,
  DONE_LABEL,
  PENDING_LABEL,
  STATUSES,
  STATUS_TO_COLOR,
};
