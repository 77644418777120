import { AppBar, Toolbar } from "@mui/material";
import { QUERIES, WITH_DATE_TIME_PAGES, pathMatches } from "../../utils";
import React, { useEffect, useState } from "react";
import {
  filter,
  flatten,
  has,
  head,
  identity,
  ifElse,
  juxt,
  map,
  pipe,
  prop,
  tap,
} from "ramda";
import {
  hasPermissionSelector,
  roomIdSelector,
  userPermissionsSelector,
} from "../../stores/selectors/appSelectors";
import { useHistory, useLocation } from "react-router-dom";
import AdminHyroLogo from "../Logos/AdminHyroLogo";
import BetaChip from "../Logos/BetaChip";
import BotManager from "../../components/widgets/header/BotManager";
import ChangeRequestsMenu from "../../components/widgets/change-requests-menu/Menu";
import DashboardHyroLogo from "../Logos/DashboardHyroLogo";
import DateInfo from "./DateInfo";
import DrawerAndToggle from "../Nav/DrawerAndToggle";
import UserMenu from "./UserMenu";
import WebSnippetManagerAndToggle from "./WebSnippetManagerAndToggle";
import { filterItemByPermission } from "../../permissionsHandler";
import navigationItems from "../Nav/navigationItems";
import { styled } from "@mui/material/styles";
import useAppStore from "../../stores/appStore";

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "isAdminPanel",
})(({ theme, isAdminPanel }) => ({
  backgroundColor: isAdminPanel ? "#EF4269" : theme.palette.primary.main,
}));

const isWithDateTimePath = pathMatches(WITH_DATE_TIME_PAGES);
const isWithLimitByTimePath = (pathname) => pathname.match(`/${QUERIES}/`);

export default () => {
  const { pathname } = useLocation();
  const hasPermission = useAppStore(hasPermissionSelector);
  const userPermissions = useAppStore(userPermissionsSelector);
  const selectedRoomId = useAppStore(roomIdSelector);

  const isAdminPanel = pathname.includes("/admin");

  const shouldShowRoomItems = selectedRoomId && !isAdminPanel;

  const itemByPermission = filterItemByPermission(
    userPermissions,
    hasPermission
  );
  const isBetaItem = (pathname) =>
    pipe(
      itemByPermission,
      map(
        ifElse(has("children"), prop("children"), itemByPermission, identity)
      ),
      flatten,
      filter(has("url")),
      filter(pipe(prop("url"), (url) => pathname.match(url))),
      head,
      prop("isBeta")
    )(navigationItems(selectedRoomId));

  const history = useHistory();
  const [isWithLimitByTime, setIsWithLimitByTime] = useState(
    isWithLimitByTimePath(window.location.pathname)
  );
  const [isWithDateTime, setIsWithDateTime] = useState(
    isWithDateTimePath(window.location.pathname)
  );
  useEffect(
    () =>
      history.listen(
        pipe(
          prop("pathname"),
          juxt([
            pipe(isWithDateTimePath, tap(setIsWithDateTime)),
            pipe(isWithLimitByTimePath, tap(setIsWithLimitByTime)),
          ])
        )
      ),
    [history]
  );
  return (
    <StyledAppBar position="fixed" isAdminPanel={isAdminPanel}>
      <Toolbar>
        <DrawerAndToggle />
        {isAdminPanel ? <AdminHyroLogo /> : <DashboardHyroLogo />}
        {window.location.pathname && isBetaItem(window.location.pathname) && (
          <BetaChip />
        )}
        <div style={{ flex: 1 }} />
        {isWithDateTime && <DateInfo isWithLimitByTime={isWithLimitByTime} />}
        {shouldShowRoomItems && (
          <>
            <WebSnippetManagerAndToggle />
            <BotManager />
            <ChangeRequestsMenu />
          </>
        )}
        <UserMenu />
      </Toolbar>
    </StyledAppBar>
  );
};
