import { Card, CardContent } from "@mui/material";
import React, { useState } from "react";

import JSONView from "./JSONView";

export default ({ responses, labels, id }) => {
  const [showJSON, setShowJSON] = useState(labels.map(() => false));

  const setIndexJSON = (idx) => {
    setShowJSON(showJSON.map((show, index) => (index === idx ? !show : show)));
  };
  return (
    <Card
      sx={{
        maxWidth: "100%",
        marginBottom: "5px",
        color: "#000",
        backgroundColor: "#B5D7FF",
      }}
    >
      <CardContent>
        {responses.map((response, index) => (
          <div key={id + labels[index]}>
            <div
              onClick={() => setIndexJSON(index)}
              style={{ cursor: "pointer" }}
            >
              <span style={{ fontWeight: "bold" }}>{labels[index]}: </span>
              {response.text}
            </div>
            {showJSON[index] && (
              <JSONView key={id + labels[index]} src={response} />
            )}
          </div>
        ))}
      </CardContent>
    </Card>
  );
};
