import { Grid, TextField } from "@mui/material";
import { HyroFileUploader } from "../../hyro-components";
import React from "react";

export default ({ name, setName, setFile }) => (
  <Grid container alignItems="stretch" direction="column" spacing={2}>
    <Grid item>
      <TextField
        label="Data Source Name"
        sx={{ width: "100%" }}
        value={name}
        onChange={({ target: { value } }) => setName(value)}
        variant="outlined"
      />
    </Grid>
    <Grid item>
      <HyroFileUploader
        onValid={setFile}
        onClear={() => setFile(null)}
        acceptedTypes=".csv"
      />
    </Grid>
  </Grid>
);
