import React, { useContext, useEffect, useState } from "react";
import {
  always,
  isEmpty,
  isNil,
  last,
  map,
  mergeAll,
  pipe,
  reject,
  toPairs,
  when,
} from "ramda";
import {
  hasPermissionSelector,
  roomIdSelector,
} from "../../../stores/selectors/appSelectors";
import Box from "@mui/material/Box";
import DateTimeContext from "../../../contexts/DateTimeContext";
import LatestConversations from "./LatestConversations";
import { MOBILE_BROWSER_INFO } from "../../../utils";
import USER_PERMISSIONS from "../../../userPermissions";
import { conversationService } from "../../../services";
import useAppStore from "../../../stores/appStore";

const Filters = {
  NumOfMessages: "#OfMessages",
  Medium: "medium",
  Device: "device",
  Origin: "origin",
};

const filterAndValueToQuery = ([filterField, filterValue]) =>
  ({
    [Filters.NumOfMessages]: {
      1: { messageCount: { $eq: 1 } },
      "2-3": { messageCount: { $gte: 2, $lte: 3 } },
      "4-5": { messageCount: { $gte: 4, $lte: 5 } },
      "5+": { messageCount: { $gt: 5 } },
    },
    [Filters.Medium]: {
      voice: { isVoice: true },
      text: { isText: true },
      "suggestion click": { isVoice: false, isText: false },
    },
    [Filters.Device]: {
      desktop: {
        "browserInfo.os": { $nin: [...MOBILE_BROWSER_INFO, "nexmo"] },
      },
      mobile: {
        "browserInfo.os": { $in: MOBILE_BROWSER_INFO },
      },
      phone: {
        "browserInfo.os": "nexmo",
      },
    },
    [Filters.Origin]: {
      test: { "browserInfo.origin": { $eq: "user-test" } },
      live: { "browserInfo.origin": { $not: { $eq: "user-test" } } },
    },
  }[filterField][filterValue]);

const makeFilterParamsObject = pipe(
  toPairs,
  reject(pipe(last, isEmpty)),
  map(pipe(filterAndValueToQuery, when(isNil, always({})))),
  mergeAll,
  JSON.stringify
);

export default () => {
  const hasPermission = useAppStore(hasPermissionSelector);
  const selectedRoomId = useAppStore(roomIdSelector);
  const { fromDate, toDate } = useContext(DateTimeContext);
  const [conversations, setConversations] = useState([]);
  const [page, setPage] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [count, setCount] = useState(0);
  const PAGE_SIZE = 50;
  const [filterParameters, setFilterParameters] = useState({
    [Filters.NumOfMessages]: "",
    [Filters.Medium]: "",
    [Filters.Device]: "",
    [Filters.Origin]: "live",
  });

  const handleFilterChange = (filterField, filterValue) =>
    setFilterParameters({
      ...filterParameters,
      [filterField]: filterValue,
    });
  useEffect(() => {
    const promise = (async () => {
      setIsDataLoading(true);
      const { conversations, total } = await conversationService.find({
        pageSize: PAGE_SIZE,
        currentPage: page,
        from: fromDate,
        to: toDate,
        filterParameters: makeFilterParamsObject(filterParameters),
      });
      setIsDataLoading(false);
      setConversations(conversations);
      setCount(total);
    })();
    return promise.cancel;
  }, [fromDate, toDate, filterParameters, page, selectedRoomId]);

  return (
    hasPermission(USER_PERMISSIONS.CONVERSATIONS) && (
      <Box m={1.5}>
        <LatestConversations
          isDataLoading={isDataLoading}
          page={page}
          setPage={setPage}
          showRefresh
          count={count}
          Filters={Filters}
          makeFilterParamsObject={makeFilterParamsObject}
          filterParameters={filterParameters}
          setFilterParameters={setFilterParameters}
          conversations={conversations}
          handleFilterChange={handleFilterChange}
          useFilters
          usePagers
        />
      </Box>
    )
  );
};
