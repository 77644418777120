import { Autocomplete, Box, TextField } from "@mui/material";
import { HyroDialog, HyroOption, HyroOptionGroup } from "../../hyro-components";
import React, { useState } from "react";
import { any, assoc, isEmpty, pipe, values } from "ramda";
import { usersService, workspacesService } from "../../../services";
import ClientIcon from "./ClientIcon";
import HealthcareIcon from "./HealthcareIcon";
import InternalIcon from "./InternalIcon";
import OtherIcon from "./OtherIcon";
import ProspectIcon from "./ProspectIcon";
import RealEstateIcon from "./RealEstateIcon";
import { WORKSPACE_TYPES } from "../../../utils";
import { default as gamla } from "gamlajs";
import { styled } from "@mui/material/styles";
import useAppStore from "../../../stores/appStore";
import { useEffectAsync } from "../../../hooks";
import { userDataSelector } from "../../../stores/selectors/appSelectors";

const StyledTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  fontSize: "16px",
  paddingBottom: "8px",
}));

export default ({ open, close, myWorkspaces, onCreate }) => {
  const { email } = useAppStore(userDataSelector);
  const [owners, setOwners] = useState([]);
  const [workspaceData, setWorkspaceData] = useState({
    type: "",
    vertical: "",
    name: "",
    hyroOwner: email,
  });
  useEffectAsync(async () => {
    setOwners(await usersService.findHyroUserEmails());
  }, []);
  const invalidWorkspaceName = gamla.contains(myWorkspaces, workspaceData.name);
  return (
    <HyroDialog
      saveDisabledOverride={
        invalidWorkspaceName || pipe(values, any(isEmpty))(workspaceData)
      }
      fieldSpec={[
        {
          component: (
            <Box
              display="flex"
              flexDirection="row"
              pb="32px"
              mt="5px"
              columnGap="14px"
            >
              <TextField
                label="Workspace Name"
                error={invalidWorkspaceName}
                helperText={
                  invalidWorkspaceName ? "Workspace name already in use" : ""
                }
                value={workspaceData.name}
                onChange={({ target: { value } }) =>
                  setWorkspaceData(assoc("name", value))
                }
                variant="outlined"
                fullWidth
              />
              <Autocomplete
                disableClearable
                loading={isEmpty(owners)}
                onChange={(_, selection) =>
                  setWorkspaceData(assoc("hyroOwner", selection))
                }
                options={owners}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Owner" />
                )}
                value={workspaceData.hyroOwner}
                fullWidth
              />
            </Box>
          ),
        },
        {
          component: (
            <Box pb="32px">
              <StyledTitle>Type</StyledTitle>
              <HyroOptionGroup
                value={workspaceData.type}
                handleChange={(value) => setWorkspaceData(assoc("type", value))}
              >
                <HyroOption name={WORKSPACE_TYPES.CLIENT} Icon={ClientIcon} />
                <HyroOption
                  name={WORKSPACE_TYPES.PROSPECT}
                  Icon={ProspectIcon}
                />
                <HyroOption
                  name={WORKSPACE_TYPES.INTERNAL}
                  Icon={InternalIcon}
                />
              </HyroOptionGroup>
            </Box>
          ),
        },
        {
          component: (
            <>
              <StyledTitle>Vertical</StyledTitle>
              <HyroOptionGroup
                value={workspaceData.vertical}
                handleChange={(value) =>
                  setWorkspaceData(assoc("vertical", value))
                }
              >
                <HyroOption name={"Healthcare"} Icon={HealthcareIcon} />
                <HyroOption name={"Real Estate"} Icon={RealEstateIcon} />
                <HyroOption name={"Other"} Icon={OtherIcon} />
              </HyroOptionGroup>
            </>
          ),
        },
      ]}
      handleClose={close}
      handleSubmit={async () => {
        await workspacesService.createWorkspace({ workspace: workspaceData });
        onCreate(workspaceData.name);
        close();
      }}
      open={open}
      submitButtonText="Save"
      title="Create new workspace"
    />
  );
};
