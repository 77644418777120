import { map, mergeAll, pipe, times } from "ramda";

import { default as gamla } from "gamlajs";

const getRelevantGraphData = (
  graphData,
  searchText,
  useExactMatch,
  maxDistanceFromRoot,
  maxRank,
  maxNodes
) => {
  const edgesSet = new Set();
  let nodeIds = new Set();

  const nodeIdsByDistanceFromRoot = times(() => new Set(), maxDistanceFromRoot);

  const rankByNodeId = pipe(
    map((node) => ({ [node.id]: 0 })),
    mergeAll
  )(graphData.nodes);

  const rootNodeIds = getRootNodeIdsFromSearchText(
    graphData.nodes,
    searchText,
    useExactMatch
  );

  for (let i = 0; i < maxDistanceFromRoot; i++) {
    nodeIds = new Set();

    let nodesToIterate;
    if (i === 0) {
      nodesToIterate = rootNodeIds.slice();
    } else {
      nodesToIterate = Array.from(nodeIdsByDistanceFromRoot[i - 1]);
    }

    for (const nodeIdx in nodesToIterate) {
      const nodeId = nodesToIterate[nodeIdx];

      nodeIds.add(nodeId);

      for (const edgeIdx in graphData.edges) {
        const edge = graphData.edges[edgeIdx];
        if (
          isEdgeRelevant(
            edge,
            graphData,
            nodeId,
            rootNodeIds,
            rankByNodeId,
            maxRank
          )
        ) {
          edgesSet.add(edge);

          rankByNodeId[edge.from]++;
          rankByNodeId[edge.to]++;

          nodeIds.add(edge.from);
          nodeIds.add(edge.to);
        }
      }
    }

    nodeIdsByDistanceFromRoot[i] = nodeIds;
  }

  nodeIds = new Set();
  for (const i in nodeIdsByDistanceFromRoot) {
    nodeIds = union(nodeIds, nodeIdsByDistanceFromRoot[i]);
  }

  let nodeIdsArray = Array.from(nodeIds);

  if (nodeIds.size > maxNodes) {
    nodeIdsArray = nodeIdsArray.slice(0, maxNodes);
  }

  const edgesArray = Array.from(edgesSet);

  const nodesArray = [];
  for (const i in nodeIdsArray) {
    let node = getNodeFromNodeId(graphData.nodes, nodeIdsArray[i]);
    if (rootNodeIds.includes(nodeIdsArray[i])) {
      node = Object.assign({}, node);
      node.multi = true;
      node.font = {
        color: "red",
        size: 16,
        face: "arial",
      };
    }
    nodesArray.push(node);
  }

  return {
    graphData: { nodes: nodesArray, edges: edgesArray },
    nodeIds,
    nodesArray,
  };
};

const isEdgeRelevant = (
  edge,
  graphData,
  nodeId,
  rootNodeIds,
  rankByNodeId,
  M
) => {
  if (
    !rootNodeIds.includes(nodeId) &&
    (rankByNodeId[edge.from] > M || rankByNodeId[edge.to] > M)
  ) {
    return false;
  }

  return edge.from === nodeId || edge.to === nodeId;
};

const getNodeFromNodeId = (nodesArray, nodeId) => {
  for (const nodeIdx in nodesArray) {
    if (nodesArray[nodeIdx].id === nodeId) {
      return nodesArray[nodeIdx];
    }
  }
  return null;
};

const getRootNodeIdsFromSearchText = (
  nodesArray,
  searchText,
  useExactMatch
) => {
  const rootNodeIds = [];
  for (const nodeIdx in nodesArray) {
    let useNode;
    if (!useExactMatch && gamla.isValidRegExp(searchText)) {
      const regex = new RegExp(searchText, "i");
      useNode =
        nodesArray[nodeIdx].id.match(regex) ||
        nodesArray[nodeIdx].label.match(regex) ||
        nodesArray[nodeIdx].title.match(regex);
    } else {
      useNode =
        nodesArray[nodeIdx].id.toLowerCase() === searchText.toLowerCase() ||
        nodesArray[nodeIdx].label.toLowerCase() === searchText.toLowerCase() ||
        nodesArray[nodeIdx].title.toLowerCase() === searchText.toLowerCase();
    }
    if (useNode) rootNodeIds.push(nodesArray[nodeIdx].id);
  }
  return rootNodeIds;
};

const union = (setA, setB) => new Set([...setA, ...setB]);

export { getRelevantGraphData, getNodeFromNodeId };
