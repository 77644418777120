import { Box } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import React from "react";

const CircleOutlined = () => (
  <Box
    className="indicateChecked"
    borderRadius="50%"
    borderColor="#fff"
    border="2px solid"
    margin="4px"
    width="22px"
    height="22px"
  />
);

export default ({ name, image, selected, onClick }) => (
  <Box
    width="172px"
    height="180px"
    bgcolor="#fff"
    display="flex"
    position="relative"
    flexDirection="column"
    borderRadius="8px"
    padding="12px"
    margin="12px"
    sx={
      onClick && {
        "&:hover": {
          cursor: "pointer",
          "& .indicateChecked": {
            borderColor: "#0000008A",
          },
        },
      }
    }
    onClick={onClick}
    {...(selected
      ? {
          border: "1px solid #3F51B5",
          boxShadow: "none",
        }
      : {
          border: "1px solid rgba(0, 0, 0, 0.23)",
          boxShadow:
            "0px 2px 1px -1px rgba(0, 0, 0, 0.2), " +
            "0px 1px 1px rgba(0, 0, 0, 0.14), " +
            "0px 1px 3px rgba(0, 0, 0, 0.12)",
        })}
  >
    <Box position="absolute" right={12}>
      {selected ? <CheckCircle htmlColor="#4548C4" /> : <CircleOutlined />}
    </Box>
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {image && (
        <Box pb="14px">
          <img alt={name} width="75px" height="75px" src={image} />
        </Box>
      )}
      {name}
    </Box>
  </Box>
);
