import { Grid } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "AddEntitiesMenuItem";

const classes = {
  menuItemTitle: `${PREFIX}-menuItemTitle`,
  menuItemDescription: `${PREFIX}-menuItemDescription`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.menuItemTitle}`]: {
    color: "rgb(0, 0, 0, 0.87)",
    fontSize: "16px",
  },

  [`& .${classes.menuItemDescription}`]: {
    color: "rgb(0, 0, 0, 0.54)",
    fontSize: "14px",
  },
}));

export default ({ title, description }) => (
  <StyledGrid container direction="column">
    <Grid item>
      <span className={classes.menuItemTitle}>{title}</span>
    </Grid>
    <Grid item>
      <span className={classes.menuItemDescription}>{description}</span>
    </Grid>
  </StyledGrid>
);
