import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Paper,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import {
  filter,
  identity,
  isEmpty,
  map,
  not,
  path,
  pipe,
  prop,
  propEq,
  values,
} from "ramda";

import { ConversationDetail } from "./conversation";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DeviceIcon from "./DeviceIcon";
import Identicon from "identicon.js";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import eventTypes from "./conversation/EventTypes";
import moment from "moment-timezone";
import { styled } from "@mui/material/styles";
import useAppStore from "../../../stores/appStore";
import { userPermissionsSelector } from "../../../stores/selectors/appSelectors";

const ConvoFooterGrid = styled(Grid)(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  margin: "15px",
  width: "100%",
}));

const StyledFormGroup = styled(FormGroup)(() => ({
  flexDirection: "row",
  justifyContent: "center",
}));

const StyledFormControl = styled(FormControlLabel)(() => ({
  "& .MuiFormControlLabel-label": {
    fontSize: 14,
  },
}));

const makeConversationDebugLink = (conversationId, conversationTime) => {
  const startTime = conversationTime.subtract(10, "m").valueOf();
  const endTime = conversationTime.add(2, "h").valueOf();
  return `https://app.datadoghq.com/logs?query=@conversation_id:${conversationId}&index=nlu-engine&from_ts=${startTime}&to_ts=${endTime}&live=false`;
};

const stringifyUtterances = pipe(
  values,
  map(path(["data", "utterance"])),
  filter(identity),
  map(JSON.stringify)
);

const getUserUtterances = pipe(
  prop("detail"),
  filter(propEq("eventType", eventTypes.USER_UTTERANCE)),
  stringifyUtterances
);

const getUserIdenticon = (userId) => {
  const altText = `User ${userId}`;
  return (
    <img
      src={`data:image/png;base64,${new Identicon(userId, {
        size: 35,
        background: [255, 255, 255, 255],
      }).toString()}`}
      title={altText}
      alt={altText}
    />
  );
};
export default ({
  cycleConversations,
  conversationId,
  isOpen,
  dismiss,
  conversation,
  updatedResponses,
  timeZone,
}) => {
  const { isAdmin } = useAppStore(userPermissionsSelector);

  const [detailedView, setDetailedView] = useState(false);
  const [showDiff, setShowDiff] = useState(false);
  const [alignableDiff, setAlignableDiff] = useState(false);

  return (
    <Modal
      open={isOpen}
      onClose={dismiss}
      sx={{ marginTop: "5rem", overflow: "scroll" }}
    >
      <Paper sx={{ maxWidth: "500px", margin: "auto" }}>
        <Grid container justifyContent="center">
          <Grid container item justifyContent="space-evenly" mt={2}>
            {conversation.conversationTime && (
              <Grid item style={{ fontSize: "14px" }}>
                {moment
                  .tz(conversation.conversationTime, timeZone)
                  .format("L LT z")}
              </Grid>
            )}
            {not(isEmpty(conversation.detail)) && (
              <Grid item>
                {getUserIdenticon(conversation.detail[0].userId)}
              </Grid>
            )}
            {not(isEmpty(conversation.detail)) && (
              <Grid item>
                <DeviceIcon browserInfo={conversation.detail[0].browserInfo} />
              </Grid>
            )}
            {cycleConversations && (
              <>
                <Grid item>
                  <IconButton
                    title="Previous conversation (p)"
                    aria-label="previous"
                    onClick={() => cycleConversations(-1)}
                    size="large"
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    title="Next conversation (n)"
                    aria-label="next"
                    onClick={() => cycleConversations(1)}
                    size="large"
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item>
            <StyledFormGroup>
              <StyledFormControl
                labelPlacement="start"
                control={
                  <Switch
                    color="primary"
                    defaultChecked={false}
                    onClick={() => setDetailedView(not)}
                  />
                }
                label="Details"
                aria-label="detailed"
              />
              <StyledFormControl
                disabled={!alignableDiff}
                labelPlacement="start"
                control={
                  <Switch
                    color="primary"
                    defaultChecked={false}
                    onClick={() => setShowDiff(not)}
                  />
                }
                label="Diff"
                aria-label="show diff"
              />
            </StyledFormGroup>
          </Grid>
          <Grid container direction="column" alignItems="center" item mx={2}>
            {conversation && (
              <ConversationDetail
                conversation={conversation}
                updatedResponses={updatedResponses}
                isDetailedMode={detailedView}
                showDiff={showDiff}
                setAlignableDiff={setAlignableDiff}
              />
            )}
          </Grid>
          <ConvoFooterGrid item>
            {isAdmin && conversation.isLoaded && (
              <>
                <CopyToClipboard text={getUserUtterances(conversation)}>
                  <Button color="primary">Copy user text</Button>
                </CopyToClipboard>
                <Button
                  color="primary"
                  onClick={() =>
                    window.open(
                      makeConversationDebugLink(
                        conversationId,
                        conversation.conversationTime
                      )
                    )
                  }
                >
                  Log
                </Button>
              </>
            )}
          </ConvoFooterGrid>
        </Grid>
      </Paper>
    </Modal>
  );
};
