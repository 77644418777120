import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import Avatar from "react-avatar";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import useAppStore from "../../stores/appStore";
import { useAuth0 } from "@auth0/auth0-react";
import { userDataSelector } from "../../stores/selectors/appSelectors";

export default () => {
  const { logout } = useAuth0();
  const userData = useAppStore(userDataSelector);

  const [clientMenuAnchorEl, setClientMenuAnchorEl] = useState(null);
  const toggleMenu = ({ currentTarget }) =>
    clientMenuAnchorEl
      ? setClientMenuAnchorEl(null)
      : setClientMenuAnchorEl(currentTarget);

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="false"
        color="inherit"
        onClick={toggleMenu}
        size="large"
      >
        {userData.picture ? (
          <img height="35px" src={userData.picture} alt={userData.name} />
        ) : (
          <Avatar size="35" name={userData.name} />
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={clientMenuAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(clientMenuAnchorEl)}
        onClose={toggleMenu}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            logout({ returnTo: window.location.origin });
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
};
