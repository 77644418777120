import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const PREFIX = "AddButton";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.root}`]: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    justifyContent: "left",
    textTransform: "unset !important",
    fontWeight: 400,
  },
}));

export default ({ handleCreate, label }) => {
  const [bgColor, setBgColor] = useState("transparent");

  return (
    <StyledBox
      bgcolor={bgColor}
      // https://github.com/mui-org/material-ui/issues/16995. TODO(David): use `borderBottom={1}` once Material stable version is 5.
      border={1}
      borderTop={0}
      borderLeft={0}
      borderRight={0}
      borderColor="rgba(21, 22, 71, 0.1)"
      onMouseEnter={() => setBgColor("secondary.light")}
      onMouseLeave={() => setBgColor("transparent")}
      p={2}
    >
      <Button
        color="primary"
        classes={classes}
        fullWidth
        onClick={handleCreate}
      >
        + {label}
      </Button>
    </StyledBox>
  );
};
