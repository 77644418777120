import { TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

const HEADERS_COLOR = "#3B44BD";

export default ({ headCells }) => (
  <TableHead>
    <TableRow>
      {headCells.map((headCell) => (
        <TableCell style={{ color: HEADERS_COLOR }} key={headCell}>
          {headCell}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
