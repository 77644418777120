import {
  DISPLAY,
  TRIGGERS,
  triggerInvalidFormatMessages,
  triggerIsDuplicate,
} from "../utils";
import { Relations, relationEq, subjectEq } from "../../../../triplets";
import {
  addIndex,
  always,
  append,
  assocPath,
  both,
  concat,
  either,
  flatten,
  indexBy,
  isEmpty,
  juxt,
  last,
  map,
  pipe,
  prop,
  propEq,
  reject,
  values,
} from "ramda";
import BotEditorAddButton from "../AddButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputWithIcons from "./InputWithIcons";
import React from "react";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";

const StyledTitleGrid = styled(Grid)(() => ({
  color: "rgba(21, 22, 71, 1)",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
}));

export default ({
  pendingUpdatedTriplets,
  conceptKey,
  row,
  rowContent,
  setInvalidFormat,
  setPendingUpdatedTriplets,
  setRowContent,
}) => {
  const handleNewTrigger = () => {
    if (
      pipe(
        prop(TRIGGERS),
        either(isEmpty, pipe(last, prop("value")))
      )(rowContent)
    ) {
      setRowContent((rowContent) => ({
        ...rowContent,
        [TRIGGERS]: pipe(
          prop(TRIGGERS),
          append({ id: uuidv4(), key: conceptKey, value: "" })
        )(rowContent),
      }));
    }
  };

  const handleEditTriggers = ({
    event: {
      target: { value },
    },
    value: initialValue,
  }) => {
    const updatedRowContent = {
      ...rowContent,
      [TRIGGERS]: pipe(
        prop(TRIGGERS),
        indexBy(prop("value")),
        assocPath([initialValue, "value"], value),
        values
      )(rowContent),
    };
    setRowContent(updatedRowContent);
    updateTriggersInTriplets(updatedRowContent);
  };

  const handleDeleteTriggers = (trigger, index) => () => {
    const updatedRowContent = {
      ...rowContent,
      [TRIGGERS]: pipe(
        prop(TRIGGERS),
        reject(propEq("value", trigger.value))
      )(rowContent),
    };
    setRowContent(updatedRowContent);
    setInvalidFormat(`trigger${index}`)(false);
    updateTriggersInTriplets(updatedRowContent);
  };

  const updateTriggersInTriplets = (updatedRowContent) => {
    const newTriplets = pipe(
      prop(TRIGGERS),
      map(juxt([prop("key"), always(Relations.CONCEPT_TRIGGER), prop("value")]))
    )(updatedRowContent);

    setPendingUpdatedTriplets(
      pipe(
        reject(
          both(relationEq(Relations.CONCEPT_TRIGGER), subjectEq(conceptKey))
        ),
        values,
        concat(newTriplets)
      )(pendingUpdatedTriplets)
    );
  };

  const handleEditDisplay = ({
    event: {
      target: { value },
    },
  }) => {
    const updatedRowContent = {
      ...rowContent,
      display: { ...rowContent.display, value },
    };
    setRowContent(updatedRowContent);
    updateDisplayInTriplets(updatedRowContent);
  };

  const updateDisplayInTriplets = (updatedRowContent) => {
    if (updatedRowContent.display.value !== row.display.value) {
      const newTriplet = [
        conceptKey,
        Relations.CONCEPT_DISPLAY,
        updatedRowContent.display.value,
      ];

      setPendingUpdatedTriplets(
        pipe(
          reject(
            both(relationEq(Relations.CONCEPT_DISPLAY), subjectEq(conceptKey))
          ),
          values,
          append(newTriplet)
        )(pendingUpdatedTriplets)
      );
    }
  };

  return (
    <Box maxHeight="330px">
      <Grid container direction="column" spacing={1}>
        <StyledTitleGrid item>Name</StyledTitleGrid>
        <Grid item>
          <Box mb={2}>
            <InputWithIcons
              handleEdit={handleEditDisplay}
              initialValue={rowContent.display.value}
              placeholder="Type the trigger name here"
              computeInvalidFormatMessages={triggerInvalidFormatMessages}
              setInvalidFormat={setInvalidFormat(DISPLAY)}
            />
          </Box>
        </Grid>
        <StyledTitleGrid item>Triggers</StyledTitleGrid>
        {pipe(
          prop(TRIGGERS),
          addIndex(map)((trigger, index) => (
            <Grid item key={trigger.id}>
              <InputWithIcons
                handleEdit={handleEditTriggers}
                handleDelete={handleDeleteTriggers(trigger, index)}
                initialValue={trigger.value}
                placeholder="Type the new trigger here"
                computeInvalidFormatMessages={pipe(
                  juxt([
                    triggerInvalidFormatMessages,
                    triggerIsDuplicate(pendingUpdatedTriplets),
                  ]),
                  flatten
                )}
                setInvalidFormat={setInvalidFormat(`trigger${index}`)}
              />
            </Grid>
          ))
        )(rowContent)}
        <Grid item>
          <BotEditorAddButton
            handleCreate={handleNewTrigger}
            label="Add a new trigger"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
