import { Autocomplete, Box, Paper, TextField } from "@mui/material";
import {
  BOT_TYPES,
  RESPONSES,
  generateDraft,
  replaceSpecialChars,
} from "../../../utils";
import {
  HyroLoading,
  HyroOption,
  HyroOptionGroup,
  HyroStepperDialog,
} from "../../hyro-components";
import React, { useEffect, useState } from "react";
import {
  StyledDropdownLastItem,
  StyledDropdownLastItemText,
} from "../../../styles/Dropdown";
import {
  always,
  append,
  assoc,
  assocPath,
  cond,
  dropLast,
  either,
  equals,
  flatten,
  isEmpty,
  isNil,
  juxt,
  map,
  not,
  pipe,
  replace,
} from "ramda";
import {
  botConnectorTemplate,
  phoneConnectorTemplate,
  roomTemplate,
  webConnectorTemplate,
} from "../../../defaultConnectorConfigs";
import { deploymentService, workspacesService } from "../../../services";
import AddIcon from "@mui/icons-material/Add";
import BotTemplate from "./BotTemplate";
import CreateWorkspaceDialog from "../workspace-creation/CreateWorkspaceDialog";
import DemoIcon from "./DemoIcon";
import LiveIcon from "./LiveIcon";
import PhoneIcon from "./PhoneIcon";
import ScreenIcon from "./ScreenIcon";
import TestIcon from "./TestIcon";
import blankTemplate from "../../../static/templates/blank_template.csv";
import { default as gamla } from "gamlajs";
import healthCareIcon from "../../../static/images/healthcare_icon.svg";
import healthcareTemplate from "../../../static/templates/healthcare_template.csv";
import { notifyCreateAssistant } from "../../../analytics/notifyCustomActions";
import { object } from "../../../triplets";
import { parse } from "csv-parse/browser/esm/sync";
import { styled } from "@mui/material/styles";
import useAppStore from "../../../stores/appStore";
import { userDataSelector } from "../../../stores/selectors/appSelectors";

const StyledAddIcon = styled(AddIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const HEALTHCARE_DEMO = "Healthcare Demo";
const BLANK = "Blank";

const WEB = "Web";
const PHONE = "Phone";

const getBotId = replaceSpecialChars("_");

const invalidBotName = (botNames, botIds) =>
  either(gamla.contains(botNames), pipe(getBotId, gamla.contains(botIds)));

const replaceInObjects = (findText, replaceText) =>
  map(
    pipe(
      juxt([dropLast(1), pipe(object, replace(findText, replaceText))]),
      flatten
    )
  );

const getTemplateTriplets = (botName) =>
  gamla.asyncPipe(
    cond([
      [equals(HEALTHCARE_DEMO), always(healthcareTemplate)],
      [equals(BLANK), always(blankTemplate)],
    ]),
    fetch,
    (res) => res.text(),
    parse,
    replaceInObjects("<client_name>", botName)
  );

const BotNameAndWorkspaceStep = ({
  name,
  setName,
  workspace,
  setWorkspace,
  myWorkspaces,
  openCreateWorkspace,
  error,
}) => (
  <Box display="flex" flexDirection="column" rowGap="20px">
    <TextField
      label="Assistant Name"
      error={not(isEmpty(error))}
      helperText={error}
      value={name}
      onChange={({ target: { value } }) => setName(value)}
      variant="outlined"
      fullWidth
    />
    <div style={{ display: "flex", alignItems: "flex-end" }}>
      <Autocomplete
        disableClearable
        loading={isNil(myWorkspaces)}
        loadingText="Loading your workspaces..."
        onChange={(_, selection) => setWorkspace(selection)}
        options={myWorkspaces || []}
        noOptionsText="No workspaces found..."
        renderInput={(params) => (
          <TextField {...params} fullWidth label="Workspace" />
        )}
        value={workspace}
        PaperComponent={({ children }) => (
          <Paper>
            {children}
            <StyledDropdownLastItem onMouseDown={openCreateWorkspace}>
              <StyledAddIcon fontSize="small" />
              <StyledDropdownLastItemText>
                Create new workspace
              </StyledDropdownLastItemText>
            </StyledDropdownLastItem>
          </Paper>
        )}
        ListboxProps={{ style: { maxHeight: "30vh" } }}
        fullWidth
      />
    </div>
  </Box>
);

const BotChannelStep = ({ channel, setChannel }) => (
  <HyroOptionGroup value={channel} handleChange={setChannel}>
    <HyroOption name={WEB} Icon={ScreenIcon} />
    <HyroOption name={PHONE} Icon={PhoneIcon} />
  </HyroOptionGroup>
);

const BotTypeStep = ({ type, setType }) => (
  <HyroOptionGroup value={type} handleChange={setType}>
    <HyroOption name={BOT_TYPES.TEST} Icon={TestIcon} />
    <HyroOption name={BOT_TYPES.DEMO} Icon={DemoIcon} />
    <HyroOption name={BOT_TYPES.LIVE} Icon={LiveIcon} />
  </HyroOptionGroup>
);

const BotTemplateStep = ({ template, setTemplate }) => (
  <HyroOptionGroup value={template} handleChange={setTemplate}>
    <BotTemplate name={BLANK} />
    <BotTemplate name={HEALTHCARE_DEMO} image={healthCareIcon} />
  </HyroOptionGroup>
);

export default ({ open, handleClose }) => {
  const [stepperOpen, setStepperOpen] = useState(open);
  const [loading, setLoading] = useState(false);
  const [createWorkspaceOpen, setCreateWorkspaceOpen] = useState(false);

  const userData = useAppStore(userDataSelector);
  const [botIds, setBotIds] = useState([]);
  const [botNames, setBotNames] = useState([]);
  const [myWorkspaces, setMyWorkspaces] = useState(null);

  useEffect(() => {
    (async () => {
      setBotIds(await deploymentService.clientNames());
      setBotNames(await deploymentService.findRoomFriendlyNames());
      setMyWorkspaces(
        await workspacesService.getMyWorkspaceNames({ email: userData.email })
      );
    })();
  }, [setBotNames, setBotIds, setMyWorkspaces, userData]);

  const [botData, setBotData] = useState({
    name: "",
    workspace: "",
    channel: "",
    type: "",
    template: "",
  });

  const steps = [
    {
      heading: "Let's create a new assistant",
      component: (
        <BotNameAndWorkspaceStep
          name={botData.name}
          setName={(name) => setBotData(assoc("name", name))}
          workspace={botData.workspace}
          setWorkspace={(workspace) =>
            setBotData(assoc("workspace", workspace))
          }
          myWorkspaces={myWorkspaces}
          openCreateWorkspace={() => setCreateWorkspaceOpen(true)}
          error={
            invalidBotName(botNames, botIds)(botData.name)
              ? "Conflicts with an existing bot name"
              : ""
          }
        />
      ),
      nextDisabled:
        either(invalidBotName(botNames, botIds), isEmpty)(botData.name) ||
        isEmpty(botData.workspace),
    },
    {
      heading: "What is the bot's channel?",
      component: (
        <BotChannelStep
          channel={botData.channel}
          setChannel={(channel) => setBotData(assoc("channel", channel))}
        />
      ),
      nextDisabled: isEmpty(botData.channel),
    },
    {
      heading: "What is the bot's type?",
      component: (
        <BotTypeStep
          type={botData.type}
          setType={(type) => setBotData(assoc("type", type))}
        />
      ),
      nextDisabled: isEmpty(botData.type),
    },
    {
      heading: "How do you want to get started?",
      component: (
        <BotTemplateStep
          template={botData.template}
          setTemplate={(template) => setBotData(assoc("template", template))}
        />
      ),
      nextDisabled: isEmpty(botData.template),
    },
  ];

  const createAssistant = async () => {
    setStepperOpen(false);
    setLoading(true);

    const room = pipe(
      assoc("friendlyName", botData.name),
      assoc("type", botData.type)
    )(roomTemplate);
    const botId = getBotId(botData.name);
    const botConnector = assocPath(
      ["config", "clientName"],
      botId
    )(botConnectorTemplate);

    const connectors = cond([
      [equals(WEB), always([webConnectorTemplate, botConnector])],
      [
        equals(PHONE),
        always([webConnectorTemplate, phoneConnectorTemplate, botConnector]),
      ],
    ])(botData.channel);

    const templateTriplets = await getTemplateTriplets(botData.name)(
      botData.template
    );

    const newRoomId = await deploymentService.createAssistant({
      room,
      connectors,
      draft: generateDraft(userData.email),
      fileContent: templateTriplets,
      commitMessage: `Adding ${botData.template} Template`,
      workspaceName: botData.workspace,
      userData,
    });

    notifyCreateAssistant(newRoomId, botData.type);

    window.location.replace(`/${RESPONSES}/${newRoomId}`);
  };

  return (
    <>
      <HyroLoading open={loading} loadingText="Creating your new bot" />
      <HyroStepperDialog
        open={stepperOpen}
        steps={steps}
        handleDone={createAssistant}
        handleCancel={handleClose}
      />
      {createWorkspaceOpen && (
        <CreateWorkspaceDialog
          open={createWorkspaceOpen}
          close={() => setCreateWorkspaceOpen(false)}
          myWorkspaces={myWorkspaces}
          onCreate={(workspace) => {
            setMyWorkspaces(append(workspace));
            setBotData(assoc("workspace", workspace));
          }}
        />
      )}
    </>
  );
};
