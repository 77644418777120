import { Box, Button } from "@mui/material";
import React, { forwardRef } from "react";
import { Alert } from "@mui/material";

/**
 * Severity can be "info", "success" or "warning"
 * @param alertText
 * @param dismissText
 * @param severity
 * @param handleDismiss
 * @returns {JSX.Element}
 */
export default forwardRef(
  ({ alertText, dismissText, severity, handleDismiss }, ref) => (
    <Alert
      ref={ref}
      variant="filled"
      severity={severity}
      action={
        <Button
          size="small"
          onClick={handleDismiss}
          sx={{
            fontWeight: 500,
            color: "#FFF",
          }}
        >
          {dismissText}
        </Button>
      }
    >
      <Box fontWeight={400} fontSize={16}>
        {alertText}
      </Box>
    </Alert>
  )
);
