import { Alert, Grid, Snackbar, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  createPreviewAssistantIdSelector,
  createPreviewAssistantInProgressSelector,
  previewAssistantCreationFailedSelector,
  previewAssistantIdSelector,
} from "../../stores/selectors/previewSelectors";
import {
  draftSelector,
  isPublishingTripletsSelector,
  isSavingTripletsSelector,
  isTripletsIdleSelector,
} from "../../stores/selectors/tripletsSelectors";
import DraftButtons from "./DraftButtons";
import DraftStatus from "./DraftStatus";
import PreviewButtons from "./PreviewButtons";
import { SEVERITY_LEVELS } from "../../utils";
import { assoc } from "ramda";
import { isBotPreviewableSelector } from "../../stores/selectors/appSelectors";
import { styled } from "@mui/material/styles";
import useAppStore from "../../stores/appStore";

const Root = styled("div")(({ theme }) => ({
  width: "80%",
  backgroundColor: theme.palette.primary.dark,
  left: "10%",
  top: "64px",
  position: "fixed",
  zIndex: 1,
  borderRadius: "0px 0px 8px 8px",
  color: theme.palette.primary.contrastText,
}));

const StyledAlert = styled(Alert)(() => ({
  ".MuiAlert-filledInfo": {
    background: "#323232",
  },
}));

const ERROR_IN_BUILD_BOT_ALERT = {
  open: true,
  message: "An error occurred during the build - please try again later",
  severity: SEVERITY_LEVELS.ERROR,
};

export default () => {
  const createAssistantForPreview = useAppStore(
    createPreviewAssistantIdSelector
  );
  const createPreviewAssistantInProgress = useAppStore(
    createPreviewAssistantInProgressSelector
  );
  const previewAssistantCreationFailed = useAppStore(
    previewAssistantCreationFailedSelector
  );
  const previewAssistantId = useAppStore(previewAssistantIdSelector);

  const isPublishingTriplets = useAppStore(isPublishingTripletsSelector);
  const isSavingTriplets = useAppStore(isSavingTripletsSelector);
  const isTripletsIdle = useAppStore(isTripletsIdleSelector);
  const draft = useAppStore(draftSelector);
  const isBotPreviewable = useAppStore(isBotPreviewableSelector);
  const [snackbarAlert, setSnackbarAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const inProgressOrTestIsRunning =
    isSavingTriplets ||
    isPublishingTriplets ||
    createPreviewAssistantInProgress;

  const previewIsReady = !inProgressOrTestIsRunning && previewAssistantId;

  useEffect(() => {
    if (isTripletsIdle && isBotPreviewable) createAssistantForPreview();
  }, [createAssistantForPreview, isTripletsIdle, isBotPreviewable]);

  useEffect(() => {
    if (isSavingTriplets) {
      setSnackbarAlert({
        open: true,
        message: "Saving changes",
        severity: SEVERITY_LEVELS.SUCCESS,
      });
    }
  }, [setSnackbarAlert, isSavingTriplets]);

  useEffect(() => {
    if (previewAssistantCreationFailed) {
      setSnackbarAlert(ERROR_IN_BUILD_BOT_ALERT);
    }
  }, [setSnackbarAlert, previewAssistantCreationFailed]);

  const handleCloseSnackbar = (setSnackbarAlert) =>
    setSnackbarAlert(assoc("open", false));

  return draft ? (
    <Root data-testid="portal-header">
      <Toolbar variant="dense">
        <Grid
          container
          spacing={2}
          direction="row"
          justifycontent="space-between"
          alignItems="stretch"
        >
          <DraftStatus {...{ isSavingTriplets }} />
          {isBotPreviewable ? (
            <PreviewButtons {...{ previewIsReady, setSnackbarAlert }} />
          ) : (
            <Grid item xs={3} />
          )}
          <DraftButtons
            {...{
              inProgressOrTestIsRunning,
              setSnackbarAlert,
              handleCloseSnackbar,
            }}
          />
        </Grid>
      </Toolbar>

      <Snackbar
        open={snackbarAlert.open}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar(setSnackbarAlert)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {/* TODO(David): use HyroAlert component. */}
        <StyledAlert
          onClose={() => handleCloseSnackbar(setSnackbarAlert)}
          variant="filled"
          severity={snackbarAlert.severity}
        >
          {snackbarAlert.message}
        </StyledAlert>
      </Snackbar>
    </Root>
  ) : null;
};
