import { Box, MenuItem } from "@mui/material";
import DataSourceIconBox from "./DataSourceIconBox";
import DownloadIcon from "@mui/icons-material/GetAppOutlined";
import React from "react";
import ThreeDotMenu from "../knowledge-editor/ThreeDotMenu";
import { map } from "ramda";
import moment from "moment";
import { storageService } from "../../../services";

const downloadFile = async (fileHash) => {
  const fileUrl = await storageService.downloadFile({ fileHash });
  const element = document.createElement("a");
  element.href = fileUrl;
  element.target = "_blank";
  document.body.appendChild(element);
  element.click();
};

export default ({ name, updateDate, fileHash }) => {
  const formattedDate = moment(updateDate, "YYYY-MM-DD h:mm:ss a").format(
    "[Updated] DD MMM, h:mm"
  );
  return (
    <Box
      sx={{
        width: "310px",
        minHeight: "161px",
        backgroundColor: "#FFF",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        gridRowGap: "8px",
      }}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <DataSourceIconBox type="External File" />
        <ThreeDotMenu
          menuItems={map(({ display, id, run }) => (
            <MenuItem key={id} onClick={run}>
              {display}
            </MenuItem>
          ))([
            {
              id: "download",
              display: (
                <>
                  <Box pr={1}>
                    <DownloadIcon color="action" />
                  </Box>
                  Download
                </>
              ),
              run: () => downloadFile(fileHash),
            },
          ])}
        />
      </Box>
      <Box fontWeight={500} fontSize="20px">
        {name}
      </Box>
      <Box fontWeight={400} fontSize="12px" color="#00000099">
        {formattedDate}
      </Box>
    </Box>
  );
};
