import { HyroDialog, HyroTabsWithTitleAndButton } from "../../hyro-components";
import React, { useState } from "react";
import { always, assocPath, equals, map, path, when } from "ramda";
import Box from "@mui/material/Box";
import { INPUT_TYPES } from "../../../utils";
import SettingsTab from "./SettingsTab";

const handleUpdateField = (setSettingsConfig) => (fieldPath) => (value) =>
  setSettingsConfig(
    assocPath(fieldPath, when(equals(""), always(undefined))(value))
  );
const getFieldValue = (settingsConfig) => (fieldPath) =>
  path(fieldPath || [])(settingsConfig);

const getTabsSpec = (settingsConfig, setSettingsConfig) =>
  map(({ title, sections }) => ({
    label: title,
    value: title,
    content: (
      <SettingsTab
        title={title}
        getFieldValue={getFieldValue(settingsConfig)}
        handleUpdateField={handleUpdateField(setSettingsConfig)}
        sections={sections}
      />
    ),
  }));

export default ({ title, tabsConfig, settings, publishSettings }) => {
  const [initialSettingsConfig, setInitialSettingsConfig] = useState(settings);
  const [settingsConfig, setSettingsConfig] = useState(settings);
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);
  return (
    <Box mt={4}>
      <HyroTabsWithTitleAndButton
        title={`${title} Settings`}
        tabsSpec={getTabsSpec(settingsConfig, setSettingsConfig)(tabsConfig)}
        handleClick={() => setPublishDialogOpen(true)}
        buttonDisabled={equals(initialSettingsConfig, settingsConfig)}
        buttonText="Publish"
      />
      <HyroDialog
        fieldSpec={[
          {
            inputType: INPUT_TYPES.DESCRIPTION,
            label:
              "You are about to publish changes to production, this will affect the bot within a few minutes.",
          },
        ]}
        handleClose={() => setPublishDialogOpen(false)}
        handleSubmit={async () => {
          await publishSettings(settingsConfig);
          // TODO(ENG-1704): ADD FEEDBACK MESSAGE ONCE POSSIBLE
          setInitialSettingsConfig(settingsConfig);
          setPublishDialogOpen(false);
        }}
        open={publishDialogOpen}
        submitButtonText="Publish"
        title={`Publish ${title} Settings Changes`}
      />
    </Box>
  );
};
