import { map, mergeAll } from "ramda";

export default mergeAll([
  {
    ADMIN: "admin",
  },
  map((s) => `read:${s}`, {
    CONVERSATIONS: "conversations",
    CONVERSIONS: "conversions",
    DATA_SOURCES: "dataSources",
    FIND_A_PROVIDER: "findAProvider",
    GENERAL_SETTINGS: "generalSettings",
    INSIGHTS: "insights",
    KNOWLEDGE_EDITOR: "knowledgeEditor",
    KNOWLEDGE_EXPLORER: "knowledgeExplorer",
    MISSING_TERMS: "missingTerms",
    ONLINE_QUERIES: "onlineQueries",
    QUALITY_KPIS: "qualityKPIs",
    RECORDING: "recording",
    RESPONSES: "responses",
    SITE_SEARCH: "siteSearch",
    TEST_BOT: "testBot",
  }),
  map((s) => `write:${s}`, {
    CREATE_BOT: "createBot",
  }),
]);
