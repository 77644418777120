import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";

const PREFIX = "HyroHeader";

const classes = {
  headingText: `${PREFIX}-headingText`,
  button: `${PREFIX}-button`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.headingText}`]: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: "roboto",
  },

  [`& .${classes.button}`]: {
    textTransform: "unset !important",
    fontWeight: 500,
  },
}));

export default ({ title, handleSearch, buttonSpec }) => (
  <StyledGrid container justifyContent="flex-start" alignItems="center">
    <Grid item xs={6}>
      <h1 className={classes.headingText}>{title}</h1>
    </Grid>
    <Grid
      item
      container
      xs={6}
      spacing={3}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
          onChange={({ target: { value } }) => handleSearch(value)}
          size="small"
          variant="outlined"
          placeholder="Search"
        />
      </Grid>
      <Grid item>
        <Button
          className={classes.button}
          startIcon={buttonSpec.icon}
          color="primary"
          size="medium"
          variant="contained"
          onClick={buttonSpec.run}
        >
          {buttonSpec.text}
        </Button>
      </Grid>
    </Grid>
  </StyledGrid>
);
