import Box from "@mui/material/Box";
import HyroInfoBox from "../../hyro-components/HyroInfoBox";
import React from "react";

export default () => {
  const InfoBoxBody = () => (
    <Box ml="10px">
      <ol>
        <li key={1}>
          Search the relevant location on this website -{" "}
          <a
            href="https://boundingbox.klokantech.com/"
            style={{
              color: "#000000DE",
              textDecoration: "underline",
            }}
            target="noopener noreferrer"
          >
            https://boundingbox.klokantech.com/
          </a>
        </li>
        <li key={2}>Define the relevant boundaries on the map</li>
        <li key={3}>In the dropdown below the map, select "CSV"</li>
        <li key={4}>Copy and paste the coordinates here</li>
      </ol>
    </Box>
  );

  return <HyroInfoBox title="How to get coordinates?" body={<InfoBoxBody />} />;
};
