import { BOTS_DIRECTORY_NAME, generateDraft } from "../../utils";
import {
  PROVIDER,
  Relations,
  TRIPLETS_STATUSES,
  objectToTriplets,
  relationIn,
} from "../../triplets";
import {
  always,
  concat,
  evolve,
  filter,
  find,
  ifElse,
  isEmpty,
  isNil,
  keys,
  mergeRight,
  not,
  pipe,
  propEq,
  reject,
} from "ramda";
import { botPortalService } from "../../services";

export default (set, get) => ({
  // original triplets = most up to date master triplets: used for comparison while you are editing a draft
  originalTriplets: [],
  currentTriplets: [],
  draft: null,
  tripletsStatus: TRIPLETS_STATUSES.IDLE,
  lastUpdateTimestamp: null,
  clearTriplets: () => {
    set({
      originalTriplets: [],
      currentTriplets: [],
      draft: null,
      tripletsStatus: TRIPLETS_STATUSES.LOADING,
    });
  },
  initTriplets: async () => {
    // TODO(ENG-3478): Error handling
    set({ tripletsStatus: TRIPLETS_STATUSES.LOADING });
    const { changeRequests, userData } = get();
    const originalTriplets = (await botPortalService.originalTriplets()) || [];

    // TODO(ENG-3480): Move this mechanism to the backend
    // Determine if there is an open draft being edited
    let lastDraft = await botPortalService.getLastDraftByUserId({
      userId: userData.email,
    });
    // there is an issue with getLastDraftByUserId endpoint returning "[]" if no drafts
    if (isEmpty(lastDraft)) lastDraft = null;
    const draft = ifElse(
      pipe(find(propEq("draft", lastDraft)), isNil),
      always(lastDraft),
      always(null)
    )(changeRequests);

    const currentTriplets = draft
      ? (await botPortalService.tripletsFromDraft({
          draft,
        })) || []
      : originalTriplets;

    set({
      originalTriplets,
      currentTriplets,
      draft,
      tripletsStatus: TRIPLETS_STATUSES.IDLE,
    });
  },
  updateCurrentTriplets: async (updateFn) => {
    // TODO(ENG-3478): Error handling
    const { clientName, userData, draft, currentTriplets } = get();
    const updatedTriplets = updateFn(currentTriplets);
    set({ currentTriplets: updatedTriplets });

    const tripletsDir = `${BOTS_DIRECTORY_NAME}/${clientName}`;
    const commitMessage = `Edit ${tripletsDir}/triplets.csv`;

    set({ tripletsStatus: TRIPLETS_STATUSES.SAVING });
    if (!draft) {
      const draft = generateDraft(userData.email);
      set({ draft });
      await botPortalService.createDraftAndUpdateTriplets({
        draft,
        fileContent: updatedTriplets,
        commitMessage,
        userData,
      });
    } else {
      await botPortalService.editTriplets({
        draft,
        fileContent: updatedTriplets,
        commitMessage,
        userData,
      });
    }

    set({
      tripletsStatus: TRIPLETS_STATUSES.IDLE,
      lastUpdateTimestamp: Date.now(),
    });
  },
  publishCurrentTripletsToDraft: async (changeDescription) => {
    // TODO(ENG-3478): Error handling
    const { draft, originalTriplets } = get();
    set({ tripletsStatus: TRIPLETS_STATUSES.PUBLISHING });

    try {
      await botPortalService.mergePendingBranch({
        description: changeDescription,
        draft,
      });
    } catch (error) {
      console.error(error);
    }

    const changeRequests = await botPortalService.getChangeRequests();
    set({
      changeRequests,
      currentTriplets: originalTriplets,
      draft: null,
      tripletsStatus: TRIPLETS_STATUSES.IDLE,
    });
  },
  deletePublishedDraft: async (draftToDelete) => {
    const { changeRequests, userData } = get();
    const updatedChangeRequests = reject(propEq("draft", draftToDelete))(
      changeRequests
    );
    set({ changeRequests: updatedChangeRequests });
    // TODO(ENG-3478): Error handling/loading spinner
    await botPortalService.deleteDraft({
      draft: draftToDelete,
      userData,
    });
  },
  deleteCurrentDraft: async () => {
    const { draft, originalTriplets, userData } = get();
    set({ currentTriplets: originalTriplets, draft: null });
    // TODO(ENG-3478): Error handling/loading spinner
    await botPortalService.deleteDraft({
      draft,
      userData,
    });
  },
  updateResultCardConfig: async (modifiedConfig) => {
    const result_card_triplet_format_fns = {
      [Relations.RENDER_TITLE]: (subtitle) =>
        subtitle ? `name,${subtitle}` : "name",
    };
    const relationsToReject = keys(modifiedConfig);
    const tripletsToConcat = pipe(
      evolve(result_card_triplet_format_fns),
      filter(pipe(isEmpty, not)),
      mergeRight({ id: PROVIDER }),
      objectToTriplets
    )(modifiedConfig);

    const { updateCurrentTriplets } = get();
    const tripletsUpdateFn = pipe(
      reject(relationIn(relationsToReject)),
      concat(tripletsToConcat)
    );
    await updateCurrentTriplets(tripletsUpdateFn);
  },
});
