import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { filter, identity } from "ramda";
import DebounceInput from "react-debounce-input";
import React from "react";

export default ({
  inputs,
  name,
  queryInput: { key, optional, placeholder, type, values },
  setInputs,
}) => {
  const inputProps = {
    label: placeholder,
    margin: "none",
    style: { width: "100%" },
    value: inputs[name][key],
    onChange: ({ target: { value } }) =>
      setInputs((state) => ({
        ...state,
        [name]: {
          ...state[name],
          [key]: value,
        },
      })),
    placeholder,
    required: !optional,
  };

  return (
    <Box m={1} minWidth="120px">
      <FormControl key={key}>
        <Grid item>
          {type === "dropdown" ? (
            <Select {...inputProps}>
              {filter(identity)([
                placeholder && (
                  <MenuItem key="" value="" disabled>
                    {placeholder}
                  </MenuItem>
                ),
                values &&
                  values.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  )),
              ])}
            </Select>
          ) : (
            <DebounceInput
              element={TextField}
              debounceTimeout={200}
              {...inputProps}
            />
          )}
        </Grid>
      </FormControl>
    </Box>
  );
};
