import React, { cloneElement } from "react";
import { map, path, pipe } from "ramda";
import { Box } from "@mui/material";

export default ({ children, value, handleChange }) => (
  <Box
    bgcolor="#F5F5F5"
    pt="48px"
    pb="48px"
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
    <Box fontSize="24px" fontWeight={500} pb="24px">
      How do you want to get started?
    </Box>
    <Box display="flex" flexDirection="row">
      {map((child) => (
        <React.Fragment key={child.props.name}>
          {cloneElement(child, {
            ...child.props,
            onClick: () => pipe(path(["props", "name"]), handleChange)(child),
            selected: child.props.name === value,
          })}
        </React.Fragment>
      ))(children)}
    </Box>
  </Box>
);
