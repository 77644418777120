import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FieldGroup from "./FieldGroup";
import { not } from "ramda";
import { styled } from "@mui/material/styles";

const PREFIX = "HiddenFieldGroup";

const classes = {
  toggle: `${PREFIX}-toggle`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.toggle}`]: {
    marginBottom: "10px",
    fontFamily: "roboto",
    fontWeight: 500,
    fontSize: "14px",
    textTransform: "none",
  },
}));

export default ({
  fieldList,
  getFieldValue,
  handleUpdateField,
  toggleText,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <StyledBox>
      <Button
        className={classes.toggle}
        color="primary"
        startIcon={visible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={() => setVisible(not)}
      >
        {visible ? "Hide " : "Show "}
        {toggleText}
      </Button>
      {visible && (
        <Box>
          <FieldGroup
            fieldList={fieldList}
            getFieldValue={getFieldValue}
            handleUpdateField={handleUpdateField}
          />
        </Box>
      )}
    </StyledBox>
  );
};
