import { DATA_SOURCE_TYPES } from "../../../utils";
import DataSourceType from "./DataSourceType";
import { Grid } from "@mui/material";
import React from "react";

const dataSourcesSpec = [
  {
    type: DATA_SOURCE_TYPES.EXTERNAL_FILE,
  },
  {
    type: DATA_SOURCE_TYPES.API,
    disabled: true,
  },
  {
    type: DATA_SOURCE_TYPES.MANUAL,
    disabled: true,
  },
];

export default ({ setDataType }) => (
  <Grid container spacing={2}>
    {dataSourcesSpec.map(({ type, disabled }) => (
      <Grid item key={type}>
        <DataSourceType
          type={type}
          handleClick={() => setDataType(type)}
          disabled={disabled}
        />
      </Grid>
    ))}
  </Grid>
);
