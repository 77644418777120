import {
  ChatBubbleOutline,
  Phone as PhoneIcon,
  Code as SnippetIcon,
} from "@mui/icons-material";
import { Grid, Tooltip } from "@mui/material";
import { INPUT_TYPES, SEVERITY_LEVELS } from "../../utils";
import React, { useState } from "react";
import {
  initiateCallToAssistantSelector,
  previewAssistantIdSelector,
} from "../../stores/selectors/previewSelectors";
import { CopyToClipboard } from "react-copy-to-clipboard";
import HyroDialog from "../../components/hyro-components/HyroDialog";
import MediumButton from "./MediumButton";
import Snippet from "../../components/widgets/overview/Snippet";
import { makeSnippet } from "../../snippetGenerator";
import { not } from "ramda";
import { notifyPreviewUsage } from "../../analytics/notifyCustomActions";
import { styled } from "@mui/material/styles";
import useAppStore from "../../stores/appStore";
const ButtonsContainer = styled(Grid)(() => ({
  display: "flex",
  gap: 8,
}));

export default ({ previewIsReady, setSnackbarAlert }) => {
  const previewAssistantId = useAppStore(previewAssistantIdSelector);
  const initiateCallToAssistant = useAppStore(initiateCallToAssistantSelector);

  const [phoneCallDialogOpen, setPhoneCallDialogOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);

  const handleInitiateCall = async (data, setData) => {
    const callRes = await initiateCallToAssistant(data.phoneNumber);
    setSnackbarAlert({
      open: true,
      ...callRes,
    });
    if (callRes.message) {
      setPhoneCallDialogOpen(false);
      setData({ ...data, phoneNumber: "" });
    }
  };

  return (
    <ButtonsContainer
      item
      container
      justifycontent="flex-center"
      direction="row"
      alignItems="center"
      xs={3}
    >
      <CopyToClipboard
        aria-label="copy"
        onCopy={() => {
          notifyPreviewUsage("snippet");
          setSnackbarAlert({
            open: true,
            message: "Snippet copied to clipboard",
            severity: SEVERITY_LEVELS.SUCCESS,
          });
        }}
        text={makeSnippet({
          roomId: previewAssistantId,
          environment: process.env.NODE_ENV,
          isBotPortal: true,
        })}
      >
        <MediumButton disabled={!previewIsReady}>
          <SnippetIcon fontSize="small" />
        </MediumButton>
      </CopyToClipboard>
      <MediumButton
        aria-label="call"
        disabled={!previewIsReady}
        onClick={() => {
          notifyPreviewUsage("call");
          setPhoneCallDialogOpen(true);
        }}
      >
        <Tooltip title="Initiate Call" placement="bottom">
          <PhoneIcon fontSize="small" />
        </Tooltip>
      </MediumButton>
      <MediumButton
        aria-label="chat"
        disabled={!previewIsReady}
        onClick={() => {
          notifyPreviewUsage("chat");
          setChatOpen(not);
        }}
      >
        <Tooltip
          title={chatOpen ? "Close Chat" : "Open Chat"}
          placement="bottom"
        >
          <ChatBubbleOutline fontSize="small" />
        </Tooltip>
      </MediumButton>
      <HyroDialog
        {...{
          fieldSpec: [
            {
              key: "phoneNumber",
              inputType: INPUT_TYPES.TEXT,
              label: "Phone number",
              validationFunction: (value) =>
                value && value.length < 8 ? ["Phone number is not valid"] : [],
            },
            {
              inputType: INPUT_TYPES.DESCRIPTION,
              label:
                "Example number (Israel): +972549894555 or (USA): +16462485599",
            },
          ],
          handleClose: () => setPhoneCallDialogOpen(false),
          handleSubmit: handleInitiateCall,
          open: phoneCallDialogOpen,
          submitButtonText: "Call me",
          title: "Test via phone call",
        }}
      />
      {previewIsReady && chatOpen && (
        <Tooltip title="Copy Snippet" placement="bottom">
          <Snippet open={chatOpen} roomId={previewAssistantId} isBotPortal />
        </Tooltip>
      )}
    </ButtonsContainer>
  );
};
