import React, { useState } from "react";

import BoxWithInvalidFormatMessages from "./BoxWithInvalidFormatMessages";
import { DebounceInput } from "react-debounce-input";
import Grid from "@mui/material/Grid";
import Icons from "./Icons";
import { InputBase } from "@mui/material";

export default ({
  computeInvalidFormatMessages,
  handleEdit,
  handleDelete,
  initialValue,
  placeholder,
  inputProps,
  setInvalidFormat,
}) => {
  const [showIcons, setShowIcons] = useState(false);
  const [currentValue, setCurrentValue] = useState(initialValue);
  let input = null;
  const handleMouseEnter = () => {
    setShowIcons(true);
  };

  const handleMouseLeave = () => {
    setShowIcons(false);
  };

  return (
    <BoxWithInvalidFormatMessages
      boxProps={{
        p: 2,
      }}
      computeInvalidFormatMessages={computeInvalidFormatMessages}
      currentValue={currentValue}
      customHandleMouseEnter={handleMouseEnter}
      customHandleMouseLeave={handleMouseLeave}
      setInvalidFormat={setInvalidFormat}
      spacing={1}
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={11}>
          <DebounceInput
            element={InputBase}
            color="primary"
            debounceTimeout={500}
            fullWidth
            onChange={(event) => {
              setCurrentValue(event.target.value);
              handleEdit({ event, value: currentValue });
            }}
            placeholder={placeholder}
            value={currentValue}
            inputRef={(_input) => (input = _input)}
            {...inputProps}
          />
        </Grid>
        <Grid item xs={1}>
          <Icons
            handleDelete={handleDelete}
            setEditClicked={() => {
              input.firstChild.focus();
              setShowIcons(false);
            }}
            showIcons={showIcons}
          />
        </Grid>
      </Grid>
    </BoxWithInvalidFormatMessages>
  );
};
