import {
  roomIdSelector,
  roomsSelector,
} from "../stores/selectors/appSelectors";
import InsightsBase from "../components/widgets/insights/InsightsBase";
import NothingHereYet from "./NothingHereYet";
import React from "react";
import { insightsService } from "../services";
import { roomHasLookerId } from "../looker";
import useAppStore from "../stores/appStore";

export default () => {
  const rooms = useAppStore(roomsSelector);
  const selectedRoomId = useAppStore(roomIdSelector);

  return roomHasLookerId(selectedRoomId, rooms) ? (
    <InsightsBase
      lookerEmbedDashboardObjectGetter={
        insightsService.getLookerEmbedDashboardObject
      }
    />
  ) : (
    <NothingHereYet />
  );
};
