import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDropdownLastItemText = styled(Box)(({ theme }) => ({
  marginLeft: "4px",
  fontSize: "14px",
  fontWeight: 500,
  color: theme.palette.primary.main,
}));

export const StyledDropdownLastItem = styled(Box)(() => ({
  borderTop: "1px solid",
  borderColor: "#EEEEEE",
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingBottom: "8px",
  paddingTop: "8px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontSize: "14px",
  lineHeight: "24px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
}));
