import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

export default ({
  open,
  close,
  title,
  text,
  confirmFunction,
  disableConfirmButton,
}) => (
  <>
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={() => {
            confirmFunction();
            close();
          }}
          color="primary"
          disabled={disableConfirmButton}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  </>
);
