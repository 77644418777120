import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { equals, pipe } from "ramda";
import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "OnOff";

const classes = {
  radioGroup: `${PREFIX}-radioGroup`,
};

const StyledFormControl = styled(FormControl)(() => ({
  [`& .${classes.radioGroup}`]: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
  },
}));

export default ({ value, onChange }) => (
  <StyledFormControl component="fieldset">
    <RadioGroup
      className={classes.radioGroup}
      value={value ? "On" : "Off"}
      onChange={({ target: { value } }) =>
        pipe(
          equals("On"),
          (bool) => ({ target: { value: bool } }),
          onChange
        )(value)
      }
    >
      <FormControlLabel
        labelPlacement="start"
        value="On"
        control={<Radio color="primary" />}
        label="On"
      />
      <FormControlLabel
        labelPlacement="start"
        value="Off"
        control={<Radio color="primary" />}
        label="Off"
      />
    </RadioGroup>
  </StyledFormControl>
);
