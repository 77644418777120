import { Relations, object, relationEq } from "../../../triplets";
import {
  addIndex,
  always,
  anyPass,
  apply,
  both,
  either,
  endsWith,
  equals,
  filter,
  flatten,
  gte,
  head,
  identity,
  ifElse,
  includes,
  isEmpty,
  juxt,
  last,
  lte,
  map,
  pipe,
  split,
  toUpper,
  tryCatch,
  uniq,
  unless,
} from "ramda";
import { EMAIL_REGEX } from "../../../utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import ReactMarkdown from "react-markdown";
import emojiRegex from "emoji-regex/RGI_Emoji";
import { default as gamla } from "gamlajs";

const EVENTS = "events";
const EVENTS_LABEL = "Events";

const RESPONSE = "response";
const RESPONSE_LABEL = "Bot response";

const SUGGESTIONS = "suggestions";
const SUGGESTIONS_LABEL = "Suggestions";

const TRIGGERS = "triggers";
const TRIGGERS_LABEL = "Name & Triggers";

const DISPLAY = "display";

const TITLE = "title";
const TITLE_LABEL = "Title";

const GENERAL_RESPONSES = "general_responses";
const CANNED_RESPONSES = "canned_responses";

const CANNED_RESPONSE_SUGGESTION_TYPE = "cannedResponse";
const TEXT = "text";

const TEXT_COLOR = "rgba(21,22,71,0.7)";

const hasEmail = (text) => EMAIL_REGEX.test(text);
const hasUrl = (text) =>
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/.test(
    text
  );
const hasMarkdown = (text) =>
  ReactDOMServer.renderToString(<ReactMarkdown>{text}</ReactMarkdown>) !==
  `<p>${text}</p>`;

const hasEmoji = (text) => emojiRegex().test(text);
const hasNoSpaceColon = (text) => /[A-Za-z]:[A-Za-z]/.test(text);
const isPhoneNumber = (text) => /[0-9-.]/.test(text);

const isUpper = pipe(juxt([identity, toUpper]), apply(equals));
const FIRST_CHARS = ["¿", "<", "*", "["];
const LAST_CHARS = [".", "!", "?", ">", ":", "*"];

const rowResponseInvalidFormatMessages = pipe(
  gamla.trim(["-"]),
  ifElse(
    anyPass([hasEmail, hasUrl, hasMarkdown]),
    always([]),
    pipe(
      juxt([
        ifElse(
          pipe(
            head,
            anyPass([
              isUpper,
              gamla.contains(FIRST_CHARS),
              both(lte("0"), gte("9")),
            ])
          ),
          always(null),
          always(
            `Each row must start with an upper letter [A-Z], a number [0-9] or one of the following: "${FIRST_CHARS.join(
              '", "'
            )}".`
          )
        ),
        ifElse(
          either(
            endsWith('."'),
            pipe(last, either(gamla.contains(LAST_CHARS), hasEmoji))
          ),
          always(null),
          always(
            `Each row must end with one of the following: "${LAST_CHARS.join(
              '", "'
            )}", or an emoji.`
          )
        ),
        ifElse(
          hasNoSpaceColon,
          always(`Each row must not contain a colon without spaces.`),
          always(null)
        ),
      ]),
      filter(identity)
    )
  )
);

const containsHebrewChar = (text) => /[\u0590-\u05FF]/.test(text);

const triggerIsDuplicate = (pendingTriplets) => (text) =>
  pipe(
    filter(relationEq(Relations.CONCEPT_TRIGGER)),
    map(object),
    (a) => addIndex(filter)((e, i) => a.indexOf(e) !== i, a),
    includes(text)
  )(pendingTriplets)
    ? ["Text must not conflict with an existing trigger"]
    : [];

const containsHebrewCharMessage = ifElse(
  containsHebrewChar,
  always(["Text must not contain hebrew characters."]),
  always([])
);

const notEmptyMessage = ifElse(
  isEmpty,
  always(["Text must not be empty."]),
  always([])
);

const triggerInvalidFormatMessages = pipe(
  juxt([
    notEmptyMessage,
    containsHebrewCharMessage,
    ifElse(
      includes(","),
      always(["Trigger must not contain commas."]),
      always([])
    ),
  ]),
  flatten
);

const eventInvalidDataFormat = tryCatch(
  pipe(unless(isEmpty, JSON.parse), always([])),
  always(["JSON object has invalid format"])
);

const responseInvalidFormatMessages = pipe(
  juxt([
    notEmptyMessage,
    pipe(
      split("\n"),
      juxt([
        pipe(map(rowResponseInvalidFormatMessages), flatten),
        containsHebrewCharMessage,
      ]),
      flatten
    ),
  ]),
  flatten,
  uniq
);

export {
  TEXT_COLOR,
  DISPLAY,
  EVENTS,
  EVENTS_LABEL,
  RESPONSE,
  RESPONSE_LABEL,
  SUGGESTIONS,
  SUGGESTIONS_LABEL,
  TRIGGERS,
  TRIGGERS_LABEL,
  TITLE,
  TITLE_LABEL,
  GENERAL_RESPONSES,
  CANNED_RESPONSES,
  containsHebrewCharMessage,
  notEmptyMessage,
  isPhoneNumber,
  hasUrl,
  responseInvalidFormatMessages,
  triggerInvalidFormatMessages,
  CANNED_RESPONSE_SUGGESTION_TYPE,
  TEXT,
  eventInvalidDataFormat,
  triggerIsDuplicate,
};
