import { IconButton, Menu } from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems}
      </Menu>
    </>
  );
};
