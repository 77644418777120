import React, { useEffect, useMemo, useState } from "react";
import BotEditorBox from "./Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

const PREFIX = "BoxWithInvalidFormatMessages";

const classes = {
  invalidFormat: `${PREFIX}-invalidFormat`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.invalidFormat}`]: {
    color: "#EE5D5D",
    fontSize: 11,
  },
}));

const BG_COLOR = "#F8F8F8";
const INVALID_FORMAT_BG_COLOR = "#FFF3F3";

export default ({
  boxProps,
  children,
  computeInvalidFormatMessages,
  customHandleMouseEnter,
  customHandleMouseLeave,
  currentValue,
  setInvalidFormat,
  spacing,
}) => {
  const [bgColor, setBgColor] = useState(BG_COLOR);
  const invalidFormatMessages = useMemo(
    () =>
      computeInvalidFormatMessages
        ? computeInvalidFormatMessages(currentValue)
        : [],
    [computeInvalidFormatMessages, currentValue]
  );

  const handleMouseEnter = () => {
    if (!invalidFormatMessages.length) {
      setBgColor("secondary.light");
    }
    if (customHandleMouseEnter) {
      customHandleMouseEnter();
    }
  };

  const handleMouseLeave = () => {
    if (!invalidFormatMessages.length) {
      setBgColor(BG_COLOR);
    }
    if (customHandleMouseLeave) {
      customHandleMouseLeave();
    }
  };

  useEffect(() => {
    if (computeInvalidFormatMessages) {
      if (invalidFormatMessages.length) {
        setBgColor(INVALID_FORMAT_BG_COLOR);
        setInvalidFormat(true);
      } else {
        if (bgColor === INVALID_FORMAT_BG_COLOR) {
          setBgColor(BG_COLOR);
        }
        setInvalidFormat(false);
      }
    }
  }, [
    bgColor,
    computeInvalidFormatMessages,
    invalidFormatMessages,
    setBgColor,
    setInvalidFormat,
  ]);

  return (
    <StyledGrid container direction="column" spacing={spacing}>
      <Grid item>
        <BotEditorBox
          bgColor={bgColor}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          styleProps={boxProps}
        >
          {children}
        </BotEditorBox>
      </Grid>
      <Grid item>
        <div className={classes.invalidFormat}>
          {invalidFormatMessages.map((message, index) => (
            <div key={index}>* {message}</div>
          ))}
        </div>
      </Grid>
    </StyledGrid>
  );
};
