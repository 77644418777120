import { pipe, split } from "ramda";
import React from "react";
import { TextField } from "@mui/material";

// TODO(ENG-1678): Improve UI of the component
export default ({ value, label, onChange }) => (
  <TextField
    value={value || ""}
    onChange={({ target: { value } }) =>
      pipe(split(","), (list) => ({ target: { value: list } }), onChange)(value)
    }
    label={label}
    sx={{ width: "100%" }}
    variant="outlined"
  />
);
