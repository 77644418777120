import React from "react";

export default ({ message }) => (
  <div
    style={{
      height: "80%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 35,
      textAlign: "center",
    }}
  >
    <div style={{ fontWeight: "bold" }}>{message}</div>
    <div>Try selecting a different time period.</div>
  </div>
);
