import Box from "@mui/material/Box";
import HistoricalConversationModal from "../components/widgets/overview/HistoricalConversationModal";
import LatestConversationsFromMongo from "../components/widgets/overview/LatestConversationsFromMongo";
import OverviewInsights from "../components/widgets/overview/OverviewInsights";
import React from "react";
import { useQueryParamState } from "../hooks";

const MemoizedOverviewInsights = React.memo(OverviewInsights);

export default ({ key: roomId }) => {
  const [conversationId, setConversationId] =
    useQueryParamState("conversationId");

  return (
    <Box>
      {conversationId && (
        <HistoricalConversationModal
          isOpen={!!conversationId}
          conversationId={conversationId}
          dismiss={() => setConversationId(null)}
        />
      )}
      <MemoizedOverviewInsights roomId={roomId} />
      <LatestConversationsFromMongo />
    </Box>
  );
};
