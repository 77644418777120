import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  adaptV4Theme,
} from "@mui/material";
import { FIELD_TYPES, tripletsToRows } from "./utils";
import React, { useEffect, useState } from "react";
import {
  T,
  always,
  assoc,
  both,
  cond,
  difference,
  equals,
  filter,
  has,
  head,
  identity,
  ifElse,
  isEmpty,
  isNil,
  juxt,
  keys,
  lensProp,
  map,
  not,
  of,
  over,
  pipe,
  prop,
  reject,
  toLower,
  useWith,
} from "ramda";
import { Autocomplete } from "@mui/material";
import DropDown from "../../hyro-components/DropDown";
import { Relations } from "../../../triplets";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { currentTripletsSelector } from "../../../stores/selectors/tripletsSelectors";
import { default as gamla } from "gamlajs";
import useAppStore from "../../../stores/appStore";

const isRequired = has(Relations.FIELD_REQUIRED);

const requiredTheme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: "#EF4068",
          "&$error": {
            color: "#EF4068",
          },
        },
      },
    },
  })
);

const getRefPossibleValues = (entityType) =>
  pipe(
    tripletsToRows(entityType),
    map(
      pipe(
        juxt([
          pipe(prop("id"), head),
          ifElse(
            pipe(prop(Relations.CONCEPT_DISPLAY), isNil),
            pipe(prop("id"), head),
            pipe(prop(Relations.CONCEPT_DISPLAY), head)
          ),
        ]),
        ([value, display]) => ({
          display,
          value,
        })
      )
    )
  );

export default ({ entityType, spec, row, addRow, handleClose }) => {
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [rowToAdd, setRowToAdd] = useState(null);
  const updateRow =
    (fieldName) =>
    ({ target: { value } }) => {
      setRowToAdd(assoc(fieldName, of(value)));
    };
  const updateRowMultipleValues = (fieldName) => (event, value) => {
    setRowToAdd(assoc(fieldName, value));
  };
  const deleteChip = (fieldName, chipToDelete) => () => {
    setRowToAdd(over(lensProp(fieldName), reject(equals(chipToDelete))));
  };
  const currentTriplets = useAppStore(currentTripletsSelector);

  useEffect(() => {
    // We have to set rowToAdd here and not as an initial state because we have to wait for row to come from the server (otherwise rowToAdd will be null)
    setRowToAdd(row);
  }, [row, setRowToAdd]);
  useEffect(() => {
    if (rowToAdd) {
      pipe(
        useWith(difference, [
          pipe(filter(isRequired), map(prop(Relations.FIELD_RELATION))),
          pipe(reject(equals([""])), keys),
        ]),
        isEmpty,
        not,
        setSaveDisabled
      )(spec, rowToAdd);
    }
  }, [rowToAdd, spec]);
  return (
    <ThemeProvider theme={requiredTheme}>
      <Dialog open={Boolean(row)} onClose={handleClose} fullWidth>
        <DialogTitle>Add {entityType}</DialogTitle>
        <DialogContent>
          <Box fontWeight={400} fontSize="14px" mb="20px" color="#00000099">
            <span style={{ color: "#EF4068" }}>*</span> Required information
          </Box>
          <Grid container direction="column" spacing={2}>
            {map((field) => (
              <Grid item key={field.id}>
                {pipe(
                  prop(Relations.FIELD_TYPE),
                  cond([
                    [
                      equals(FIELD_TYPES.RADIO),
                      always(
                        <FormControl>
                          <FormLabel required={isRequired(field)}>
                            {field[Relations.FIELD_LABEL]}
                          </FormLabel>
                          <RadioGroup
                            aria-label={field[Relations.FIELD_LABEL]}
                            name={field[Relations.FIELD_LABEL]}
                            onChange={updateRow(
                              toLower(field[Relations.FIELD_RELATION])
                            )}
                          >
                            {field[Relations.FIELD_OPTION] &&
                              map((option) => (
                                <FormControlLabel
                                  value={option}
                                  checked={pipe(
                                    prop(
                                      toLower(field[Relations.FIELD_RELATION])
                                    ),
                                    both(identity, pipe(head, equals(option)))
                                  )(rowToAdd)}
                                  key={option}
                                  control={<Radio color="primary" />}
                                  label={gamla.capitalize(option)}
                                />
                              ))(field[Relations.FIELD_OPTION])}
                          </RadioGroup>
                        </FormControl>
                      ),
                    ],
                    [
                      equals(FIELD_TYPES.MULTIPLE_VALUES),
                      always(
                        <Autocomplete
                          multiple
                          id={field.id}
                          options={[]}
                          value={
                            prop(
                              toLower(field[Relations.FIELD_RELATION]),
                              rowToAdd
                            ) || []
                          }
                          freeSolo
                          onChange={updateRowMultipleValues(
                            toLower(field[Relations.FIELD_RELATION])
                          )}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                key={option}
                                {...getTagProps({ index })}
                                onDelete={deleteChip(
                                  toLower(field[Relations.FIELD_RELATION]),
                                  option
                                )}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={field[Relations.FIELD_LABEL]}
                              helperText="Hit Enter to add value"
                              required={isRequired(field)}
                            />
                          )}
                        />
                      ),
                    ],
                    [
                      equals(FIELD_TYPES.REF),
                      always(
                        <DropDown
                          label={field[Relations.FIELD_ENTITY]}
                          options={getRefPossibleValues(
                            field[Relations.FIELD_ENTITY]
                          )(currentTriplets)}
                          value={
                            (rowToAdd &&
                              rowToAdd[
                                toLower(field[Relations.FIELD_RELATION])
                              ]) ||
                            ""
                          }
                          onChange={updateRow(
                            toLower(field[Relations.FIELD_RELATION])
                          )}
                        />
                      ),
                    ],
                    [
                      T,
                      always(
                        <TextField
                          label={field[Relations.FIELD_LABEL]}
                          id={field.id}
                          value={
                            (rowToAdd &&
                              rowToAdd[
                                toLower(field[Relations.FIELD_RELATION])
                              ]) ||
                            ""
                          }
                          fullWidth
                          variant="outlined"
                          onChange={updateRow(
                            toLower(field[Relations.FIELD_RELATION])
                          )}
                          required={isRequired(field)}
                        />
                      ),
                    ],
                  ])
                )(field)}
              </Grid>
            ))(spec)}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "24px" }}>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => addRow(rowToAdd)}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};
