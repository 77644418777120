import { Box, Grid, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NoDataMessage from "./NoDataMessage";
import Paper from "@mui/material/Paper";
import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "ChartWrapper";

const classes = {
  chart: `${PREFIX}-chart`,
  header: `${PREFIX}-header`,
  title: `${PREFIX}-title`,
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.chart}`]: {
    minWidth: 300,
    height: 400,
    marginBottom: "2%",
    padding: "1%",
  },

  [`& .${classes.header}`]: {
    paddingTop: 10,
    paddingLeft: 20,
  },

  [`& .${classes.title}`]: {
    display: "flex",
    fontSize: "1.2rem",
    justifyContent: "flex-start",
  },
}));

export default ({ title, showNoDataMessage, placeholder, children }) => (
  <StyledPaper className={classes.chart}>
    <Box className={classes.header}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <div className={classes.title}>{title}</div>
        </Grid>
        {placeholder && (
          <Grid item>
            <Tooltip title={placeholder} placement="right">
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Box>
    {showNoDataMessage && (
      <NoDataMessage
        message={
          "Whoops! We currently lack sufficient data to show this graph."
        }
      />
    )}
    {children}
  </StyledPaper>
);
