import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";

export default ({ searchText, setSearchText }) => (
  <TextField
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    onChange={({ target: { value } }) => setSearchText(value)}
    size="small"
    value={searchText}
    variant="outlined"
  />
);
