import { Box, Chip } from "@mui/material";
import { length, map, slice } from "ramda";
import React from "react";
import { styled } from "@mui/material/styles";

const CHIP_LIMIT = 1;

const ChipCountBox = styled(Box)(() => ({
  border: "1px solid #4548C4",
  borderRadius: "16px",
  paddingLeft: "14px",
  paddingRight: "14px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: "#4548C4",
  fontSize: "13px",
  fontWeight: 400,
}));

export default ({ values }) => (
  <Box display="flex" justifyContent="flex-start">
    {map((chipLabel) => (
      <Chip label={chipLabel} key={chipLabel} style={{ marginRight: "4px" }} />
    ))(slice(0, CHIP_LIMIT)(values))}
    {length(values) > CHIP_LIMIT && (
      <ChipCountBox>+{length(values) - CHIP_LIMIT}</ChipCountBox>
    )}
  </Box>
);
