import React from "react";

export default ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.56 9.22001L23 12L20.56 14.78L20.9 18.46L17.29 19.28L15.4 22.46L12 21L8.6 22.47L6.71 19.29L3.1 18.47L3.44 14.78L1 12L3.44 9.21001L3.1 5.53001L6.71 4.72001L8.6 1.54001L12 3.00001L15.4 1.54001L17.29 4.72001L20.9 5.54001L20.56 9.22001ZM16.49 9.52997L10.56 15.46L7.39001 12.29L8.45001 11.23L10.56 13.34L15.43 8.46997L16.49 9.52997Z"
      fill={fill}
    />
  </svg>
);
