import { always, prop } from "ramda";
import { batchValidate, urlCheckPrefix, urlCheckUnique } from "./validation";
import BaseSiteSearch from "./BaseSiteSearch";
import BlockIcon from "@mui/icons-material/Block";
import React from "react";
import { Relations } from "../../../triplets";

const dialogFields = [
  {
    key: Relations.SITE_SEARCH_BLOCK_URL,
    label: "URL",
    required: true,
    setValidationFn: (pendingTriplets, row) =>
      batchValidate([
        urlCheckUnique(Relations.SITE_SEARCH_BLOCK_URL)(
          pendingTriplets,
          prop("id")(row)
        ),
        urlCheckPrefix,
      ]),
  },
  {
    key: Relations.SITE_SEARCH_BLOCK_NOTE,
    label: "Note",
    required: false,
    setValidationFn: always(always([])),
  },
];

export default ({ pendingTriplets, setPendingTriplets }) => (
  <BaseSiteSearch
    pendingTriplets={pendingTriplets}
    setPendingTriplets={setPendingTriplets}
    dialogFields={dialogFields}
    dialogTitle="Configure Blocked Source"
    headerTitle={
      <span>
        These URLs{" "}
        <span
          style={{
            textDecoration: "underline",
          }}
        >
          won't
        </span>{" "}
        appear in the site search:
      </span>
    }
    firstRelation={Relations.SITE_SEARCH_BLOCK_URL}
    firstAlias="URL"
    secondRelation={Relations.SITE_SEARCH_BLOCK_NOTE}
    secondAlias="Note"
    statusIcon={<BlockIcon htmlColor="#F44336" />}
  />
);
