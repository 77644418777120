import Box from "@mui/material/Box";
import React from "react";

export default ({
  children,
  handleMouseEnter,
  handleMouseLeave,
  showIcons,
}) => (
  <Box
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    border={1}
    borderColor={showIcons ? "#DEDEE3" : "transparent"}
    p={1}
  >
    {children}
  </Box>
);
