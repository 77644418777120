import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { inc, isEmpty, map, pipe } from "ramda";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EventTypes from "../../overview/conversation/EventTypes.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputWithIcons from "./InputWithIcons";
import JSONPretty from "react-json-pretty";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { eventInvalidDataFormat } from "../utils";
import { styled } from "@mui/material/styles";

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  root: {
    "&$.Mui-disabled": {
      backgroundColor: "white",
      opacity: 1,
    },
  },
  disabled: {},
}));

export default ({
  isAdmin,
  eventObj,
  idx,
  handleEditEventType,
  handleEditEventData,
  handleDeleteEvent,
  setInvalidFormat,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) =>
    setExpanded(isExpanded && panel);

  const disableExpansionProps = {
    onClick: (event) => expanded && event.stopPropagation(),
    onFocus: (event) => expanded && event.stopPropagation(),
  };

  const ADMIN_EVENT_TYPES = [EventTypes.INTENT, EventTypes.EXTERNAL];

  const [hover, setHover] = useState(false);

  const isDisabled =
    !isAdmin && eventObj.type !== EventTypes.EXTERNAL && eventObj.type !== "";

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      border={1}
      borderColor={hover ? "#DEDEE3" : "transparent"}
    >
      <Accordion
        key={eventObj.key}
        disabled={isDisabled}
        expanded={expanded === eventObj.key}
        onChange={handleChange(eventObj.key)}
        style={{ boxShadow: "none" }}
      >
        <StyledAccordionSummary
          expandIcon={hover && !isDisabled && <ExpandMoreIcon />}
        >
          <Grid container alignItems="center">
            <Grid
              item
              style={{
                minWidth: "3%",
                fontWeight: "bold",
              }}
            >
              {`${inc(idx)}.`}
            </Grid>
            <Grid item xs={1} {...disableExpansionProps}>
              <Select
                IconComponent={
                  expanded && isAdmin ? KeyboardArrowDownIcon : "none"
                }
                disableUnderline
                disabled={!expanded || !isAdmin}
                color="primary"
                value={eventObj.type}
                variant="standard"
                onChange={({ target: { value } }) =>
                  handleEditEventType({ value: { ...eventObj, type: value } })
                }
                renderValue={(selected) => (
                  <Chip
                    sx={
                      isDisabled
                        ? {
                            color: "#a8a8a8",
                          }
                        : {
                            backgroundColor: "#d7d9f1",
                            color: "#161453",
                          }
                    }
                    label={selected}
                  />
                )}
              >
                {isAdmin &&
                  pipe(
                    map((eventSelection) => (
                      <MenuItem key={eventSelection} value={eventSelection}>
                        <Chip
                          color="primary"
                          label={eventSelection}
                          sx={{
                            backgroundColor: "#d7d9f1",
                            color: "#161453",
                          }}
                        />
                      </MenuItem>
                    ))
                  )(ADMIN_EVENT_TYPES)}
              </Select>
            </Grid>
            <Grid
              container
              item
              xs={10}
              direction="row"
              justifyContent="flex-end"
            >
              {hover && !isDisabled && (
                <Grid item {...disableExpansionProps}>
                  <DeleteOutlineIcon
                    sx={{
                      color: "#787878",
                      cursor: "pointer",
                    }}
                    fontSize="small"
                    onClick={handleDeleteEvent(eventObj)}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Box ml={4} style={{ width: "96%" }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item>
                <InputWithIcons
                  initialValue={eventObj.data}
                  placeholder="Add event data in JSON format."
                  inputProps={{ multiline: true, style: { fontSize: "12px" } }}
                  handleEdit={handleEditEventData(eventObj.key)}
                  computeInvalidFormatMessages={eventInvalidDataFormat}
                  setInvalidFormat={setInvalidFormat(eventObj.key)}
                />
              </Grid>
              <Grid item>
                {isEmpty(eventInvalidDataFormat(eventObj.data)) &&
                  eventObj.data !== "" && (
                    <p style={{ fontWeight: "bold" }}>Preview:</p>
                  )}
                <JSONPretty
                  id="json-pretty"
                  theme={{
                    main: "line-height:1.3;color:#272822;overflow:auto;",
                    error: "opacity:0",
                    key: "color:#f92672;",
                    string: "color:#262882;",
                    value: "color:#a6e22e;",
                    boolean: "color:#ac81fe;",
                  }}
                  data={eventObj.data}
                />
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
