import { CONNECTOR_TYPES } from "./utils";

// TODO(ENG-1690): CONSIDER ADDING OF FURTHER DEFAULTS TO THE TEMPLATES BELOW
//  TAKE DEFAULTS FROM THE WIDGET SERVER
//  ALSO CONSIDER WHETHER A KEY SHOULD REMAIN IN THE OBJECT IF ITS VALUE BECOMES UNDEFINED

const roomTemplate = {
  switchCommands: [
    {
      commandType: "add",
      data: {
        id: CONNECTOR_TYPES.BOT_SOCKET_CONNECTOR,
      },
    },
  ],
  config: {
    analysis: {
      wentLiveDate: new Date(),
      tags: [],
    },
  },
  isActive: true,
  errorResponseText:
    "Something went wrong. You can try again later while we try to fix the problem.",
};

const webConnectorTemplate = {
  type: CONNECTOR_TYPES.WEB_CONNECTOR,
  config: {
    origins: ["/*", "https://www.hyro.ai"],
    style: {},
    features: {
      initialMaxVisitors: -1,
      sttConfidenceResponse: true,
      "redact-PII": true,
      userUtteranceRecording: false,
      useWidgetOpenState: true,
    },
    defaultPathConfig: {
      allowTraffic: {
        platforms: {
          desktop: true,
          mobile: true,
        },
      },
      UIFeatures: {
        numberOfIterationsToShow: -1,
        textAreaPlaceholder: "How can I help?",
        shouldTruncate: true,
      },
    },
  },
};

const phoneConnectorTemplate = {
  type: CONNECTOR_TYPES.PHONE_CONNECTOR,
  description: "add friendly description",
  config: {
    phrases: ["an array of phrases for the specific client"],
    features: {
      voiceName: "en-US-Wavenet-D",
      userUtteranceRecording: true,
    },
  },
};

const botConnectorTemplate = {
  type: CONNECTOR_TYPES.BOT_SOCKET_CONNECTOR,
  config: {},
};

export {
  webConnectorTemplate,
  phoneConnectorTemplate,
  botConnectorTemplate,
  roomTemplate,
};
