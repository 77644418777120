import { Box, CircularProgress, Dialog } from "@mui/material";
import React from "react";

export default ({ open, loadingText }) => (
  <Dialog open={open} fullScreen>
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Box mb={4}>
        <CircularProgress size={42} color="primary" />
      </Box>
      <Box fontWeight={500} fontSize="24px">
        {loadingText}
      </Box>
    </Box>
  </Dialog>
);
