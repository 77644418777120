import ActionDiff from "../ActionDiff";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "Confirmation";

const classes = {
  text: `${PREFIX}-text`,
  subtitle: `${PREFIX}-subtitle`,
  title: `${PREFIX}-title`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.text}`]: {
    color: "#151647",
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "24px",
  },

  [`& .${classes.subtitle}`]: {
    fontSize: "14px",
  },

  [`& .${classes.title}`]: {
    fontSize: "20px",
  },
}));

export default ({
  actionKey,
  deploymentDisplayName,
  pendingUpdatedTriplets,
  conceptKey,
  triplets,
}) => (
  <StyledGrid container direction="column">
    <Grid item className={`${classes.text} ${classes.title}`}>
      Publish to production?
    </Grid>
    <Grid item className={`${classes.text} ${classes.subtitle}`}>
      You're about to push these changes to {deploymentDisplayName}'s live
      deployment:
    </Grid>
    <Grid item>
      <Box mt={2}>
        <ActionDiff
          actionKey={actionKey}
          conceptKey={conceptKey}
          newTriplets={pendingUpdatedTriplets}
          oldTriplets={triplets}
        />
      </Box>
    </Grid>
  </StyledGrid>
);
