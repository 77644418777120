import { Box, Grid } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DataSourceIconBox from "./DataSourceIconBox";
import React from "react";

export default ({ type, disabled, handleClick }) => (
  <Box
    sx={{
      boxShadow: disabled
        ? "none"
        : "0px 2px 1px -1px rgba(0, 0, 0, 0.2), " +
          "0px 1px 1px rgba(0, 0, 0, 0.14), " +
          "0px 1px 3px rgba(0, 0, 0, 0.12)",
      border: disabled ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
      backgroundColor: "#FFF",
      borderRadius: "8px",
      padding: "24px",
      width: "176px",
      height: "136px",
    }}
  >
    <Grid container spacing={3} direction="column">
      <Grid container item>
        <Grid item xs={6}>
          <DataSourceIconBox type={type} />
        </Grid>
        {disabled && (
          <Grid
            item
            xs={6}
            sx={{
              height: "20px",
              width: "85px",
              fontFamily: "roboto",
              fontWeight: 500,
              fontSize: "12px",
              color: "#00000099",
              bgcolor: "#F5F5F5",
              borderRadius: "64px",
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            Coming soon
          </Grid>
        )}
      </Grid>
      <Grid
        item
        sx={{
          fontFamily: "roboto",
          fontWeight: 500,
          fontSize: "20px",
        }}
      >
        {type}
      </Grid>
      <Grid
        alignItems="center"
        container
        item
        disabled={disabled}
        onClick={disabled ? () => {} : handleClick}
        spacing={1}
        sx={{
          fontFamily: "roboto",
          fontWeight: 500,
          fontSize: "15px",
          color: disabled ? "#00000042" : "#3F51B5",
          "&:hover": {
            cursor: disabled ? "mouse" : "pointer",
          },
        }}
      >
        <Grid item>Get started</Grid>
        <Grid item>
          <ChevronRightIcon />
        </Grid>
      </Grid>
    </Grid>
  </Box>
);
