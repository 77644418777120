import { Divider, Grid, MenuItem } from "@mui/material";
import { T, always, cond, isEmpty, isNil } from "ramda";
import Box from "@mui/material/Box";
import React from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";

const PREFIX = "ChangeRequestsLastUpdate";

const classes = {
  lastUpdatedText: `${PREFIX}-lastUpdatedText`,
  lastUpdatedDescription: `${PREFIX}-lastUpdatedDescription`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(() => ({
  [`& .${classes.lastUpdatedText}`]: {
    opacity: 0.6,
    fontSize: "12px",
    fontWeight: 500,
  },

  [`& .${classes.lastUpdatedDescription}`]: {
    opacity: 0.6,
    fontSize: "12px",
    display: "inline-flex",
    whiteSpace: "pre-line",
    textAlign: "center",
  },
}));

export default ({ lastLiveChangeRequest }) => {
  const lastUpdated = (content) => (
    <span className={classes.lastUpdatedText}>{content}</span>
  );

  return (
    <Root>
      <Divider />
      <MenuItem
        disabled
        style={{
          opacity: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#F5F5F5",
          minHeight: "58px",
        }}
      >
        <Box>
          {cond([
            [isEmpty, always(lastUpdated("Loading last bot update..."))],
            [isNil, always(lastUpdated("No updates yet"))],
            [
              T,
              always(
                <Grid
                  container
                  direction="column"
                  justifycontent="center"
                  alignItems="center"
                >
                  <Box mb="-3px">
                    <Grid item>
                      {lastUpdated(
                        `Last bot update: ${moment(
                          lastLiveChangeRequest.updated_at
                        ).format("D MMM, H:mm")}`
                      )}
                    </Grid>
                  </Box>
                  <Grid item>
                    <span className={classes.lastUpdatedDescription}>
                      "{lastLiveChangeRequest.description}"
                    </span>
                  </Grid>
                </Grid>
              ),
            ],
          ])(lastLiveChangeRequest)}
        </Box>
      </MenuItem>
    </Root>
  );
};
