import React, { useState } from "react";
import {
  hasPermissionSelector,
  roomIdSelector,
} from "../../stores/selectors/appSelectors";
import ChatBubble from "@mui/icons-material/ChatBubbleOutlined";
import ChatBubbleOutlined from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { IconButton } from "@mui/material";
import Snippet from "../../components/widgets/overview/Snippet";
import USER_PERMISSIONS from "../../userPermissions";
import { not } from "ramda";
import useAppStore from "../../stores/appStore";

export default () => {
  const hasPermission = useAppStore(hasPermissionSelector);
  const selectedRoomId = useAppStore(roomIdSelector);

  const [chatOpen, setChatOpen] = useState(false);

  const toggleChatOpen = () => setChatOpen(not);

  return (
    <>
      {chatOpen && hasPermission(USER_PERMISSIONS.TEST_BOT) && (
        <Snippet open={chatOpen} roomId={selectedRoomId} />
      )}
      {hasPermission(USER_PERMISSIONS.TEST_BOT) && (
        <IconButton color="inherit" onClick={toggleChatOpen} size="large">
          {chatOpen ? <ChatBubble /> : <ChatBubbleOutlined />}
        </IconButton>
      )}
    </>
  );
};
