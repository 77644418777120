import { Box, Grid, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Relations,
  objectToTriplets,
  relationIn,
  relationPrefixEq,
  tripletsToObjects,
} from "../../../triplets";
import {
  all,
  allPass,
  always,
  any,
  applySpec,
  concat,
  equals,
  filter,
  head,
  identity,
  ifElse,
  includes,
  isEmpty,
  isNil,
  join,
  length,
  map,
  multiply,
  not,
  of,
  pipe,
  prepend,
  prop,
  props,
  reject,
  split,
  test,
  toString,
  trim,
  when,
  zipObj,
} from "ramda";
import CoordinatesInfoBox from "./CoordinatesInfoBox";
import { DebounceInput } from "react-debounce-input";
import { isNumber } from "../../../utils";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";

const PREFIX = "LocationSettings";

const classes = {
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  titleText: `${PREFIX}-titleText`,
  button: `${PREFIX}-button`,
  lockIcon: `${PREFIX}-lockIcon`,
};

const StyledPaper = styled(Paper)(({ theme: { spacing } }) => ({
  [`&.${classes.paper}`]: {
    height: 500,
    padding: "24px",
    marginTop: spacing(1),
    width: "100%",
  },

  [`& .${classes.heading}`]: {
    fontWeight: 500,
    fontSize: "20px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "roboto",
    marginBottom: "25px",
  },

  [`& .${classes.titleText}`]: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "roboto",
  },

  [`& .${classes.button}`]: {
    textTransform: "unset !important",
    fontWeight: 500,
  },

  [`& .${classes.lockIcon}`]: {
    width: 18,
    height: 18,
    marginRight: 5,
  },
}));

const LOCATION_BOUNDS_RELATIONS = [
  Relations.LOCATION_CONTEXT_SOUTHWEST_LONGITUDE,
  Relations.LOCATION_CONTEXT_SOUTHWEST_LATITUDE,
  Relations.LOCATION_CONTEXT_NORTHEAST_LONGITUDE,
  Relations.LOCATION_CONTEXT_NORTHEAST_LATITUDE,
];

const LOCATION_CONTEXT = "location_context";

const validateSearchBounds = pipe(
  split(","),
  ifElse(
    allPass([
      pipe(map(pipe(includes(" "), not)), all(identity)),
      pipe(length, equals(4)),
      pipe(map(isNumber), all(identity)),
    ]),
    always(""),
    always("Coordinates must be 4 comma-separated numbers without spaces")
  )
);

const validateSearchRadius = ifElse(
  allPass([pipe(test(/^0+/), not), isNumber]),
  always(""),
  always("Radius must be a positive number without leading 0s")
);

const tripletsToLocationContext = pipe(
  filter(relationPrefixEq(LOCATION_CONTEXT)),
  tripletsToObjects,
  head,
  applySpec({
    id: pipe(
      prop("id"),
      when(isNil, always(`${uuidv4()}_${LOCATION_CONTEXT}`))
    ),
    currSearchRadius: pipe(
      prop(Relations.LOCATION_CONTEXT_SEARCH_RADIUS),
      when(isNil, always(""))
    ),
    currSearchBounds: pipe(
      props(LOCATION_BOUNDS_RELATIONS),
      ifElse(any(isNil), always(""), join(","))
    ),
  })
);

const updateTriplets = (setTriplets) => (relations, id, objects) => {
  const newTriplets = objectToTriplets(
    zipObj(prepend("id", relations), prepend(id, objects))
  );
  setTriplets(pipe(reject(relationIn(relations)), concat(newTriplets)));
};

const onChange =
  (
    validate,
    setValue,
    setError,
    setTriplets,
    relations,
    locationContextId,
    getObjects
  ) =>
  ({ target: { value } }) => {
    const trimmedValue = trim(value);
    const valid = validate(trimmedValue);
    setValue(trimmedValue);
    setError(valid);
    if (isEmpty(valid)) {
      updateTriplets(setTriplets)(
        relations,
        locationContextId,
        getObjects(trimmedValue)
      );
    }
  };

export default ({ pendingTriplets, setPendingTriplets }) => {
  const [locationContextId, setLocationContextId] = useState("");
  const [searchRadius, setSearchRadius] = useState("");
  const [searchBounds, setSearchBounds] = useState("");
  const [searchBoundsError, setSearchBoundsError] = useState("");
  const [searchRadiusError, setSearchRadiusError] = useState("");

  useEffect(() => {
    const { id, currSearchRadius, currSearchBounds } =
      tripletsToLocationContext(pendingTriplets);
    setLocationContextId(id);
    setSearchRadius(String(currSearchRadius / 1000));
    setSearchBounds(currSearchBounds);
  }, [pendingTriplets]);

  const onChangeSearchBounds = onChange(
    validateSearchBounds,
    setSearchBounds,
    setSearchBoundsError,
    setPendingTriplets,
    LOCATION_BOUNDS_RELATIONS,
    locationContextId,
    split(",")
  );

  const onChangeSearchRadius = onChange(
    validateSearchRadius,
    setSearchRadius,
    setSearchRadiusError,
    setPendingTriplets,
    of(Relations.LOCATION_CONTEXT_SEARCH_RADIUS),
    locationContextId,
    pipe(parseInt, multiply(1000), toString, of)
  );

  return (
    <StyledPaper className={classes.paper}>
      <Box className={classes.heading}>Location settings</Box>
      <CoordinatesInfoBox />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <h1 className={classes.titleText}>Search Boundaries</h1>
        </Grid>
        <Grid item xs={6}>
          <DebounceInput
            element={TextField}
            error={not(isEmpty(searchBoundsError))}
            helperText={searchBoundsError}
            label="Coordinates"
            variant="outlined"
            value={searchBounds}
            onChange={onChangeSearchBounds}
            fullWidth
            debounceTimeout={700}
          />
        </Grid>
        <Grid item xs={6}>
          <h1 className={classes.titleText}>Search Radius</h1>
        </Grid>
        <Grid item xs={6}>
          <DebounceInput
            element={TextField}
            error={not(isEmpty(searchRadiusError))}
            helperText={searchRadiusError}
            label="Threshold (in Kilometers)"
            variant="outlined"
            value={searchRadius}
            onChange={onChangeSearchRadius}
            fullWidth
            debounceTimeout={700}
          />
        </Grid>
      </Grid>
    </StyledPaper>
  );
};
