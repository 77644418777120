import {
  ALL_ASSISTANTS,
  CHANNEL_SETTINGS,
  DASHBOARD,
  DATA_SOURCES,
  FIND_A_PROVIDER,
  GENERAL_SETTINGS,
  HEALTHCARE_INSIGHTS,
  INSIGHTS,
  KNOWLEDGE_DEBUGGER,
  KNOWLEDGE_EDITOR,
  KNOWLEDGE_EXPLORER,
  QUERIES,
  REAL_ESTATE_INSIGHTS,
  RESPONSES,
  RND_INSIGHTS,
  SITE_SEARCH,
  TEMPLATE_SETTINGS,
} from "../../utils";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AllAssistantsIcon from "../../components/widgets/data-sources/AllAssistanstsIcon";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import DataSourceIcon from "../../components/widgets/data-sources/DataSourceIcon";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import USER_PERMISSIONS from "../../userPermissions";

const OVERVIEW_PAGE_NAME = "Overview";

export default (selectedRoomId) => [
  {
    key: OVERVIEW_PAGE_NAME,
    text: OVERVIEW_PAGE_NAME,
    icon: HomeOutlinedIcon,
    url: `/${DASHBOARD}/${selectedRoomId}`,
    requiredPermission: USER_PERMISSIONS.CONVERSATIONS,
  },
  {
    key: "settings",
    text: "Settings",
    icon: SettingsOutlinedIcon,
    children: [
      {
        key: "general",
        text: "General",
        url: `/${GENERAL_SETTINGS}/${selectedRoomId}`,
        requiredPermission: USER_PERMISSIONS.GENERAL_SETTINGS,
      },
      {
        key: "template",
        text: "Template",
        url: `/${TEMPLATE_SETTINGS}/${selectedRoomId}`,
        requiredPermission: USER_PERMISSIONS.GENERAL_SETTINGS,
      },

      {
        key: "channel",
        text: "Channel",
        url: `/${CHANNEL_SETTINGS}/${selectedRoomId}`,
        requiredPermission: USER_PERMISSIONS.GENERAL_SETTINGS,
      },
    ],
  },
  {
    key: "data_sources",
    text: "Data Sources",
    icon: DataSourceIcon,
    url: `/${DATA_SOURCES}/${selectedRoomId}`,
    requiredPermission: USER_PERMISSIONS.DATA_SOURCES,
  },
  {
    key: "skills",
    text: "Skills",
    icon: OfflineBoltOutlinedIcon,
    children: [
      {
        key: "responses",
        text: "Responses",
        url: `/${RESPONSES}/${selectedRoomId}`,
        requiredPermission: USER_PERMISSIONS.RESPONSES,
        isBeta: true,
      },
      {
        key: "find_a_provider",
        text: "Find a Provider",
        url: `/${FIND_A_PROVIDER}/${selectedRoomId}`,
        requiredPermission: USER_PERMISSIONS.FIND_A_PROVIDER,
        isBeta: true,
      },
      {
        key: "site_search",
        text: "Site Search",
        url: `/${SITE_SEARCH}/${selectedRoomId}`,
        requiredPermission: USER_PERMISSIONS.SITE_SEARCH,
        isBeta: true,
      },
    ],
  },
  {
    key: "knowledge",
    text: "Knowledge",
    icon: SchoolOutlinedIcon,
    children: [
      {
        key: "editor",
        text: "Editor",
        url: `/${KNOWLEDGE_EDITOR}/${selectedRoomId}`,
        requiredPermission: USER_PERMISSIONS.KNOWLEDGE_EDITOR,
      },
      {
        key: "explorer",
        text: "Explorer",
        url: `/${KNOWLEDGE_EXPLORER}/${selectedRoomId}`,
        requiredPermission: USER_PERMISSIONS.KNOWLEDGE_EXPLORER,
      },
      {
        key: "debugger",
        text: "Debugger",
        url: `/${KNOWLEDGE_DEBUGGER}`,
        requiredPermission: USER_PERMISSIONS.ADMIN,
      },
    ],
  },
  {
    key: "analytics",
    text: "Analytics",
    icon: AssessmentOutlinedIcon,
    children: [
      {
        key: "queries",
        text: "Queries",
        url: `/${QUERIES}/${selectedRoomId}`,
        requiredPermission: USER_PERMISSIONS.ONLINE_QUERIES,
        isBeta: true,
      },
      {
        key: "replay",
        text: "Replay",
        url: "/replay",
        requiredPermission: USER_PERMISSIONS.ADMIN,
      },
    ],
  },
  {
    key: "insights",
    text: "Insights",
    url: `/${INSIGHTS}/${selectedRoomId}`,
    icon: InsightsOutlinedIcon,
    requiredPermission: USER_PERMISSIONS.INSIGHTS,
  },
  {
    key: "admin_panel",
    text: "Admin Panel",
    url: `/admin/${ALL_ASSISTANTS}`,
    icon: AdminPanelSettingsIcon,
    requiredPermission: USER_PERMISSIONS.ADMIN,
  },
  {
    key: "to_client_dashboards",
    text: "To Client Dashboards",
    icon: ArrowBackOutlined,
    url: `/dashboard/${selectedRoomId}`,
    isAdminPanelItem: true,
  },
  {
    key: "admin_insights",
    text: "Insights",
    icon: InsightsOutlinedIcon,
    isAdminPanelItem: true,
    children: [
      {
        key: "r&d_insights",
        text: "R&D Insights",
        url: `/admin/${RND_INSIGHTS}`,
        isAdminPanelItem: true,
      },
      {
        key: "healthcare_insights",
        text: "Healthcare Insights",
        url: `/admin/${HEALTHCARE_INSIGHTS}`,
        isAdminPanelItem: true,
      },
      {
        key: "real_estate_insights",
        text: "Real Estate Insights",
        url: `/admin/${REAL_ESTATE_INSIGHTS}`,
        isAdminPanelItem: true,
      },
    ],
    selectedByDefault: true,
  },
  {
    key: "all_assistants",
    text: "All Assistants",
    icon: AllAssistantsIcon,
    url: `/admin/${ALL_ASSISTANTS}`,
    isAdminPanelItem: true,
  },
];
