import React, { useEffect, useRef } from "react";

import { resolveEnvironmentSnippetLocation } from "../../../snippetGenerator";
import { styled } from "@mui/material/styles";

const PREFIX = "Snippet";

const classes = {
  chatFrameContainer: `${PREFIX}-chatFrameContainer`,
  chatFrame: `${PREFIX}-chatFrame`,
};

const Root = styled("span")(() => ({
  [`&.${classes.chatFrameContainer}`]: {
    width: "80%",
    height: "80%",
    bottom: 0,
    right: 0,
    position: "fixed",
    zIndex: 1,
  },

  [`& .${classes.chatFrame}`]: {
    width: "100%",
    height: "100%",
    border: 0,
  },
}));

export default ({ open, roomId, isBotPortal }) => {
  const container = useRef(null);

  useEffect(() => {
    container.current.innerHTML = "";

    if (!open) {
      return;
    }
    const frame = document.createElement("iframe");
    frame.className = classes.chatFrame;
    container.current.appendChild(frame);

    const widgetId = frame.contentDocument.createElement("script");
    widgetId.text = `window.HYRO_WIDGET_ID="${roomId}";window._HYRO_USER_TESTING=true`;
    const script = frame.contentDocument.createElement("script");
    script.src =
      resolveEnvironmentSnippetLocation(process.env.NODE_ENV) + "?id=" + roomId;
    if (isBotPortal) {
      const widgetServerUrl = frame.contentDocument.createElement("script");
      widgetServerUrl.text = `window.HYRO_WIDGET_SERVER_URL="${process.env.REACT_APP_PREVIEW_WIDGET_SERVER_URL}";`;
      frame.contentDocument.body.appendChild(widgetServerUrl);
    }
    frame.contentDocument.body.appendChild(widgetId);
    frame.contentDocument.body.appendChild(script);
  }, [roomId, open, isBotPortal]);

  return <Root className={classes.chatFrameContainer} ref={container} />;
};
