import Chip from "@mui/material/Chip";
import React from "react";

import { getHyroColorRGB } from "../utils";

const chipStyle = (typeName) =>
  ({
    secondary: { backgroundColor: "#e0e0e0", color: "#000" },
    primary: { backgroundColor: getHyroColorRGB(0.8), color: "#fff" },
    success: { backgroundColor: "#4dbd74", color: "#fff" },
    danger: { backgroundColor: "rgb(220, 0, 78)", color: "#ffffff" },
    warning: { backgroundColor: "#ffc400", color: "#000" },
  }[typeName]);

export default ({ color, text, onClick }) => (
  <Chip
    size="small"
    style={{
      ...chipStyle(color),
      fontSize: "10px",
      height: "20px",
    }}
    label={text}
    onClick={onClick}
  />
);
