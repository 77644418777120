import {
  Button,
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
} from "@mui/material";
import { any, isEmpty } from "ramda";
import QueryInput from "./QueryInput";
import React from "react";

export default ({
  analysisWSIsReady,
  availableQuery: { description, display_name, inputs: queryInputs, name },
  conversations,
  inputs,
  isDataLoading,
  sendQuery,
  setInputs,
  showNoResult,
  ws,
}) => (
  <TableRow hover>
    <TableCell style={{ width: "15%" }}>{display_name}</TableCell>
    <TableCell style={{ width: "30%" }}>{description}</TableCell>
    <TableCell style={{ width: "45%" }}>
      <Grid container justifyContent="space-between">
        {queryInputs.map((queryInput) => (
          <QueryInput
            key={queryInput.key}
            inputs={inputs}
            name={name}
            queryInput={queryInput}
            setInputs={setInputs}
          />
        ))}
      </Grid>
      {showNoResult[name] && !conversations.length && (
        <p style={{ color: "#EE5D5D" }}>
          Your search did not match any conversation. Make sure that the
          spelling is correct, or try different keywords.
        </p>
      )}
    </TableCell>
    <TableCell style={{ width: "10%" }}>
      {isDataLoading[name] ? (
        <CircularProgress size={24} />
      ) : (
        <Button
          disabled={
            (ws && !ws.readyState) ||
            !analysisWSIsReady ||
            any(isEmpty)(inputs[name])
          }
          onClick={sendQuery(name)}
          size="small"
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#3A48B6",
            color: "#FFFFFF",
          }}
          variant="contained"
        >
          Run
        </Button>
      )}
    </TableCell>
  </TableRow>
);
