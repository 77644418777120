import { HyroLoader, HyroTabsWithTitle } from "../components/hyro-components";
import {
  currentTripletsSelector,
  isRefreshingTripletsSelector,
  updateCurrentTripletsSelector,
} from "../stores/selectors/tripletsSelectors";
import BlockList from "../components/widgets/site-search/BlockList";
import Box from "@mui/material/Box";
import DataSource from "../components/widgets/site-search/DataSource";
import React from "react";
import useAppStore from "../stores/appStore";

const getTabsSpec = (pendingTriplets, setPendingTriplets) => [
  {
    label: "Data Source",
    value: "data_source",
    content: (
      <DataSource
        pendingTriplets={pendingTriplets}
        setPendingTriplets={setPendingTriplets}
      />
    ),
  },
  {
    label: "Block List",
    value: "block_list",
    content: (
      <BlockList
        pendingTriplets={pendingTriplets}
        setPendingTriplets={setPendingTriplets}
      />
    ),
  },
];

export default () => {
  const currentTriplets = useAppStore(currentTripletsSelector);
  const isRefreshingTriplets = useAppStore(isRefreshingTripletsSelector);
  const updateCurrentTriplets = useAppStore(updateCurrentTripletsSelector);

  const tabsSpec = getTabsSpec(currentTriplets, updateCurrentTriplets);

  if (isRefreshingTriplets) return <HyroLoader />;

  return (
    <Box mt={4}>
      <HyroTabsWithTitle title="Site Search" tabsSpec={tabsSpec} />
    </Box>
  );
};
