import { HyroAlert } from "./index";
import React from "react";
import { Snackbar } from "@mui/material";

export default ({ message, severity, showFeedback, closeFeedback }) => (
  <Snackbar
    open={showFeedback}
    onClose={closeFeedback}
    autoHideDuration={6000}
    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
  >
    <HyroAlert
      alertText={message}
      dismissText="GOT IT"
      severity={severity}
      handleDismiss={closeFeedback}
    />
  </Snackbar>
);
