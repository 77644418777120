import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import {
  HyroAlert,
  HyroLoader,
  HyroPageTitle,
} from "../components/hyro-components";
import React, { useEffect, useState } from "react";
import { Relations, object, relationEq, subject } from "../triplets";
import {
  T,
  always,
  concat,
  cond,
  dropLast,
  equals,
  find,
  flatten,
  isEmpty,
  juxt,
  map,
  pipe,
  propEq,
  replace,
} from "ramda";
import {
  currentTripletsSelector,
  isRefreshingTripletsSelector,
  updateCurrentTripletsSelector,
} from "../stores/selectors/tripletsSelectors";
import {
  roomIdSelector,
  roomsSelector,
} from "../stores/selectors/appSelectors";
import BotTemplate from "../components/widgets/bot-creation/BotTemplate";
import BotTemplateGroup from "../components/widgets/bot-creation/BotTemplateGroup";
import blankTemplate from "../static/templates/blank_template.csv";
import { default as gamla } from "gamlajs";
import { getClientDisplayName } from "../utils";
import healthCareIcon from "../static/images/healthcare_icon.svg";
import healthcareTemplate from "../static/templates/healthcare_template.csv";
import { parse } from "csv-parse/browser/esm/sync";
import useAppStore from "../stores/appStore";

const HEALTHCARE_DEMO = "Healthcare Demo";
const BLANK = "Blank";

const replaceInObjects = (findText, replaceText) =>
  map(
    pipe(
      juxt([dropLast(1), pipe(object, replace(findText, replaceText))]),
      flatten
    )
  );

const isHealthcareTemplate = pipe(
  find(relationEq(Relations.ENTITY_FIELDS)),
  subject,
  equals("provider")
);

const getChosenTemplate = cond([
  [isEmpty, always("")],
  [isHealthcareTemplate, always(HEALTHCARE_DEMO)],
  [T, always(BLANK)],
]);

// TODO(Marco): Use `HyroDialog` component.
const BotTemplateDialog = ({ handleSave, close }) => {
  const [template, setTemplate] = useState("");
  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>Templates</DialogTitle>
      <DialogContent>
        <BotTemplateGroup value={template} handleChange={setTemplate}>
          <BotTemplate name={BLANK} />
          <BotTemplate name={HEALTHCARE_DEMO} image={healthCareIcon} />
        </BotTemplateGroup>
      </DialogContent>
      <DialogActions>
        <Box mb="15px" ml="15px" mr="15px">
          <Button color="primary" onClick={close}>
            Cancel
          </Button>
          <Button
            disabled={isEmpty(template)}
            color="primary"
            variant="contained"
            onClick={() => {
              handleSave(template);
              close();
            }}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const saveTemplateTriplets = (setPendingTriplets, clientDisplayName) =>
  gamla.asyncPipe(
    cond([
      [equals(HEALTHCARE_DEMO), always(healthcareTemplate)],
      [equals(BLANK), always(blankTemplate)],
    ]),
    fetch,
    (res) => res.text(),
    parse,
    replaceInObjects("<client_name>", clientDisplayName),
    concat,
    setPendingTriplets
  );

export default () => {
  const currentTriplets = useAppStore(currentTripletsSelector);
  const isRefreshingTriplets = useAppStore(isRefreshingTripletsSelector);
  const updateCurrentTriplets = useAppStore(updateCurrentTripletsSelector);
  const rooms = useAppStore(roomsSelector);
  const selectedRoomId = useAppStore(roomIdSelector);
  const [clientDisplayName, setClientDisplayName] = useState("");
  const [chosenTemplate, setChosenTemplate] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(true);

  useEffect(() => {
    setChosenTemplate(getChosenTemplate(currentTriplets));
  }, [currentTriplets, setChosenTemplate]);

  useEffect(() => {
    if (rooms?.length && selectedRoomId) {
      setClientDisplayName(
        getClientDisplayName(find(propEq("roomId", selectedRoomId))(rooms))
      );
    }
  }, [rooms, selectedRoomId]);

  if (isRefreshingTriplets) return <HyroLoader />;

  return (
    <Box mx={4} mt={4}>
      <HyroPageTitle title="Template Settings" />
      <Paper>
        <Box p={3} mt={1}>
          <Grid container spacing={6}>
            <Grid item xs={4}>
              <Box pb="10px">
                <h5>Templates</h5>
              </Box>
              {isEmpty(chosenTemplate) && alertOpen && (
                <HyroAlert
                  alertText="You must select a template"
                  dismissText="GOT IT"
                  severity="info"
                  handleDismiss={() => setAlertOpen(false)}
                />
              )}
            </Grid>
            <Grid item xs={8}>
              {chosenTemplate ? (
                <BotTemplate
                  name={chosenTemplate}
                  image={chosenTemplate === HEALTHCARE_DEMO && healthCareIcon}
                />
              ) : (
                <>
                  <Box mb="25px">No template selected yet</Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setDialogOpen(true)}
                  >
                    SELECT TEMPLATE
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {dialogOpen && (
        <BotTemplateDialog
          handleSave={(template) => {
            setChosenTemplate(template);
            saveTemplateTriplets(
              updateCurrentTriplets,
              clientDisplayName
            )(template);
            setAlertOpen(false);
          }}
          close={() => setDialogOpen(false)}
        />
      )}
    </Box>
  );
};
