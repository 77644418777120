import React, { useState } from "react";

import Event from "../../../Event";
import JSONView from "./JSONView";
import UtterancePlayer from "./UtterancePlayer";
import { not } from "ramda";

export default ({ color, text, data }) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Event
        color={color}
        text={text}
        onClick={data && (() => setShowDetails(not))}
      />
      {showDetails && data && data.cloudRecordingId && (
        <UtterancePlayer
          cloudRecordingId={data.cloudRecordingId}
          autoPlay={false}
        />
      )}
      <div style={{ marginTop: "5px" }}>
        {showDetails && data && <JSONView src={{ ...data }} />}
      </div>
    </div>
  );
};
