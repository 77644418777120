import { PHONE_CONFIG, WIDGET_CONFIG } from "./SettingsConfigs";
import React, { useEffect, useState } from "react";
import BaseSettings from "../components/widgets/bot-settings/BaseSettings";
import { CONNECTOR_TYPES } from "../utils";
import { HyroLoader } from "../components/hyro-components";
import { deploymentService } from "../services";
import { isEmpty } from "ramda";
import { roomIdSelector } from "../stores/selectors/appSelectors";
import useAppStore from "../stores/appStore";

const PHONE = "Phone";
const WIDGET = "Widget";

export default () => {
  const selectedRoomId = useAppStore(roomIdSelector);

  const [settingsConfig, setSettingsConfig] = useState({});
  const [settingsName, setSettingsName] = useState({});

  const getSettingsByConnectors = (connectors) => {
    const phoneConnector = connectors.find(
      (con) => con.type === CONNECTOR_TYPES.PHONE_CONNECTOR
    );
    setSettingsName(phoneConnector ? PHONE : WIDGET);
    setSettingsConfig(
      phoneConnector
        ? phoneConnector
        : connectors.find((con) => con.type === CONNECTOR_TYPES.WEB_CONNECTOR)
    );
  };

  useEffect(() => {
    setSettingsConfig({});
    if (selectedRoomId) {
      (async () => {
        const connectors = await deploymentService.findConnector();
        getSettingsByConnectors(connectors);
      })();
    }
  }, [selectedRoomId]);
  return isEmpty(settingsConfig) ? (
    <HyroLoader />
  ) : (
    <BaseSettings
      title={settingsName}
      tabsConfig={settingsName === PHONE ? PHONE_CONFIG : WIDGET_CONFIG}
      settings={settingsConfig}
      publishSettings={async (widgetConfig) => {
        await deploymentService.updateConnector({
          connector: widgetConfig,
        });
      }}
    />
  );
};
