import { Box } from "@mui/material";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import React from "react";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";

const getDeviceIcon = (browserInfo) => {
  switch (browserInfo.os || browserInfo.name) {
    case "iOS":
    case "Windows Mobile":
    case "Android OS":
    case "BlackBerry OS":
      return <SmartphoneOutlinedIcon sx={{ opacity: "60%" }} />;

    case "nexmo":
    case "twilio":
    case "phone":
      return <LocalPhoneOutlinedIcon sx={{ opacity: "60%" }} />;

    default:
      return <DesktopWindowsOutlinedIcon sx={{ opacity: "60%" }} />;
  }
};

export default ({ browserInfo }) =>
  browserInfo && <Box fontSize="24px">{getDeviceIcon(browserInfo)}</Box>;
