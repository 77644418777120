import {
  ALL_ASSISTANTS,
  CHANNEL_SETTINGS,
  DASHBOARD,
  DATA_SOURCES,
  FIND_A_PROVIDER,
  GENERAL_SETTINGS,
  HEALTHCARE_INSIGHTS,
  INSIGHTS,
  KNOWLEDGE_DEBUGGER,
  KNOWLEDGE_EDITOR,
  KNOWLEDGE_EXPLORER,
  PORTAL_PAGES,
  QUERIES,
  REAL_ESTATE_INSIGHTS,
  RESPONSES,
  RND_INSIGHTS,
  SITE_SEARCH,
  TEMPLATE_SETTINGS,
  pathMatches,
} from "../utils";
import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { juxt, pipe, prop, tap } from "ramda";
import {
  roomIdSelector,
  userPermissionsSelector,
} from "../stores/selectors/appSelectors";
import AllAssistants from "../components/widgets/all-assistants/AllAssistants";
import BotInsights from "../pages/BotInsights";
import ChannelSettings from "../pages/ChannelSettings";
import { Container } from "@mui/material";
import DataSources from "../pages/DataSources";
import FindAProvider from "../pages/FindAProvider";
import GeneralSettings from "../pages/GeneralSettings";
import { HyroLoader } from "../components/hyro-components";
import InsightsHealthcare from "../pages/InsightsHealthcare";
import InsightsRealEstate from "../pages/InsightsRealEstate";
import InsightsRnD from "../pages/InsightsRnD";
import KnowledgeDebugger from "../pages/KnowledgeDebugger";
import KnowledgeEditing from "../pages/KnowledgeEditing";
import KnowledgeExplorer from "../pages/KnowledgeExplorer";
import Overview from "../pages/Overview";
import PortalHeader from "./PortalHeader";
import Queries from "../pages/Queries";
import Replay from "../pages/Replay";
import Responses from "../pages/Responses";
import SiteSearch from "../pages/SiteSearch";
import TemplateSettings from "../pages/TemplateSettings";
import { draftSelector } from "../stores/selectors/tripletsSelectors";
import { styled } from "@mui/material/styles";
import useAppStore from "../stores/appStore";
const isPortalPath = pathMatches(PORTAL_PAGES);

const AppWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "showDraftBorder",
})(({ theme, showDraftBorder }) => ({
  boxSizing: "border-box",
  marginTop: "64px",
  height: "calc(100vh - 64px)",
  overflow: "scroll",
  position: "relative",
  border: `4px solid transparent`,
  ...(showDraftBorder && {
    borderColor: theme.palette.primary.dark,
  }),
}));

export default () => {
  const selectedRoomId = useAppStore(roomIdSelector);
  const userPermissions = useAppStore(userPermissionsSelector);
  const draft = useAppStore(draftSelector);
  const history = useHistory();

  const [isPortal, setIsPortal] = useState(
    isPortalPath(window.location.pathname)
  );
  const showDraftBorder = isPortal && draft;
  useEffect(
    () =>
      history.listen(
        pipe(prop("pathname"), juxt([pipe(isPortalPath, tap(setIsPortal))]))
      ),
    [history]
  );

  // TODO (ENG-3075): use the key prop for each route.
  return (
    <AppWrapper className="app" {...{ showDraftBorder }}>
      {isPortal && <PortalHeader />}
      <main className="main">
        <Container disableGutters maxWidth={false}>
          <Suspense fallback={<HyroLoader />}>
            <Switch>
              <Route
                path={`/${DASHBOARD}/:roomId?`}
                name="Overview"
                component={Overview}
                key={selectedRoomId}
              />
              <Route
                path={`/${GENERAL_SETTINGS}/:roomId?`}
                name="General Settings"
                component={GeneralSettings}
                key={selectedRoomId}
              />
              <Route
                path={`/${TEMPLATE_SETTINGS}/:roomId?`}
                name="Template Settings"
                component={TemplateSettings}
              />
              <Route
                path={`/${CHANNEL_SETTINGS}/:roomId?`}
                name="Channel"
                component={ChannelSettings}
                key={selectedRoomId}
              />
              <Route
                path={`/${DATA_SOURCES}/:roomId?`}
                name="Data Sources"
                component={DataSources}
              />
              <Route path="/replay" name="Replay" component={Replay} />
              <Route
                path={`/${RESPONSES}/:roomId?`}
                name="Responses"
                component={Responses}
              />
              <Route
                path={`/${FIND_A_PROVIDER}/:roomId?`}
                name="Find a Provider"
                component={FindAProvider}
              />
              <Route
                path={`/${KNOWLEDGE_EXPLORER}/:graphHash?`}
                name="Knowledge Explorer"
                component={KnowledgeExplorer}
                key={selectedRoomId} // This is NOT a mistake, it's a method that forces rerender, read more at ENG-2913.
              />
              <Route
                path={`/${KNOWLEDGE_DEBUGGER}/:graphHash?`}
                name="Knowledge Debugger"
                component={KnowledgeDebugger}
              />
              <Route
                path={`/${SITE_SEARCH}/:roomId?`}
                name="Site Search"
                component={SiteSearch}
              />
              <Route
                path={`/${QUERIES}/:snippetId?`}
                name="Queries"
                component={Queries}
              />
              <Route
                path={`/${KNOWLEDGE_EDITOR}/:roomId?`}
                name="Knowledge Editor"
                component={KnowledgeEditing}
              />
              <Route
                path={`/${INSIGHTS}/:roomId?`}
                name="Insights"
                component={BotInsights}
                key={selectedRoomId} // This is NOT a mistake, it's a method that forces rerender, read more at ENG-2913.
              />
              {userPermissions.isAdmin && (
                <Route path={"/admin"}>
                  <Switch>
                    <Route
                      path={`/admin/${HEALTHCARE_INSIGHTS}`}
                      name="Healthcare Insights"
                      component={InsightsHealthcare}
                    />

                    <Route
                      path={`/admin/${REAL_ESTATE_INSIGHTS}`}
                      name="Real Estate Insights"
                      component={InsightsRealEstate}
                    />

                    <Route
                      path={`/admin/${RND_INSIGHTS}`}
                      name="Rnd Insights"
                      component={InsightsRnD}
                    />

                    <Route
                      condition={userPermissions.isAdmin}
                      path={`/admin/${ALL_ASSISTANTS}`}
                      name="All Assistants"
                      component={AllAssistants}
                    />
                  </Switch>
                </Route>
              )}
              <Redirect to={`/${DASHBOARD}`} />
            </Switch>
          </Suspense>
        </Container>
      </main>
    </AppWrapper>
  );
};
