import { datadogRum } from "@datadog/browser-rum";

const initDatadog = () => {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_DASHBOARD_RUM_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_DASHBOARD_RUM_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: process.env.REACT_APP_DATADOG_DASHBOARD_RUM_SERVICE_NAME,
    env: process.env.REACT_APP_ENV,
    version: process.env.CI_COMMIT_SHORT_SHA,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "allow",
  });

  datadogRum.startSessionReplayRecording();
};

export default initDatadog;
