import React from "react";

export default ({ fill }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.333 0C4.81301 0 0.333008 4.48 0.333008 10C0.333008 15.52 4.81301 20 10.333 20C15.853 20 20.333 15.52 20.333 10C20.333 4.48 15.853 0 10.333 0ZM10.333 18C5.92301 18 2.33301 14.41 2.33301 10C2.33301 5.59 5.92301 2 10.333 2C14.743 2 18.333 5.59 18.333 10C18.333 14.41 14.743 18 10.333 18ZM4.83301 15.5L12.343 12.01L15.833 4.5L8.32301 7.99L4.83301 15.5ZM10.333 8.9C10.943 8.9 11.433 9.39 11.433 10C11.433 10.61 10.943 11.1 10.333 11.1C9.72301 11.1 9.23301 10.61 9.23301 10C9.23301 9.39 9.72301 8.9 10.333 8.9Z"
      fill={fill}
    />
  </svg>
);
