import {
  always,
  apply,
  groupBy,
  is,
  isNil,
  juxt,
  map,
  mergeWith,
  pipe,
  unapply,
  uniqBy,
  unless,
  when,
} from "ramda";

import { object, subject } from "../../../triplets";

const isString = is(String);

const elementHash = unless(isString, JSON.stringify);
const tripletHash = JSON.stringify;

const fastConcat = (elements) => {
  const result = [];
  for (const x of elements) {
    for (const y of x) {
      result.push(y);
    }
  }
  return result;
};

const getAllElements = pipe(fastConcat, uniqBy(elementHash));

const makeEdgeIndex = pipe(
  juxt([
    groupBy(pipe(subject, elementHash)),
    groupBy(pipe(object, elementHash)),
  ]),
  apply(mergeWith(unapply(fastConcat))),
  (index) =>
    pipe((element) => index[elementHash(element)], when(isNil, always([])))
);

// Given a list of triplets will return a
// function to determine if a triplet is contained in the triplets in O(1).
const makeTripletIndex = pipe(
  map(tripletHash),
  (hashList) => new Set(hashList),
  (hashSet) => pipe(tripletHash, (hashed) => hashSet.has(hashed))
);

export {
  elementHash,
  getAllElements,
  makeEdgeIndex,
  isString,
  makeTripletIndex,
  tripletHash,
};
