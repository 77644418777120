import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Grid from "@mui/material/Grid";
import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "Icons";

const classes = {
  icon: `${PREFIX}-icon`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.icon}`]: {
    color: "#B4B4C3",
    cursor: "pointer",
  },
}));

export default ({ handleDelete, setEditClicked, showIcons }) => (
  <StyledGrid
    container
    direction="row"
    spacing={1}
    alignItems="center"
    justifyContent="flex-end"
    wrap="nowrap"
  >
    {showIcons && (
      <>
        <Grid item>
          <CreateOutlinedIcon
            className={classes.icon}
            fontSize="small"
            onClick={() => setEditClicked(true)}
          />
        </Grid>
        {handleDelete && (
          <Grid item>
            <DeleteOutlineIcon
              className={classes.icon}
              fontSize="small"
              onClick={handleDelete}
            />
          </Grid>
        )}
      </>
    )}
  </StyledGrid>
);
