import { DASHBOARD, INPUT_TYPES } from "../../../utils";
import React, { useState } from "react";
import { Button } from "@mui/material";
import { HyroDialog } from "../../hyro-components";
import { deploymentService } from "../../../services";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(() => ({
  backgroundColor: "#F44336",
  color: "#FFF",
  "&:hover": {
    backgroundColor: "#F44336",
    color: "#FFF",
  },
  width: "148px",
}));

export default () => {
  const [deactivateBotOpen, setDeactivateBotOpen] = useState(false);
  return (
    <>
      <StyledButton
        onClick={() => setDeactivateBotOpen(true)}
        size="small"
        variant="contained"
      >
        Deactivate bot
      </StyledButton>
      <HyroDialog
        fieldSpec={[
          {
            inputType: INPUT_TYPES.DESCRIPTION,
            label:
              "Once you deactivate the bot, it will be permanently deleted. This cannot be undone.",
          },
        ]}
        handleClose={() => setDeactivateBotOpen(false)}
        handleSubmit={async () => {
          await deploymentService.deactivateRoom({});
          // TODO(ENG-1704): ADD FEEDBACK MESSAGE ONCE POSSIBLE
          setDeactivateBotOpen(false);
          window.location.replace(`/${DASHBOARD}`);
        }}
        open={deactivateBotOpen}
        submitButtonText="Deactivate Bot"
        submitButtonColor="secondary"
        title="Are you sure you want to deactivate this bot?"
      />
    </>
  );
};
