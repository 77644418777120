import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useQueryParamState = (queryParamName) => {
  const location = useLocation();
  const [value, setValue] = useState(
    new URLSearchParams(location.search).get(queryParamName)
  );
  // using refs because i don't care about the queryparams status except on
  // the first mount. Sadly, this means that back won't work, so i use history.replace
  const locationRef = useRef(location);
  const history = useHistory();
  const historyRef = useRef(history);

  useEffect(() => {
    const searchParams = new URLSearchParams(locationRef.current.search);
    if (value) searchParams.set(queryParamName, value);
    else searchParams.delete(queryParamName);

    historyRef.current.replace({
      search: searchParams.toString(),
    });
  }, [value, queryParamName, historyRef, locationRef]);

  return [value, setValue];
};

const useTrackProgress = (f) => {
  const [pending, setPending] = useState(false);
  return [
    pending,
    async (...args) => {
      setPending(true);
      await f(...args);
      setPending(false);
    },
  ];
};
const useEffectAsync = (f, deps) => {
  useEffect(() => {
    f();
    // eslint-disable-next-line
  }, deps);
};

// Taken from https://usehooks.com/usePrevious/
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export {
  useQueryParamState,
  useEffectAsync,
  useTrackProgress,
  usePrevious,
  useWindowDimensions,
};
