import {
  ATTRIBUTE_KEY,
  ATTRIBUTE_LABEL,
  CUSTOMIZED_CODE_KEY,
  CUSTOMIZED_CODE_LABEL,
  DONE_LABEL,
  MAPPING_METHOD_KEY,
  MAPPING_METHOD_LABEL,
  NAME_KEY,
  NEW_NAME_KEY,
  SEARCHABLE_KEY,
  SEARCHABLE_LABEL,
  STATUS_KEY,
  STATUS_LABEL,
  STATUS_TO_COLOR,
  TEMPLATE_KEY,
  TEMPLATE_LABEL,
  UNMAPPED_LABEL,
} from "./Constants";
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Relations,
  getObjectByRelation,
  relation,
  subject,
} from "../../../triplets";
import {
  T,
  apply,
  applySpec,
  assoc,
  complement,
  concat,
  cond,
  either,
  equals,
  filter,
  find,
  groupBy,
  head,
  identity,
  ifElse,
  includes,
  isEmpty,
  juxt,
  map,
  mergeDeepRight,
  mergeLeft,
  not,
  pipe,
  prop,
  propEq,
  props,
  reject,
  split,
  toLower,
  values,
  when,
} from "ramda";

import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MapDataDialog from "./MapDataDialog";
import SearchBox from "./SearchBox";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";

const PREFIX = "MapData";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
  chip: `${PREFIX}-chip`,
};

const StyledPaper = styled(Paper)(({ theme: { spacing } }) => ({
  [`&.${classes.root}`]: {
    padding: "1%",
    marginTop: spacing(1),
    width: "100%",
  },

  [`& .${classes.button}`]: {
    textTransform: "unset !important",
    fontWeight: 500,
  },

  [`& .${classes.icon}`]: {
    cursor: "pointer",
  },

  [`& .${classes.chip}`]: {
    color: "white",
    fontSize: "14px",
  },
}));

const DEFAULT_DATA = {
  template: "",
  customised_code: "",
  status: UNMAPPED_LABEL,
  searchable: "false",
  required: "false",
};

const DATA_SPEC = map(mergeDeepRight(DEFAULT_DATA))({
  provider_id: {
    id: "provider id",
    name: "Provider id",
    required: "true",
  },
  name: {
    id: NAME_KEY,
    name: "Name",
    searchable: "true",
    required: "true",
  },
  profile_image: {
    id: "profile image",
    name: "Profile image",
  },
  profile_link: {
    id: "profile link",
    name: "Profile link",
  },
  speciality: {
    id: "specialty",
    name: "Speciality",
    searchable: "true",
    required: "true",
  },
  professional_suffix: {
    id: "professional suffix",
    name: "Professional suffix",
  },
  address: {
    id: "location_type",
    name: "Address",
    required: "true",
  },
  phone: {
    id: "phone",
    name: "Phone",
  },
  expertise: {
    id: "expertise",
    name: "Expertise",
    searchable: "true",
  },
  gender: {
    id: "gender",
    name: "Gender",
  },
  language: {
    id: "language",
    name: "Language",
    searchable: "true",
  },
  accepting_new_patients: {
    id: "accepting new patients",
    name: "Accepting new patients",
    searchable: "true",
  },
  online_scheduling: {
    id: "scheduling",
    name: "Online scheduling",
  },
});

const headCells = [
  { id: ATTRIBUTE_KEY, label: ATTRIBUTE_LABEL, width: "50%" },
  { id: SEARCHABLE_KEY, label: SEARCHABLE_LABEL, width: "18%" },
  {
    id: MAPPING_METHOD_KEY,
    label: MAPPING_METHOD_LABEL,
    width: "17%",
  },
  { id: STATUS_KEY, label: STATUS_LABEL, width: "12%" },
];

const idToWidth = (id) => find(propEq("id", id), headCells);

const filterEntities = (text) =>
  filter(
    pipe(
      props([NAME_KEY, TEMPLATE_KEY, MAPPING_METHOD_KEY, STATUS_KEY]),
      map(toLower),
      filter(includes(text)),
      complement(isEmpty)
    )
  );

export default ({ pendingTriplets, setPendingTriplets }) => {
  const [rows, setRows] = useState({});
  const [editedRow, setEditedRow] = useState({});
  const [rowHover, setRowHover] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (pendingTriplets) {
      const rows = pipe(
        filter(pipe(relation, split("/"), head, equals(ATTRIBUTE_KEY))),
        groupBy(subject),
        map(
          applySpec({
            id: pipe(head, subject),
            name: getObjectByRelation(Relations.ATTRIBUTE_NAME),
            template: getObjectByRelation(Relations.ATTRIBUTE_TEMPLATE),
            status: getObjectByRelation(Relations.ATTRIBUTE_STATUS),
            customized_code: getObjectByRelation(
              Relations.ATTRIBUTE_CUSTOMIZED_CODE
            ),
          })
        ),
        mergeDeepRight(DATA_SPEC),
        values,
        map(
          cond([
            [
              prop(CUSTOMIZED_CODE_KEY),
              assoc(MAPPING_METHOD_KEY, CUSTOMIZED_CODE_LABEL),
            ],
            [prop(TEMPLATE_KEY), assoc(MAPPING_METHOD_KEY, TEMPLATE_LABEL)],
            [T, assoc(MAPPING_METHOD_KEY, "")],
          ])
        )
      )(pendingTriplets);
      setRows(rows);
    }
  }, [setRows, pendingTriplets]);

  const handleAddRow = () => {
    const newEditedRow = pipe(
      ifElse(
        either(
          propEq(MAPPING_METHOD_KEY, TEMPLATE_LABEL),
          pipe(prop(MAPPING_METHOD_KEY), isEmpty)
        ),
        mergeLeft({
          status: DONE_LABEL,
          customized_code: "",
        }),
        assoc(TEMPLATE_KEY, "")
      ),
      when(
        prop(NEW_NAME_KEY),
        pipe(juxt([prop(NEW_NAME_KEY), identity]), apply(assoc(NAME_KEY)))
      )
    )(editedRow);
    setPendingTriplets(
      pipe(
        reject(pipe(subject, equals(newEditedRow.id))),
        concat([
          [newEditedRow.id, Relations.ATTRIBUTE_NAME, newEditedRow.name],
          [
            newEditedRow.id,
            Relations.ATTRIBUTE_TEMPLATE,
            newEditedRow.template,
          ],
          [newEditedRow.id, Relations.ATTRIBUTE_STATUS, newEditedRow.status],
          [
            newEditedRow.id,
            Relations.ATTRIBUTE_CUSTOMIZED_CODE,
            newEditedRow.customized_code,
          ],
        ])
      )
    );
    setEditedRow({});
  };

  return (
    <StyledPaper className={classes.root}>
      {not(isEmpty(editedRow)) && (
        <MapDataDialog
          editedRow={editedRow}
          setEditedRow={setEditedRow}
          handleAddRow={handleAddRow}
        />
      )}
      <Box mx={2} mt={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <h2>Provider attributes</h2>
          </Grid>
          <Grid item container justifyContent="flex-start" alignItems="center">
            <Grid item xs={6}>
              <span style={{ color: "red" }}> *</span>Required
            </Grid>
            <Grid
              item
              container
              xs={6}
              spacing={3}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <SearchBox
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </Grid>
              <Grid item>
                <Button
                  className={classes.button}
                  startIcon={<AddIcon />}
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={() =>
                    setEditedRow(
                      mergeDeepRight(DEFAULT_DATA, {
                        id: `${uuidv4()}_${ATTRIBUTE_KEY}`,
                        new_name: "",
                      })
                    )
                  }
                >
                  Add custom attribute
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TableContainer>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align="left"
                        width={headCell.width}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                {not(isEmpty(rows)) && (
                  <TableBody>
                    {pipe(
                      filterEntities(searchText),
                      map((row) => (
                        <TableRow
                          key={row.id}
                          hover
                          onClick={() => setEditedRow(row)}
                          onMouseEnter={() => setRowHover(row.id)}
                          onMouseLeave={() => setRowHover(false)}
                        >
                          <TableCell width={idToWidth(ATTRIBUTE_KEY)}>
                            {row.name}
                            {row.required === "true" && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </TableCell>
                          <TableCell width={idToWidth(SEARCHABLE_KEY)}>
                            {row.searchable === "true" ? (
                              <CheckCircleIcon color="primary" />
                            ) : (
                              <CancelIcon color="disabled" />
                            )}
                          </TableCell>
                          <TableCell width={idToWidth(MAPPING_METHOD_KEY)}>
                            {row.mapping_method}
                          </TableCell>
                          <TableCell width={idToWidth(STATUS_KEY)}>
                            <Chip
                              className={classes.chip}
                              label={row.status}
                              style={{
                                backgroundColor: STATUS_TO_COLOR[row.status],
                              }}
                            />
                          </TableCell>
                          <TableCell width="3%">
                            {rowHover === row.id && (
                              <BorderColorIcon
                                className={classes.icon}
                                fontSize="small"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    )(rows)}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </StyledPaper>
  );
};
