import React, { useContext, useEffect, useState } from "react";
import { head, map, pipe, prop } from "ramda";
import ConversationModal from "./ConversationModal";
import DateTimeContext from "../../../contexts/DateTimeContext";
import { conversationService } from "../../../services";
import eventTypes from "./conversation/EventTypes";
import { default as gamla } from "gamlajs";
import moment from "moment";
import { roomIdSelector } from "../../../stores/selectors/appSelectors";
import useAppStore from "../../../stores/appStore";

const loadHistoricalConversation = (conversationId) =>
  conversationService.get({ conversationId });
const rerunConversation = (historicalConversation, conversationId) =>
  gamla.asyncPipe(
    (input) => conversationService.create(input),
    prop("events"),
    map(pipe(head, prop("data")))
  )({
    conversationId,
    userUtteranceHistory: historicalConversation
      .filter((e) => e.eventType !== eventTypes.BOT_UTTERANCE)
      .map(({ eventType, data, createdAt }) => ({
        type: eventType,
        data,
        createdAt,
      })),
  });

export default ({ isOpen, dismiss, conversationId, cycleConversations }) => {
  const selectedRoomId = useAppStore(roomIdSelector);
  const { timeZone } = useContext(DateTimeContext);

  const [historicalConversation, setHistoricalConversation] = useState({
    detail: [],
    isLoading: false,
    hasError: false,
    isLoaded: false,
  });

  const [updatedResponses, setUpdatedResponses] = useState({
    responses: [],
    compare: [0, 1],
    isLoading: false,
    hasError: false,
    isLoaded: false,
  });

  // Load historical conversation.
  useEffect(() => {
    const reload = () => {
      setHistoricalConversation({
        ...historicalConversation,
        hasError: false,
      });
    };

    (async () => {
      if (
        !isOpen ||
        historicalConversation.isLoaded ||
        historicalConversation.isLoading ||
        historicalConversation.hasError
      ) {
        return;
      }

      setHistoricalConversation({
        ...historicalConversation,
        isLoading: true,
      });

      let conversationDetail;
      try {
        conversationDetail = await loadHistoricalConversation(conversationId);
      } catch (err) {
        setHistoricalConversation({
          ...historicalConversation,

          isLoading: false,
          hasError: true,
          reload,
        });
        return;
      }

      if (!conversationDetail || !conversationDetail.length) {
        dismiss();
        return;
      }

      setHistoricalConversation({
        ...historicalConversation,
        conversationTime: moment.utc(conversationDetail[0].createdAt),
        detail: conversationDetail,
        label: "Historical",
        isLoading: false,
        isLoaded: true,
      });
    })();
  }, [
    historicalConversation.isLoading,
    selectedRoomId,
    isOpen,
    historicalConversation,
    conversationId,
    dismiss,
  ]);

  // Get updated response to historical conversation.
  useEffect(() => {
    const reload = () => {
      setUpdatedResponses({
        ...updatedResponses,
        hasError: false,
      });
    };

    (async () => {
      if (
        !historicalConversation.isLoaded ||
        updatedResponses.isLoading ||
        updatedResponses.isLoaded ||
        updatedResponses.hasError
      ) {
        return;
      }

      setUpdatedResponses({
        ...updatedResponses,
        isLoading: true,
      });

      let upToDateResponse;
      try {
        upToDateResponse = await rerunConversation(
          historicalConversation.detail,
          conversationId
        );
      } catch (err) {
        console.error(err);
        setUpdatedResponses({
          ...updatedResponses,
          hasError: true,
          isLoading: false,
          reload,
        });
        return;
      }

      setUpdatedResponses({
        ...updatedResponses,
        responses: [upToDateResponse],
        labels: ["Up to date"],
        isLoaded: true,
        isLoading: false,
      });
    })();
  }, [
    selectedRoomId,
    historicalConversation.isLoaded,
    historicalConversation.detail,
    updatedResponses,
    conversationId,
  ]);

  return (
    <ConversationModal
      isOpen={isOpen}
      dismiss={dismiss}
      cycleConversations={cycleConversations}
      conversation={historicalConversation}
      updatedResponses={updatedResponses}
      conversationId={conversationId}
      timeZone={timeZone}
    />
  );
};
