import { INSIGHTS_DASHBOARD_NAMES } from "../utils";
import InsightsBase from "../components/widgets/insights/InsightsBase";
import React from "react";
import { insightsService } from "../services";

export default () => {
  const getLookerEmbedDashboardObject = () =>
    insightsService.getLookerEmbedDashboardObjectByName({
      dashboardName: INSIGHTS_DASHBOARD_NAMES.rAndD,
    });

  return (
    <InsightsBase
      lookerEmbedDashboardObjectGetter={getLookerEmbedDashboardObject}
    />
  );
};
