import {
  Box,
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Relations, object, relationEq, subject } from "../../../triplets";
import {
  always,
  concat,
  equals,
  filter,
  groupBy,
  ifElse,
  isEmpty,
  juxt,
  keys,
  length,
  map,
  negate,
  pipe,
  reject,
  subtract,
  toPairs,
  toString,
  unnest,
} from "ramda";
import { Autocomplete } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "SortingLogic";

const classes = {
  content: `${PREFIX}-content`,
  flexBox: `${PREFIX}-flexBox`,
  levelCard: `${PREFIX}-levelCard`,
};

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  [`& .${classes.content}`]: {
    minHeight: 500,
    padding: "24px",
    marginTop: spacing(1),
  },

  [`& .${classes.flexBox}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "35px",
  },

  [`& .${classes.levelCard}`]: {
    height: 80,
    marginTop: 25,
    marginBottom: 25,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

const buildTripletsFromSortLevelsObj = pipe(
  toPairs,
  map(([key, valuesArr]) =>
    map((value) => [value, Relations.CONCEPT_PRIORITY_GROUP, key])(valuesArr)
  ),
  unnest
);

export default ({ pendingTriplets, setPendingTriplets }) => {
  const [sortLevels, setSortLevels] = useState({});
  const [currentLastIndex, setCurrentLastIndex] = useState("");
  const [focusedLevel, setFocusedLevel] = useState();

  const handleChange = (updatedData, sortingLevelIndex) => {
    const updatedSortLevels = {
      ...sortLevels,
      [sortingLevelIndex]: updatedData,
    };

    setPendingTriplets(
      pipe(
        reject(relationEq(Relations.CONCEPT_PRIORITY_GROUP)),
        concat(buildTripletsFromSortLevelsObj(updatedSortLevels))
      )
    );
  };

  useEffect(() => {
    if (pendingTriplets) {
      pipe(
        filter(relationEq(Relations.CONCEPT_PRIORITY_GROUP)),
        groupBy(object),
        map(map(subject)),
        juxt([
          ifElse(isEmpty, always(setSortLevels({ 0: [] })), setSortLevels),
          ifElse(
            isEmpty,
            always(setCurrentLastIndex("0")),
            pipe(
              keys,
              length,
              pipe(subtract(1), negate),
              toString,
              setCurrentLastIndex
            )
          ),
        ])
      )(pendingTriplets);
    }
  }, [setSortLevels, setCurrentLastIndex, pendingTriplets]);

  return (
    <StyledBox>
      <Paper className={classes.content}>
        <Box className={classes.flexBox}>
          <Typography variant="h6">Sorting levels</Typography>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => {
              const newIndex = Object.keys(sortLevels).length;
              setSortLevels({ ...sortLevels, [newIndex]: [] });
              setCurrentLastIndex(newIndex.toString());
            }}
          >
            + Add another sorting level
          </Button>
        </Box>
        {Object.entries(sortLevels).map(([sortLevelIndex, sortLevelValues]) => (
          <Box key={sortLevelIndex}>
            <Paper elevation={0} className={classes.levelCard}>
              <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                {sortLevelIndex === "0" ? "Sort by" : "Then sort by"}
              </Typography>
              <Autocomplete
                multiple
                freeSolo
                value={sortLevelValues.length ? sortLevelValues : []}
                onChange={(event, value) => handleChange(value, sortLevelIndex)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ width: 750 }}
                    variant="outlined"
                    label="Attribute"
                    onFocus={() => setFocusedLevel(sortLevelIndex)}
                    onBlur={() => setFocusedLevel("")}
                    helperText={ifElse(
                      equals(focusedLevel),
                      always("Hit ⏎ Enter to add a new value"),
                      always("Insert 1 attribute or multiple values")
                    )(sortLevelIndex)}
                  />
                )}
                options={[]}
              />
            </Paper>
            {currentLastIndex !== sortLevelIndex ? <Divider /> : null}
          </Box>
        ))}
      </Paper>
    </StyledBox>
  );
};
