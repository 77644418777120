import { INSIGHTS_DASHBOARD_NAMES } from "../../../utils";
import InsightsBase from "../insights/InsightsBase";
import React from "react";
import { insightsService } from "../../../services";

export default ({ roomId }) => {
  const getLookerEmbedDashboardObject = () =>
    insightsService.getLookerEmbedDashboardObjectByName({
      dashboardName: INSIGHTS_DASHBOARD_NAMES.overview,
      filterByAssistant: true,
      roomId,
    });

  return (
    <InsightsBase
      lookerEmbedDashboardObjectGetter={getLookerEmbedDashboardObject}
    />
  );
};
