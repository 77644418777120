import React, { useEffect, useState } from "react";
import { accessTokenSelector } from "../stores/selectors/appSelectors";
import useAppStore from "../stores/appStore";

const ClientSocketContext = React.createContext(null);
const IS_ANALYSIS_WS_READY = "isAnalysisWsReady";

const ClientSocketProvider = ({ children }) => {
  const accessToken = useAppStore(accessTokenSelector);
  const [ws, setWs] = useState(null);
  const [isAnalysisWsReady, setIsAnalysisWsReady] = useState(null);
  const [lastMessage, setLastMessage] = useState(null);
  useEffect(() => {
    if (accessToken) {
      const url = new URL(window.location);
      const protocol = url.protocol === "http:" ? "ws" : "wss";
      const host = process.env.REACT_APP_DASHBOARD_API_HOST;
      const socket = new WebSocket(
        `${protocol}://${host}?token=${accessToken}`
      );
      setWs(socket);
      return () => socket.close();
    }
  }, [accessToken, setWs]);
  useEffect(() => {
    if (!ws) return;
    ws.onmessage = (event) => {
      const { type, data } = JSON.parse(event.data);
      if (type === IS_ANALYSIS_WS_READY) {
        setIsAnalysisWsReady(data);
      }
      setLastMessage({ type, data });
    };
    ws.onopen = () => {
      ws.send(JSON.stringify({ type: IS_ANALYSIS_WS_READY }));
    };
  }, [ws, setIsAnalysisWsReady]);
  return (
    <ClientSocketContext.Provider
      value={{
        ws,
        lastMessage,
        isAnalysisWsReady,
      }}
    >
      {children}
    </ClientSocketContext.Provider>
  );
};

export default ClientSocketContext;

export { ClientSocketProvider };
