import { Box, Divider, Grid, Paper } from "@mui/material";
import { last, map, not, pipe, propEq } from "ramda";
import FieldGroup from "./FieldGroup";
import HiddenFieldGroup from "./HiddenFieldGroup";
import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "SettingsTab";

const classes = {
  paper: `${PREFIX}-paper`,
  titleText: `${PREFIX}-titleText`,
  heading: `${PREFIX}-heading`,
};

const StyledPaper = styled(Paper)(({ theme: { spacing } }) => ({
  [`&.${classes.paper}`]: {
    minHeight: 500,
    padding: "24px",
    marginTop: spacing(1),
    width: "100%",
  },

  [`& .${classes.titleText}`]: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "roboto",
    marginTop: "20px",
  },

  [`& .${classes.heading}`]: {
    fontWeight: 500,
    fontSize: "20px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "roboto",
    marginBottom: "25px",
  },
}));

export default ({ title, sections, getFieldValue, handleUpdateField }) => (
  <StyledPaper className={classes.paper}>
    <Box className={classes.heading}>{title}</Box>
    {map(({ title, fields, hiddenFields }) => (
      <Box key={title}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h1 className={classes.titleText}>{title}</h1>
          </Grid>
          <Grid item xs={6}>
            <FieldGroup
              fieldList={fields}
              getFieldValue={getFieldValue}
              handleUpdateField={handleUpdateField}
            />
            {hiddenFields && (
              <HiddenFieldGroup
                fieldList={hiddenFields}
                getFieldValue={getFieldValue}
                handleUpdateField={handleUpdateField}
                toggleText="Overrides"
              />
            )}
          </Grid>
        </Grid>
        {pipe(last, propEq("title", title), not)(sections) && <Divider />}
      </Box>
    ))(sections)}
  </StyledPaper>
);
