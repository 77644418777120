import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { map } from "ramda";

export default ({ label, options, value, onChange }) => (
  <Box marginTop="20px">
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select value={value || ""} label={label} onChange={onChange}>
        {map(({ display, value }) => (
          <MenuItem key={value} value={value}>
            {display}
          </MenuItem>
        ))(options)}
      </Select>
    </FormControl>
  </Box>
);
