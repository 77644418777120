import { always, prop } from "ramda";
import {
  batchValidate,
  depthCheckNumber,
  urlCheckPrefix,
  urlCheckUnique,
} from "./validation";
import BaseSiteSearch from "./BaseSiteSearch";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import React from "react";
import { Relations } from "../../../triplets";

const dialogFields = [
  {
    key: Relations.SITE_SEARCH_URL,
    label: "URL",
    required: true,
    setValidationFn: (pendingTriplets, row) =>
      batchValidate([
        urlCheckUnique(Relations.SITE_SEARCH_URL)(
          pendingTriplets,
          prop("id")(row)
        ),
        urlCheckPrefix,
      ]),
  },
  {
    key: Relations.SITE_SEARCH_DEPTH,
    label: "Depth",
    required: true,
    setValidationFn: always(depthCheckNumber),
  },
];

export default ({ pendingTriplets, setPendingTriplets }) => (
  <BaseSiteSearch
    pendingTriplets={pendingTriplets}
    setPendingTriplets={setPendingTriplets}
    dialogFields={dialogFields}
    dialogTitle="Configure Data Source"
    headerTitle="These URLs will appear in the site search:"
    firstRelation={Relations.SITE_SEARCH_URL}
    firstAlias="URL"
    secondRelation={Relations.SITE_SEARCH_DEPTH}
    secondAlias="Depth"
    statusIcon={<CheckCircleOutlinedIcon htmlColor="#4CAF50" />}
  />
);
