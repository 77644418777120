import {
  RESULT_CARD_CONFIG_RELATIONS,
  Relations,
  TRIPLETS_STATUSES,
  relationEq,
  relationIn,
  subject,
  tripletsToObjects,
} from "../../triplets";
import {
  always,
  equals,
  evolve,
  filter,
  head,
  ifElse,
  length,
  map,
  mergeRight,
  nth,
  omit,
  pipe,
  reduce,
  split,
  values,
} from "ramda";

export const handleDraftTripletsSelector = (state) => [
  state.publishCurrentTripletsToDraft,
  state.deleteCurrentDraft,
];
export const deletePublishedDraftSelector = (state) =>
  state.deletePublishedDraft;
export const updateCurrentTripletsSelector = (state) =>
  state.updateCurrentTriplets;
export const isRefreshingTripletsSelector = (state) =>
  state.tripletsStatus === TRIPLETS_STATUSES.LOADING ||
  state.tripletsStatus === TRIPLETS_STATUSES.PUBLISHING;
export const isSavingTripletsSelector = (state) =>
  state.tripletsStatus === TRIPLETS_STATUSES.SAVING;
export const isPublishingTripletsSelector = (state) =>
  state.tripletsStatus === TRIPLETS_STATUSES.PUBLISHING;
export const isTripletsIdleSelector = (state) =>
  state.tripletsStatus === TRIPLETS_STATUSES.IDLE;
export const draftSelector = (state) => state.draft;
export const currentTripletsSelector = (state) => state.currentTriplets;
export const originalTripletsSelector = (state) => state.originalTriplets;
export const lastUpdateTimestampSelector = (state) => state.lastUpdateTimestamp;
export const updateResultCardConfigSelector = (state) =>
  state.updateResultCardConfig;

const RESULT_CARD_UI_FORMAT_FNS = {
  [Relations.RENDER_TITLE]: pipe(
    split(","),
    ifElse(pipe(length, equals(1)), always(""), nth(1))
  ),
};

const RESULT_CARD_UI_DEFAULTS = reduce(
  (defaults, rel) => ({ ...defaults, [rel]: "" }),
  {}
)(values(RESULT_CARD_CONFIG_RELATIONS));

const isResultCardConfigTriplet = relationIn(
  values(RESULT_CARD_CONFIG_RELATIONS)
);

export const resultCardConfigSelector = (state) =>
  pipe(
    filter(isResultCardConfigTriplet),
    pipe(tripletsToObjects, head),
    omit(["id"]),
    evolve(RESULT_CARD_UI_FORMAT_FNS),
    mergeRight(RESULT_CARD_UI_DEFAULTS)
  )(state.currentTriplets);

export const knowledgeEntityTypesSelector = (state) =>
  pipe(
    filter(relationEq(Relations.ENTITY_FIELDS)),
    map(subject)
  )(state.currentTriplets);
