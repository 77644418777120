import { Button, Grid } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "FileUploadCta";

const classes = {
  fileUploadCta: `${PREFIX}-fileUploadCta`,
  fileUploadCtaButton: `${PREFIX}-fileUploadCtaButton`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.fileUploadCta}`]: {
    fontSize: "16px",
    fontWeight: 500,
    marginRight: "5px",
  },

  [`& .${classes.fileUploadCtaButton}`]: {
    color: "#3F51B5",
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "unset !important",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      outline: 0,
    },
    display: "inline-block",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  },
}));

export default ({ ctaContent, buttonContent, buttonAction }) => (
  <StyledGrid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
    <Grid item className={classes.fileUploadCta}>
      {ctaContent}
    </Grid>
    <Grid item>
      <Button
        size="small"
        className={classes.fileUploadCtaButton}
        onClick={buttonAction}
      >
        {buttonContent}
      </Button>
    </Grid>
  </StyledGrid>
);
