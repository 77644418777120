import { IconButton } from "@mui/material";
import disabledStyle from "./disabledStyle";
import { styled } from "@mui/material/styles";

const MediumButton = styled(IconButton)(({ theme }) => ({
  width: 36,
  borderRadius: 8,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  ...disabledStyle(theme),
}));

export default MediumButton;
