import { MenuItem, Select } from "@mui/material";
import React, { useContext, useState } from "react";
import { keys, map, pipe } from "ramda";

import AdapterMoment from "@mui/lab/AdapterMoment";
import Box from "@mui/material/Box";
import DatePicker from "@mui/lab/DatePicker";
import DateTimeContext from "../../contexts/DateTimeContext";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Popover from "@mui/material/Popover";
import { TextField } from "@mui/material";
import moment from "moment-timezone";
import { styled } from "@mui/material/styles";

const PREFIX = "DateSelector";

const classes = {
  dividerText: `${PREFIX}-dividerText`,
};

const StyledPopover = styled(Popover)(() => ({
  [`& .${classes.dividerText}`]: {
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

export default ({ anchorElement, isOpen, onClose, limitByTime }) => {
  const { fromDate, setFromDate, setToDate, timeZoneMoment, toDate } =
    useContext(DateTimeContext);
  const dateFormat = "MM/DD/YYYY";

  const [datePreset, setDatePreset] = useState("");
  const [toDatePickerOpen, setToDatePickerOpen] = useState(false);
  const [fromDatePickerOpen, setFromDatePickerOpen] = useState(false);

  const date2WeeksAgo = timeZoneMoment().subtract("14", "d").startOf("d");

  const dateRangePresets = {
    "Last 24 hours": () => [moment().subtract("24", "h"), moment()],
    Yesterday: () => [
      timeZoneMoment().subtract("1", "d").startOf("d"),
      timeZoneMoment().subtract("1", "d").endOf("d"),
    ],
    "Last 7 days": () => [
      timeZoneMoment().subtract("7", "d").startOf("d"),
      timeZoneMoment().subtract("1", "d").endOf("d"),
    ],
    "Last 14 days": () => [
      date2WeeksAgo,
      timeZoneMoment().subtract("1", "d").endOf("d"),
    ],
  };

  const selectFromDate = (date) => {
    if (!isNaN(date.valueOf()) && (!limitByTime || date >= date2WeeksAgo)) {
      setFromDate(date.toDate());
      setDatePreset("");
      setFromDatePickerOpen(false);
    }
  };

  const selectToDate = (date) => {
    if (!isNaN(date.valueOf()) && (!limitByTime || date >= date2WeeksAgo)) {
      setToDate(date.toDate());
      setDatePreset("");
      setToDatePickerOpen(false);
    }
  };

  const selectDatePreset = (presetName) => {
    const [fromDate, toDate] = dateRangePresets[presetName]();
    setFromDate(fromDate.toDate());
    setToDate(toDate.toDate());
    setDatePreset(presetName);
  };

  return (
    <StyledPopover
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={anchorElement}
    >
      <Box sx={{ width: "250px", padding: "20px" }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <div className={classes.dividerText}>From</div>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                renderInput={(props) => (
                  <TextField label="Start date" variant="standard" {...props} />
                )}
                style={{ color: "white" }}
                color="white"
                disableToolbar
                disableFuture
                minDate={limitByTime ? date2WeeksAgo : undefined}
                variant="inline"
                inputFormat={dateFormat}
                margin="normal"
                id="from-date-picker"
                value={timeZoneMoment(fromDate)}
                open={fromDatePickerOpen}
                onOpen={() => setFromDatePickerOpen(true)}
                onClose={() => setFromDatePickerOpen(false)}
                onChange={selectFromDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.dividerText}>To</div>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                renderInput={(props) => (
                  <TextField label="End date" variant="standard" {...props} />
                )}
                disableToolbar
                minDate={timeZoneMoment(fromDate)}
                variant="inline"
                inputFormat={dateFormat}
                margin="normal"
                id="to-date-picker"
                value={timeZoneMoment(toDate)}
                open={toDatePickerOpen}
                onOpen={() => setToDatePickerOpen(true)}
                onClose={() => setToDatePickerOpen(false)}
                onChange={selectToDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.dividerText}>Or</div>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel>Custom range</InputLabel>
                <Select
                  variant="standard"
                  value={datePreset}
                  onChange={({ target: { value } }) => {
                    selectDatePreset(value);
                  }}
                  displayEmpty
                  sx={{
                    minWidth: "125px",
                  }}
                >
                  <MenuItem value="" disabled />
                  {pipe(
                    keys,
                    map((presetName, idx) => (
                      <MenuItem
                        key={idx}
                        value={presetName}
                        onClick={() => selectDatePreset(presetName)}
                      >
                        {presetName}
                      </MenuItem>
                    ))
                  )(dateRangePresets)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>
    </StyledPopover>
  );
};
