import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { Delete as DeleteIcon, Replay as RetryIcon } from "@mui/icons-material";
import { INPUT_TYPES, SEVERITY_LEVELS } from "../../utils";
import React, { useState } from "react";
import {
  createPreviewAssistantIdSelector,
  previewAssistantCreationFailedSelector,
} from "../../stores/selectors/previewSelectors";
import {
  handleDraftTripletsSelector,
  isPublishingTripletsSelector,
} from "../../stores/selectors/tripletsSelectors";
import { Box } from "@mui/material";
import HyroDialog from "../../components/hyro-components/HyroDialog";
import MediumButton from "./MediumButton";
import { clientNameSelector } from "../../stores/selectors/appSelectors";
import disabledStyle from "./disabledStyle";
import { notifyPublishChanges } from "../../analytics/notifyCustomActions";
import { styled } from "@mui/material/styles";
import useAppStore from "../../stores/appStore";

const TrashCanButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.error.dark,
  },
  ...disabledStyle(theme),
}));

const PublishButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#373BB2",
  },
  ...disabledStyle(theme),
}));

export default ({
  inProgressOrTestIsRunning,
  setSnackbarAlert,
  handleCloseSnackbar,
}) => {
  const [publishCurrentTripletsToDraft, deleteCurrentDraft] = useAppStore(
    handleDraftTripletsSelector
  );
  const previewAssistantCreationFailed = useAppStore(
    previewAssistantCreationFailedSelector
  );
  const createAssistantForPreview = useAppStore(
    createPreviewAssistantIdSelector
  );
  const isPublishingTriplets = useAppStore(isPublishingTripletsSelector);
  const clientName = useAppStore(clientNameSelector);

  const [deleteDraftConfirmationOpen, setDeleteDraftConfirmationOpen] =
    useState(false);
  const [publishConfirmationOpen, setPublishConfirmationOpen] = useState(false);

  const handleDiscard = async () => {
    await deleteCurrentDraft();
    setDeleteDraftConfirmationOpen(false);
  };
  const handleClickPublish = async ({ changeDescription }) => {
    setSnackbarAlert({
      open: true,
      message: "Publishing changes",
      severity: SEVERITY_LEVELS.SUCCESS,
    });
    await publishCurrentTripletsToDraft(changeDescription);
    setPublishConfirmationOpen(false);
    handleCloseSnackbar(setSnackbarAlert);
  };

  return (
    <Grid
      container
      item
      xs={4}
      spacing={2}
      direction="row"
      justifyContent="flex-end"
    >
      {previewAssistantCreationFailed && (
        <Grid item>
          <MediumButton
            disabled={isPublishingTriplets}
            onClick={() => {
              createAssistantForPreview();
            }}
          >
            <Tooltip title="Retry build" placement="bottom">
              <RetryIcon fontSize="small" />
            </Tooltip>
          </MediumButton>
        </Grid>
      )}
      <Grid item>
        <TrashCanButton
          aria-label="delete"
          onClick={() => setDeleteDraftConfirmationOpen(true)}
          disabled={isPublishingTriplets}
        >
          <Tooltip title="Delete Draft" placement="bottom">
            <DeleteIcon fontSize="small" />
          </Tooltip>
        </TrashCanButton>
      </Grid>
      <Grid item>
        <PublishButton
          disabled={inProgressOrTestIsRunning}
          color="primary"
          size="medium"
          variant="contained"
          onClick={() => {
            notifyPublishChanges();
            setPublishConfirmationOpen(true);
          }}
        >
          Publish this draft
        </PublishButton>
      </Grid>
      <HyroDialog
        fieldSpec={[
          {
            inputType: INPUT_TYPES.DESCRIPTION,
            label: (
              <Box mb={5}>
                <div>
                  You are about to publish some changes on {clientName} bot.
                </div>
                <div>This will update the bot in production.</div>
              </Box>
            ),
          },
          {
            key: "changeDescription",
            inputType: INPUT_TYPES.TEXT,
            required: true,
            props: { multiline: true, rows: 2 },
            label: "Add a description for your changes",
          },
        ]}
        handleClose={() => setPublishConfirmationOpen(false)}
        handleSubmit={handleClickPublish}
        open={publishConfirmationOpen}
        submitButtonText="Submit"
        title="Publish to production"
      />

      <HyroDialog
        fieldSpec={[
          {
            inputType: INPUT_TYPES.DESCRIPTION,
            label:
              "Once you discard draft, this cannot be undone and will delete all the changes you made.",
          },
        ]}
        handleClose={() => setDeleteDraftConfirmationOpen(false)}
        handleSubmit={handleDiscard}
        open={deleteDraftConfirmationOpen}
        submitButtonText="Discard"
        title="Discard Draft?"
      />
    </Grid>
  );
};
