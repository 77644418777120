import InputWithIcons from "./InputWithIcons";
import React from "react";
import { responseInvalidFormatMessages } from "../utils";

export default ({ handleInvalidFormat, response, setResponse }) => (
  <InputWithIcons
    handleEdit={({
      event: {
        target: { value },
      },
    }) => setResponse(value)}
    initialValue={response}
    inputProps={{ multiline: true }}
    placeholder="Type the bot response here"
    computeInvalidFormatMessages={responseInvalidFormatMessages}
    setInvalidFormat={handleInvalidFormat}
  />
);
