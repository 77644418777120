import {
  any,
  empty,
  filter,
  has,
  ifElse,
  isEmpty,
  map,
  pipe,
  prop,
} from "ramda";

export const filterItemByPermission = (userPermissions, hasPermission) =>
  isEmpty(userPermissions)
    ? empty
    : pipe(
        filter(
          ifElse(
            has("children"),
            pipe(
              prop("children"),
              map(prop("requiredPermission")),
              any(hasPermission)
            ),
            pipe(prop("requiredPermission"), hasPermission)
          )
        )
      );
