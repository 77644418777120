import React from "react";

export default ({ fill }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.55 7.79005L8.50005 5.50005V14.5L11.55 12.21L14.5 10L11.55 7.79005ZM11.55 7.79005L8.50005 5.50005V14.5L11.55 12.21L14.5 10L11.55 7.79005ZM11.55 7.79005L8.50005 5.50005V14.5L11.55 12.21L14.5 10L11.55 7.79005ZM9.50005 2.07005V0.0500488C7.49005 0.250049 5.66005 1.05005 4.18005 2.26005L5.60005 3.69005C6.71005 2.83005 8.04005 2.25005 9.50005 2.07005ZM4.19005 5.10005L2.76005 3.68005C1.55005 5.16005 0.750049 6.99005 0.550049 9.00005H2.57005C2.75005 7.54005 3.33005 6.21005 4.19005 5.10005ZM2.57005 11H0.550049C0.750049 13.01 1.55005 14.84 2.76005 16.32L4.19005 14.89C3.33005 13.79 2.75005 12.46 2.57005 11ZM4.18005 17.74C5.66005 18.9501 7.50005 19.75 9.50005 19.9501V17.93C8.04005 17.75 6.71005 17.17 5.60005 16.31L4.18005 17.74ZM20.5 10C20.5 15.16 16.58 19.42 11.55 19.9501V17.93C15.47 17.41 18.5 14.05 18.5 10C18.5 5.95005 15.47 2.59005 11.55 2.07005V0.0500488C16.58 0.580049 20.5 4.84005 20.5 10Z"
      fill={fill}
      fillOpacity="1"
    />
  </svg>
);
