import React from "react";

export default () => (
  <svg
    width="65"
    height="54"
    viewBox="0 0 65 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.24862 21.6995L10.3912 13.4833L34.626 3.41342L56.599 11.8008L63.4716 18.4263L57.9039 20.2372L59.0327 38.8936L36.2865 50.8987L10.1979 39.5487L9.47174 24.351L3.24862 21.6995Z"
      fill="#A7AAF8"
    />
    <path
      d="M36.2625 49.4946L36.2684 23.469"
      stroke="#4548C4"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.7677 24.7394L27.3585 31.6672L34.5536 24.0664"
      stroke="#4548C4"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.1126 15.9318L34.5084 8.71161L52.7449 14.9809L36.1561 23.6021L16.1126 15.9318Z"
      fill="#F5F5F5"
    />
    <path
      d="M56.3314 20.1218L55.2444 13.5385L34.7539 24.4569L11.4195 14.0875"
      stroke="#4548C4"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
