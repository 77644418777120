import { KNOWLEDGE_EXPLORER, sortAlphabetically } from "../utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  always,
  any,
  applySpec,
  equals,
  filter,
  find,
  groupBy,
  head,
  ifElse,
  isEmpty,
  join,
  juxt,
  last,
  map,
  mergeAll,
  not,
  nth,
  objOf,
  pipe,
  prop,
  propEq,
  sortBy,
  toPairs,
  uniq,
  unless,
  values,
} from "ramda";
import {
  getNodeFromNodeId,
  getRelevantGraphData,
} from "../components/widgets/knowledge-explorer/Graph";
import { matchPath, useHistory } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DebounceInput from "react-debounce-input";
import FormControlLabel from "@mui/material/FormControlLabel";
import Graph from "react-graph-vis";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import { Relations } from "../triplets";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
// TODO(ENG-1977): Bring back tooltips once "react-debounce-input" has been removed.
// import Tooltip from "@mui/material/Tooltip";
import { default as gamla } from "gamlajs";
import { kgviewerService } from "../services";
import moment from "moment";
import { roomIdSelector } from "../stores/selectors/appSelectors";
import { styled } from "@mui/material/styles";
import useAppStore from "../stores/appStore";
import { useEffectAsync } from "../hooks";

const PREFIX = "KnowledgeExplorer";
const MAIN_LABEL = "main";

const classes = {
  root: `${PREFIX}-root`,
  controlsRow: `${PREFIX}-controlsRow`,
  select: `${PREFIX}-select`,
  graphPaper: `${PREFIX}-graphPaper`,
  countsText: `${PREFIX}-countsText`,
  fab: `${PREFIX}-fab`,
  paper: `${PREFIX}-paper`,
};

const Root = styled("div")(({ theme: { spacing } }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    marginLeft: spacing(3),
    marginRight: spacing(3),
    marginTop: spacing(3),
    overflow: "hidden",
  },

  [`& .${classes.controlsRow}`]: {
    padding: "5px 15px",
  },

  [`& .${classes.select}`]: {
    width: "100%",
  },

  [`& .${classes.graphPaper}`]: {
    marginTop: "15px",
    padding: "5px",
  },

  [`& .${classes.countsText}`]: {
    padding: "5px",
  },

  [`& .${classes.fab}`]: {
    margin: spacing(1),
    position: "fixed",
    right: "20px",
    bottom: "20px",
  },

  [`& .${classes.paper}`]: {
    width: "800px",
  },
}));

const CONCEPT_TYPE = "concept/type";
const HASH_LENGTH = 40;

const defaultNodeColor = "#6470b8";
const primitiveTypeToColor = {
  textual: defaultNodeColor,
  url: "#88ccee",
  date: "#44aa99",
  phone: "#117733",
  duration: "#999933",
  identifier: "#ddcc77",
  money: "#cc6677",
  location: "#f58119",
  name: "#aa4499",
  unit_size: "#aa1c3b",
};

const primitiveValueToString = (primitiveValue) =>
  `<${primitiveValue.kind}: [${primitiveValue.text}]>`;

const filterByRelation = (relation) => filter(pipe(nth(1), equals(relation)));

const displayObjs = pipe(
  filterByRelation("concept/display"),
  unless(isEmpty, pipe(head, nth(2)))
);

const makeNodes = pipe(
  groupBy(head),
  values,
  map(
    applySpec({
      id: pipe(head, head),
      group: pipe(displayObjs, ifElse(isEmpty, always(""), prop("kind"))),
      label: ifElse(
        pipe(displayObjs, isEmpty),
        pipe(head, head, (nodeId) => `<node_id: ${nodeId}>`),
        pipe(displayObjs, prop("text"))
      ),
      title: pipe(
        filterByRelation(Relations.CONCEPT_TRIGGER),
        map(pipe(nth(2), primitiveValueToString)),
        join(", "),
        (title) => `(${title})`
      ),
    })
  )
);

const makeTypeNodes = pipe(filterByRelation(CONCEPT_TYPE), map(nth(2)), uniq);

const makeEdges = pipe(
  filter(pipe(nth(1), gamla.contains(["concept/association", CONCEPT_TYPE]))),
  map(
    applySpec({
      from: head,
      to: nth(2),
    })
  )
);

const makeGraphVisJSON = pipe(
  prop("triplets"),
  applySpec({
    nodes: makeNodes,
    edges: makeEdges,
    typeNodes: makeTypeNodes,
  })
);

const parseGraphHash = (location) => {
  const match = matchPath(location.pathname, {
    path: `/${KNOWLEDGE_EXPLORER}/:roomId/:graphHash`,
    exact: true,
    strict: true,
  });
  return match ? match.params.graphHash : null;
};

const getLastKgUpdate = (graphHash) =>
  pipe(
    values,
    find(propEq("result_hash", graphHash)),
    prop("last_run_timestamp"),
    (date) => moment(date).format("MMMM Do YYYY H:mm")
  );

export default () => {
  const selectedRoomId = useAppStore(roomIdSelector);

  const groupOptions = {};
  Object.keys(primitiveTypeToColor).forEach((type) => {
    groupOptions[type] = {
      color: primitiveTypeToColor[type] || defaultNodeColor,
    };
  });

  const options = {
    layout: {
      hierarchical: false,
    },
    nodes: {
      shape: "dot",
      size: 10,
    },
    edges: {
      color: "#000000",
    },
    physics: {
      enabled: true,
      repulsion: {
        nodeDistance: 500,
        springLength: 100,
        centralGravity: 1,
      },
      solver: "repulsion",
    },
    height: "780px",
    groups: groupOptions,
  };

  const [searchText, setSearchText] = useState("");
  const [graphHash, setGraphHash] = useState("");
  const [graphData, setGraphData] = useState(null);
  const [relevantGraphData, setRelevantGraphData] = useState(null);
  const [selectedTypeNode, setSelectedTypeNode] = useState("");
  const [useExactMatch, setUseExactMatch] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [maxDistanceFromRoot, setMaxDistanceFromRoot] = useState(1);
  const [maxRank, setMaxRank] = useState(10);
  const [maxNodes, setMaxNodes] = useState(500);
  const [versionsFileObj, setVersionsFileObj] = useState({});

  const historyRef = useRef(useHistory());

  const graphList = useCallback(
    () =>
      pipe(
        toPairs,
        map(pipe(juxt([pipe(head, objOf("label")), nth(1)]), mergeAll))
      )(versionsFileObj),
    [versionsFileObj]
  );

  const graphs = versionsFileObj ? graphList(versionsFileObj) : [];

  const searchDisabled = isDataLoading || !graphData;

  const numNodesFound = relevantGraphData ? relevantGraphData.nodeIds.size : 0;
  const nodesDisplayed = relevantGraphData
    ? relevantGraphData.nodesArray.length
    : 0;

  const showGraph =
    relevantGraphData && relevantGraphData.graphData.nodes.length > 0;

  const handleDoubleClick = (event) => {
    const [nodeId] = event.nodes;
    if (nodeId) {
      onChangeSearchText(getNodeFromNodeId(graphData.nodes, nodeId).label);
      setUseExactMatch(true);
    }
  };

  const selectTypeNode = (typeNode) => {
    setSelectedTypeNode(typeNode);
    setUseExactMatch(true);
    setSearchText(typeNode);
  };

  const onChangeSearchText = (text) => {
    setSearchText(text.trim());
    setSelectedTypeNode("");
  };

  const events = {
    select(event) {
      // eslint-disable-next-line
      const { nodes, edges } = event;
    },
    doubleClick: handleDoubleClick,
  };

  useEffectAsync(async () => {
    if (!graphHash) {
      setGraphData(null);
      return;
    }

    let requestCancelled = false;
    // We want to get the graph only when the length of input is equal to 40.

    if (graphHash.length === HASH_LENGTH) {
      setIsDataLoading(true);
      const data = await kgviewerService.getGraph({
        graphHash,
      });
      if (!requestCancelled) {
        // Ignore previous request if a new one was already sent.
        setGraphData(makeGraphVisJSON(data));
      }
      setIsDataLoading(false);
    }

    return () => {
      // Gets called when this effect is re-run.
      requestCancelled = true;
    };
  }, [setGraphData, setIsDataLoading, graphHash]);

  useEffectAsync(async () => {
    setVersionsFileObj(await kgviewerService.getVersionsFile());
  }, [selectedRoomId, setVersionsFileObj]);

  useEffect(() => {
    const urlGraphHash = parseGraphHash(historyRef.current.location);
    if (
      urlGraphHash &&
      pipe(graphList, any(propEq("result_hash", urlGraphHash)))(versionsFileObj)
    ) {
      setGraphHash(urlGraphHash);
    }
  }, [setGraphHash, historyRef, graphList, versionsFileObj]);

  useEffect(() => {
    if (
      graphHash &&
      parseGraphHash(historyRef.current.location) !== graphHash &&
      pipe(graphList, any(propEq("result_hash", graphHash)))(versionsFileObj)
    ) {
      historyRef.current.replace(
        `/${KNOWLEDGE_EXPLORER}/${selectedRoomId}/${graphHash}`
      );
    }
  }, [graphHash, historyRef, selectedRoomId, graphList, versionsFileObj]);

  useEffect(() => {
    if (isEmpty(versionsFileObj)) {
      return;
    }

    if (!graphHash) {
      const mainGraphHash = pipe(
        graphList,
        filter(propEq("label", MAIN_LABEL)),
        sortBy(prop("last_run_timestamp")),
        last,
        prop("result_hash")
      )(versionsFileObj);
      setGraphHash(mainGraphHash);
    }
  }, [graphHash, graphList, setGraphHash, versionsFileObj]);

  useEffect(() => {
    if (!graphData || searchText.length === 0) {
      return;
    }

    setIsDataLoading(true);
    const relevantGraphData = getRelevantGraphData(
      graphData,
      searchText,
      useExactMatch,
      maxDistanceFromRoot,
      maxRank,
      maxNodes
    );
    setRelevantGraphData(relevantGraphData);

    setIsDataLoading(false);
  }, [
    searchText,
    useExactMatch,
    maxDistanceFromRoot,
    maxRank,
    maxNodes,
    graphData,
    setIsDataLoading,
  ]);

  return (
    <Root className={classes.root}>
      <Paper className={classes.controlsRow}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={9}>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Autocomplete
                  data-testid="graph-autocomplete"
                  classes={{
                    paper: classes.paper,
                  }}
                  options={map(prop("result_hash"))(graphs)}
                  onChange={(event, value) => {
                    setGraphHash(value ? value : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      fullWidth
                      label="Graph hash"
                      onChange={({ target: { value } }) => setGraphHash(value)}
                      variant="outlined"
                    />
                  )}
                  value={graphHash}
                  disabled={!graphHash}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  select
                  label="Types"
                  margin="normal"
                  variant="outlined"
                  value={selectedTypeNode}
                  disabled={searchDisabled}
                  onChange={({ target }) => {
                    selectTypeNode(target.value);
                  }}
                  className={classes.select}
                >
                  <MenuItem value="">
                    <em>Types</em>
                  </MenuItem>
                  {graphData &&
                    sortAlphabetically(graphData.typeNodes).map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <DebounceInput
                  element={TextField}
                  disabled={searchDisabled}
                  minLength={2}
                  debounceTimeout={1000}
                  margin="normal"
                  fullWidth
                  label="Search"
                  variant="outlined"
                  value={searchText}
                  onChange={({ target: { value } }) =>
                    onChangeSearchText(value)
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={useExactMatch}
                      onChange={({ target: { checked } }) =>
                        setUseExactMatch(checked)
                      }
                      name="exactMatchSwitch"
                      color="primary"
                    />
                  }
                  label="Exact match"
                />
              </Grid>
              <Grid item xs={1}>
                {isDataLoading && (
                  <CircularProgress size={24} color="secondary" />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid
              container
              spacing={3}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs={4}>
                {/* <Tooltip title="Max number of nodes to draw" placement="bottom">*/}
                <DebounceInput
                  element={TextField}
                  type="number"
                  debounceTimeout={1000}
                  id="outlined-basic"
                  label="Max nodes"
                  variant="outlined"
                  margin="normal"
                  value={maxNodes}
                  onChange={({ target: { value } }) => setMaxNodes(value)}
                />
                {/* </Tooltip>*/}
              </Grid>
              <Grid item xs={4}>
                {/* <Tooltip*/}
                {/*  title="Show only nodes that have up to this amount of neighbors"*/}
                {/*  placement="bottom"*/}
                {/* > */}
                <DebounceInput
                  element={TextField}
                  type="number"
                  debounceTimeout={1000}
                  id="outlined-basic"
                  label="Max rank"
                  variant="outlined"
                  margin="normal"
                  value={maxRank}
                  onChange={({ target: { value } }) => setMaxRank(value)}
                />
                {/* </Tooltip>*/}
              </Grid>
              <Grid item xs={4}>
                {/* <Tooltip*/}
                {/*  title="Show only nodes at maximum distance from root"*/}
                {/*  placement="bottom"*/}
                {/* >*/}
                <DebounceInput
                  element={TextField}
                  type="number"
                  debounceTimeout={1000}
                  id="outlined-basic"
                  label="Max distance"
                  variant="outlined"
                  margin="normal"
                  value={maxDistanceFromRoot}
                  onChange={({ target: { value } }) =>
                    setMaxDistanceFromRoot(value)
                  }
                />
                {/* </Tooltip>*/}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={classes.countsText}>
            <Box>
              {relevantGraphData &&
                searchText !== "" &&
                numNodesFound === 0 && (
                  <div>No nodes with "{searchText}" were found.</div>
                )}
              {relevantGraphData && searchText !== "" && numNodesFound > 0 && (
                <div>
                  Found {numNodesFound} nodes for "{searchText}"
                  {nodesDisplayed !== numNodesFound
                    ? `(displaying ${nodesDisplayed})`
                    : ""}
                  .
                </div>
              )}
              {graphData && pipe(isEmpty, not)(versionsFileObj) && (
                <div>
                  Last KG update: {getLastKgUpdate(graphHash)(versionsFileObj)},
                  total nodes: {graphData.nodes.length}, edges:{" "}
                  {graphData.edges.length}, types: {graphData.typeNodes.length}.
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {showGraph && (
        <Paper className={classes.graphPaper}>
          <Grid container>
            <Grid item xs={12}>
              <Graph
                graph={relevantGraphData.graphData}
                options={options}
                events={events}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
    </Root>
  );
};
