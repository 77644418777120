import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { always, objOf, when } from "ramda";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import OptionsDialog from "./OptionsDialog";
import OptionsWithTextDialog from "./OptionsWithTextDialog";
import { RESULT_CARD_CONFIG_DIALOG_TYPES } from "./Constants";

export default ({
  name,
  value,
  text,
  options,
  nullable,
  onSave,
  setInFocus,
  dialogType,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogProps = {
    name,
    value,
    nullable,
    options,
    onClose: () => setDialogOpen(false),
  };
  return (
    <>
      <ListItem
        sx={when(
          always(value),
          objOf("&:hover")
        )({
          backgroundColor: "#0000000A",
          cursor: "pointer",
        })}
        onMouseEnter={() => setInFocus(name)}
        onMouseLeave={() => setInFocus("")}
        onClick={() => setDialogOpen(true)}
      >
        <ListItemText
          primary={
            <Typography
              variant="body1"
              sx={{
                color: value ? "rgba(0,0,0,0.87)" : "rgba(0,0,0,0.32)",
              }}
            >
              {name}
            </Typography>
          }
          secondary={
            <Typography
              variant="inherit"
              sx={{
                color: value ? "rgba(0,0,0,0.54)" : "rgba(0,0,0,0.32)",
                textTransform: "capitalize",
              }}
            >
              {value || "Hidden"}
            </Typography>
          }
        />
        <Box paddingRight={1}>
          <IconButton
            onClick={() => setDialogOpen(true)}
            edge="end"
            aria-label="edit"
            size="large"
          >
            <CreateOutlinedIcon />
          </IconButton>
        </Box>
      </ListItem>
      {dialogOpen &&
        (dialogType === RESULT_CARD_CONFIG_DIALOG_TYPES.OPTIONS_WITH_TEXT ? (
          <OptionsWithTextDialog
            {...dialogProps}
            text={text}
            onSave={async (newValue, newText) => {
              await onSave(newValue, newText);
              setDialogOpen(false);
            }}
          />
        ) : (
          <OptionsDialog
            {...dialogProps}
            onSave={async (newValue) => {
              await onSave(newValue);
              setDialogOpen(false);
            }}
          />
        ))}
    </>
  );
};
