import { Button, Grid } from "@mui/material";
import HyroTabs from "./HyroTabs";
import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "HyroTabsWithTitleAndButton";

const classes = {
  actionAlignment: `${PREFIX}-actionAlignment`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.actionAlignment}`]: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "flex-start",
  },
}));

export default ({
  title,
  handleClick,
  buttonDisabled,
  buttonText,
  tabsSpec,
}) => (
  <HyroTabs
    headerComponent={
      <StyledGrid
        container
        spacing={10}
        direction="row"
        justifyContent="space-between"
      >
        <Grid item xs={5}>
          <h1>{title}</h1>
        </Grid>
        <Grid item xs={5} className={classes.actionAlignment}>
          <Button
            disabled={buttonDisabled}
            color="primary"
            size="medium"
            variant="contained"
            onClick={handleClick}
          >
            {buttonText}
          </Button>
        </Grid>
      </StyledGrid>
    }
    tabsSpec={tabsSpec}
  />
);
