import { Paper, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import { head, pipe, prop } from "ramda";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const PREFIX = "HyroTabs";

const classes = {
  content: `${PREFIX}-content`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.content}`]: {
    height: 500,
    padding: "1%",
  },
}));

export default ({ headerComponent, tabsSpec }) => {
  const [activeTab, setActiveTab] = useState(
    pipe(head, prop("value"))(tabsSpec)
  );

  const handleChange = (event, newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  return (
    <StyledBox mx={4}>
      {headerComponent}
      <TabContext value={activeTab}>
        <Box borderBottom="1px solid #b5b5b5" mt={3}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            {tabsSpec.map(({ label, value }) => (
              <Tab label={label} value={value} key={value} />
            ))}
          </Tabs>
        </Box>
        {tabsSpec.map(({ content, label, value }) => (
          <TabPanel value={value} key={value}>
            {content ?? (
              <Paper className={classes.content}>{label} content</Paper>
            )}
          </TabPanel>
        ))}
      </TabContext>
    </StyledBox>
  );
};
