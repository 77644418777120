import { ADMIN } from "../../utils";
import { Chip } from "@mui/material";
import HyroLogo from "./HyroLogo";
import React from "react";
import { styled } from "@mui/material/styles";
import svgWhiteLogo from "../../static/images/logo_white.svg";

const StyledChip = styled(Chip)(() => ({
  backgroundColor: "white",
  marginLeft: "10px",
  color: "#EF4269",
  letterSpacing: "0.16px",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "18px",
  minWidth: "45px",
  height: "20px",
  padding: "12px 7px",
  ".MuiChip-label": {
    padding: "1px 2px",
  },
}));

export default () => (
  <>
    <HyroLogo redirectTo={`/${ADMIN}`} src={svgWhiteLogo} />
    <StyledChip label="Admin" />
  </>
);
