import { Box, TextField } from "@mui/material";
import { T, always, cond, equals, map } from "ramda";
import AddPhoneNumber from "./AddPhoneNumber";
import ColorPicker from "./ColorPicker";
import CommaInput from "./CommaInput";
import DropDown from "../../hyro-components/DropDown";
import { INPUT_TYPES } from "../../../utils";
import ImageUploader from "./ImageUploader";
import OnOff from "./OnOff";
import React from "react";

export default ({ fieldList, getFieldValue, handleUpdateField }) => (
  <>
    {
      <Box display="flex" flexDirection="column" my="10px">
        {map(
          ({
            name,
            type,
            options,
            path,
            validate,
            text,
            element,
            width,
            height,
          }) => {
            const props = {
              key: name,
              label: name,
              value: getFieldValue(path) || "",
              onChange: ({ target: { value } }) =>
                handleUpdateField(path)(value),
              ...(validate && { validate }),
            };
            return (
              <Box my="10px">
                {cond([
                  [
                    equals(INPUT_TYPES.TEXT),
                    always(
                      <TextField
                        {...props}
                        variant="outlined"
                        sx={{ width: "100% " }}
                      />
                    ),
                  ],
                  [
                    equals(INPUT_TYPES.DESCRIPTION),
                    always(
                      <TextField
                        {...props}
                        variant="outlined"
                        multiline
                        rows={3}
                        sx={{ width: "100% " }}
                      />
                    ),
                  ],
                  [equals(INPUT_TYPES.BOOLEAN), always(<OnOff {...props} />)],
                  [
                    equals(INPUT_TYPES.COLOR),
                    always(<ColorPicker {...props} label={name} />),
                  ],
                  [
                    equals(INPUT_TYPES.DROPZONE),
                    always(
                      <ImageUploader
                        {...props}
                        uploadNote={text}
                        width={width}
                        height={height}
                      />
                    ),
                  ],
                  [
                    equals(INPUT_TYPES.ADD_ONE),
                    always(<AddPhoneNumber {...props} text={text} />),
                  ],
                  [
                    equals(INPUT_TYPES.DROPDOWN),
                    always(<DropDown {...props} options={options} />),
                  ],
                  [
                    equals(INPUT_TYPES.COMMA_SEPARATED_ARRAY),
                    always(<CommaInput {...props} />),
                  ],
                  [
                    T,
                    always(
                      <React.Fragment key={name}>{element}</React.Fragment>
                    ),
                  ],
                ])(type)}
              </Box>
            );
          }
        )(fieldList)}
      </Box>
    }
  </>
);
