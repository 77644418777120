import { Box, FormControlLabel, Radio } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const CustomRadio = styled(Radio)(() => ({
  color: "primary",
  padding: "0px",
  marginTop: "9px",
  marginLeft: "9px",
  marginRight: "9px",
}));

const OptionHelperText = styled(Box)(() => ({
  fontWeight: 400,
  color: "#2D2C44B2",
  fontSize: "12px",
  paddingBottom: "15px",
}));

export default ({ disabled, value, helperText }) => (
  <>
    <FormControlLabel
      key={value}
      value={value}
      disabled={disabled}
      control={<CustomRadio />}
      label={
        <Typography variant="inherit" style={{ textTransform: "capitalize" }}>
          {value}
        </Typography>
      }
    />
    <Box pl="32px">
      <OptionHelperText>{helperText}</OptionHelperText>
    </Box>
  </>
);
