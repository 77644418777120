import {
  Relations,
  relationEq,
  subjectEq,
  subjectIn,
} from "../../../../triplets";
import {
  addIndex,
  always,
  append,
  assoc,
  assocPath,
  both,
  concat,
  either,
  indexBy,
  juxt,
  map,
  pipe,
  prop,
  propEq,
  reduce,
  reject,
  values,
} from "ramda";
import BotEditorAddButton from "../AddButton";
import Box from "@mui/material/Box";
import EventRow from "./EventRow";
import EventTypes from "../../overview/conversation/EventTypes";
import Grid from "@mui/material/Grid";
import React from "react";
import useAppStore from "../../../../stores/appStore";
import { userPermissionsSelector } from "../../../../stores/selectors/appSelectors";
import { v4 as uuidv4 } from "uuid";

export default ({
  pendingUpdatedTriplets,
  rowContent,
  setInvalidFormat,
  setPendingUpdatedTriplets,
  setRowContent,
}) => {
  const actionKey = rowContent.response.key;

  const { isAdmin } = useAppStore(userPermissionsSelector);

  const handleNewEvent = () => {
    const newEventObj = {
      key: `${uuidv4()}_event`,
      type: EventTypes.EXTERNAL,
      data: "",
    };
    const updatedRowContent = {
      ...rowContent,
      events: pipe(prop("events"), append(newEventObj))(rowContent),
    };
    setRowContent(updatedRowContent);
    updateEventsInTriplets(updatedRowContent);
  };

  const handleEditEventType = ({ value: newEventObj }) => {
    const updatedRowContent = {
      ...rowContent,
      events: pipe(
        prop("events"),
        indexBy(prop("key")),
        assoc(newEventObj.key, newEventObj),
        values
      )(rowContent),
    };
    setRowContent(updatedRowContent);
    updateEventsInTriplets(updatedRowContent);
  };

  const handleEditEventData =
    (eventKey) =>
    ({
      event: {
        target: { value },
      },
    }) => {
      const updatedRowContent = {
        ...rowContent,
        events: pipe(
          prop("events"),
          indexBy(prop("key")),
          assocPath([eventKey, "data"], value),
          values
        )(rowContent),
      };
      setRowContent(updatedRowContent);
      updateEventsInTriplets(updatedRowContent);
    };

  const handleDeleteEvent = (eventObj) => () => {
    const updatedRowContent = {
      ...rowContent,
      events: pipe(
        prop("events"),
        reject(propEq("key", eventObj.key))
      )(rowContent),
    };
    setRowContent(updatedRowContent);
    updateEventsInTriplets(updatedRowContent);
  };

  const updateEventsInTriplets = (updatedRowContent) => {
    const actionToEvent = juxt([
      always(actionKey),
      always(Relations.ACTION_EVENT),
      prop("key"),
    ]);

    const eventToEventType = juxt([
      prop("key"),
      always(Relations.EVENT_TYPE),
      prop("type"),
    ]);

    const eventToEventData = juxt([
      prop("key"),
      always(Relations.EVENT_DATA),
      prop("data"),
    ]);

    const newTriplets = pipe(
      map(juxt([actionToEvent, eventToEventType, eventToEventData])),
      reduce(concat, [])
    )(updatedRowContent.events);

    const eventKeys = map(prop("key"))(rowContent.events);
    setPendingUpdatedTriplets(
      pipe(
        reject(
          either(
            both(relationEq(Relations.ACTION_EVENT), subjectEq(actionKey)),
            subjectIn(eventKeys)
          )
        ),
        concat(newTriplets)
      )(pendingUpdatedTriplets)
    );
  };

  return (
    <Box maxHeight="330px">
      <Grid container direction="column" justifyContent="flex-end">
        {pipe(
          prop("events"),
          addIndex(map)((event, idx) => (
            <EventRow
              isAdmin={isAdmin}
              eventObj={event}
              idx={idx}
              handleEditEventType={handleEditEventType}
              handleEditEventData={handleEditEventData}
              handleDeleteEvent={handleDeleteEvent}
              setInvalidFormat={setInvalidFormat}
            />
          ))
        )(rowContent)}
        <Grid item>
          <Box pb={4}>
            <BotEditorAddButton
              handleCreate={handleNewEvent}
              label="Add a new event"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
