import ApiIcon from "../../../static/images/api.svg";
import { Box } from "@mui/material";
import { DATA_SOURCE_TYPES } from "../../../utils";
import FileIcon from "../../../static/images/file.svg";
import ManualIcon from "../../../static/images/manual.svg";
import React from "react";

const DATA_SOURCE_TYPE_TO_ICON = {
  [DATA_SOURCE_TYPES.EXTERNAL_FILE]: FileIcon,
  [DATA_SOURCE_TYPES.API]: ApiIcon,
  [DATA_SOURCE_TYPES.MANUAL]: ManualIcon,
};

export default ({ type }) => (
  <Box
    sx={{
      backgroundColor: "#4548C414",
      width: "48px",
      height: "48px",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <img src={DATA_SOURCE_TYPE_TO_ICON[type]} />
  </Box>
);
